<template>
  <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm" class="bg-white p-3">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item class="w-100" prop="name" label="Tên phòng học">
          <el-input v-model="form.name" placeholder="Tên phòng học"/>
        </el-form-item>
        <el-form-item class="w-100" prop="code" label="Code">
          <el-input v-model="form.code" placeholder="Code"/>
        </el-form-item>
        <el-form-item class="w-100" prop="branch" label="Chi nhánh">
          <el-select class="w-100" v-model="form.branch" placeholder="Chọn chi nhánh">
            <el-option
                v-for="item in listBranch"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import {textInputValidate} from "@/validator";
import configs from "@/configs";
import {NameRouter} from "@/constants/nameRouter";
import {GetListFacility} from "@/services/facility";
import {notifyErr} from "@/mixins/utils";


export default {
  expose: ['validForm'],
  name: "InfoRoom",
  components: {},
  props: {
    isDetail: {type: Boolean, default: false},
    form: {},
  },
  data() {
    return {
      validation: false,
      addContentRule: {
        branch: [textInputValidate],
        name: [textInputValidate],
        code: [textInputValidate],
      },
      listBranch: [],
      configs: configs,
      NameRouter: NameRouter,
      loading: false,
    }
  },
  created() {
    this.getListBranch();
  },
  methods: {
    async getListBranch() {
      this.loading = true;
      GetListFacility().then(res => {
        if (res.data.status == 'success') {
          this.listBranch = res.data.data.listBranch.data
        }
        this.loading = false;
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
        this.loading = false
      })
    },
    async validForm() {
      return await this.$refs.contentForm.validate((valid, fields) => {
        return !valid;
      })
    },
  }
}
</script>
<script setup>
import {Delete, Plus, Refresh} from '@element-plus/icons-vue'
</script>
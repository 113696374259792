<template>
  <div class="search bg-white p-3 mb-4">
    <div class="row">
      <!-- <div class="col-3 offset-6" style="display:flex">
        <batchSelect v-model="searchParams.batch_id" :show-label="false" :is-multiple="false"></batchSelect>

        <el-col  class="pb-3">
        <el-input placeholder="Tìm kiếm tên học sinh" v-model="setup.search_name" />
      </el-col> -->
      <!-- </div>  -->
      <div class="py-2 text-end">
        <el-row :gutter="20" :align="10">
          <el-col :span="6" class="pb-3">
            <el-input v-model="searchParams.keyword" placeholder="Tìm kiếm tên bài kiểm tra" />
          </el-col>
          <el-col :span="6" class="pb-3">
            <batchSelect v-model="searchParams.batch_id" :show-label="false" :is-multiple="false"></batchSelect>
          </el-col>
          <el-col :span="6" class="pb-3">
            <courseSelect v-model="searchParams.course_id" :showLabel="false" />
          </el-col>
          <el-col :span="6" class="pb-3">
            <el-select placeholder="Tìm kiếm trạng thái" class="w-100" v-model="searchParams.is_hackathon">
              <el-option label="Tất cả" :value="null" ></el-option>
              <el-option  label="Bài kiểm tra Hackathon" :value="0" ></el-option>
              <el-option  label="Bài kiểm tra cuối module" :value="1"></el-option> 
            </el-select>
          </el-col>


          <el-button type="primary mr-2" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm</el-button>
          <el-button type="primary" @click="reload()" :icon="Refresh">Làm mới</el-button>


        </el-row>
      </div>


    </div>
  </div>
  <div id="managementBatches" class="bg-white p-2">
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading">
      <el-table-column prop="fromDate" label="TG bắt đầu" min-width="150" />
      <el-table-column prop="toDate" label="TG kết thúc" min-width="150" />
      <el-table-column prop="title" label="Tên bài kiểm tra" min-width="150" />

      <!-- <el-table-column prop="deadline" label="TG chậm nhất" min-width="150" /> -->
      <el-table-column prop="nameBatch" label="Tên lớp" min-width="100" />
      <el-table-column prop="nameCourse" label="Tên module" min-width="120" />
      <el-table-column prop="string_type" label="Bài kiểm tra" min-width="120" />
      <el-table-column label="Trạng thái" min-width="120">
        <template #default="scope">
          <div class="pl-3">
            <el-checkbox :disabled="true" :checked="scope.row.isActive === configsSetUp.status.active" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Hành động" min-width="200">
        <template #default="scope">
          <div class="d-flex">
            <handle-button eventCode="assignments.show" icon="fa fa-edit" typeButton="primary"
              @click="setData(scope.row)" />

            <handle-button type="link" eventCode="assignments.show" icon="fa fa-user-plus" typeButton="primary"
              :to="{ name: nameRouter.PAGES.GRADING_TEACHER, params: { id: scope.row.id } }" />
            <handle-button type="link" eventCode="assignments.show" icon="fa fa-marker" typeButton="primary" title="Chấm điểm"
              :to="{ name: nameRouter.PAGES.STUDENT_LIST, params: { id: scope.row.batch_id }, query: { assignmentId: scope.row.id } }" />
            <handle-button eventCode="assignments.destroy" icon="fa fa-trash" typeButton="danger"
              @click="openConfirm(scope.row)" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination v-show="isShowPagination" :total="total" :page-index="pageIndex" @currentPageChange="nextPage">
      </pagination>
    </div>
  </div>
  <confirm v-model="setup.confirmDialog" @deleteService="deleteAssignment" @close="setup.confirmDialog = false" />
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import { formatDateTime, notifyErr, notifySuccess, setBreadcrumb } from "@/mixins/utils";
import { checkSpecialCharacter, textInputValidate } from "@/validator";
import { NameRouter } from "@/constants/nameRouter";
import { DeleteAssignment, GetAssignment } from "@/services/common";
import batchSelect from "@/views/Batches/batchSelect";
import confirm from "@/components/confirm";
import courseSelect from "@/views/Course/courseSelect.vue";
export default {
  name: "ListAssignment",
  mixins: [setBreadcrumb],
  components: {
    pagination,
    courseSelect,
    // batchSelect,
    confirm
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogVisible: false,
      dialogAdd: false,
      infoStudent: {},
      model: {
        end_date: "",
        start_date: "",
        name: "",
        roadmap_id: "",

      },
      rulesLogin: {
        end_date: [textInputValidate],
        start_date: [textInputValidate],
        name: [textInputValidate, checkSpecialCharacter],
        roadmap_id: [textInputValidate]
      },
      nameRouter: NameRouter,
      roadmaps: [],
      searchParams: {
        batch_id: null,
        course_id: null,
        keyword: null,
        is_hackathon:null,
      },
      setup: {
        confirmDialog: false,
      },
      deleteId: '',

    };
  },


  created() {
    this.getList()
  },
  methods: {
    getList(params) {
      this.loading = true
      if (!params) {
        params = { 
          per_page: configs.itemOnPage, 
          page: this.currentPage, 
          batch_id: this.searchParams.batch_id, 
          course_id: this.searchParams.course_id, 
          keyword: this.searchParams.keyword,
       
        }
      }
      GetAssignment(params).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.assignments.data.map(e => {
            dataTable.push({
              id: e.id,
              nameBatch: e.batch ? e.batch.batch_name : '',
              nameCourse: e.course ? e.course.title : '',
              title: e.title,
              toDate: formatDateTime(e.to_date),
              batch_id: e.batch ? e.batch_id : '',
              fromDate: formatDateTime(e.from_date),
              deadline: formatDateTime(e.deadline),
              isActive: e.is_active,
              is_hackathon:e.is_hackathon,
              string_type: e.is_hackathon === 0 ? "hackathon" : "cuối module"
            })
          })
     
          this.tableData = dataTable;
          this.total = e.data.data.assignments.total
          this.isShowPagination = e.data.data.assignments.total > configs.itemOnPage
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = {
        per_page: configs.itemOnPage,
        page: this.currentPage,
        keyword: this.searchParams.keyword,
        batch_id: this.searchParams.batch_id,
        course_id: this.searchParams.course_id,
      }
      this.getList(params);
    },
    searchData() {
      let params = {
        per_page: configs.itemOnPage,
        page: 1,
        keyword: this.searchParams.keyword,
        batch_id: this.searchParams.batch_id,
        course_id: this.searchParams.course_id,
      }

      this.getList(params)
    },
    reload() {
      location.reload();
    },
    setData(data) {
      this.$helpers.setContentAssignment(data)
      this.$router.push({ name: NameRouter.PAGES.ASSIGNMENT_DETAIL, params: { id: data.id } })
    },
    openConfirm(data) {
      this.setup.confirmDialog = true
      this.deleteId = data.id
    },
    deleteAssignment() {
      DeleteAssignment(this.deleteId).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('delete')
          this.getList()
          this.setup.confirmDialog = false
        }
      }).catch(err => {
        notifyErr(err.response.messages)
      })
    }
  }
}
</script>
<script setup>
import { Refresh } from '@element-plus/icons-vue'
import configsSetUp from "@/configs";
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

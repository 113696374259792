<template>
  <router-view></router-view>
</template>
<script>
import {setBreadcrumb} from "@/mixins/utils";

export default {
  mixins: [setBreadcrumb],
  name: 'HomeBatch'
}
</script>
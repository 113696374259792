<template>
  <moduleCheckBox :show-checkbox="false"></moduleCheckBox>
</template>
<script>
import moduleCheckBox from "@/views/ManagementModule/moduleCheckBox";
import {setBreadcrumb} from "@/mixins/utils";

export default {
  name: 'List-Module',
  components: {
    moduleCheckBox
  },
  mixins: [setBreadcrumb],
}
</script>
<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
    <el-tab-pane label="Thêm mới module" name="first">
      <infoModule ref="moduleInfo" :info-form="formModule" @resetContent="resetContent"></infoModule>
      <div class="text-center py-4">
        <handle-button
            label="Tạo module"
            eventCode="modules.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createdModule"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane label="Thêm mới nội dung" name="content">
      <courseCheckbox ref="courseListModule" v-model="courseId"></courseCheckbox>
      <div class="text-center py-4">
        <handle-button
            label="Tạo module"
            eventCode="modules.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createdModule"
        />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import infoModule from "@/views/ManagementModule/infoModule";
import courseCheckbox from "@/views/Course/courseCheckbox";
import {CreateModule} from "@/services/course"
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'Create-Module',
  components: {
    infoModule,
    courseCheckbox,
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      formModule: {
        title: '',
        code:'',
        duration: '',
        description: '',
        image: '',
      },
      courseId: [],
      setup:{
        activeName: 'first',
      },
      nameRouter: NameRouter
    }
  },
  methods: {
    async createdModule() {
      let vailForm = await this.$refs.moduleInfo.validForm()
      if (!vailForm){
        return false
      }
      let valid =  await this.$refs['courseListModule'].validCourse()
      if (!valid ) {
        this.setup.activeName = 'content'
        return false
      }
      let courses = [];
      this.courseId.map((item, index) => {
        courses.push({id: item, sort_order: index})
      })
      this.formModule.courses = courses

      CreateModule(this.formModule).then(res => {
        if (res.data.status == 'success') {
          notifySuccess()
          this.$router.push(NameRouter.PAGES.LIST_MODULE)
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
      event.preventDefault()
    },

    resetContent() {
      this.courseId = []
    }
  }
}
</script>
<script setup>
import {Check} from '@element-plus/icons-vue'

</script>
<template>
  <el-form :model="form" :rules="formRule" ref="courseForm" label-width="120px" label-position="top">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="text-bold">Ảnh đại diện<span class="text-danger">*</span></div>
        <div class="py-3">
          <el-form-item prop="avatar">
            <el-upload
                :limit="1"
                class="avatar-uploader"
                :class="imageUrl? 'disable-event': '' "
                :before-upload="beforeAvatarUpload"
                ref="avatar"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar">
              <el-icon v-if="!imageUrl" class="avatar-uploader-icon">
                <Plus/>
              </el-icon>
            </el-upload>
          </el-form-item>
          <div class="ml-lg-5 pl-lg-5 pl-md-5" v-if="imageUrl">
            <el-button type="danger" size="small" class="p-1">
              <el-icon>
                <Delete/>
              </el-icon>
              <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>
            </el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="Tên khóa học" prop="name">
              <el-input v-model="form.name"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Mã code" prop="code">
              <el-input v-model="form.code"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Mô tả ngắn" prop="summary">
              <el-input v-model="form.summary"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="row">
              <div class="col-9">
                <subjectSelected
                    ref="subjectSelect"
                    @selected="subjectId"
                    v-model="form.subject"
                    :validation="validateSubject"
                />
              </div>
              <div class="col-3" style="align-self: center">
                <el-button class="mt-3" :icon="Plus" @click="setup.createSubjectDialog = true"></el-button>
                <el-button class="mt-3" :icon="Refresh" @click="reloadSubject"></el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Thời gian học (phút)" prop="duration">
              <el-input v-model="form.duration"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Độ ưu tiên (khi tìm kiếm)" prop="duration">
              <el-input v-model="form.position"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Phiên bản" prop="version">
              <el-input v-model="form.version"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Cấp độ" prop="level">
              <el-input v-model="form.level"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Nội dung loại" prop="type" @change="resetContent">
              <el-radio-group v-model="form.type" :disabled="isUpdate">
                <el-radio :label="hasSection" size="lage" border>Có chương học</el-radio>
                <el-radio :label="notSection" size="lage" border>Không chương học</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Trạng thái" prop="isActive" @change="resetContent">
              <el-radio-group v-model="form.isActive">
                <el-radio :label="configs.status.active" size="lage" border>{{ configs.status.activeStr }}
                </el-radio>
                <el-radio :label="configs.status.inActive" size="lage" border>{{ configs.status.inActiveStr }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Bạn sẽ học" class="text-bold" prop="whatLearn">
              <div class="border w-100 py-2 px-1 rounded">
                <el-tag
                    v-for="tag in form.whatLearn"
                    :key="tag"
                    class="mx-1"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'whatLearn')"
                >
                  {{ tag }}
                </el-tag>
                <input
                    v-if="whatLearnVisible"
                    ref="whatLearn"
                    id="whatLearn"
                    class="ml-1 w-25"
                    size="small"
                    @keyup.enter="handleInputConfirm('whatLearn')"
                    @blur="handleInputConfirm('whatLearn')"
                />
                <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput('whatLearn')">
                  + Thêm môn học
                </el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Yêu cầu cơ bản" class="text-bold" prop="requirement">
              <div class="border w-100 py-2 px-1 rounded">
                <el-tag
                    v-for="tag in form.requirement"
                    :key="tag"
                    class="mx-1"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'requirement')"
                >
                  {{ tag }}
                </el-tag>
                <input
                    v-if="requirementVisible"
                    ref="requirement"
                    id="requirement"
                    class="ml-1 w-25"
                    @keyup.enter="handleInputConfirm('requirement')"
                    @blur="handleInputConfirm('requirement')"
                />
                <el-button v-show="!requirementVisible" class="button-new-tag ml-1" size="small"
                           @click="showInput('requirement')">
                  + Thêm yêu cầu
                </el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Cho học thử">
              <el-switch v-model="form.isFree" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24">
        <div :class="{'hasErr': descriptionValid}">
          <editorTiny v-model="form.description"></editorTiny>
          <small style="color: #f56c6c" v-if="descriptionValid">{{ messages.NULL }}</small>
        </div>
      </el-col>
    </el-row>
  </el-form>
  <createSubject v-model="setup.createSubjectDialog"></createSubject>
</template>

<script>
import subjectSelected from "@/components/subjectSelected";
import editorTiny from "@/components/editor";
import {checkIsInteger, textInputValidate} from "@/validator";
import {isImage, notifyFiled} from "@/mixins/utils";
import {UploadFile} from "@/services/common";
import createSubject from "@/views/MangementSubject/createSubject";

export default {
  name: "InfoCourse",
  expose: ['validForm', 'setImage', 'searchSubject'],
  components: {
    subjectSelected,
    editorTiny,
    createSubject
  },
  props: {
    form: Object,
    isUpdate: {type: Boolean, default: false}
  },
  created() {
  },
  data() {
    return {
      descriptionValid: false,
      formRule: {
        name: [textInputValidate],
        code:[textInputValidate],
        summary: [textInputValidate],
        subject: [textInputValidate],
        type: [textInputValidate],
        avatar: [textInputValidate],
        roadmapId: [textInputValidate],
        level: [textInputValidate, checkIsInteger],
        version: [textInputValidate],
        duration: [textInputValidate, checkIsInteger],
        position: [textInputValidate, checkIsInteger],
      },
      hasSection: 1,
      notSection: 2,
      whatLearnVisible: false,
      requirementVisible: false,
      whatLearnInput: null,
      requirementInput: null,
      validateSubject: false,
      editor: null,
      imageUrl: '',
      setup: {
        createSubjectDialog: false
      },

    }
  },
  methods: {
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled()
        return false
      }
      this.imageUrl = URL.createObjectURL(uploadFile)
      this.file = await uploadFile
      await this.uploadFile()
      return true
    },
    async uploadFile() {
      let formData = new FormData
      formData.append('file', this.file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          this.form.avatar = e.data.data.filePath
          path = e.data.data.filePath
        }
        return path
      })
    },
    handleInputConfirm(type) {
      if (this.whatLearnVisible || this.requirementVisible) {
        let inputValue = document.getElementById(type).value;
        if (inputValue) {
          this.form[type].push(inputValue);
        }
        document.getElementById(type).focus()
        document.getElementById(type).value = '';
      }
      if (type === 'whatLearn') {
        this.whatLearnVisible = false;
      }
      if (type === 'requirement') {
        this.requirementVisible = false;
      }

    },
    handleClose(tag, type) {
      this.form[type].splice(this.form[type].indexOf(tag), 1);
    },
    showInput(type) {
      if (type === 'whatLearn') {
        this.whatLearnVisible = true;
      }
      if (type === 'requirement') {
        this.requirementVisible = true;
      }

    },
    handleRemove() {
      this.$refs.avatar.clearFiles()
      this.imageUrl = ''
    },
    resetContent() {
      if (this.form.type == 1) {
        this.sectionForm = [
          {
            name: '',
            duration: '',
            position: '',
            isActive: 1,
            id: '1',
            materials: [{
              id: 1,
              name: '',
              position: '',
              file: '',
              isActive: true,
              msg: '',
              msgNum: '',
              valid: false,
              validNum: false,
            }]
          }
        ]
      }
      if (this.sectionForm == 2) {
        this.materials = [{
          id: 1,
          name: '',
          position: '',
          file: 0,
          isActive: true,
          msg: '',
          msgNum: '',
          valid: false,
          validNum: false,
        }]
      }
    },
    subjectId(val) {
      this.form.subject = val
    },
    async validForm() {
      this.descriptionValid = this.form.description === ''
      this.validateSubject = this.form.subject === ''

      let validCourse = await this.$refs.courseForm.validate((valid, fields) => {
        if (!valid || this.form.validateSubject || this.descriptionValid) {
          return false;
        }
      });
      return validCourse
    },
    setImage(url) {
      this.imageUrl = url
    },
    searchSubject(keyword) {
      this.$refs.subjectSelect.searchSubject(keyword)
    },
    reloadSubject() {
      this.$refs.subjectSelect.reloadSubject()
    }
  },
}
</script>
<script setup>
import {Delete, Plus, Refresh} from '@element-plus/icons-vue'
import {ref, reactive} from "vue";
import messages from "@/constants/messages";
import configs from "@/configs";

const requirement = ref()
const whatLearn = ref()
const avatar = ref()
const input = ref('')
</script>

<style scoped lang="scss">
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.avatar-uploader .avatar {
  max-width: 300px;
  max-height: 300px;
  min-height: 250px;
  min-width: 250px;
  display: block;
  position: relative;
}

.action-image {
  background: #000000b5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  pointer-events: none;
  display: none;

  svg {
    color: white;
  }

  .action-delete {
    font-size: 18px;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
}

::v-deep .el-upload:hover {
  .action-image {
    display: block;
  }
}

.hasErr {
  ::v-deep .tox {
    border: 1px solid red;
  }
}
</style>

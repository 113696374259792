<template>
  <div id="managementBatches" class="bg-white p-3">
    <div class="py-2 text-end">
      <el-button
          type="primary"
          :icon="Plus"
          @click="dialogAdd= true"
      >
        Thêm mới lớp học
      </el-button>
    </div>
    <div class="box-search">
      <div class="">
        <i>Tìm kiếm theo mục đích</i>
      </div>
      <form onsubmit="searchData()" v-on:keyup.enter="searchData">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-input v-model="setup.search_name" placeholder="Tìm kiếm tên bài học"/>
          </el-col>
          <el-col :span="6">
            <el-input v-model="setup.code" placeholder="Tìm kiếm mã code"/>
          </el-col>
          <el-col :span="6" class="">
            <el-select
                placeholder="Tìm kiếm trạng thái"
                class="w-100"
                v-model="setup.filter_active"
            >
              <el-option :value="null" label="Tất cả"/>
              <el-option
                  :value="configs.status.active"
                  :label="configs.status.activeStr"
              />
              <el-option
                  :value="configs.status.inActive"
                  :label="configs.status.inActiveStr"
              />
            </el-select>
          </el-col>
          <el-col :span="6" class="">
            <el-select
                placeholder="Tìm kiếm trạng thái"
                class="w-100"
                v-model="setup.filter_active"
            >
              <el-option :value="null" label="Tất cả"/>
              <el-option
                  :value="configs.status.active"
                  :label="configs.status.activeStr"
              />
              <el-option
                  :value="configs.status.inActive"
                  :label="configs.status.inActiveStr"
              />
            </el-select>
          </el-col>
          <el-col :span="5">
            <handle-button event-code="batches.get-all-batch" @click="searchData" v-on:keyup.enter="searchData"
                           typeButton="primary" label="Tìm kiếm"
                           icon="fa fa-search"/>
            <handle-button event-code="batches.get-all-batch" @click="resetData" v-on:keyup.enter="searchData"
                           typeButton="warning" label="Làm mới"
                           icon="fa fa-search"/>
          </el-col>
        </el-row>
      </form>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed border>
      <el-table-column fixed prop="id" label="ID" width="50"/>
      <el-table-column fixed prop="batch_name" label="Tên lớp" width="250"/>
      <el-table-column fixed prop="code" label="Mã lớp" width="100"/>
      <el-table-column fixed prop="roadmap_name" label="Tên khóa học" min-width="250"/>
      <el-table-column prop="countStudentInBatch" label="Sĩ số" width="100"/>
      <el-table-column prop="start_date" label="Ngày bắt đầu" width="150" sortable/>
      <el-table-column prop="end_date" label="Ngày kết thúc" width="150" sortable/>
      <el-table-column
          label="Trạng thái"
          width="150"
      >
        <template #default="scope">
          <el-button
              v-if="scope.row.isActive"
              type="success" size="small"
          >
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button
              v-if="!scope.row.isActive"
              type="warning" size="small"
          >
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          width="600"
      >
        <template #default="scope">
          <div class="d-flex">
            <handle-button
                type="link"
                label="Thêm HV"
                event-code="batches.assign-batch"
                icon="fa fa-plus"
                type-button="primary"
                :to="{name: nameRouter.PAGES.ADD_STUDENT_TO_BATCH, params: {id: scope.row.id}}"
            />
            <handle-button
                type="link"
                title="Báo cáo điểm danh trong ngày"
                label="BCĐD trong ngày"
                event-code="batches.assign-batch"
                icon="far fa-file-alt"
                type-button="primary"
                :to="{name: nameRouter.PAGES.ROLL_CALL_TODAY, query:{batch_id: scope.row.id}}"
            />
            <handle-button
                type="link"
                label="Thêm GV"
                event-code="batches.assign-teacher"
                icon="fa fa-plus"
                type-button="primary mr-2"
                :to="{name: nameRouter.PAGES.ADD_TEACHER_TO_BATCH, params: {id: scope.row.id}}"
            />
            <handle-button
                type="link" 
                label="Chi tiết"
                event-code="batches.get-detail-batch"
                icon="fa fa-plus"
                type-button="primary"
                :to="{name: nameRouter.PAGES.BATCH_DETAIL, params: {id: scope.row.id}}"
            />
            <handle-button
            type="link"
            label="Bảng điểm"
            event-code="batches.score-board"
            icon="fa fa-plus"
            type-button="primary"
            :to="{name: nameRouter.PAGES.SCORE_BOARD, params: {id: scope.row.id}}"
        />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
  <div class="addBatch">
    <el-dialog
        v-model="dialogAdd"
        width="40%"
        @close="closeDialog"
    >
      <template #title>
        <div class="text-center text-primary">
          <h4>Thêm mới lớp học</h4>
        </div>
      </template>
      <el-form
          ref="formAdd"
          class="login-form"
          :model="model"
          label-position="top"
          :rules="rulesLogin"
      >
        <el-form-item prop="name" label="Tên lớp">
          <el-input
              v-model="model.name"
              placeholder="Tên lớp"
          />
        </el-form-item>
        <el-form-item prop="code" label="Mã lớp học">
          <el-input
              v-model="model.code"
              placeholder="Mã lớp học"
          />
        </el-form-item>
        <el-form-item prop="roadmap_id" label="Lộ trình học">
          <el-select
              v-model="model.roadmap_id"
              class="w-100"
              filterable
              default-first-option
              :reserve-keyword="false"
              placeholder="Chọn lộ trình học"
          >
            <el-option
                v-for="item in roadmaps"
                :key="item.id"
                :label="item.title"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="start_date" label="Ngày bắt đầu">
          <el-date-picker
              v-model="model.start_date"
              class="w-100"
              type="date"
              :disabled-date="(time) => disabledEndDate(time, model.end_date)"
              placeholder="Ngày bắt đầu"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item prop="end_date" label="Ngày kết thúc">
          <el-date-picker
              v-model="model.end_date"
              :disabled-date="(time) => disabledStartDate(time, model.start_date) "
              class="w-100"
              type="date"
              placeholder="Ngày kết thúc"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item prop="time" label="Thời gian một buổi học">
          <el-time-picker
              v-model="model.time"
              is-range
              range-separator="Đến"
              start-placeholder="Bắt đầu"
              end-placeholder="Kết thúc"
              format="HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="Trạng thái" prop="isActive">
          <el-radio-group v-model="model.is_active">
            <el-radio :label="CONFIG_APP.status.active">
              {{ CONFIG_APP.status.activeStr }}
            </el-radio>
            <el-radio :label="CONFIG_APP.status.inActive">
              {{ CONFIG_APP.status.inActiveStr }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="danger" :icon="Close" @click="closeDialog">Hủy</el-button>
        <el-button type="primary" :icon="Plus" @click="createBatch">Thêm mới</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {CreatedBatch, GetListBatches} from "@/services/batches";
import {GetAllRoadmap} from "@/services/roadmap";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {textInputValidate} from "@/validator";
import {NameRouter} from "@/constants/nameRouter";
import moment from "moment";
import Configs from "@/configs";

export default {
  name: "ListBatches",
  components: {
    pagination
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogVisible: false,
      dialogAdd: false,
      infoStudent: {},
      model: {
        end_date: "",
        start_date: "",
        code: "",
        name: "",
        roadmap_id: "",
        time: null,
        is_active: configs.status.active,
      },
      rulesLogin: {
        code: [textInputValidate],
        end_date: [textInputValidate],
        start_date: [textInputValidate],
        name: [textInputValidate],
        roadmap_id: [textInputValidate],
        time: [textInputValidate],
      },
      setup: {
        'search_name': null,
        'status': null,
        'code': null,
        'start_date': null,
        'end_date': null,
      },
      nameRouter: NameRouter,
      roadmaps: [],
      configs: Configs
    };
  },
  watch: {},

  created() {
    this.getRoadmap()
    this.getBatches()
    if(this.$route.query){
      this.setup = this.$route.query
    }
    // this.setup = this.$route.query
  },
  methods: {
    getBatches() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage, ...this.setup}
      GetListBatches(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.allBatch.data.map((e, index) => {
            dataTable.push({
              index: e.index,
              id: e.id,
              batch_name: e.batch_name,
              code: e.code,
              roadmap_name: e.roadmaps.title,
              countStudentInBatch: e.batch_students_count,
              start_date: e.start_date ? formatDate(e.start_date) : '',
              end_date: e.end_date ? formatDate(e.end_date) : '',
              isActive: e.is_active == configs.status.active
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.allBatch.total
          this.isShowPagination = e.data.data.allBatch.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },

    async createBatch() {
      let valid = await this.$refs.formAdd.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });


      if (!valid) return false
      let object = {
        batch_name: this.model.name,
        code: this.model.code,
        roadmap_id: this.model.roadmap_id,
        end_date: this.model.end_date,
        is_active: this.model.is_active,
        start_date: this.model.start_date,
        start_time: moment(this.model.time[0]).format('HH:mm:ss'),
        end_time: moment(this.model.time[1]).format('HH:mm:ss')
      }
      CreatedBatch(object).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('add')
          this.closeDialog()
          this.getBatches()
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
    },
    closeDialog() {
      this.dialogAdd = false
      this.$refs.formAdd.resetFields()
    },
    getRoadmap() {
      GetAllRoadmap().then(e => {
        if (e.data.status == 'success') {
          this.roadmaps = e.data.data.allRoadmap
        }

      }).catch(err => {
        notifyErr()
      })
    },
    disabledStartDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() < dateNew.getTime()
      }
    },
    disabledEndDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() > dateNew.getTime()
      }
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getBatches(index);
    },
    searchData() {
      event.preventDefault()
      this.currentPage = 1;
      this.pageIndex = 1;
      this.getBatches();
      this.addParams(1)
    },
    resetData() {
      this.currentPage = 1;
      this.pageIndex = 1;
      this.setup = {
        'search_name': null,
        'status': null,
        'code': null,
        'start_date': null,
        'end_date': null,
      }
      this.getBatches()
      this.addParams()
    },
    addParams(isDisable) {
      let query = this.setup
      if (!isDisable) query = {};

      this.$router.push({query: query})
    }
  }

}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {
  Check,
  Plus,
  Edit,
  Close,
  Search,
  Star,
} from '@element-plus/icons-vue'

const disabledDate = (time, data) => {

  return time.getTime() > Date.now()
}
const dialogAdd = ref()
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

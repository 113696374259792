const CONFIG_APP = {
  totalSlide: 20,
  itemOnPage: 20,
  typeUser: {
    partner: 1,
    customer: 2,
  },
  typeSend: {
    all: 1,
    group: 2,
  },
  status: {
    active: 1,
    inActive: 0,
    activeStr: "Đang hoạt động",
    inActiveStr: "Dừng hoạt động",
  },
  statusEvent: {
    0: "Không bị lặp",
    1: "Bị lặp nhiều ngày",
  },
  statusFacility: [
    { id: "0", label: "Không hoạt động" },
    { id: "1", label: "Hoạt động" },
  ],
  roles: {
    1: "Admin",
    2: "Coordinator",
    3: "Operator",
    4: "Trainer",
    5: "Trainer assistant",
  },
  education: {
    1: "Trung học phổ thông",
    2: "Đại học",
  },

  area: [
    { id: 1, name: "Miền Bắc" },
    { id: 2, name: "Miền Trung" },
    { id: 3, name: "Miền Nam" },
  ],

  checkingStatus: {
    0: "Điểm danh đủ (học đủ, đúng giờ)",
    1: "Nghỉ phép đúng quy định",
    2: "Nghỉ không đúng quy định",
    3: "Đi muộn có phép",
    4: "Đi muộn không phép",
    5: "Học không đủ buổi",
  },
  typeChecking: [
    { key: 0, label: "Điểm danh đủ (học đủ, đúng giờ)", isChecked: true },
    { key: 1, label: "Nghỉ phép đúng quy định", isChecked: false },
    { key: 2, label: "Nghỉ không đúng quy định", isChecked: false },
    { key: 3, label: "Đi muộn có phép", isChecked: false },
    { key: 4, label: "Đi muộn không phép", isChecked: false },
    { key: 5, label: "Học không đủ buổi", isChecked: false },
  ],
  statusAssigmentFile: {
    1: "Đã nộp",
    2: "Nộp muộn",
    3: "Không nộp",
  },
  reportStatus: {
    checked: 1,
    notCheck: 0,
    insert: 0,
    update: 1,
  },
  type: {
    1: "Tệp",
    2: "Link youtube",
    3: "Markdown",
  },
  type_of_purpose: {
    1: "Chuẩn bị",
    2: "Trên lớp",
    3: "Về nhà",
  },
  gender: ["Nam", "nữ", "Khác"],
  genderInfo: [
    { key: 1, value: "Nam" },
    { key: 2, value: "Nữ" },
    { key: 3, value: "khác" },
  ],
  tinymceKey: process.env.VUE_APP_TINYMCE_KEY,
  baseUrlImage:
    process.env.VUE_APP_BASE_URL_API + "/api/get-file-content?file_path=",
  baseUrlImageProduct:
    process.env.VUE_APP_BASE_URL_API_PRODUCT +
    "/api/get-file-content?file_path=",
  baseUrlImageOnline:
    process.env.VUE_APP_BASE_URL_API_WEBONLINE +
    "/api/get-file-content?file_path=",
  downloadFile: process.env.VUE_APP_BASE_URL_API + "/api/get-file?file_path=",
  fieldAccessExcel: [
    { name: "Họ tên", field: "name" },
    { name: "Tên đăng nhập", field: "username" },
    { name: "SĐT", field: "phone_number" },
    { name: "Email", field: "email" },
    { name: "Ngày sinh", field: "birthday" },
    { name: "Địa chỉ", field: "address" },
    { name: "Giới tính", field: "gender" },
  ],
  typeTeacher: [
    { key: 1, value: "Giảng viên" },
    { key: 2, value: "Trợ giảng" },
  ],

  acceptedStatus: [
    { value: 0, label: "Đang chờ" },
    { value: 1, label: "Chấp nhận" },
    { value: 2, label: "Từ chối" },
  ],

  submissionFile: 1,
  rattingStatus: { 1: "Chưa hiểu", 2: "Chưa hiểu rõ", 3: "Đã hiểu" },
  timeLearn: {
    checkIn: "08:00",
    checkout: "18:00",
  },
  typeOfUser: {
    0: "Loại hình khác",
    1: "Giáo viên hoặc trợ giảng",
  },
  labelStatus: {
    // number_of_early_leave: 'Về sớm',
    // number_of_late_arrival: 'Đi muộn',
    // number_of_missing_hours: 'Chưa đủ giờ',
    // number_of_off_afternoon: 'Nghỉ chiều',
    number_of_off_day: "Nghỉ cả ngày",
    // number_of_off_morning: 'Nghỉ sáng',
    number_of_ok: "Đi đầy đủ",
    // number_of_rest_day: 'Ngày nghỉ',
    number_haft_day: "Nghỉ nửa ngày",
  },

  typeDateMulti: [
    {
      value: "0",
      label: "Chọn một ngày",
    },
    {
      value: "1",
      label: "Chọn nhiều ngày",
    },
  ],

  dateLoop: [
    {
      value: "0",
      label: "1 lần",
    },
    {
      value: "1",
      label: "Nhiều lần",
    },
  ],

  dayOrDate: [
    {
      value: "0",
      label: "Lặp theo thứ",
    },
    {
      value: "1",
      label: "Lặp theo ngày",
    },
  ],

  typeLoopDate: [
    {
      value: "1",
      label: "Lặp theo tháng",
    },
    {
      value: "2",
      label: "Lặp theo năm",
    },
  ],

  isDayOff: [
    {
      value: "1",
      label: "Ngày nghỉ",
    },
    {
      value: "0",
      label: "Ngày bình thường",
    },
  ],

  typeUpdate: [
    { value: "0", label: "Update 1 ngày" },
    { value: "1", label: "Update nhiều ngày" },
  ],
  typeReason: {
    1: "Bổ sung điểm danh",
    2: "Xin nghỉ phép",
  },

  activeSurvey: [
    { value: "0", label: "Chưa kích hoạt" },
    { value: "1", label: "Đã kích hoạt" },
  ],

  mandatorySurvey: [
    { value: "0", label: "Không bắt buộc" },
    { value: "1", label: "Bắt buộc" },
  ],

  anonymousSurvey: [
    { value: "0", label: "Không ẩn danh" },
    { value: "1", label: "Ẩn danh" },
  ],

  questionTypeSurvey: [
    { value: "0", label: "Text" },
    { value: "1", label: "Radio" },
    { value: "2", label: "Checkbox" },
  ],

  questionRequireSurvey: [
    { value: "0", label: "Không yêu cầu" },
    { value: "1", label: "Yêu cầu" },
  ],

  typeRoadmap: [
    {
      id: 0,
      label: "Lộ trình",
      router: {
        detail: "detail-roadmap",
        create: "create-roadmap",
      },
      icon: "&#9672;",
      textColor: "text-dark",
      textSize: "text-md",
    },
    {
      id: 1,
      label: "Khóa học",
      router: {
        detail: "course-detail",
        create: "create-course",
      },
      icon: "&#9671;",
      textColor: "text-danger",
      textSize: "text-base",
    },
    {
      id: 2,
      label: "Mô đun",
      router: {
        detail: "detail-module",
        create: "create-module",
      },
      icon: "&#9634;",
      textColor: "text-warning",
      textSize: "text-sm",
    },
    {
      id: 3,
      label: "Bài học",
      router: {
        detail: "detail-section",
        create: "create-section",
      },
      icon: "&#9673;",
      textColor: "text-success",
      textSize: "",
    },
    {
      id: 4,
      label: "Khung giờ",
      router: {
        detail: "detail-material",
        create: "create-material",
      },
      icon: "&#9678;",
      textColor: "text-info",
      textSize: "",
    },
    {
      id: 5,
      label: "File",
      router: {
        detail: "detail-file",
        create: "create-file",
      },
      icon: "&#9635;",
      textColor: "text-dark-gray",
      textSize: "text-sm",
    },
  ],
  typeSetting: ["System", "Image", "Page", "Rules", "RulesClass"],
  typeRuleSetting: ["point_decrement", "point_increment"],
  blogPriority: [
    { label: "Không ưu tiên", value: 0 },
    { label: "Ưu tiên", value: 1 },
  ],

  blogActive: [
    { label: "Không hoạt động", value: 0 },
    { label: "Hoạt động", value: 1 },
  ],

  blogStatus: [
    { label: "Đang chờ", value: 1 },
    { label: "Từ chối", value: 2 },
    { label: "Chấp nhận", value: 3 },
  ],

  valueStatus: {
    4: "Về sớm",
    3: "Đi muộn",
    8: "Chưa đủ giờ",
    7: "Nghỉ chiều",
    5: "Nghỉ cả ngày",
    6: "Nghỉ sáng",
    1: "Điểm danh đầy đủ",
    2: "Ngày nghỉ",
  },
  offLearnRequest: {
    1: "Xin nghỉ cả ngày",
    2: "Xin nghỉ sáng",
    3: "Xin nghỉ chiều",
    4: "Xin đi muộn",
    5: "xin về sớm",
    6: "xin nghỉ chiều",
  },
};
export default CONFIG_APP;

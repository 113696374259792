<template>
  <div class="bg-white">
    <info-room :form="form" ref="formRoom"/>
    <div class="text-start py-3 px-3">
      <handle-button
          label="Tạo phòng học"
          eventCode=""
          icon="fa fa-plus"
          typeButton="primary"
          @click="createRoom"/>
    </div>
  </div>
</template>
<script>
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import infoRoom from "@/views/Room/infoRoom";
import {CreateRoom} from "@/services/room";

export default {
  name: "CreateEvent",
  mixins: [setBreadcrumb],
  components: {
    infoRoom
  },
  data() {
    return {
      configs: configs,
      NameRouter: NameRouter,
      form: {}
    };
  },


  methods: {
    async createRoom() {
      let validEvent = await this.$refs.formRoom.validForm()
      if (!validEvent) return false
      let param = {
        name: this.form.name,
        branch_id: this.form.branch,
        code:this.form.code
      }
      CreateRoom(param).then(e => {
        notifySuccess("add")
        this.$router.push({name: NameRouter.PAGES.LIST_ROOM})
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
  }
  ,
}

</script>
<script setup>

</script>
<style scoped lang="scss">

</style>
<template>
  <span v-if="type !== 'link'">
    <button class="btn py-1 mx-1" style="font-size: 12px" :class="'btn-'+typeButton" @click="handleClick()"
            :disabled="disabled"
            v-if="showButton || isAdmin">
      <i :class="icon" class="mr-2"/> {{ label }}
    </button>
  </span>
  <span v-if="type === 'link'">
    <router-link class="btn py-1 mx-1" style="font-size: 12px" :class="'btn-'+typeButton" :to="to" :title="title"
                 v-if="showButton || isAdmin"
                 :target="target">
      <i :class="icon" class="mr-2"/> {{ label }}
    </router-link>
  </span>
  <span v-if="type === 'link' && !showButton && !isAdmin">
    <el-popconfirm
        cancel-button-text=""
        title="Bạn không có quyền truy cập."
    >
      <template #reference>
        <button class="btn py-1" style="font-size: 12px" :class="'btn-'+typeButton" v-if="!showButton || !isAdmin"
                :disabled="true">
          <i :class="icon" class="mr-2"/> {{ label }}
        </button>
      </template>
    </el-popconfirm>

  </span>
</template>
<script>

export default {
  name: 'HandleButton',
  props: {
    type: {type: String, default: 'btn-default'},
    label: {type: String},
    icon: {type: String},
    to: {type: Object, default: null},
    eventCode: {type: String},
    disabled: {type: Boolean, default: false},
    typeButton: {type: String},
    target: {type: String, default: ''},
    title: {type: String, default: ''}
  },
  data() {
    return {
      showButton: true,
      role: this.$helpers.getRole()
    }
  },
  computed: {
    isAdmin() {
      return this.$helpers.roleAdmin === this.role.is_admin
    }
  },
  created() {
    let permissions = this.$helpers.getPermission()
    this.showButton = permissions.find(el => el.permission.route_name == this.eventCode) ?? false
  },
  methods: {
    handleClick() {
      event.preventDefault();
      this.$emit('click')
    }
  }
}
</script>
<template>
  <el-select v-model="dataSelect" class="m-2" placeholder="Lựa chọn" size="small">
    <el-option
      v-for="item in optionsLeave"
      :key="item.id"
      :label="item.name"
      :value="item.id"
      @click="getOption(item.id,userId)"
    />
  </el-select>
</template>
<script>
import {setBreadcrumb,} from "@/mixins/utils";

export default {
  name: "selectStatus",
  props: ['getOption', 'userId']

  ,
  mixins: [setBreadcrumb],
  data() {
    return {
      dataSelect: '',
      optionsLeave: [
        {id: 1, name: "Nghỉ có phép"},
        {id: 2, name: "Nghỉ nửa ngày"},
        {id: 3, name: "Nghỉ không phép"},
      ],

    };
  },


  watch: {},


}
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {Check, Close} from "@element-plus/icons-vue";
</script>


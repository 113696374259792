<template>
  <div class="bg-white">
    <info-event :form="form" ref="formEvent" />
    <div class="text-center py-3 px-3">
      <handle-button label="Tạo sự kiện" eventCode="" icon="fa fa-plus" typeButton="primary" @click="createEvent" />
    </div>
  </div>
</template>
<script>
import configs from "@/configs";
import { notifyErr, notifySuccess, setBreadcrumb } from "@/mixins/utils";
import { NameRouter } from "@/constants/nameRouter";
import infoEvent from "@/views/Event/infoEvent";
import { CreateEvent } from "@/services/event";

export default {
  name: "CreateEvent",
  mixins: [setBreadcrumb],
  components: {
    infoEvent
  },
  created() {
    this.getDayEvent();
  },
  data() {
    return {
      configs: configs,
      NameRouter: NameRouter,
      form: {}
    };
  },


  methods: {
    async createEvent() {
      let validEvent = await this.$refs.formEvent.validForm()
      if (!validEvent) return false
      let param = {
        "date_loop": this.form.date_loop,
        "day_or_date": this.form.day_or_date,
        "type_loop_date": this.form.type_loop_date,
        "start_day": this.form.day[0].toLocaleDateString("sv-SE"),
        "end_day": this.form.day[1].toLocaleDateString("sv-SE"),
        "event_name": this.form.event_name,
        "start_time": "00:00:00",
        "end_time": "23:59:59",
        "event_is_day_off": this.form.event_is_day_off,
      }


      CreateEvent(param).then(e => {
        notifySuccess("add")
        this.$router.push({ name: NameRouter.PAGES.LIST_EVENT })
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
    getDayEvent() {

      if (this.$route.params.id) {
        this.form.day = [
          new Date(this.$route.params.id),
          new Date(this.$route.params.id),
        ]
      }
    },
  }
  ,
}

</script>
<script setup>

</script>
<style scoped lang="scss"></style>
<template>
    <div class="bg-white p-3 rounded">
        <infoScoreboardStd :form="form" ref="infoAssign"></infoScoreboardStd>
    </div>
</template>
<script>
import { setBreadcrumb } from "@/mixins/utils";
import infoScoreboardStd from "@/views/AssigmentFile/infoScoreboardStd";
export default {
    name: 'createScoreboardStd',
    components: {
        infoScoreboardStd
    },
    mixins: [setBreadcrumb],
    watch: {},
    methods: {
    }}
</script>
<script setup>
import { ref } from "vue";
</script>
  
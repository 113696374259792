<template>
  <div id="management-course" class="bg-white p-3">
    <div class="box-search py-2">
      <div class=""><i>Tìm kiếm theo điều kiện</i></div>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="setup.keyword" placeholder="Tìm kiếm khóa học"/>
        </el-col>
        <el-col :span="6">
          <el-input v-model="setup.code" placeholder="Tìm kiếm Mã code"/>
        </el-col>
        <el-col :span="6">
          <roadmap-select v-model="setup.roadmap_id"/>
        </el-col>
        <el-col :span="6">
          <select-module :show-label="false" ref="moduleSelectBox"/>
        </el-col>
        <el-col :span="6">
          <div class="d-flex">
            <handle-button
                label="Tìm kiếm"
                eventCode="courses.index"
                icon="fa fa-search"
                typeButton="primary"
                @click="searchData"/>

            <handle-button
                label="Tạo Khóa học"
                :target="true"
                type="link"
                eventCode="courses.store"
                icon="fa fa-plus"
                typeButton="primary"
                :to="{ name: nameRouter.PAGES.CREATE_COURSE}"/>
            <handle-button
                label="Làm mới"
                eventCode="courses.index"
                typeButton="warning"
                @click="reload"/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="py-2" v-if="!isPass"><small class="text-danger">{{ Messages.COURSE_NULL }}</small></div>
    <el-table
        :data="tableData"
        @select="handleSelected"
        @selection-change="handleSelectAll"
        style="width: 100%"
        ref="courseList"
        fixed v-loading="loading"
        border
    >
      <el-table-column type="selection" width="55" v-if="showCheckbox"/>
      <el-table-column prop="id" label="Id" min-width="50"/>
      <el-table-column
          label="Hình ảnh"
          width="160">
        <template #default="scope">
          <el-image :src="scope.row.avatar" style="width: 100px; height: 100px" :fit="fit">
            <div slot="placeholder" class="image-slot">
              Loading<span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Tên Khóa học" min-width="150"/>
      <el-table-column prop="code" label="Mã code" min-width="150"/>
      <el-table-column prop="duration" label="Thời gian học(phút)" min-width="120"/>
      <el-table-column prop="version" label="Phiên bản" min-width="120"/>
      <el-table-column prop="level" label="Trình độ" width="100"/>
      <el-table-column prop="type" label="Có chương" min-width="110">
        <template #default="scope">
          <div class="pl-4">
            <el-checkbox :checked="scope.row.type === hasSection" disabled/>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="TT hoạt động"
          width="160">
        <template #default="scope">
          <el-button
              v-if="scope.row.isActive"
              type="success"
              size="small">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button
              type="warning"
              v-if="!scope.row.isActive" size="small">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="Hành động"
          fixed="right"
          min-width="200">
        <template #default="scope">
          <div class="d-flex">
            <handle-button
                label=""
                type="link"
                eventCode="courses.show"
                icon="fa fa-edit"
                typeButton="primary"
                :to="{name: nameRouter.PAGES.DETAIL_COURSE, params: {id: scope.row.id}}"
            />
            <handle-button
                label=""
                eventCode="courses.destroy"
                icon="fa fa-trash"
                typeButton="danger"
                v-if="!showCheckbox"
                @click="openConfirmDialog(scope.row.id)"/>
            <handle-button
                label=""
                event-code="courses.show"
                icon="fa fa-eye"
                typeButton="danger"
                @click="previewForAdmin(scope.row.id)"/>
          </div>
        </template>

      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
    <confirm v-model="setup.confirmDialog" @deleteService="deleteCourse" v-if="!showCheckbox"></confirm>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifySuccess} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {DeleteCourse, GetListCourse, PreviewCourse} from "@/services/course";
import confirm from "@/components/confirm";
import roadmapSelect from "@/components/roadmapSelect";
import selectModule from "@/views/ManagementModule/selectModule";

export default {
  expose: ['validCourse', 'getCourse'],
  name: "Course-checkbox",
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: true}
  },
  components: {
    pagination,
    confirm,
    roadmapSelect,
    selectModule
  },
  data() {
    return {
      setup: {
        keyword: '',
        code: '',
        roadmap_id: '',
        module_id: '',
        confirmDialog: null,
        idDelete: null
      },
      tableData: [],
      selected: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      nameRouter: NameRouter,
      isPass: true,
      hasSection: 1,
    };
  },
  watch: {
    selected() {
      this.$emit('update:modelValue', this.selected)
    },
    tableData: {
      handler(newTable) {
        let that = this
        that.$nextTick(function () {
          that.toggleSelection()
        })
      },
      immediate: true
    },
    'setup.roadmap_id'() {
      this.setup.module_id = null
      this.$refs.moduleSelectBox?.searchByRoadmap(this.setup.roadmap_id)
    }
  },

  created() {
    let query = this.$router.currentRoute?.value?.query;
    let {
      keyword,
      code,
      roadmap_id,
      module_id,
      confirmDialog,
      idDelete
    } = query;
    if (keyword ||
        code ||
        roadmap_id ||
        module_id ||
        confirmDialog ||
        idDelete) {
      this.setup = {...query,per_page: configs.itemOnPage, page: this.currentPage}
      this.getCourse()
    } else {
      this.getCourse()
    }


  },
  methods: {
    getCourse() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage, ...this.setup}
      GetListCourse(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.courses.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.title,
              code: e?.code,
              avatar: e.full_course_image_url,
              duration: e.duration && e.duration > 0 ? (e.duration / 60) + ' phút' : 0 + 'phút',
              summary: e.summary,
              level: e.level,
              isActive: e.is_active,
              version: e.version,
              sort_order: e.sort_order,
              type: e.type,
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.courses.total
          this.isShowPagination = e.data.data.courses.total > 0
          this.selected = this.modelValue
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getCourse(index);
    },
    searchData(index) {
      this.currentPage = 1
      this.getCourse(index);
      this.$router.push({path: location.pathname, query: this.setup})
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.selected.push(row.id)
      }
      this.$emit('update:modelValue', this.selected)
    },
    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.selected.findIndex(id => e.id == id) > -1) return
          this.selected.push(e.id)
        })
        this.$emit('update:modelValue', this.selected)
      }
    },
    toggleSelection(table) {
      if (!this.selected) {
        return false
      }
      this.selected.forEach(e => {
        let item = this.tableData.find(item => e == item.id)
        if (!item) return false;
        this.$refs.courseList.toggleRowSelection(item);
      })
    },
    async validCourse() {
      if (this.selected.length < 1) {
        this.isPass = false

      } else {
        this.isPass = true
      }
      return this.isPass
    },
    reload() {
      this.setup.keyword = ''
      this.setup.code = ''
      this.setup.roadmap_id = ''
      this.setup.module_id = ''
      this.getCourse()
      this.$router.push({path: location.pathname, query: {}})
    },
    openConfirmDialog(id) {
      this.setup.confirmDialog = true
      this.setup.idDelete = id
    },

    deleteCourse() {
      DeleteCourse(this.setup.idDelete).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('delete')
          this.getCourse()
          this.setup.confirmDialog = false
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    previewForAdmin(id) {
      PreviewCourse(id).then(result => {
        console.log(result)
        let url = this.$helpers.openPreviewCourse(result.data.data.token, id)
        window.open(url, "_blank")
      }).catch()
    }
  },
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {Search} from '@element-plus/icons-vue'
import Messages from "@/constants/messages";

const disabledDate = (time, data) => {

  return time.getTime() > Date.now()
}
const dialogAdd = ref()
</script>
<style scoped lang="scss">
::v-deep .cell {
  word-break: break-word !important;
}
</style>


<template>
  <div class="bg-white">
    <info-user :form="form" ref="formUser"/>
    <div class="text-center py-3">
      <handle-button
          label="Tạo người dùng"
          eventCode="users.store"
          icon="fa fa-plus"
          typeButton="primary"
          @click="createUser"/>
    </div>
  </div>
</template>
<script>
import infoUser from "@/views/ManagementUser/infoUser";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {CreatedUser} from "@/services/users";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'Create-user',
  components: {
    infoUser
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      form: {
        name: '',
        username: '',
        email: '',
        description: '',
        role_level: '',
        is_active: 1,
        avatar: ''
      }
    }
  },
  methods: {
    async createUser() {
      let valid = await this.$refs.formUser.validForm()
      if (!valid) return notifyErr('Vui lòng điền đầy đủ thông tin.')
      CreatedUser(this.form).then(el => {
        if (el.data.status == 'success') {
          notifySuccess('add', 'Tạo người dùng thành công')
          this.$router.push(NameRouter.PAGES.LIST_USER)
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    }
  }
}
</script>
<style lang="scss">
::v-deep .is-error{

}
</style>
<template>
  <div class="w-100" :class="{'hasErr': validation}">
    <label v-if="showLabel" style="color: #606266"> <span class="text-danger">*</span> Khung giờ</label>
    <vSelect
        v-model="setup.materialId"
        placeholder="Lựa chọn khung giờ"
        :disabled="disableSelect"
        :options="materialList"
        label="name"
        :class="{'is-invalid': validation}"
        :reduce="(material) => material.id"
        class="w-100"
        :multiple="isMultiple"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import {GetMaterial} from "@/services/course";
import CONFIG_APP from "@/configs";

export default {
  expose: ['resetData', 'searchData', 'searchBy'],
  name: "material-select",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn khung giờ.'},
    validation: Boolean,
    modelValue: Array,
    idOld: {type: Array, default: null},
    isMultiple: {type: Boolean, default: true},
    isAssignment: {type: Boolean, default: null},
    showLabel: {type: Boolean, default: true},
  },
  data() {
    return {
      setup: {
        materialId: [] || '',
      },
      hasNextPage: false,
      disableSelect: false,
      materialList: [],
      page: 1,
      batch_id: '',
      keyword: ''
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {
    'setup.materialId'() {
      this.$emit('selected', this.setup.materialId)
      this.$emit('update:modelValue', this.setup.materialId)
    },
    materialList() {
      this.setup.materialId = this.modelValue
    }
  },
  created() {
    this.getMaterial()
  },
  methods: {
    getMaterial(params) {
      this.hasNextPage = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page, is_assignment: this.isAssignment}
      }
      GetMaterial(params).then(e => {
        if (e.data.status === 'success') {
          let data = e.data.data.materials.data
          if (this.idOld) {
            this.idOld.map(id => {
              let index = data.findIndex(el => el.id === id)
              if (index > -1) {
                data.splice(index, 1)
              }
            })
          }
          this.materialList = data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        if (err.response.data == 'failed') {
          location.reload();
        }
      })
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      this.keyword = query
      let params = {
        per_page: this.per_page,
        page: 1,
        keyword: query,
        is_assignment: this.isAssignment,
        batch_id: this.batch_id
      }
      this.getMaterial(params)
    },
    resetData() {
      this.materialList = []
      this.setup.materialId = null
    },
    searchData(batchId) {
      this.materialList = []
      this.batch_id = batchId
      let params = {
        per_page: this.per_page,
        page: 1,
        keyword: this.keyword,
        is_assignment: this.isAssignment,
        batch_id: batchId,
        course_id: this.course_id
      }
      this.getMaterial(params)
    },
    searchBy(data) {
      this.disableSelect = true
      this.materialList = []
      this.course_id = data.course_id
      let params = {
        per_page: this.per_page,
        page: 1,
        ...data
      }
      this.getMaterial(params)
    },
  },
}
</script>
<script setup>
import {NameRouter} from "@/constants/nameRouter";
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}
::v-deep .vs__actions {
  button {
    font-size: 8px !important;
  }
}
</style>

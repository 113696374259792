<template>
  <div id="infoBatch">
    <div class="p-3 bg-white infoBatch">
      <div class="text-center mb-3">
        <h5>Thông tin cơ sở</h5>
      </div>
      <el-row :gutter="20">
        <el-col :span="12" style="text-align: center">
          <el-image
              style="width: 300px; height: 300px"
              :src="batch.avatar ? batch.avatar : 'https://rikkei.edu.vn/wp-content/uploads/2022/07/1bf78b8067d7a489fdc6.jpg'"
          />
        </el-col>
        <el-col :span="12" style="display:flex; flex-direction: column; justify-content: space-between">
          <div>
            Tên cơ sở: {{ batch.name }}
          </div>
          <div>
            Mã code: {{ batch.code }}
          </div>
          <div>
            Khu vực: {{ batch.area }}
          </div>
          <div>
            Địa chỉ: {{ batch.area }}
          </div>
          <div>
            Trạng thái: {{ batch.is_active == "0" ? configs.statusFacility[0].label : configs.statusFacility[1].label }}
          </div>
          <div style="display:flex; gap:4px">
            Mô tả: <span v-html="batch.description"/>
          </div>
        </el-col>
      </el-row>
      <div class="buttonEdit">
        <el-button @click="setup.dialogAdd = true">
          <i class="fa fa-edit"/>
        </el-button>
      </div>
    </div>
    <div class="p-3 my-3 bg-white">
      <div class="d-flex justify-content-between">
        <div class="justify-content-start">
          <div class="text-center ">
            <h5>Danh sách nhân viên</h5>
          </div>
        </div>
        <div class="justify-content-end">
          <handle-button
              type="link"
              event-code=""
              type-button="btn-outline-dark ml-3 py-1"
              label="Thêm nhân viên" icon="fa fa-plus"
              :to="{name: nameRouter.PAGES.ADD_USERS_TO_FACILITY, params: {id:idFacility}}"
          />
        </div>
      </div>
      <el-table
          :data="dataTable.staffTable"
          style="width: 100%"
          fixed
          border
      >
        <el-table-column fixed prop="id" label="Id" min-width="150"/>
        <el-table-column fixed prop="username" label="Tên đăng nhập" min-width="150"/>
        <el-table-column prop="name" label="Họ tên" min-width="150"/>
        <el-table-column prop="email" label="email" min-width="200"/>
        <el-table-column
            fixed="right"
            min-width="140"
            label="Hành động"
        >
          <template #default="scope">
            <el-button
                type="danger"
                size="small"
                @click="openUserConfirm(scope.row.id)"
            >
              <i class="fa fa-trash"/><span class="pl-2 text-white"> Loại bỏ</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="p-3 my-3 bg-white">
      <div class="d-flex justify-content-between">
        <div class="justify-content-start">
          <div class="text-center ">
            <h5>Danh sách lớp học</h5>
          </div>
        </div>
        <div class="justify-content-end">
          <handle-button
              type="link"
              event-code=""
              type-button="btn-outline-dark ml-3 py-1"
              label="Thêm lớp học" icon="fa fa-plus"
              :to="{name: nameRouter.PAGES.ADD_CLASS_TO_FACILITY, params: {id:idFacility}}"
          />
        </div>
      </div>
      <el-table
          ref="staffTable"
          :data="dataTable.classTable"
          style="width: 100%"
          fixed
          border
      >
        <el-table-column fixed prop="id" label="Id" min-width="150"/>
        <el-table-column fixed prop="batch_name" label="Tên lớp" min-width="150"/>
        <el-table-column prop="code" label="Mã Code" min-width="150"/>
        <el-table-column prop="start_date" label="Ngày bắt đầu" min-width="150"/>
        <el-table-column prop="end_date" label="Ngày kết thúc" min-width="150"/>
        <el-table-column
            fixed="right"
            min-width="140"
            label="Hành động"
        >
          <template #default="scope">
            <el-button
                type="danger"
                size="small"
                @click="openClassConfirm(scope.row.id)"
            >
              <i class="fa fa-trash"/><span class="pl-2 text-white"> Loại bỏ</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <confirm
        v-model="setup.userRemoveConfirm" @close="closeDialog"
        @deleteService="removeUserBatches"
    />
    <confirm
        v-model="setup.classRemoveConfirm" @close="closeDialog"
        @deleteService="removeClassBatches"
    />
    <el-dialog
        v-model="setup.dialogAdd"
        width="40%"
        @close="closeDialog"
    >
      <template #title>
        <div class="text-center text-primary">
          <h4>Cập nhật cơ sở</h4>
        </div>
      </template>
      <el-form
          ref="formAdd"
          class="login-form"
          :model="form"
          label-position="top"
          :rules="rulesForm"
          @submit="updateBatch"
      >
        <!--        <div class="text-bold">Ảnh đại diện-->
        <!--          <span class="text-danger">*</span>-->
        <!--        </div>-->
        <!--        <el-form-item prop="avatar">-->
        <!--          <el-upload-->
        <!--              ref="avatar"-->
        <!--              :limit="1"-->
        <!--              class="avatar-uploader"-->
        <!--              :class="imageUrl? 'disable-event': '' "-->
        <!--              :before-upload="beforeAvatarUpload"-->
        <!--          >-->
        <!--            <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar"-->
        <!--                 style="max-width: 500px; object-fit: cover">-->
        <!--            <el-icon v-if="!imageUrl" class="avatar-uploader-icon">-->
        <!--              <Plus/>-->
        <!--            </el-icon>-->
        <!--          </el-upload>-->
        <!--        </el-form-item>-->
        <!--        <div v-if="imageUrl" class="ml-lg-5 pl-lg-5 pl-md-5">-->
        <!--          <el-button type="danger" size="small" class="p-1">-->
        <!--            <el-icon>-->
        <!--              <Delete/>-->
        <!--            </el-icon>-->
        <!--            <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>-->
        <!--          </el-button>-->
        <!--        </div>-->
        <el-form-item prop="name" label="Tên cơ sở">
          <el-input
              v-model="form.name"
              placeholder="Tên cơ sở"
          />
        </el-form-item>
        <el-form-item prop="code" label="Mã code">
          <el-input
              v-model="form.code"
              placeholder="Mã code"
          />
        </el-form-item>
        <el-form-item prop="area" label="Khu vực">
          <el-select
              v-model="form.area"
              class="w-100"
              filterable
              default-first-option
              :reserve-keyword="false"
              placeholder="Chọn khu vực"
          >
            <el-option
                v-for="item in configs.area"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="area" label="Khu vực">
          <el-select
              v-model="form.area"
              class="w-100"
              filterable
              default-first-option
              :reserve-keyword="false"
              placeholder="Chọn khu vực"
          >
            <el-option
                v-for="item in configs.area"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="address" label="Địa chỉ">
          <el-input
              v-model="form.address"
              placeholder="Địa chỉ"
          />
        </el-form-item>
        <el-form-item prop="area" label="Trạng thái">
          <el-select
              v-model="form.is_active"
              class="w-100"
              filterable
              default-first-option
              :reserve-keyword="false"
              placeholder="Chọn trạng thái"
          >
            <el-option
                v-for="item in configs.statusFacility"
                :key="item.id"
                :label="item.label"
                :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="description" label="Mô tả">
          <div style="width:100%">
            <editorTiny v-model="form.description" style="width:100%"/>
          </div>
        </el-form-item>


        <div class="dialog-footer text-right">
          <el-button type="danger" :icon="Close" @click="closeDialog">
            Hủy
          </el-button>
          <el-button type="primary" :icon="Plus" @click="updateBatch">
            Cập nhật
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import editorTiny from "@/components/editor";
import configs from "@/configs";
import {formatDate, isImage, notifyErr, notifyFiled, setBreadcrumb, notifySuccess} from "@/mixins/utils";
import {checkSpecialCharacter, textInputValidate} from "@/validator";
import {NameRouter} from "@/constants/nameRouter";
import confirm from "@/components/confirm";
import {GetDetailFacility, RemoveUserFromFacility, UpdateFacility,RemoveClassFromFacility} from "@/services/facility";

export default {
  name: "BatchDetail",

  components: {
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      nameRouter: NameRouter,
      idFacility:this.$route.params.id,
      userIdRemoved: [],
      classIdRemoved:[],
      configs: configs,
      loading: false,
      batch: {id: ''},
      dataTable:{
        staffTable: [],
        classTable: [],
      },
      imageUrl: '',
      setup: {
        dialogAdd: false,
        userRemoveConfirm: false,
        classRemoveConfirm: false,
      },
      rulesForm: {
        avatar: [textInputValidate],
        name: [textInputValidate],
        code: [textInputValidate, checkSpecialCharacter],
        area: [textInputValidate],
        description: [textInputValidate],
        address: [textInputValidate],
        is_active: [textInputValidate],
      },
      form: {name: '', code: '', area: '', description: '', address: '',is_active:''},
    }
  },

  async created() {
    await this.getInFoFacilityDetail(this.idFacility)
  },
  methods: {
    getInFoFacilityDetail(param) {
      this.loading = true
      GetDetailFacility(param).then(res => {
        if (res.data.status == 'success') {
          this.batch = res.data.data.branchDetail[0]
          this.dataTable.staffTable = res.data.data.branchDetail[0]?.user
          this.dataTable.classTable = res.data.data.branchDetail[0]?.batch
          this.form = {
            name: res.data.data.branchDetail[0].name,
            code: res.data.data.branchDetail[0].code,
            is_active: res.data.data.branchDetail[0].is_active == "0" ? configs.statusFacility[0].label : configs.statusFacility[1].label,
            address: res.data.data.branchDetail[0].address,
            description: res.data.data.branchDetail[0].description,
            area: res.data.data.branchDetail[0].area
          }
          this.loading = false
        }
      })

    },
    closeDialog() {
      this.setup.dialogAdd = false;
      this.setup.userRemoveConfirm = false;
      this.setup.classRemoveConfirm = false;
      this.userIdRemoved = [];
      this.classIdRemoved= [];

    },
    async updateBatch(event) {
      let valid = await this.$refs.formAdd.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return notifyErr('Vui lòng điền đầy đủ thông tin.');

      UpdateFacility(this.form, this.idFacility).then(e => {
        this.setup.dialogAdd = false
        this.getInFoFacilityDetail(this.idFacility)
        notifySuccess("edit");
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
    openUserConfirm(id) {
      this.setup.userRemoveConfirm = true;
      this.userIdRemoved = [id]

    },
    openClassConfirm(id) {
      this.setup.classRemoveConfirm = true;
      this.classIdRemoved =[id];
    },
    async removeUserBatches() {
      let param = {
        user_ids: this.userIdRemoved
      }
      this.loading = true
      RemoveUserFromFacility(this.idFacility, param).then(res => {
        if (res.data.status == 'success') {
          this.getInFoFacilityDetail(this.idFacility)
          this.setup.userRemoveConfirm = false;
          notifySuccess("delete")
        }
        this.loading = false
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
    removeClassBatches() {
      let param = {
        batch_ids: this.classIdRemoved
      }
      this.loading = true
      RemoveClassFromFacility(this.idFacility, param).then(res => {
        if (res.data.status == 'success') {
          this.getInFoFacilityDetail(this.idFacility)
          this.setup.classRemoveConfirm = false;
          notifySuccess("delete")
        }
        this.loading = false
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled()
        return false
      }
      this.imageUrl = URL.createObjectURL(uploadFile)
      this.form.avatar = this.imageUrl
      return true
    },
    handleRemove() {
      this.$refs.avatar.clearFiles()
      this.imageUrl = ''
      this.form.avatar = ""
    },
  }
}

</script>
<script setup>
import HandleButton from "@/components/button";
import {Plus, Close} from "@element-plus/icons-vue";
</script>

<style scoped lang="scss">
.infoBatch {
  position: relative;

  .buttonEdit {
    position: absolute;
    top: 4%;
    right: 1%;
  }
}
</style>

import {createApp} from "vue";
import plugin from "./plugin";
import App from "./App.vue";
import store from "./stores";
import router from "./router";
import ElementPlus from 'element-plus'
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import 'element-plus/theme-chalk/index.css'
import "bootstrap/dist/css/bootstrap.min.css";
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import "./scss/app.scss";
import VueCookies from 'vue3-cookies'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import {faEnvelope, faLock, fas} from '@fortawesome/free-solid-svg-icons'
import {fab, faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import Vi from 'element-plus/lib/locale/lang/vi'
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import handleButton from '@/components/button'

library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch()
const app = createApp(App);

VMdEditor.use(vuepressTheme, {
    Prism,
}, createTodoListPlugin);
VMdEditor.lang.use('en-US', enUS);
app.use(VueCookies, {
    expireTimes: "7d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});
app.use(router);
app.use(plugin);
app.component('handleButton', handleButton)
app.use(VMdEditor);
app.use(store);
app.use(ElementPlus, {locale: Vi});
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount("#app");

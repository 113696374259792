<template>
  <div id="editor-tiny-custom">
    <editor
        :api-key="tinyMce"
        :init="buildTiny"
        v-model="modelValue"
    />
  </div>
</template>
<script>
import editor from '@tinymce/tinymce-vue'
import configs from "@/configs";
import {UploadFile} from "@/services/common";
import {GetFile} from "@/services/common";

export default {
  name: 'editor-tiny',
  components: {
    editor
  },
  props: {
    modelValue: String,
  },
  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue)
    }
  },
  data() {
    return {
      configs:configs,
      buildTiny: {
        height : "30vh",
        menubar: false,
        valueEditor: '',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],

        automatic_uploads: false,
        image_title: true,

        file_picker_types: 'image',

        file_picker_callback: function (cb) {
          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.onchange = async function () {
            let file = this.files[0];
            let formData = new FormData
            formData.append('file',file )
            let path = "";


            try {
                let res = await  UploadFile(formData);
                path = configs.baseUrlImage + res.data.data.filePath;
            }catch (error){
              console.log(error);
            }



            let reader = new FileReader();
            reader.onload = function () {
              cb(path, { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
        toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | image '

      },
      tinyMce: configs.tinymceKey,
      test: ''

    }
  },
  methods:{

  }

}
</script>
<style lang="scss" scoped>
#editor-tiny-custom {
  ::v-deep .tox-statusbar__branding {
    display: none !important;
  }
}

</style>
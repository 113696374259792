<template>
  <div class="text-center" id="login-page">
    <div class="login">
      <el-card>
        <h4>Đổi mật khẩu</h4>
        <el-form
          class="login-form"
          :model="model"
          :rules="rulesLogin"
          ref="form"
        >
          <el-form-item prop="password">
            <el-label class="">Mật khẩu cũ</el-label>
            <el-input
              v-model="model.password"
              placeholder="Mật khẩu cũ"
              type="password"
              :prefix-icon="Lock"
              autocomplete="off"
              @change="onChange"
            ></el-input>
          </el-form-item>
          <el-form-item prop="nepassword">
            <el-label>Mật khẩu mới</el-label>
            <el-input
              v-model="model.nepassword"
              placeholder="Mật khẩu mới"
              type="password"
              :prefix-icon="Lock"
              autocomplete="off"
              @change="onChange"
            ></el-input>
          </el-form-item>
          <el-form-item prop="rePassword">
            <el-label>Nhập lại mật khẩu</el-label>
            <el-input
              v-model="model.rePassword"
              placeholder="Nhập lại mật khẩu"
              type="password"
              @change="onChange"
              autocomplete="off"
              :prefix-icon="Lock"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="text-center pb-3">
          <el-row :gutter="10">
            <el-col :span="24">
              <button
                class="login-button btn btn-info text-white py-1"
                @click="submit"
              >
                Lưu
              </button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {changePassword } from "@/services/auth";
import {
  textInputValidate,
  checkPassword,
} from "@/validator";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import { NameRouter } from "@/constants/nameRouter";

export default {
  name: "ChangePassword",
  mixins: [setBreadcrumb],
  data() {
    return {
      model: {
        password:"",
        nepassword: "",
        rePassword: "",
      },
      loading: false,
      rulesLogin: {
        password: [
          textInputValidate,
          {
            required: true,
            trigger: "blur",
          },
        ],
        nepassword: [
          textInputValidate,
          checkPassword,
          {
            validator: (rule, val, callback) => {
              if (val != this.model.rePassword)
                callback(new Error("Mật khẩu chưa giống nhau."));
              callback();
            },
            required: true,
            trigger: "blur",
          },
        ],
        rePassword: [
          textInputValidate,
          checkPassword,
          {
            validator: (rule, val, callback) => {
              if (val != this.model.nepassword)
                callback(new Error("Mật khẩu chưa giống nhau."));
              callback();
            },
            required: true,
            trigger: "blur",
          },
        ],
      },
      nameRouter: NameRouter,
    };
  },
  methods: {
    async submit(e) {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        e.preventDefault();
        return false;
      }
      let obj = {
        old_password: this.model.password,
        password: this.model.nepassword,
      };
      changePassword(obj)
        .then((e) => {
          if (e.data.status == "success") {
            notifySuccess("Thay đổi thành công");
          }
        })
        .catch((err) => {
          if (err.response) {
            notifyErr(err.response.data.message);
          }
        });
      e.preventDefault();
    },
    async onChange() {
      let valid = await this.$refs.form.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) {
        return false;
      }
    },
  },
};
</script>
<script setup>
import { Lock, User } from "@element-plus/icons-vue";
</script>
<style scoped lang="scss">
#login-page {
  margin-top: 20px;
}
#login-page .login .el-card {
  width: 800px;
  display: flex;
  justify-content: center;
}
#login-page .login-form {
  width: 700px;
}
</style>

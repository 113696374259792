<template>
  <div id="addStudentsToBatch">
    <el-form :model="form" ref="examForm" label-width="120px" label-position="top" :rules="rulesForm">
      <div class="bg-white p-3">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
                label="Tiêu đề"
                prop="title">
              <el-input
                  v-model="form.title"
                  placeholder="Tiều đề bài kiểm tra"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
                label="Thời gian thi(phút)"
                prop="duration">
              <el-input
                  v-model="form.duration"
                  placeholder="Thời gian thi."
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="text-center pt-4">
              <handle-button
                  label="Tạo bài thi"
                  eventCode="exams.store"
                  icon="fa fa-plus"
                  typeButton="primary"
                  @click="createExam"/>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="bg-white p-3 mt-3">
        <el-form ref="searchForm" label-width="120px" label-position="top">
          <div class="row">
            <div class="col-3 offset-4">
              <el-form-item
                  label="Nội dung câu hỏi">
                <el-input
                    v-model="setup.keyword"
                    placeholder="Nội dung câu hỏi"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="col-3">
              <el-form-item
                  label="Môn học">
                <el-input
                    v-model="setup.subjectKeyword"
                    placeholder="mộn học"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="col-2">
              <div class="pt-4 mt-1  text-center">
                <el-button type="primary" :loading="loading" @click="search">
                  <i class="fa fa-search"></i> Tìm kiếm
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
        <div class="p-2" v-if="isValid">
          <i class="fa fa-warning text-danger"></i> <small class="text-danger ">{{ messageErr }}</small>
        </div>
        <el-table
            class=" pb-3"
            :data="tableData"
            style="width: 100%"
            ref="studentTable"
            fixed
            @select="handleSelected"
            @selection-change="handleSelectAll"
            v-loading="loading">
          <el-table-column type="selection" width="55"/>
          <el-table-column prop="title" label="Nội dung câu hỏi" min-width="200"/>
          <el-table-column prop="type" label="Loại" min-width="150">
            <template #default="scope">
              <b v-if="manyCorrect === scope.row.type">Nhiều đáp án</b>
              <b v-if="manyCorrect !== scope.row.type">Một đáp án</b>
            </template>
          </el-table-column>
          <el-table-column prop="subjects" label="Môn học" min-width="200"/>
          <el-table-column prop="type" label="" min-width="150">
            <template #default="scope">
              <handle-button
                  label="Chỉnh sửa"
                  eventCode="exams.get-detail-exam"
                  icon="fa fa-edit"
                  typeButton="primary"
                  @click="openEditDialog(scope.row.id)"/>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb-3 text-center">
          <pagination
              v-show="isShowPagination"
              :total="total"
              :page-index="pageIndex"
              @currentPageChange="nextPage"
          ></pagination>
        </div>
      </div>
    </el-form>
    <edit-question v-model="setup.editDialog" ref="editQuestionDialog" @resetQuestion="getQuestions"></edit-question>
  </div>
</template>
<script>
import {CreatedExam, ListQuestion} from "@/services/exam";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {textInputValidate, ValidService} from "@/validator";
import Messages from "@/constants/messages";
import editQuestion from "@/views/Exam/editQuestion";

export default {
  name: "CreateExam",
  components: {
    pagination,
    editQuestion
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      nameRouter: NameRouter,
      loading: false,
      setup: {
        selected: [],
        keyword: '',
        subjectKeyword: '',
        editDialog: false,
      },
      form: {title: '', duration: ''},
      rulesForm: {
        title: [textInputValidate],
        duration: [textInputValidate, ValidService.checkNumber]
      },
      isValid: false,
      messageErr: Messages.QUESTION_REQUIRE,
      manyCorrect: 2,
    };
  },
  watch: {
    tableData: {
      handler(newTable) {
        let that = this
        that.$nextTick(function () {
          that.toggleSelection()
        })
      },
      immediate: true
    }
  },
  async created() {
    this.getQuestions()
  },
  methods: {
    getQuestions(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      ListQuestion(params).then(e => {
        let dataTable = []
        if (e.data.status === 'success') {
          let questions = e.data.data.questions.data
          questions.map(e => {
            let subjects = e.subject_question
            let subjectStr = ''
            if (subjects && subjects.length > 0) {

              subjects.forEach((item, index) => {
                if (index < (subjects.length - 1)) {
                  subjectStr += (item.subject ? item.subject.name : 'subject' + index) + ', '
                }
                if (index === (subjects.length - 1)) {
                  subjectStr += (item.subject ? item.subject.name : 'subject' + index)
                }
              })
            }
            dataTable.push({
              id: e.id,
              title: e.content,
              type: e.type,
              subjects: subjectStr
            })
          })
          this.total = e.data.data.questions.total
          this.isShowPagination = e.data.data.questions.total > 1
        }
        this.loading = false
        this.tableData = dataTable
      }).catch(err => {
        this.loading = false
      })
    },
    async nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getQuestions();
    },
    handleSelected(selected, row) {
      let indexItem = this.setup.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.setup.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.setup.selected.push(row.id)
      }

    },
    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.setup.selected.findIndex(id => e.id == id) > -1) return
          this.setup.selected.push(e.id)
        })
      }
    },
    toggleSelection(table) {
      if (this.setup.selected.length < 1) {
        return false
      }
      this.setup.selected.forEach(e => {
        let item = this.tableData.find(item => e == item.id)
        if (!item) return false;
        this.$refs.studentTable.toggleRowSelection(item);
      })
    },
    search() {
      let params = {
        per_page: configs.itemOnPage,
        page: 1,
        keyword: this.setup.keyword == '' ? null : this.setup.keyword,
        keyword_subject: this.setup.subjectKeyword ?? null
      }
      this.getQuestions(params)
    },
    async createExam() {
      event.preventDefault()
      let valid = await this.$refs.examForm.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (this.setup.selected.length < 1) {
        this.isValid = true
        valid = false
      }
      if (!valid) return false
      this.loading = true

      let obj = {
        title: this.form.title,
        duration: this.form.duration * 60,
        question_ids: this.setup.selected
      }
      CreatedExam(obj).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('add', 'Tạo bài thi thành công.')
          this.setup.selected = []
          this.$refs.examForm.resetFields()
          this.$router.push({name: NameRouter.PAGES.LIST_EXAM})
        }
        if (e.data.status === 'failed') {
          notifyErr('Tạo bài thi thất bại.')
        }
        this.loading = false
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
        this.loading = false
      })

    },
    openEditDialog(id) {
      event.preventDefault();
      this.setup.editDialog = true
      this.$refs.editQuestionDialog.getQuestionDetail(id)
    }
  },
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
</script>
<style scoped lang="scss">

</style>

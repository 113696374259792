<template>
  <div class="p-2" v-loading="loading">
    <div class="py-2 bg-white box-search">
      <div class="">
        <i>Tìm kiếm theo mục đích</i>
      </div>
      <el-row :gutter="10">
        <el-col :span="8" >
          <batch-select v-model="setup.batch_id" :show-label="false" :is-multiple="false" v-if="showBatchSelect"/>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              v-model="setup.date"
              class="w-100"
              type="date"
              placeholder="Ngày điểm danh"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-col>
        <el-col :span="4">
          <div class="d-flex justify-content-end">
            <button class="mx-3 py-1 btn btn-primary" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm</button>
            <button class="btn btn-warning mx-3 py-1" @click="reload">Làm mới</button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class=" my-3 p-3 bg-white">
      <el-row :gutter="20" v-if="studentRollCalls.length >0">
        <el-col :span="24"><b class="bold my-4">Thông tin lớp học:</b></el-col>
        <el-col :span="12">
          <div class="pb-2">
            <span>Tên lớp học:</span> {{ batch.batch_name }}
          </div>
          <div class="pb-2">
            <span>Tổng số học sinh:</span> {{ studentRollCalls.length }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="pb-2">
            <span>Mã lớp:</span> {{ batch.code }}
          </div>
          <div class="pb-2">
            <span>Thời gian học:</span> {{ batch.start_date }} đến {{ batch.end_date }}
          </div>
        </el-col>
        <el-col :span="24" class=" mt-2 mb-2 pt-2" style="border-top: 2px dashed #b0b3bc;">
          <b class="bold">Số lượt điểm danh:</b><small>(theo trạng thái)</small></el-col>
        <el-col :span="6">Sĩ số: {{ studentRollCalls.length }}</el-col>
        <el-col :span="6">Số người điểm danh đi : {{ countCheckin }}</el-col>
        <el-col :span="6">Số người điểm danh về: {{ countCheckout }}</el-col>
        <el-col v-for="(val, key) in configs.labelStatus" :key="key" :span="6">{{ `${val}: ${countStatus[key]}` }}
        </el-col>

      </el-row>
    </div>
    <div class="p-3 bg-white">
      <el-table :data="studentRollCalls" border>
        <el-table-column label="ID" width="50" prop="studentId"/>
        <el-table-column label="Tên học viên" min-width="150" prop="studentName"/>
        <el-table-column label="Ngày điểm danh" min-width="100" prop="dateRollCall"/>
        <el-table-column label="checkin" min-width="100" prop="checkin"/>
        <el-table-column label="checkout" min-width="100" prop="checkout"/>
        <el-table-column label="Trang thái" min-width="150" prop="status"/>
        <el-table-column label="action" width="150">
          <template #default="scope">
            <handle-button
                type-button="primary"
                icon="fa fa-eye"
                type="link"
                label="Chi tiết"
                event-code="students.get-detail-student"
                :to="{
              name: nameRouter.PAGES.DETAIL_STUDENT,
              params: { id: scope.row.studentId },
              query: {activeName: 'historyRollCall' }
            }"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {RollCallStatus, StudentRollCallReport} from "@/services/reportMaterial";
import batchSelect from "@/views/Batches/batchSelect";
import {formatDataServe, getNow, getToday, notifyErr, setBreadcrumb} from "@/mixins/utils";
import configs from "@/configs";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'RoleCallToDay',
  components: {
    batchSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      studentRollCalls: [],
      loading: true,
      showBatchSelect: true,
      setup: {
        batch_id: null,
        date: getToday(),
      },
      nameRouter: NameRouter,
      status: [],
      batch: {},
      countStatus: null,
      configs: configs,
      countCheckin: 0,
      countCheckout: 0,
    }
  },
  created() {
    this.getStatus()
    const $query = this.$route.query
    if (!$query || !$query?.batch_id) {
      this.setup.batch_id = null;
      this.studentRollCalls = []
      this.loading = false
    } else {
      this.setup.batch_id = $query?.batch_id
      this.getRollCall()
      this.showBatchSelect = true
    }
  },
  methods: {
    getRollCall() {
      if (!this.setup.batch_id) return notifyErr('Lớp học không được để chống.')
      StudentRollCallReport({batch_id: this.setup.batch_id, date: formatDataServe(this.setup.date)}).then(res => {
        if (res.data.status === 'success') {
          let rollCallStudent = []
          this.batch = res.data.data.batch
          res.data.data.rollCallReports.map(item => {
            let student = item.students
            item = item.roll_call_report.length > 0 ? item.roll_call_report[0] : null
            let status = []
            if (item?.time_check_in) {
              this.countCheckin++
            }
            if (item?.time_check_out) {
              this.countCheckout++
            }
            if (item) {
              if (this.setup.date === getToday()) {
                if (item.roll_call_report_status.length > 0) {
                  if (getNow() < configs.timeLearn.checkout) {
                    status.push(item.roll_call_report_status[0].roll_call_master_status?.name)
                  } else if (getNow() >= configs.timeLearn.checkout) {
                    item.roll_call_report_status.map(statusName => {
                      status.push(statusName.roll_call_master_status?.name)
                    })
                  }
                }
              }
              if (this.setup.date < getToday()) {
                if (item.roll_call_report_status.length > 0) {
                  item.roll_call_report_status.map(statusName => {
                    status.push(statusName.roll_call_master_status?.name)
                  })
                }
              }
            }
            rollCallStudent.push({
              studentId: student.id,
              studentName: student?.name,
              dateRollCall: item ? item.day : '',
              checkin: item ? item.time_check_in : '',
              checkout: item ? item.time_check_out : '',
              status: status.join(',')
            })
          })
          this.countStatus = res.data.data.countStatus
          this.studentRollCalls = rollCallStudent
        }
        this.loading = false
      }).catch(err => {
        notifyErr(err.response.data.message)
        this.loading = false
      })
    },
    getStatus() {
      RollCallStatus().then(res => {
        if (res.data.status === 'success') {
          this.status = res.data.data.rollCallMasterStatus
        }
      }).catch()
    },
    searchData() {
      this.$router.push({path: location.pathname, query: this.setup})
      this.getRollCall()
    },
    reload() {
      this.$router.push({path: location.pathname, query: {}})
      this.getRollCall()
    }
  }
}
</script>

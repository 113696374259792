<template>
  <div id="addStudentsToBatch">
    <div class="bg-white p-3">
      <div class="box-search py-2 " >
        <el-row :gutter="10">
          <el-col :span="6" class=" text-right">
            <el-input v-model="setup.keyword" placeholder="Tìm kiếm người dùng"/>
          </el-col>
          <el-col :span="10" class="text-right">
            <div class="d-flex">
              <button class="mx-3 py-1 btn btn-primary" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm</button>
              <button class="btn btn-warning mx-3 py-1" @click="reload"> Làm mới</button>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="d-flex pt-3"><h5>Danh sách giảng viên</h5></div>
    </div>
    <el-table
        class="px-3 pb-3"
        :data="tableData"
        style="width: 100%"
        ref="studentTable"
        border
        v-loading="loading">
      <el-table-column prop="username" label="Tên đăng nhập" width="150"/>
      <el-table-column prop="name" label="Họ tên" min-width="150"/>
      <el-table-column prop="email" label="Email" min-width="150"/>
      <el-table-column prop="role" label="Quyền hạn" min-width="100"/>
      <el-table-column
          label="TT hoạt động"
          min-width="100">
        <template #default="scope">
          <el-button
              type="success"
              size="small" v-if="scope.row.isActive">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button
              type="warning"
              size="small" v-if="!scope.row.isActive">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          min-width="100">
        <template #default="scope">
          <el-button
              type="warning"
              size="small"
              @click="assignTeacher(scope.row)"
              :disabled="scope.row.teacherHadClass">
            GV chấm bài
          </el-button>

          <handle-button
          icon="fa fa-trash"
          typeButton="danger"
          @click="openConfirm(scope.row)"
          v-if="scope.row.teacherHadClass"
         />
        </template>
      </el-table-column>
    </el-table>
    <div class="bg-white p-3">
      <pagination
          :total="total"
          v-show="isShowPagination"
          :pageIndex="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
  <el-dialog v-model="setup.dialogSetType" title="Thêm giáo viên với vai trò." width="30%">
    <div class="p-3">
      <el-select v-model="form.type" class="w-100">
        <div v-for="(type, index) in CONFIG_APP.typeTeacher" :key="type.key">
          <el-option
              :value="type.key"
              :label="type.value"
              v-if="idTeacherMain ? index > 0: index <= CONFIG_APP.typeTeacher.length">
          </el-option>
        </div>
      </el-select>
    </div>
    
    
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="setup.dialogSetType = false">Hủy</el-button>
        <el-button type="primary" @click="assignTeacher">Thêm vào lớp</el-button>
      </div>
    </template>
  </el-dialog>
  <confirm v-model="setup.confirmDialog" @deleteService="assignmentTeacherDelete" @close="setup.confirmDialog = false"/>
</template>
<script>

import {AssignmentTeacher,AssignmentTeacherDelete} from "@/services/common"
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, notifyWarning, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListTeacher} from "@/services/users";
import confirm from "@/components/confirm";
export default {
  name: "AddTeacherIntoBatch",
  components: {
    pagination
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      assignmentId: this.$route.params.id,
      assignment_id: this.$route.params.id,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
      loading: false,
      selected: [],
      batch: {},
      idTeacherMain: '',
      teachersInBatch: [],
      setup: {
        dialogSetType: false,
        keyword: null,
        confirmDialog: false,
      },
      typeTeacherMain: 1,
      typeTeacherSupport: 2,
      typeTeacher: 1,
      form: {
        type: 2,
        batch_id: this.$route.params.id,
        teacher_id: '',
      },
      deleteId: ''

    };
  },
  async created() {
    await this.getTeacher()
  },

  methods: {
    async getTeacher(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage, filter_active: configs.status.active}
      }
      GetListTeacher(params).then(e => {
        let dataTable = []
        let teacherInBatch = []
        let teacherHadClass = false, mainTeacher
        if (e.data.status === 'success') {
          let teachers = e.data.data.list_teacher.data
          teachers.map(e => {
            teacherHadClass = false
            mainTeacher = false
            if (e.batch_teacher && e.batch_teacher.length > 0) {
              e.batch_teacher.map(batch => {
                if (batch.type == configs.typeTeacher[0].key && batch.batch_id == this.idBatch) {
                  mainTeacher = true
                  this.idTeacherMain = e.id
                }
              })
              e.assignment_trainer.map(assignment => {
                if (assignment.assignment_id == this.assignmentId) {
                  teacherHadClass = true
                }
              })

            }
            dataTable.push({
              id: e.id,
              username: e.username,
              email: e.email,
              name: e.name,
              type: e.type,
              teacherHadClass: teacherHadClass,
              mainTeacher: mainTeacher,
              role: configs.roles[e.role_level],
              isActive: e.is_active == configs.status.active,
            })
          })
          this.teachersInBatch = teacherInBatch
          this.total = e.data.data.list_teacher.total
          this.isShowPagination = e.data.data.list_teacher.total > 1
        }
        this.loading = false

        this.tableData = dataTable
      }).catch(err => {
        this.loading = false
      })
    },
    async nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      await this.getTeacher();

    },
    closeDialog() {
      this.setup.dialogSetType = false
      this.form = {
        type: this.typeTeacherSupport,
        batch_id: this.$route.params.id,
        teacher_id: '',
      }
    },

    openDiagLog(row) {
  if (row.teacherHadClass) {
    return notifyWarning('Giảng viên đã chấm lớp này.');
  }
  this.form.user_id = row.id;
  if (!this.idTeacherMain) {
    return this.setup.dialogSetType = true;
  }
  this.form.type = this.typeTeacherSupport;
  this.assignTeacher();
},
    assignTeacher(row) {
      this.form.user_id = row.id;  
      this.form.assignment_id = this.assignment_id
      this.loading = true
      AssignmentTeacher(this.form).then(e => {
        if (e.data.status == 'success') {
          notifySuccess('add', 'Thêm GV chấm bài thành công.')
          this.setup.dialogSetType = false
          this.getTeacher();
        }

      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
      this.loading = false
    },

    openConfirm(data) {
      this.setup.confirmDialog = true
      this.deleteId = data.id
    },
    assignmentTeacherDelete() {
      AssignmentTeacherDelete({assignment_id: this.assignmentId, user_id: this.deleteId}).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('delete')
          this.getTeacher();
            this.setup.confirmDialog = false;
        }
      }).catch(err => {
        notifyErr(err.response.messages)
      })
      this.loading = false
    },
    searchData() {
      let params = {
        per_page: configs.itemOnPage,
        page: this.currentPage,
        search_name: this.setup.keyword ?? null
      }
      this.getTeacher(params);
    },
    reload() {
      let params = {
        keyword: null,
        per_page: configs.itemOnPage,
        page: 1,
      }
      this.currentPage = 1
      this.getTeacher(params)
    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
</script>
<style scoped lang="scss">

</style>

<template>
  <listMaterialCheckBox :show-checkbox="false"></listMaterialCheckBox>
</template>
<script>
import listMaterialCheckBox from "@/views/ManagementMaterial/listMaterialCheckBox";
import {setBreadcrumb} from "@/mixins/utils";

export default {
  name: 'ListMaterial',
  components: {
    listMaterialCheckBox
  },
  mixins: [setBreadcrumb],
}
</script>

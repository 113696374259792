<template>
  <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm">
    <el-form-item class="w-100" prop="name" label="Tên môn học">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item class="w-100" prop="code" label="Mã code">
      <el-input v-model="form.code"></el-input>
    </el-form-item>
    <el-form-item label="Phiên bản" prop="version">
      <el-input v-model.trim="form.version"></el-input>
    </el-form-item>

    <el-form-item label="Trạng thái hoạt động">
      <el-radio-group v-model="form.is_active">
        <el-radio :label="configs.status.active" size="lage" border>
          {{ configs.status.activeStr }}
        </el-radio>
        <el-radio :label="configs.status.inActive" size="lage" border>
          {{ configs.status.inActiveStr }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
import {textInputValidate} from "@/validator";

export default {
  expose: ['validForm', 'clearForm'],
  name: "InfoSubject",

  props: {
    form: Object
  },
  data() {
    return {
      validation: false,
      addContentRule: {
        name: [textInputValidate],
        code:[textInputValidate],
        version: [textInputValidate],
        is_active: [textInputValidate]
      },
    }
  },
  methods: {
    async validForm() {
      let valid = await this.$refs.contentForm.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      })
      if (!valid) return false;
      return true
    },

    async clearForm() {
      await this.$refs.contentForm.clearValidate()
    }
  }
}
</script>
<script setup>
import configs from "@/configs";
</script>
<style scoped>

</style>

<template>
    <div class="p-2 text-end bg-white">
        <el-row :gutter="20" :align="10">
            <el-col :span="8" class="pb-3">
                <batch-select 
                    v-model="setup.batch_id" 
                    :show-label="false" 
                    :isMultiple="false" 
                    ref="batchSelectBox"/>
            </el-col>
            <el-col :span="8" class="pb-3">
                <material-select 
                    v-model="setup.material_id" 
                    :show-label="false" 
                    :isMultiple="false"
                    ref="materialSelect"/>
            </el-col>
            <el-col :span="8" class="pb-3">
                <files-select 
                    v-model="setup.file_id" 
                    :show-label="false" 
                    :isMultiple="false"
                    ref="fileSelectBox"/>
            </el-col>

            <el-col :span="5">
                <handle-button label="Tìm kiếm" eventCode="students.list-student" icon="fa fa-search" typeButton="primary" @click="getListFile" />
                <handle-button label="Làm mới" eventCode="students.list-student" icon="refresh" typeButton="warning" @click="refreshSearch" />
            </el-col>
        </el-row>
    </div>
    <div class="p-2 bg-white">
        <el-table :data="tableData" style="width: 100%" fixed v-loading="loading">
            <el-table-column fixed prop="id" label="Id" min-width="50" />
            <el-table-column prop="nameStudent" label="Họ tên" min-width="150" />
            <el-table-column prop="username" label="Tên đăng nhập" min-width="150" />
            <el-table-column prop="submitDate" label="Ngày nộp" min-width="150" />
            <el-table-column prop="fileUrl" label="Download file" min-width="120">
                <template #default="scope">
                    <a v-if="scope.row.fileUrl" target="_blank" href="#" @click="downloadFile(scope.row.linkCode)"> <i class="fa fa-download"></i> Tải file</a>
                    <span v-if="!scope.row.fileUrl">Không file</span>
                </template>
            </el-table-column>
            <el-table-column prop="urlSource" label="Url Source" min-width="120">
                <template #default="scope">
                    <a v-if="scope.row.urlSource" target="_blank" :href="scope.row.urlSource"><i class="fa fa-file-archive"></i> Xem code</a>
                    <span v-if="!scope.row.urlSource">Không file</span>
                </template>
            </el-table-column>
            <el-table-column prop="score" label="Điểm" min-width="120">
                <template #default="scope" v-if="isEnable">
                    <input v-model="scope.row.score" type="text" class="form-control" :id="'score-' + scope.row.id" @change="changeScore(scope.row.id)" />
                    <div class="invalid-feedback" :id="'err-' + scope.row.id"></div>
                </template>
            </el-table-column>
            <el-table-column label="Hành động" min-width="120">
                <template #default="scope" v-if="isEnable">
                    <handle-button label="Lưu" eventCode="assignments_files.mark-assignment-file" icon="" typeButton="primary" @click="saveScore(scope.row.student_file_id, scope.row.id)" />
                </template>
            </el-table-column>
        </el-table>
        <div class="pb-3 text-center">
            <pagination v-show="isShowPagination" :total="total" :page-index="pageIndex" @currentPageChange="nextPage"></pagination>
        </div>
    </div>
</template>
<script>
import { ListFileByStudent } from "@/services/reportMaterial";
import configs from "@/configs";
import {formatDateTime, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import pagination from "@/components/pagination";
import batchSelect from "@/views/Batches/batchSelect";
import filesSelect from "@/views/ManagerFile/filesSelect.vue";
import materialSelect from "@/views/ManagementMaterial/materialSelect.vue";
import messages from "@/constants/messages";
import {MarkExercise}from "@/services/common";
export default {
    name: "MarkingMaterial",
    components: {
    pagination,
    batchSelect,
    materialSelect,
    filesSelect,
},
  mixins: [setBreadcrumb],
    data() {
        return {
            setup: {
                module_id: null,
                batch_id: null,
                material_id: null,
                file_id: null,
            },
            roadHasModule: false,
            loading: false,
            tableData: [],
            tableChildLoad: false,
            currentPageChild: 1,
            expandRowKeys: [],
            loadMore: true,
            total: 0,
            isShowPagination: false,
            currentPage: 1,
            pageIndex: 1,
            isEnable: false,
        };
    },
    methods: {
        hasModule(param) {
            this.roadHasModule = param;
        },
        changeScore(id) {
            let message = ''
            let findIndex = this.tableData.findIndex(el => el.id === id)
            if (findIndex < 0) return false;
            let item = this.tableData[findIndex];
            let elementInput = document.getElementById('score-' + id)
            let elementErr = document.getElementById('err-' + id)
            if (!item.score) message = messages.NULL
            if (item.score && isNaN(item.score)) message = messages.NUMBER
            if (item.score && !isNaN(item.score)) {
                if ((item.score < 0 || item.score > 10)) {
                message = 'Điểm > 0 và < 10'
                } else {
                message = ''
                }
            }
            elementErr.innerText = message
            if (message) {
                elementInput.classList.add('is-invalid')
            } else {
                elementInput.classList.remove('is-invalid')
            }
        },
        saveScore(student_file_id, id) {
            let findIndex = this.tableData.findIndex(el => el.id === id)
            if (findIndex < 0) return false;
            let item = this.tableData[findIndex];
            let score = parseInt(item.score);
            if (!score) 
                return notifyErr('Vui lòng điền đầy đủ thông tin.')
            else if(isNaN(score)) 
                return notifyErr('Vui lòng nhập điểm là kiểu số.')
            else if(!isNaN(score) && (score < 0 || score > 10)) 
                return notifyErr('Vui lòng nhập điểm > 0 và < 10.')
            let obj = {student_file_id: student_file_id, score: score}
            console.log(obj);
            MarkExercise(obj)
                .then(res => {
                    if (res.data.status == 'success') {
                        notifySuccess('add', 'Chấm điểm thành công.')
                        this.getListFile()
                    }
                }).catch(err => {
                    console.log(err);
                    notifyErr()
            })
        },

        getListFile(params) {
            this.loading = true;
            params = this.setup
            if (!params) {
                params = { per_page: configs.itemOnPage, page: this.currentPage };
            }
            ListFileByStudent(params)
                .then((res) => {
                    if (res.data.status == "success") {
                        let dataTable = [];
                        res.data.data.students.map((student) => {
                            if(student.student_file.length > 0 && (student.student_file[0].file_url || student.student_file[0].link_source_code)) {
                                this.isEnable = true
                            } else {
                                this.isEnable = false
                            }
                            dataTable.push({
                                id: student.id,
                                student_file_id: student.student_file.length > 0 ? student.student_file[0].id : "",
                                nameStudent: student.name,
                                username:  student.username,
                                fileUrl: student.student_file.length > 0 ? student.student_file[0].file_url : "" ,
                                urlSource: student.student_file.length > 0 ? student.student_file[0].link_source_code : "",
                                submitDate: formatDateTime(student.created_at),
                                score: student.student_file.length > 0 ? student.student_file[0].score : "",
                                linkCode: student.file_url,
                            });
                        });
                        this.tableData = dataTable;
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    notifyErr();
                });
        },
        nextPage(index) {
            this.currentPage = index;
            this.pageIndex = index;
            this.getListFile(index);
        },
        refreshSearch() {
            location.reload();
        },
    },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
    max-height: 50vh;
    overflow-y: scroll;
    -ms-overflow-y: scroll;

    th {
        position: sticky;
        top: -0.01rem;
        background-color: #6f42c1 !important;
        border: 1px solid #b0b3bc;
        color: #ffffff;
        padding-bottom: 0;
        padding-top: 0;
    }

    td {
        text-align: center;
        padding: 0.3em 0.9em;
    }
}
</style>

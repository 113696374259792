<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card" v-loading="loading">
    <el-tab-pane label="Thêm mới lộ trình" name="first">
      <infoModule ref="moduleInfo" :info-form="formModule" @resetContent="resetContent"></infoModule>
      <div class="text-center py-4">
        <handle-button
            label="Cập nhật"
            eventCode="modules.update"
            icon="fa fa-check"
            typeButton="primary"
            @click="updatedModule"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane label="Nội dung" name="content">
      <div class="text-end py-4">
        <el-button type="primary" :icon="Plus" @click="setup.contentDialog = true">Thêm nội dung</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          ref="modules"
          fixed>
        <el-table-column
            label="Hình ảnh"
            width="160">
          <template #default="scope">
            <el-image :src="scope.row.avatar">
              <div slot="placeholder" class="image-slot">
                Loading<span class="dot">...</span>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" :label="formModule.type == notModule? 'Tên Khóa học': 'Tên module'"/>
        <el-table-column label="Vị trí">
          <template #default="scope">
            <el-input v-model="scope.row.position" @change="validPosition(scope.row.id)"/>
            <span class="text-danger" :id="'position-'+ scope.row.id"></span>
          </template>
        </el-table-column>
        <el-table-column label="Vị trí" width="100">
          <template #default="scope">
            <el-button type="danger" size="small" :icon="Delete" @click="openConfirm(scope.row.id)">Xóa</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <el-dialog v-model="setup.contentDialog" @close="closeDialog">
    <template #title>
      <div class="border-bottom py-2"><b>Thêm nội dung</b></div>
    </template>
    <el-form label-position="top" :model="addContent" :rules="addContentRule" ref="contentForm">
      <el-form-item class="w-100" prop="id">
        <courseSelect v-model="addContent.id" :idOld="idOld" ref="courseSelectBox"></courseSelect>
      </el-form-item>
      <el-form-item label="Ví trí" prop="sort_order">
        <el-input v-model="addContent.sort_order"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">Đóng</el-button>
        <el-button type="primary" @click="contentNew">Lưu</el-button>
      </div>
    </template>
  </el-dialog>
  <confirm v-model="setup.confirmDialog" @close="closeDialog('confirmDialog')" @deleteService="removeContent"></confirm>
</template>

<script>
import {DetailModule, UpdateModule} from "@/services/course"
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import courseSelect from "@/views/Course/courseSelect";
import confirm from "@/components/confirm";
import {checkIsInteger, textInputValidate} from "@/validator";
import messages from "@/constants/messages";
import infoModule from "@/views/ManagementModule/infoModule";

export default {
  name: "Detail-Module",
  components: {
    confirm,
    courseSelect,
    infoModule
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      hasModule: 1,
      notModule: 2,
      formModule: {
        title: '',
        code: '',
        duration: '',
        description: '',
        image: '',
      },
      addContent: {
        id: null,
        sort_order: ''
      },
      addContentRule: {
        id: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger]
      },
      tableData: [],
      currentModule: '',
      loading: true,
      idOld: [],
      setup: {
        confirmDialog: false,
        activeName: 'first',
        contentDialog: false,
      },
      removeContentId: ''
    }
  },
  created() {
    this.getModule()
  },
  methods: {
    async updatedModule(type) {
      let vailForm = await this.$refs.moduleInfo.validForm()
      if (!vailForm) return false
      let content = [];

      if (type && type == 'contentNew') {
        content.push(this.addContent)
      }
      if (!type) {
        let valid = this.tableData.length > 0
        if (!valid) {
          this.activeName = 'content'
          return false
        }
      }
      let error = false
      this.tableData.map((item, index) => {
        if (!item.position || isNaN(item.position)) {
          this.setup.activeName = 'content'
          notifyErr('Có lỗi nhập liệu.')
          error = true
          return false
        }
        content.push({id: item.id, sort_order: item.position})
      })
      if (error) return false
      this.formModule.courses = content
      event.preventDefault()

      UpdateModule(this.currentModule, this.formModule).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
          this.getModule()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    async getModule() {
      this.loading = true
      this.currentModule = this.$route.params.id
      DetailModule(this.currentModule).then(res => {
        this.loading = false
        if (res.data.status == 'success') {
          let module = res.data.data.module
          this.formModule = {
            title: module.title,
            code: module.code,
            duration: module.duration ? module.duration / 60 : '',
            description: module.description,
            image: module.image,
          }
          let dataTable = []
          let idOld = []
          module.module_course.map(el => {
            let e = el.course
            idOld.push(e.id)
            dataTable.push({
              id: e.id,
              title: e.title,
              avatar: e.full_course_image_url,
              duration: e.duration && e.duration > 0 ? (e.duration / 60) : 0,
              summary: e.description,
              level: e.level,
              position: el.sort_order
            })
          })
          this.idOld = idOld
          this.tableData = dataTable
          this.$refs.moduleInfo.setImage(module.full_image_url)
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })

    },
    resetContent() {
      if (this.formModule.type == this.hasModule) {
        this.moduleId = []
      }
      if (this.formModule.type == this.notModule) {
        this.courseId = []
      }
    },
    async contentNew() {
      let valid = this.$refs.contentForm.validate((valid, fields) => {
        if (!valid || !this.addContent.id) {
          return false;
        }
      })
      if (!valid) return false;
      await this.updatedModule('contentNew')
      this.closeDialog()
    },
    closeDialog(type) {
      if (type) return this.confirmDialog = false
      this.setup.contentDialog = false
      this.$refs.courseSelectBox.resetData()
      this.addContent = {
        id: null,
        sort_order: '',
      }
    },
    openConfirm(id) {
      this.setup.confirmDialog = true
      this.removeContentId = id
    },
    removeContent() {
      let index = this.tableData.findIndex(item => item.id === this.removeContentId)
      this.tableData.splice(index, 1)
      this.updatedModule()
      this.setup.confirmDialog = false
    },
    validPosition(id) {
      let item = this.tableData.find(el => id == el.id)
      if (!item.position) {
        return document.getElementById('position-' + id).innerText = messages.REQUIRE
      }
      if (item.position && isNaN(item.position)) {
        document.getElementById('position-' + id).innerText = messages.NUMBER
      }
      if (item.position && !isNaN(item.position)) {
        document.getElementById('position-' + id).innerText = ''
      }
    }
  }
}
</script>
<script setup>
import {
  Check,
  Plus,
  Delete,
} from '@element-plus/icons-vue'
</script>
<style scoped lang="scss">
::v-deep .is-error {
  .vs__dropdown-toggle {
    border-color: red;
  }
}
</style>

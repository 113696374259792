<template>
  <div>
    <el-row class="mt-2">
      <el-col>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page="pageIndex"
            @current-change="currentPageChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import configs from "@/configs";

export default {
  name: "pagination-component",
  data() {
    return {};
  },
  props: {
    total: {
      type: Number,
      default: configs.totalSlide,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: configs.itemOnPage,
    },
  },
  methods: {
    currentPageChange(index) {
      this.$emit("currentPageChange", index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .el-pagination {
  float: right;

  .btn-next,
  .btn-prev {
    border-radius: 6px;
    box-sizing: border-box;
  }



::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: #ffffff;
  border-radius: 6px;

  &:hover {
    color: #ffffff;
  }
}}
</style>

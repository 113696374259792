const SET_BREADCRUMB = (state, name) => {
    state.breadcrumb = name
}
const SET_FILE_CONTENT = (state, data) => {
    state.fileContent = data
}
const SET_NAVBAR_CHILD = (state, data) => {
    state.navbarChild = data
}
export default {
    SET_BREADCRUMB, SET_FILE_CONTENT, SET_NAVBAR_CHILD
}
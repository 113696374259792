<template>
    <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;" fixed v-loading="loading" border>
        <el-table-column prop="index" label="Số thứ tự" width="100" />
        <el-table-column prop="point_change" label="Thời gian bắt đầu bảo lưu" width="450" />
        <el-table-column prop="description" label="Lý do" />
    </el-table>
</template>
<script>
import configs from "@/configs";
import { NameRouter } from "@/constants/nameRouter";
import { GetHistoryPointStudent } from "@/services/batches";
import { notifyErr, getDefaultDate, formatDataServe, formatDate } from "@/mixins/utils";
import moment from "moment";

export default {
    name: "HistoryClass-Student",
    data() {
        return {
            tableData: [],
            loading: false,
            configs: configs,
            nameRouter: NameRouter,
            value: [],
        };
    },

    methods: {
        getHistoriesCLassStudent(param) {
            this.loading = true;
            if (!param) {
                param = {
                    start_date: formatDataServe(this.value[0]),
                    end_date: formatDataServe(this.value[1]),
                    student_id: this.$route.params.id
                };
            }
            GetHistoryCLassStudent(param).then(e => {
                let dataTable = [];
                if (e.data.status == 'success') {
                    e.data.data.listHistories.data?.map((i, index) => {
                        dataTable.push({
                            ...i,
                            index: index + 1,
                            created_at: formatDate(i.created_at),
                            point_current: i.type_change === 2 ? i.point_old - i.point_change : i.point_old + i.point_change
                        })
                    })
                    this.tableData = dataTable;
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                notifyErr()
            })
        },
        setDefaultValue() {
            this.value = getDefaultDate()
        }
    },
};
</script>
<script setup>


</script>
<style scoped></style>
  
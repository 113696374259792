<template>
  <div class="p-2">
    <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
      <el-tab-pane label="Thông tin trang" name="pageForm">
        <el-form :model="form" ref="pageForm" label-width="120px" label-position="top">
          <div class="text-end">
            <el-button type="primary" :icon="Plus" @click="addItem(configs.typeSetting[2])">Thêm từ khóa</el-button>
          </div>
          <el-row>
            <el-col :span="24">
              <div class=" bg-white p-3 m-3">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col" style="width: 15%">STT</th>
                    <th scope="col" style="width: 20%">Từ khóa</th>
                    <th scope="col" style="width: 20%">Giá trị</th>
                    <th scope="col" style="width: 30%">Trạng thái</th>
                    <th scope="col" style="width: 10%">Hành động</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(word, index) in form.pageForm" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <el-form-item
                          :prop="'pageForm['+index+'].key'"
                          :rules="rules.key"
                          class="m-0"
                      >
                        <el-input
                            v-model="word.key"
                            placeholder="Nhập từ khóa"
                        >
                        </el-input>
                      </el-form-item>
                    </td>
                    <td class="">
                      <el-form-item
                          :prop="'pageForm['+index+'].value'"
                          :rules="rules.value"
                          class="m-0"
                      >
                        <el-input
                            v-model="word.value"
                            placeholder="Nhập giá trị"
                        >
                        </el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item class="m-0 ">
                        <el-radio-group v-model="word.status" class="d-flex justify-content-center w-100">
                          <el-radio :label="1">Hoạt động</el-radio>
                          <el-radio :label="0">Dừng hoạt động</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </td>
                    <td class="text-center">
                      <el-button size="small" type="danger"
                                 :icon="Delete"
                                 @click="removeItem(configs.typeSetting[2],word.id)"
                      ></el-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
          </el-row>
          <div class="text-center">
            <handle-button
                label="Thêm từ khóa"
                eventCode=""
                icon=""
                typeButton="primary"
                @click="addWord(configs.typeSetting[2])"/>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Hình ảnh" name="imageForm">
        <el-row>
          <el-col :span="12" :offset="6">
            <el-form :model="form.imageForm" ref="imageForm" :rules="imageFormRule" label-position="top">
              <el-form-item label="Từ khóa:" prop="key">
                <el-input v-model="form.imageForm.key" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="Giá trị:">
                <el-input v-if="form.imageForm.value" v-model="form.imageForm.value" autocomplete="off" disabled/>
                <el-upload
                    v-if="!form.imageForm.value"
                    limit="1"
                    action="/"
                    ref="uploadFile"
                    :before-upload="beforeAvatarUpload"
                >
                  <el-button type="primary" :icon="UploadFilled">Chon tệp</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item label="Trạng thái:">
                <el-radio-group v-model="form.imageForm.status">
                  <el-radio :label="1">Hoạt động</el-radio>
                  <el-radio :label="0">Dừng hoạt động</el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="text-center">
                <handle-button
                    label="Thêm từ khóa"
                    eventCode=""
                    icon=""
                    typeButton="primary"
                    @click="addWord(configs.typeSetting[1])"/>
              </div>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Thông tin hệ thống" name="systemForm">
        <!--    systemForm-->
        <el-form :model="form" ref="systemForm" label-width="120px" label-position="top">
          <div class="text-end">
            <el-button type="primary" :icon="Plus" @click="addItem(configs.typeSetting[0])">Thêm từ khóa</el-button>
          </div>
          <el-row>
            <el-col :span="24">
              <div class=" bg-white p-3 m-3">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col" style="width: 15%">STT</th>
                    <th scope="col" style="width: 20%">Từ khóa</th>
                    <th scope="col" style="width: 20%">Giá trị</th>
                    <th scope="col" style="width: 30%">Trạng thái</th>
                    <th scope="col" style="width: 10%">Hành động</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(word, index) in form.systemForm" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <el-form-item
                          :prop="'systemForm['+index+'].key'"
                          :rules="rules.key"
                          class="m-0"
                      >
                        <el-input
                            v-model="word.key"
                            placeholder="Nhập từ khóa"
                        >
                        </el-input>
                      </el-form-item>
                    </td>
                    <td class="">
                      <el-form-item
                          :prop="'systemForm['+index+'].value'"
                          :rules="rules.value"
                          class="m-0"
                      >
                        <el-input
                            v-model="word.value"
                            placeholder="Nhập giá trị"
                        >
                        </el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item class="m-0 ">
                        <el-radio-group v-model="word.status" class="d-flex justify-content-center w-100">
                          <el-radio :label="1">Hoạt động</el-radio>
                          <el-radio :label="0">Dừng hoạt động</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </td>
                    <td class="text-center">
                      <el-button size="small" type="danger"
                                 :icon="Delete"
                                 @click="removeItem(configs.typeSetting[0],word.id)"
                      ></el-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
          </el-row>
          <div class="text-center">
            <handle-button
                label="Thêm từ khóa"
                eventCode=""
                icon=""
                typeButton="primary"
                @click="addWord(configs.typeSetting[0])"/>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Nội quy" name="rulesForm">
        <!--    rulesForm-->
        <el-form :model="form" ref="rulesForm" label-width="120px" label-position="top">
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form :model="form.rulesForm" ref="imageForm" :rules="rulesFormRule" label-position="top">
                <el-form-item label="Tiêu đề :" prop="title">
                  <el-input v-model="form.rulesForm.title" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="Giá trị :" prop="value">
                  <el-input v-model="form.rulesForm.value" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="Loại:">
                  <el-radio-group v-model="form.rulesForm.typeRule">
                    <el-radio :label="1">Điểm cộng</el-radio>
                    <el-radio :label="0">Điểm trừ</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="Trạng thái:">
                  <el-radio-group v-model="form.rulesForm.status">
                    <el-radio :label="1">Hoạt động</el-radio>
                    <el-radio :label="0">Dừng hoạt động</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="14">
              <el-form-item label="Mô tả:">
                <div :class="{'hasErr': descriptionValid}">
                  <editorTiny v-model="form.rulesForm.description"
                              style="height:300px; width:100% ; overflow-y: hidden; border-bottom: 1px solid #ccc;"/>
                </div>
              </el-form-item>
            </el-col>
            <el-col>
              <div class="text-center">
                <handle-button
                    label="Tạo mới"
                    eventCode="system-infos.store"
                    icon=""
                    typeButton="primary"
                    @click="addWord(configs.typeSetting[3])"/>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Quy định" name="rulesClassForm">
        <!--    rulesClassForm-->
        <el-form :model="form" ref="rulesClassForm" label-width="120px" label-position="top">
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form :model="form.rulesClassForm" ref="imageForm" :rules="rulesFormRule" label-position="top">
                <el-form-item label="Tiêu đề :" prop="title">
                  <el-input v-model="form.rulesClassForm.title" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="Dành cho lớp:">
                  <el-radio-group v-model="form.rulesClassForm.value">
                    <el-radio :label="'Fulltime'">Fulltime</el-radio>
                    <el-radio :label="'Parttime'">Parttime</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="Trạng thái:">
                  <el-radio-group v-model="form.rulesClassForm.status">
                    <el-radio :label="1">Hoạt động</el-radio>
                    <el-radio :label="0">Dừng hoạt động</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="14">
              <el-form-item label="Mô tả:">
                <div :class="{'hasErr': descriptionValid}">
                  <editorTiny v-model="form.rulesClassForm.description"
                              style="height:300px; width:100% ; overflow-y: hidden; border-bottom: 1px solid #ccc;"/>
                </div>
              </el-form-item>
            </el-col>
            <el-col>
              <div class="text-center">
                <handle-button
                    label="Tạo mới"
                    eventCode="system-infos.store"
                    icon=""
                    typeButton="primary"
                    @click="addWord(configs.typeSetting[4])"/>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Video nổi bật" name="featured_video">
        <el-row>
          <el-col :span="12" :offset="6">
            <el-form :model="form.featuredVideo" ref="featuredVideo" :rules="featuredVideoRule" label-position="top">
              <el-form-item label="Tiêu đề:" prop="key">
                <el-input v-model="form.featuredVideo.key" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="Link Youtube" prop="key">
                <el-input v-model="form.featuredVideo.value" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="Ảnh bìa:">
                <el-input v-if="form.featuredVideo.description" v-model="form.featuredVideo.description" autocomplete="off" disabled/>
                <el-upload
                    v-if="!form.featuredVideo.description"
                    limit="1"
                    action="/"
                    ref="uploadFile"
                    :before-upload="beforeAvatarUpload"
                >
                  <el-button type="primary" :icon="UploadFilled">Chon tệp</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item label="Trạng thái:">
                <el-radio-group v-model="form.imageForm.status">
                  <el-radio :label="1">Hoạt động</el-radio>
                  <el-radio :label="0">Dừng hoạt động</el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="text-center">
                <handle-button
                    label="Thêm từ khóa"
                    eventCode=""
                    icon=""
                    typeButton="primary"
                    @click="addWord('featured_video')"/>
              </div>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {textInputValidate} from "@/validator";
import {CreateSystemInfo} from "@/services/setting";
import {notifyWarning, notifySuccess, notifyErr, setBreadcrumb, isImage} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import configs from "@/configs";
import {UploadFile} from "@/services/common";
import messages from "@/constants/messages";
import editorTiny from "@/components/editor";


export default {
  name: 'SettingCreate',
  components: {
    editorTiny
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      rules: {
        key: [textInputValidate],
        value: [textInputValidate],
        description: [textInputValidate],
      },
      imageFormRule: {
        key: [textInputValidate],
      },
      featuredVideoRule: {
        key: [textInputValidate],
      },
      rulesFormRule: {
        title: [textInputValidate],
        value: [textInputValidate],
      },
      limitWord: 1,
      setup: {
        activeName: 'pageForm'
      },
      form: {
        systemForm: [
          {id: new Date().getTime(), key: '', value: '', status: 1}
        ],
        imageForm: {key: '', value: '', status: 1},
        pageForm: [
          {id: new Date().getTime() + 2, key: '', value: '', status: 1}
        ],
        rulesForm:
            {key: 'point', value: '', description: '', title: '', status: 1, typeRule: 1},
        rulesClassForm:
            {key: "rulesClassForm", value: 'Fulltime', description: '', title: '', status: 1, typeRule: 1} ,
        featuredVideo:
            {key: "", value: '',description:'', status: 1}

      },
      NameRouter: NameRouter,
      configs: configs,
      file: null,
      descriptionValid: false,
      messages: messages,
    }
  },

  watch: {
    file() {
      if (!this.file) return false;
      this.createFile()
    }
  },
  methods: {
    addItem(type) {
      if (type === this.configs.typeSetting[0]) {
        this.form.systemForm.push({id: new Date().getTime(), key: "", value: "", status: 1});
      }
      if (type === this.configs.typeSetting[2]) {
        this.form.pageForm.push({id: new Date().getTime(), key: "", value: "", status: 1});
      }

    },
    removeItem(type, idWord) {
      if (type === this.configs.typeSetting[0]) {
        let indexWord = this.form.systemForm.findIndex(e => e.id === idWord);
        if (this.form.systemForm.length == this.limitWord) {
          return notifyWarning('Phải có ít nhất một từ.')
        }
        this.form.systemForm.splice(indexWord, 1);
      }
      if (type === this.configs.typeSetting[2]) {
        let indexWord = this.form.pageForm.findIndex(e => e.id === idWord);
        if (this.form.pageForm.length == this.limitWord) {
          return notifyWarning('Phải có ít nhất một từ.')
        }
        this.form.pageForm.splice(indexWord, 1);
      }

    },
    async addWord(type) {
      if (type === this.configs.typeSetting[0]) {
        let valid = await this.$refs.systemForm.validate((valid, fields) => {
          if (!valid) {
            return false;
          }
        });
        if (!valid) return false;

        let param = this.form.systemForm.map((e) => ({
          code_system: type,
          key: e.key,
          value: e.value,
          is_active: e.status,
        }))
        for (let i = 0; i < param.length; i++) {
          try {
            let res = await CreateSystemInfo(param[i]);
          } catch (e) {
            return notifyErr();
          }
        }
        this.form.systemForm = [{key: "", value: "", status: 1}]
        return notifySuccess("add");
      }
      if (type === this.configs.typeSetting[1]) {
        let valid = await this.$refs.imageForm.validate((valid, fields) => {
          if (!valid) {
            return false;
          }
        });
        if (!valid) return false;
        if (!this.form.imageForm.value) {
          return notifyErr("Bạn chưa Upload file ");
        }
        let param = {
          code_system: type,
          key: this.form.imageForm.key,
          value: this.form.imageForm.value,
          is_active: this.form.imageForm.status,
        }
        try {
          let res = await CreateSystemInfo(param);
          notifySuccess("add");
          this.form.imageForm = {key: "", value: "", status: 1}
        } catch (e) {
          notifyErr();
        }
      }
      if (type === this.configs.typeSetting[2]) {
        let valid = await this.$refs.pageForm.validate((valid, fields) => {
          if (!valid) {
            return false;
          }
        });
        if (!valid) return false;

        let param = this.form.pageForm.map((e) => ({
          code_system: type,
          key: e.key,
          value: e.value,
          is_active: e.status,
        }))
        for (let i = 0; i < param.length; i++) {
          try {
            let res = await CreateSystemInfo(param[i]);
          } catch (e) {
            notifyErr();
          }
        }
        notifySuccess("add");
        this.form.pageForm = [{key: "", value: "", status: 1, typeRule: 1}]
      }
      if (type === this.configs.typeSetting[3]) {
        this.descriptionValid = this.form.rulesForm.description == ''
        let valid = await this.$refs.rulesForm.validate((valid, fields) => {
          if (!this.form.rulesForm.description) {
            return false;
          }
        });
        if (!valid) return false;

        let param = {
          ...this.form.rulesForm,
          is_active: this.form.rulesForm.status,
          code_system: this.form.rulesForm.typeRule == 1 ? this.configs.typeRuleSetting[1] : this.configs.typeRuleSetting[0],
        }
        try {
          let res = await CreateSystemInfo(param);
          notifySuccess("add");
          this.form.rulesForm =  {key: "point", value: 'true', description: '', title: '', status: 1, typeRule: 1}
        } catch (e) {
          notifyErr();
        }
      } if (type === this.configs.typeSetting[4]) {
        this.descriptionValid = this.form.rulesClassForm.description == ''
        let valid = await this.$refs.rulesClassForm.validate((valid, fields) => {
          if (!this.form.rulesForm.description) {
            return false;
          }
        });
        if (!valid) return false;

        let param = {
          ...this.form.rulesClassForm,
          is_active: this.form.rulesClassForm.status,
          code_system: "rules_Class",
        }
        try {
          let res = await CreateSystemInfo(param);
          notifySuccess("add");
          this.form.rulesForm =  {key: "rulesClassForm", value: 'Fulltime', description: '', title: '', status: 1, typeRule: 1}
        } catch (e) {
          notifyErr();
        }
      }
      if (type === "featured_video") {
        let valid = await this.$refs.featuredVideo.validate((valid, fields) => {
          if (!valid) {
            return false;
          }
        });
        if (!valid) return false;
        if (!this.form.featuredVideo.description) {
          return notifyErr("Bạn chưa Upload file ");
        }
        let param = {
          code_system: "featured_video",
          key: this.form.featuredVideo.key,
          value: this.form.featuredVideo.value,
          description: this.form.featuredVideo.description,
          is_active: this.form.featuredVideo.status,
        }
        try {

          let res = await CreateSystemInfo(param);

          notifySuccess("add");
          this.form.featuredVideo = {key: "", value: '',description:'', status: 1}
        } catch (e) {
          notifyErr();
        }
      }
    },
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) return notifyWarning('Vui lòng chọn tệp ảnh.')
      this.file = uploadFile
    },
    async createFile() {
      let formData = new FormData
      formData.append('file', this.file)
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          if (window.location.href.search(process.env.VUE_APP_URL_PRODUCT) !== -1) {
            this.form.imageForm.value = configs.baseUrlImageProduct + e.data.data.filePath;
            this.form.featuredVideo.description = configs.baseUrlImageProduct + e.data.data.filePath
          } else if (window.location.href.search(process.env.VUE_APP_URL_DEV) !== -1) {
            this.form.imageForm.value = configs.baseUrlImage + e.data.data.filePath
            this.form.featuredVideo.description = configs.baseUrlImage + e.data.data.filePath
          } else if (window.location.href.search(process.env.VUE_APP_URL_WEB_ONLINE) !== -1) {
            this.form.imageForm.value =configs.baseUrlImageOnline + e.data.data.filePath
            this.form.featuredVideo.description = configs.baseUrlImageOnline + e.data.data.filePath
          } else {
            this.form.imageForm.value =configs.baseUrlImage + e.data.data.filePath
            this.form.featuredVideo.description = configs.baseUrlImage + e.data.data.filePath
          }

          ;
        }
      })
    },
  }

}
</script>
<script setup>
import {
  Delete,
  Plus,
  UploadFilled
} from '@element-plus/icons-vue'
import {ref} from "vue";

const uploadFile = ref('')
</script>


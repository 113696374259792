const breadcrumbSet = ({commit}, payload) => {
    commit('SET_BREADCRUMB', payload)
}
const fileContentSet = ({commit}, payload) => {
    commit('SET_FILE_CONTENT', payload)
}
const navbarChildSet = ({commit}, payload) => {
    commit('SET_NAVBAR_CHILD', payload)
}
export default {
    breadcrumbSet,
    fileContentSet,
    navbarChildSet
}

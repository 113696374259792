<template>
  <div class="bg-white p-3">
    <infoBlog ref="blog" :infoBlog="formBlog"></infoBlog>
    <div class="text-center py-4">
      <handle-button
          label="Tạo bài viết"
          eventCode=""
          icon="fa fa-plus"
          typeButton="primary"
          @click="createBlog"
      />
    </div>
  </div>
</template>
<script>
import infoBlog from "@/views/BlogManagement/infoBlog";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {CreateBlog} from "@/services/blog";

export default {
  name: 'CreateBlog',
  components: {
    infoBlog,
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      formBlog: {
        title: '',
        description: '',
        code: '',
        slug:'',
        priority: 1,
        is_active:1,
        status:3,
        tag: [],
        content: '',
        avatar: '',
      },
      nameRouter: NameRouter,
    }
  },
  created() {
    this.generateRandomCode();
  },
  methods: {
    async createBlog() {
      let vailForm = await this.$refs.blog.validBlog()
      if (!vailForm) return false;
      let param = {
        ...this.formBlog,
        tag: this.formBlog.tag.toString(),
        slug:this.formBlog.slug + "-" + Date.now(),
      }

      try {
        await CreateBlog( param);
        notifySuccess("add");
        this.$router.push({name: NameRouter.PAGES.LIST_BLOG});
      } catch (error) {
          notifyErr(error.data.response.message);
      }
    },
    generateRandomCode(){
      this.formBlog.code = (Date.now()).toString();
    }


  }
}
</script>

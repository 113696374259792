<template>
  <div id="managementStudent" class="bg-white p-3">
    <div class="py-2 text-end">
      <el-row :gutter="20" :align="10">
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm tên học sinh" v-model="setup.search_name"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <batch-select v-model="setup.batch_id" :show-label="false" :isMultiple="false" :add-option="false"
                        ref="batchSelectBox"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <course-select v-model="setup.course_id" :show-label="false" :isMultiple="false" :add-option="true"
                         ref="courseSelectBox"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <material-select
              v-model="setup.material_id"
              :show-label="false"
              :isMultiple="false"
              :add-option="false"
              ref="materialSelectBox"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <el-select placeholder="Tìm kiếm trạng thái" class="w-100" v-model="setup.status">
            <el-option :value="null" label="Tất cả"/>
            <el-option v-for="(val, key) in configs.rattingStatus" :value="key" :label="val" :key="key"/>
          </el-select>
        </el-col>
        <el-col :span="8">
          <handle-button
              label="Tìm kiếm"
              eventCode="students.list-student"
              icon="fa fa-search"
              typeButton="primary"
              @click="searchStudent"/>
          <handle-button
              label="Làm mới"
              eventCode="students.list-student"
              icon="refresh"
              typeButton="warning"
              @click="refreshSearch"/>

        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData"
              style="width: 100%" fixed
              :row-key="row => row.id+'-'+row.materialId"
              :expand-row-keys="expandRowKeys"
              @expand-change="getRattingByMaterial"
              v-loading="loading" border>
      <el-table-column type="expand">
        <template #default="props">
          <div class="py-2 pl-5 pr-3">
            <div class="table-wrapper" :id="'student'+props.row.id" v-if="props.row.child">
              <table class="table-bordered">
                <thead>
                <tr>
                  <th>Ngày tạo</th>
                  <th>Ngày cập nhật</th>
                  <th>Bài học</th>
                  <th>Tự nhận xét</th>
                  <th>Nội dung đánh giá</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in props.row.child" :key="index">
                  <td>{{ item.createAt }}</td>
                  <td>{{ item.updateAt }}</td>
                  <td>{{ item.materialName }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.content }}</td>
                </tr>
                <tr v-if="props.row.child.length <= 0">
                  <td colspan="5">Không có lịch sử</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="index" label="TT" width="100"/>
      <el-table-column prop="id" label="ID" width="100"/>
      <el-table-column prop="name" label="Họ tên" min-width="120"/>
      <el-table-column prop="materialName" label="Tên bài học" min-width="150"/>
      <el-table-column prop="batchName" label="Tên lớp" min-width="120"/>
      <el-table-column prop="content" label="Nội dung đánh giá" min-width="200"/>
      <el-table-column prop="createdAt" label="Ngày tạo" min-width="100"/>
      <el-table-column
          fixed="right"
          label="Đánh giá"
          width="150">
        <template #default="scope">
          <el-button type="warning" size="small">{{ configs.rattingStatus[scope.row.status] }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          width="160">
        <template #default="scope">
          <handle-button
              type="link"
              label="Chi tiết"
              eventCode="students.get-detail-student"
              icon="fa fa-eye"
              typeButton="primary"
              :to="{name: nameRouter.PAGES.DETAIL_MATERIAL , params:{id: scope.row.materialId}}"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          :total="total"
          v-show="isShowPagination"
          :pageIndex="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import {GetRatting, ReportMaterial, StudentMaterialRatting} from "@/services/reportMaterial";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, formatDateTime, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import batchSelect from "@/views/Batches/batchSelect";
import courseSelect from "@/views/Course/courseSelect";
import MaterialSelect from "@/views/ManagementMaterial/materialSelect";

export default {
  name: "ListRatting",
  components: {
    pagination,
    batchSelect,
    courseSelect,
    MaterialSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogDetail: false,
      setup: {
        keyword: null,
        batch_id: null,
        material_id: null,
        course_id: null,
        status: null,
      },
      nameRouter: NameRouter,
      dialogAdd: false,
      currentId: '',
      configs: configs,
      expandRowKeys: [],
    };
  },

  created() {
    let query = this.$router.currentRoute?.value?.query;
     let {batch_id, course_id, keyword, material_id,status, filter_active, per_page, page} = query;
    if (batch_id|| course_id|| keyword|| material_id||status|| filter_active|| per_page|| page) {
      this.setup = query
      this.rattingList(query);
    } else {
      this.rattingList();
    }

  },
  watch: {
    'setup.batch_id'(old) {
      if (!old) return false
      this.setup.course_id = null
      this.$refs.courseSelectBox?.searchByBatch({batch_id: old})
    },
    'setup.course_id'(old) {
      if (!old) return false
      this.setup.material_id = null
      this.$refs.materialSelectBox?.searchBy({course_id: old})
      this.tableData = []
    },
  },


  methods: {
    rattingList(param) {
      this.loading = true
      if (!param) {
        param = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      let maxRating = 0;
      for (let key in configs.rattingStatus) {
        maxRating++
      }
      GetRatting(param).then(res => {
        let dataTable = []
        if (res.data.status === 'success') {
          let materialRatting = res.data.data.materialRatings.data
          materialRatting.map((e, index) => {
            dataTable.push({
              index: index + 1,
              id: e.student.id,
              name: e.student.name,
              batchName: e.student.batch_students[0] ? e.student.batch_students[0].batches.batch_name : '',
              courseName: e.material.courses ? e.material.courses.title : '',
              materialName: e.material.name,
              materialId: e.material.id,
              content: e.content,
              status: e.status,
              short: maxRating - e.status,
              createdAt: formatDateTime(e.created_at),
              currentPage: 1,
              loadingChild: true,
              notDataChild: false,
              child: []
            })
          })
          this.total = res.data.data.materialRatings.total
          this.isShowPagination = res.data.data.materialRatings.total > 1
          this.tableData = dataTable
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },

    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = this.setup
      params.page = this.currentPage
      params.per_page = configs.itemOnPage
      this.rattingList(params);
    },

    closeDialog(type) {
      if (type == 'dialogAdd') {
        this.dialogAdd = false
        this.$refs.formAdd.resetFields()
      }
      return this.dialogDetail = false
    },

    searchStudent() {
      this.currentPage = 1
      let params = this.setup
      params.page = this.currentPage
      params.per_page = configs.itemOnPage
      this.$router.push({path: location.pathname, query: params})
      this.rattingList(params)
    },

    refreshSearch() {
      this.currentPage = 1
      this.setup = {
        keyword: null,
        batch_id: null,
        material_id: null,
        course_id: null,
        status: null,
      }
      let params = this.setup
      params.page = this.currentPage
      params.per_page = configs.itemOnPage
      this.$router.push({path: location.pathname, query: {}})
      this.rattingList(params)
      this.$refs.batchSelectBox.resetData()
    },
    getRattingByMaterial(row, expandedRows) {
      if (!expandedRows) return false
      let indexItem = this.tableData.findIndex(item => item.id == row.id && item.materialId == row.materialId)
      if (indexItem < 0) return false
      this.expandRowKeys = []
      this.tableData[indexItem].child = []
      this.tableData[indexItem].loadingChild = true
      if (!expandedRows.find(item => item.id == row.id)) {
        this.tableData[indexItem].currentPage = 1
        return false
      }
      this.expandRowKeys.push(row.id + '-' + row.materialId)
      this.studentRatting(row.id, indexItem)
    },
    studentRatting(studentId, indexItem) {
      if (this.tableData[indexItem].notDataChild) return false
      let param = {
        student_id: studentId,
        material_id: this.tableData[indexItem].materialId
      }
      StudentMaterialRatting(param).then(res => {
        let studentReportData = res.data.data.materialRatings;
        if (studentReportData.length <= 0) {
          this.tableData[indexItem].loadingChild = false
          this.tableData[indexItem].notDataChild = true
          return false
        }
        studentReportData.map(item => {
          this.tableData[indexItem].child.push({
            name: item.name,
            student_id: item.id,
            status: item ? configs.rattingStatus[item.status] : 'Chưa đánh giá',
            materialName: this.tableData[indexItem].materialName,
            content: item.content,
            createAt: item.created_at ? formatDateTime(item.created_at) : '',
            updateAt: item.updated_at ? formatDateTime(item.updated_at) : ''
          })
        })
        this.tableData[indexItem].loadingChild = false
      }).catch(err => {
      })
    },
  },
}
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {
  Check,
  Close,
} from '@element-plus/icons-vue'
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}

.infoStudent {
  th, td {
    text-align: left;
    padding: 0.8rem;
    border-bottom: 1px solid #bbbbbb;
  }
}

.table-wrapper {
  max-height: 50vh;
  overflow-y: scroll;
  -ms-overflow-y: scroll;

  th {
    position: sticky;
    top: -0.01rem;
    background-color: #6f42c1 !important;
    border: 1px solid #B0B3BC;
    color: #ffffff;
    padding-bottom: 0;
    padding-top: 0;
  }

  td {
    text-align: center;
    padding: 0.3em 0.9em;
  }
}

</style>

<template>
  <div id="managementBatches" class="px-3 py-3">
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border>
      <div class="search my-2">
        <el-row :gutter="10">
          <el-col :offset="16" :span="4">
            <el-input v-model="setup.keyword"/>
          </el-col>
          <el-col :span="4">
            <el-button :icon="Search" type="primary" @click="searchData">Tìm kiếm</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table-column prop="id" label="Id" min-width="35"/>
      <el-table-column
          label="Hình ảnh"
          width="160"
      >
        <template #default="scope">
          <el-image :src="scope.row.avatar" style="width: 100px; height: 100px" :fit="fit">
            <div slot="placeholder" class="image-slot">
              Loading<span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Tên bài viết" min-width="150"/>
      <el-table-column prop="code" label="Mã code" min-width="70"/>
      <el-table-column prop="tag" label="Tag" min-width="100"/>
      <el-table-column prop="description" label="Description" min-width="200" />
      <el-table-column
          min-width="150"
          label="Hành động"
          fixed="right"
      >
        <template #default="scope">
          <handle-button
              type="link"
              label="Chi tiết"
              eventCode="roadmaps.show"
              icon="fa fa-eye"
              typeButton="primary"
              :to="{name: nameRouter.PAGES.DETAIL_BLOG, params: {id: scope.row.id}}"
          />
          <handle-button
              label="Xóa"
              eventCode=""
              icon="fa fa-trash"
              typeButton="danger"
              @click="openConfirmDelete(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
  <confirm v-model="confirmDelete" @close="closeConfirmDelete" @deleteService="removeBlog"></confirm>
</template>

<script>
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import configs from "@/configs";
import pagination from "@/components/pagination.vue";
import {NameRouter} from "@/constants/nameRouter";
import {GetListBlog, DeleteBlog} from "@/services/blog";
import confirm from "@/components/confirm";


export default {
  name: "ListBlog",
  components: {
    pagination,
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      idBlogDelete: "",
      confirmDelete: false,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      nameRouter: NameRouter,
      setup: {
        keyword: null
      }
    };
  },
  watch: {},

  created() {
    this.getListBlog()
  },
  methods: {
    getListBlog() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage, keyword: this.setup.keyword}
      GetListBlog(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.listPosts.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.title,
              code: e.code,
              avatar: e.full_post_image_url,
              tag:e.tag,
              description:e.description,
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.listPosts.total
          this.isShowPagination = e.data.data.listPosts.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr(err.data.response.message);
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getRoadmap(index);
    },
    openConfirmDelete(id) {
      this.confirmDelete = true
      this.idBlogDelete = id
    },
    closeConfirmDelete() {
      this.confirmDelete = false
      this.idBlogDelete = ""
    },
    removeBlog(){
      DeleteBlog(this.idBlogDelete).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('delete')
          this.confirmDelete = false
          this.idBlogDelete =""
          this.getListBlog()
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
    }
  }
}
</script>





<template>
  <div class="bg-white p-3">
    <infoBlog ref="blog" :infoBlog="formBlog"></infoBlog>
    <div class="text-center py-4">
      <handle-button
          label="Cập nhật"
          eventCode=""
          icon="fa fa-edit"
          typeButton="primary"
          @click="updateBlog"
      />
    </div>
  </div>
</template>
<script>
import infoBlog from "@/views/BlogManagement/infoBlog";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetDetailBlog, UpdateBlog} from "@/services/blog";

export default {
  name: 'DetailBlog',
  components: {
    infoBlog,
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      formBlog: {
        title: '',
        description: '',
        code: '',
        priority: 0,
        tag: [],
        content: '',
        avatar: '',
        is_active: null,
        status:null,
      },
      nameRouter: NameRouter,
    }
  },
  created() {
    this.getDetailBlog();
  },
  methods: {
    async getDetailBlog() {

      try {
        let res = await GetDetailBlog(this.$route.params.id);
        let newSlug =  res.data.data.postsDetail[0].slug.split("-");
        newSlug.pop();
        this.formBlog.title = res.data.data.postsDetail[0].title;
        this.formBlog.description = res.data.data.postsDetail[0].description;
        this.formBlog.code = res.data.data.postsDetail[0].code;
        this.formBlog.priority = res.data.data.postsDetail[0].priority;
        this.formBlog.content = res.data.data.postsDetail[0].content;
        this.formBlog.avatar = res.data.data.postsDetail[0].avatar;
        this.formBlog.is_active = res.data.data.postsDetail[0].is_active;
        this.formBlog.status = res.data.data.postsDetail[0].status;
        this.formBlog.slug = newSlug.join("-");
        this.formBlog.tag = res.data.data.postsDetail[0].tag ? res.data.data.postsDetail[0].tag.split(',') : [];
        this.$refs.blog.setImage(res.data.data.postsDetail[0].full_post_image_url);
      } catch (e) {
        notifyErr(e);
      }
    },
    async updateBlog() {
      let vailForm = await this.$refs.blog.validBlog()
      if (!vailForm) return false
      let param = {
        ...this.formBlog,
        tag: this.formBlog.tag.toString(),
      }
      try {
        await UpdateBlog(this.$route.params.id,param);
        notifySuccess("edit");
        this.$router.push({name: NameRouter.PAGES.LIST_BLOG});
      } catch (error) {
        notifyErr();
        console.log(error)
      }
    },

  }
}
</script>

export const NameRouter = {
    AUTH: {
        LOGIN: "login",
        REGISTER: "register",
        LOGOUT: "logout",
        SEND_MAIL: "send-mail",
        RESET_PASSWORD: "reset-password",
        DETAIL_ADMIN: "detail-admin",
        CHANGE_PASSWORD: "change-password",
        ME: 'me',
    },
    ERROR: {
        PERMISSION_DENIED: "permission-denied",
    },
    PAGES: {
        HOME_PAGE: "home-page",
        DASH_BOARD: "dash-board",
        LIST_STUDENTS: "list-student",
        LIST_BATCHES: "list-batches",
        LIST_FACILITY: "list-facility",
        LIST_ROOM: 'list-room',
        LIST_SCHEDULE: 'list-schedule',
        LIST_BLOG: 'list-blog',
        CREATE_BLOG: 'create-blog',
        DETAIL_BLOG: 'detail-blog',
        LIST_SURVEY: 'list-survey',
        CREATE_SURVEY: 'create-survey',
        ADD_QUESTION_TO_SURVEY: "add-question-to-survey",
        DETAIL_SURVEY: 'detail-survey',
        UPDATE_SURVEY: 'update-survey',
        CREATE_ROOM: "create-room",
        CREATE_SCHEDULE: "create-schedule",
        CREATE_SCHEDULE_IN_DAY: 'create-schedule-in-day',
        CREATE_SETTING: 'create-setting',
        LIST_SETTING: 'list-setting',
        MANAGEMENT_BATCHES: "management-batches",
        MANAGEMENT_FACILITY: "management-facility",
        MANAGEMENT_ROOM: 'management-room',
        MANGEMENT_SURVEY: 'management-survey',
        MANAGAMENT_SCHEDULE: 'management-schedule',
        MANAGEMENT_FOLDER: 'management-folder',
        MANAGEMENT_SETTING: 'management-setting',
        MANAGEMENT_BLOG: 'management-blog',
        INFO_TREE_FOLDER: 'info-tree-folder',
        HOME_BATCHES: "home-batches",
        ADD_STUDENT_TO_BATCH: "add-student-to-batch",
        ADD_TEACHER_TO_BATCH: "add-teacher-to-batch",
        ADD_USERS_TO_FACILITY: "add-users-to-facility",
        ADD_CLASS_TO_FACILITY: "add-class-to-facility",
        LIST_EVENT: "list-event",
        CREATE_EVENT: "create-events",
        CREATE_EVENT_IN_DAY: "create-event-in-day",
        DETAIL_EVENT: "detail-event",
        DETAIL_DAY_EVENT: "detail-day-event",
        DETAIL_DAY_SCHEDULE: 'detail-day-schedule',
        DETAIL_SCHEDULE: 'detail-schedule',
        MANAGEMENT_EVENT: "management-event",
        EXAM_REPORT: "exam-report",
        QUESTION_CREATE: "question-create",
        EXAM_CREATE: "exam-create",
        BATCH_DETAIL: "batch-detail",
        SCORE_BOARD:"score-board",
        FACILITY_DETAIl: 'facility-detail',
        ROOM_DETAIL: 'room-detail',
        CHECKIN_AT_BATCH: "checkin-at-batch",
        ROLL_CALL: "roll-call",
        LIST_EXAM: "list-exam",
        LIST_POINT: 'list-point',
        DETAIL_EXAM: "detail-exam",
        MANAGEMENT_EXAM: "management-exam",
        APP_MANAGEMENT: "app-management",
        TRANSLATE: "translate",
        REPORT: "report",
        COURSE: "list-course",
        COURSE_INDEX: "course",
        CREATE_COURSE: "create-course",
        DETAIL_COURSE: "course-detail",
        MANAGEMENT_FILE: "management-file",
        MANAGEMENT_ROADMAP: "management-roadmap",
        CREATE_ROADMAP: "create-roadmap",
        LIST_ROADMAP: "list-roadmap",
        DETAIL_ROADMAP: "detail-roadmap",
        CREATE_MODULE: "create-module",
        LIST_MODULE: "list-module",
        DETAIL_MODULE: "detail-module",
        MANAGEMENT_MODULE: "management-module",
        MANAGEMENT_SECTION: "management-section",
        CREATE_SECTION: "create-section",
        LIST_SECTION: "list-section",
        DETAIL_SECTION: "detail-section",
        MANAGEMENT_MATERIAL: "management-material",
        CREATE_MATERIAL: "create-material",
        LIST_MATERIAL: "list-material",
        DETAIL_MATERIAL: "detail-material",
        DOCUMENT_MANAGEMENT: 'document',
        CREATE_FILE: 'create-file',
        DETAIL_FILE: 'detail-file',
        LIST_FILE: 'list-file',
        ASSIGN_FILES: 'list-assignment-file',
        ASSIGNMENT_MANAGER: 'assignments',
        ASSIGNMENT_LIST: 'assignment-list',
        ASSIGNMENT_CREATE: 'assignment-create',
        SCORE_BOARD_STD:'score-board-student',
        ASSIGNMENT_DETAIL: 'assignment-detail',
        ASSIGNMENT_FILE_STUDENT: 'assignment-file-student',
        GRADING_TEACHER:'grading-teacher',
        STUDENT_LIST:'student-list',
        IMPORT_STUDENT: 'import-students',
        TOPIC_MANAGER: 'topics',
        TOPIC_LIST: 'topic-list',
        TOPIC_CREATE: 'topic-create',
        TOPIC_DETAIL: 'topic-detail',
        SUBJECTS: 'list-subject',
        MANAGEMENT_STUDENTS: "management-student",
        MANAGEMENT_USER: "management-users",
        LIST_USER: "list-users",
        DETAIL_USER: "detail-users",
        CREATE_USER: "create-users",
        REPORT_MATERIAL: "report-material",
        MARKING_MATERIAL: "marking-material",
        LIST_RATTING: 'list-ratting',
        LIST_SUBMISSION: 'list-submission',
        REPORT_ASSIGNMENT: 'report-assignment',
        IMPORT_POINT: 'import-point',
        REPORT_MANAGEMENT: {
            INDEX: 'report',
            STUDY: 'report/study',
            ROLL_CALL: 'report/roll-call',
        },
        ROLE_PERMISSION: 'role-permission',
        DETAIL_STUDENT: "detail-student",
        ROLL_CALL_TODAY: "roll-call-today",
        CREATE_LESSION: "create-lession"

    },
    ALIAS: {
        HOME_PAGE: "Trang chủ",
        DASH_BOARD: "Bảng điều khiển",
        LIST_STUDENTS: "Danh sách học sinh",
        MANAGEMENT_STUDENTS: "Quản lý học sinh",
        LIST_BATCHES: "Danh sách lớp",
        LIST_FACILITY: "Danh sách cơ sở",
        LIST_ROOM: "Danh sách phòng",
        LIST_SURVEY: 'Danh sách khảo sát',
        CREATE_SURVEY: "Tạo khảo sát",
        ADD_QUESTION_TO_SURVEY: "Thêm câu hỏi vào khảo sát",
        DETAIL_SURVEY: 'Chi tiết khảo sát',
        UPDATE_SURVEY: 'Cập nhật khảo sát',
        LIST_SCHEDULE: 'Danh sách lịch trình',
        LIST_BLOG: 'Danh sách bài viết',
        CREATE_BLOG: 'Tạo bài viết',
        DETAIL_BLOG: 'Chi tiết bài viết',
        INFO_TREE_FOLDER: 'Danh sách',
        CREATE_ROOM: "Tạo phòng",
        MANAGEMENT_BATCHES: "Quản lý lớp",
        MANAGEMENT_FACILITY: "Quản lý cơ sở",
        MANAGEMENT_ROOM: "Quản lý phòng",
        MANAGAMENT_SCHEDULE: "Quản lý lịch trình",
        MANAGEMENT_SETTING: 'Quản lý cài đặt',
        MANAGEMENT_FOLDER: 'Cây thư mục',
        MANAGEMENT_BLOG: 'Quản lý bài viết',
        MANAGEMENT_SURVEY: 'Quản lý khảo sát',
        ADD_STUDENT_TO_BATCH: "Thêm học sinh vào lớp",
        ADD_TEACHER_TO_BATCH: "Thêm giáo viên vào lớp",
        ADD_USERS_TO_FACILITY: "Thêm mới nhân viên",
        ADD_CLASS_TO_FACILITY: "Thêm mới lớp học",
        LIST_EVENT: "Danh sách sự kiện",
        CREATE_EVENT: "Tạo sự kiện",
        CREATE_SCHEDULE: "Tạo lịch trình",
        CREATE_SCHEDULE_IN_DAY: "Tạo lịch trình trong ngày",
        CREATE_EVENT_IN_DAY: "Tạo sự kiện trong ngày",
        CREATE_SETTING: 'Tạo thông tin',
        LIST_SETTING: 'Danh sách thông tin',
        DETAIL_EVENT: "Chi tiết sự kiện",
        DETAIL_DAY_EVENT: "Chi tiết sự kiện trong ngày",
        DETAIL_DAY_SCHEDULE: "Chi tiết lịch trình trong ngày",
        DETAIL_SCHEDULE: 'Chi tiết lịch trình',
        MANAGEMENT_EVENT: "Quản lý sự kiện",
        EXAM_REPORT: "Báo cáo bài thi",
        QUESTION_CREATE: "Tạo câu hỏi",
        EXAM_CREATE: "Tạo bài kiểm tra",
        BATCH_DETAIL: "Chi tiết lớp học",
        SCORE_BOARD:"Bảng điểm học sinh",
        FACILITY_DETAIL: "Chi tiết cơ sở",
        ROOM_DETAIL: "Chi tiết phòng",
        CHECKIN_AT_BATCH: "Điểm danh",
        LIST_POINT: "Danh sách điểm",
        ROLL_CALL: "Danh sách yêu cầu",
        LIST_EXAM: "Danh sách bài thi",
        DETAIL_EXAM: "Chi tiết bài thi",
        MANAGEMENT_EXAM: "Quản lí bài thi",
        COURSE_INDEX: "Quản lý khóa học",
        COURSE: "Danh sách khóa học",
        DETAIL_COURSE: "Chi tiết khóa học",
        CREATE_COURSE: "Tạo khóa học",
        MANAGEMENT_FILE: "Quản lý tệp",
        MANAGEMENT_ROADMAP: "Quản lý lộ trình học",
        CREATE_ROADMAP: "Tạo lộ trình",
        LIST_ROADMAP: "Danh sách lộ trình",
        DETAIL_ROADMAP: "Chi tiết lộ trình",
        CREATE_MODULE: "Tạo mô đun",
        LIST_MODULE: "Danh sách mô đun",
        DETAIL_MODULE: "Chi tiết mô đun",
        MANAGEMENT_MODULE: "Quản lý mô đun",
        MANAGEMENT_SECTION: "Quản lý bài học",
        CREATE_SECTION: "Tạo bài học",
        LIST_SECTION: "Danh sách bài học",
        DETAIL_SECTION: "Chi tiết bài học",
        MANAGEMENT_MATERIAL: "Quản lý Khung giờ",
        CREATE_MATERIAL: "Tạo khung giờ bài học",
        LIST_MATERIAL: "Danh sách khung giờ",
        DETAIL_MATERIAL: "Chi tiết bài học",
        DOCUMENT_MANAGEMENT: "Quản lý học liệu",
        CREATE_FILE: 'Tạo tệp',
        DETAIL_FILE: 'Chi tiết tệp',
        LIST_FILE: 'Danh sách tệp',
        ASSIGNMENT_MANAGER: 'Quản lý bài kiểm tra',
        ASSIGNMENT_LIST: 'Danh sách bài kiểm tra',
        ASSIGNMENT_CREATE: 'Tạo bài kiểm tra',
        SCORE_BOARD_STD:'Bảng điểm',
        ASSIGNMENT_DETAIL: 'Chi tiết bài kiểm tra',
        ASSIGNMENT_FILE_STUDENT: 'bài kiểm tra của học sinh',
        GRADING_TEACHER:'Chấm điểm của giáo viên',
        STUDENT_LIST:'Danh sách học sinh',
        IMPORT_STUDENT: 'Thêm nhiều học sinh',
        TOPIC_MANAGER: 'Quản lý Q/A',
        TOPIC_LIST: 'Danh sách Q/A',
        TOPIC_CREATE: 'Trả lời Q/A',
        TOPIC_DETAIL: 'Q/A chi tiết',
        SUBJECTS: 'Danh sách môn học',
        MANAGEMENT_USER: "Quản lý người dùng",
        LIST_USER: "Danh sách người dùng",
        DETAIL_USER: "Chi tiết người dùng",
        CREATE_USER: "Tạo người dùng",
        REPORT_MATERIAL: 'Báo cáo bài học',
        LIST_RATTING: 'Danh sách đánh giá',
        MARKING_MATERIAL: 'Chấm điểm bài học',
        LIST_SUBMISSION: 'Dannh sách bài kiểm tra',
        REPORT_ASSIGNMENT: 'Báo cáo bài kiểm tra',
        IMPORT_POINT: 'Nhập bảng điểm',
        REPORT_MANAGEMENT: {
            INDEX: 'Quản lý báo cáo',
            STUDY: 'Báo cáo học tập',
            ROLL_CALL: 'Báo cáo điểm danh',
        },
        ROLE_PERMISSION: 'Tạo quyền truy cập',
        DETAIL_STUDENT: "Thông tin chi tiết học sinh",
        ROLL_CALL_TODAY: "BC điểm danh hôm nay",
        AUTH: {
            CHANGE_PASSWORD: "Đổi mật khẩu",
            ME: "Thông tin cá nhân"
        },
        CREATE_LESSION: "Tạo bài học",
    },
};

<template>
  <div id="managementBatches" class="bg-white p-2">
    <div class="search py-2">
      <el-row :gutter="10">
        <el-col :offset="16" :span="4">
          <el-input v-model="setup.keyword"/>
        </el-col>
        <el-col :span="4">
          <el-button :icon="Search" type="primary" @click="searchData">Tìm kiếm</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border>
      <el-table-column fixed prop="id" label="ID" width="55"/>
      <el-table-column fixed prop="title" label="Tiêu đề" min-width="150"/>
      <el-table-column prop="duration" label="Thời gian thi" min-width="100"/>
      <el-table-column prop="examQuestionCount" label="Số câu hỏi" min-width="120"/>
      <el-table-column prop="createdAt" label="Ngày tạo" min-width="120"/>
      <el-table-column
          fixed="right"
          min-width="150">
        <template #default="scope">
          <handle-button
              type="link"
              label="Cập nhật"
              eventCode="materials.update"
              icon="fa fa-check"
              typeButton="primary"
              :to="{name: nameRouter.PAGES.DETAIL_EXAM, params: {id: scope.row.id}}"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, setBreadcrumb} from "@/mixins/utils";
import {checkSpecialCharacter, textInputValidate} from "@/validator";
import {NameRouter} from "@/constants/nameRouter";
import {GetExams} from "@/services/exam";

export default {
  name: "ListBatches",
  mixins: [setBreadcrumb],
  components: {
    pagination
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogVisible: false,
      dialogAdd: false,
      infoStudent: {},
      model: {
        end_date: "",
        start_date: "",
        name: "",
        roadmap_id: "",
        is_active: configs.status.active,
      },
      rulesLogin: {
        end_date: [textInputValidate],
        start_date: [textInputValidate],
        name: [textInputValidate, checkSpecialCharacter],
        roadmap_id: [textInputValidate]
      },
      nameRouter: NameRouter,
      roadmaps: [],
      setup: {
        keyword: ''
      }
    };
  },
  watch: {},
  created() {
    this.getListExam()
  },
  methods: {
    getListExam(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      GetExams(params).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.exams.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.title,
              duration: e.duration,
              examQuestionCount: e.exam_question_count,
              createdAt: e.created_at ? formatDate(e.created_at) : '',
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.exams.total
          this.isShowPagination = e.data.data.exams.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getListExam();
    },
    searchData() {
      let params = {per_page: configs.itemOnPage, page: 1, keyword: this.setup.keyword}
      this.getListExam(params);
    },
  },
}
</script>
<script setup>
import {Search} from "@element-plus/icons-vue";
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

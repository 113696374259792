import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    breadcrumb: [],
    navbarChild: [],
    fileContent: {}
}
export default {
    state,
    getters,
    mutations,
    actions,

}

<template>
  <div class="bg-white p-3">
    <info-file ref="infoFileUpdate" :form="form" :isEdit="true"/>
    <div class="text-center">
      <handle-button
          label="Cập nhật"
          eventCode="files.update-file"
          icon="fa fa-plus"
          typeButton="primary"
          @click="saveInfoFile"/>
    </div>
  </div>
</template>
<script>
import infoFile from "@/views/ManagerFile/infoFile";
import {DetailFile, UpDateFile} from "@/services/common";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import configs from "@/configs";

export default {
  name: 'DetailFile',
  components: {
    infoFile
  },
  mixins: [setBreadcrumb],
  mounted() {
    this.getFileById()
    this.$refs.infoFileUpdate.clearValidateForm()
  },

  data() {
    return {
      form: {},
    }
  },
  methods: {
    getFileById(){
      DetailFile(this.$route.params.id).then(res => {
        let fileContent = res.data.data.file
        let url = fileContent.url.replace(configs.baseUrlImage, '')
        this.form = {
          file_id: fileContent.id,
          url: fileContent.type == 3 ? configs.baseUrlImage + url : url,
          name: fileContent.name,
          code: fileContent.code,
          purpose_of_uses: fileContent.purpose_of_uses?.toString(),
          type: fileContent.type + '',
          content: fileContent.content,
          is_submission_available: fileContent.is_submission_available,
          is_skip:fileContent?.is_skip || 0
        }
      }).catch()
    },
    async saveInfoFile() {
      let valid = await this.$refs.infoFileUpdate.validForm()
      if (!valid) return false;
      let param = this.form
      param.is_submission_available = this.form.is_submission_available ? 1 : 0
      if (this.form.type === 3) {
        param.url.replace(configs.baseUrlImage, "");
      }
      UpDateFile(this.form).then(el => {
        if (el.data.status === 'success') {
          notifySuccess('add', 'cập nhật thành công.')
          this.getFileById()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
  }

}
</script>

<template>
  <div id="rolePermission" v-loading="loading" class="bg-white p-3" style="min-height: 76vh">
    <div class="d-flex justify-content-between">
      <div class="justify-content-start">
        <el-checkbox v-model="setup.openAll" border>
          Mở tất cả
        </el-checkbox>
        <br>
        <small class="text-danger">* Di chuyển chuột vào ô nhập để xem tên gốc quyền và sửa quyền</small>
      </div>
      <div class="p-2  justify-content-end">
        <handle-button
          label="Tạo quyền hạn."
          event-code="role.store"
          icon="fa fa-plus"
          type-button="primary"
          @click="openDialog"
        />
        <handle-button
          label="Lưu nhóm quyên."
          event-code="role.store"
          icon="fa fa-save"
          type-button="primary"
          @click="updateRolePermission"
        />
      </div>
    </div>
    <form ref="formPermission">
      <el-table
        ref="modules"
        v-loading="loading"
        :data="groupPermission"
        style="width: 100%"
        border
        :row-key="row => row.group"
        :expand-row-keys="expandRowKeys"
        fixed @expand-change="openCollapse"
      >
        <el-table-column type="expand" :disabled="true">
          <template #default="props">
            <div v-loading="props.row.loading" class="py-2 pl-5 pr-3 table">
              <div v-show="props.row.child" class="table-wrapper">
                <table class="table-bordered">
                  <thead>
                  <tr>
                    <th style="width: 15%;">Quyền hạn / Vai trò</th>
                    <th v-for="(role, key) in roles" :key="key" width="10%">
                      <div style="cursor: pointer" @click="openDialog(role.id)">
                        {{ role.description }}
                        <i
                          title="edit"
                          class="fa fa-edit"
                          style="font-size: 10px"
                        />
                      </div>
                      <small>({{ role.name }})</small>
                      <br>
                      <input
                        :checked="checkedAll(props.row.child, role.id, props.row.group)"
                        type="checkbox" @change="chooseAllPermissionGroup(props.row.group, role.id)"
                      >
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(permission, index) in props.row.child" :key="index">
                    <td class="table-sticky" :title="permission.route_name">
                      <div><input v-model="permission.route_description" class="w-100 form-control rounded-0"></div>
                    </td>
                    <td v-for="(role, key) in roles" :key="role.id">
                      <div v-if="permission.permission_role.length > 0" class="text-center">
                        <label :for="permission.route_name +'-'+role.id" class="w-100 pb-0">
                          <input
                            :id="permission.route_name +'-'+role.id"
                            type="checkbox"
                            :checked="!!permission.permission_role.find(el => el.role_id == role.id)"
                            :name="permission.route_name"
                            :value="role.id"
                            style="min-height: 100%; height: 100%"
                          >
                        </label>
                      </div>
                      <div v-if="permission.permission_role.length <=0" class="text-center">
                        <label
                          :for="permission.route_name +'-'+role.id" class="w-100 pb-0"
                          style="min-height: 100%; height: 100%"
                        >
                          <input
                            :id="permission.route_name +'-'+role.id"
                            type="checkbox" :name="permission.route_name"
                            :value="role.id"
                          >
                        </label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="group" label="Nhóm quyên"/>
        <template #empty>
          <div class="flex items-center justify-center h-100%">
            <el-empty/>
          </div>
        </template>
      </el-table>
    </form>
    <el-empty v-if="roles.length <= 0" description="description"/>
    <el-dialog v-model="setup.dialogVisible" title="Khởi tạo nhóm quyền" width="30%" draggable @closed="closeDialog">
      <el-form
        ref="roleForm"
        label-position="top"
        label-width="100px"
        :model="formRole"
        :rules="formRule"
      >
        <el-form-item label="Tên nhóm quyền" prop="name">
          <el-input v-model="formRole.name"/>
        </el-form-item>
        <el-form-item label="Tên mô tả" prop="description">
          <el-input v-model="formRole.description"/>
        </el-form-item>
        <el-form-item label="Loại hình người dùng ">
          <el-radio-group v-model="formRole.is_teacher">
            <el-radio v-for="(item, key) in configs.typeOfUser" :key="key" :label="key.toString()">
              {{ item }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer text-center">
          <handle-button
            label="Hủy"
            event-code="role.store"
            icon=""
            type-button="danger"
            @click="closeDialog"
          />
          <handle-button
            v-if="setup.action === 'create'"
            label="Tạo mới"
            event-code="role.store"
            icon="fa fa-plus"
            type-button="primary"
            @click="createRole"
          />
          <handle-button
            v-if="setup.action === 'edit'"
            label="Cập nhật"
            event-code="role.store"
            icon="fa fa-edit"
            type-button="primary"
            @click="updateRole"
          />
          <pop-confirm
            v-if="setup.action === 'edit'"
            label="Xóa quyền"
            event-code="role.destroy"
            @click="deleteRole"
          />
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {CreateRole, ListPermission, ListRole, UpdateRole, UpdateRolePermission, DeleteRole} from "@/services/users";
import {textInputValidate} from "@/validator";
import popConfirm from "@/components/popConfirm";
import configs from "@/configs";

export default {
  name: "ListUser",
  expose: ['validMaterial'],
  components: {popConfirm},
  mixins: [setBreadcrumb],
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: false},

  },
  data() {
    return {
      roles: [],
      permissions: [],
      loading: true,
      formRole: {
        name: '',
        description: '',
        is_teacher: '0',
      },
      formRule: {
        name: [textInputValidate],
        description: [textInputValidate],
      },
      currentRole: {},
      setup: {
        dialogVisible: false,
        action: 'create',
        openAll: true
      },
      groupPermission: [],
      expandRowKeys: [],
      configs: configs
    };
  },
  watch: {
    'setup.openAll'() {
      this.openAllCollapse()
    }
  },
  async created() {
    await this.getRoles()
    await this.getPermissions()
    // await this.openAllCollapse()
  },
  methods: {
    getRoles() {
      this.loading = true
      ListRole().then(res => {
        if (res.data.status == 'success') {
          this.roles = res.data.data.roles
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    getPermissions() {
      ListPermission().then(res => {
        if (res.data.status == 'success') {
          let groupPermission = [];
          let permissions = res.data.data.permissions
          this.permissions = res.data.data.permissions
          for (let i = 0; i < permissions.length; i++) {
            let permission = permissions[i]
            if (!permission.route_description) {
              permission.route_description = permission.route_name
            }
            let group = permission.route_name.split('.')[0]
            if (groupPermission.length === 0) {
              groupPermission.push({group: group, child: [permission]})
              continue
            }
            if (groupPermission.length > 0) {
              let indexGroup = groupPermission.findIndex(item => item.group === group)
              if (indexGroup <= -1) {
                groupPermission.push({group: group, child: [permission]})
              }
              if (indexGroup > -1) {
                groupPermission[indexGroup].child.push(permission)
              }
            }
          }
          this.groupPermission = groupPermission
          this.openAllCollapse()
        }
      }).catch(err => {
        notifyErr(err.response.data.status)
      })
    },
    async createRole() {
      let valid = await this.$refs.roleForm.validate((valid, fields) => {
        return !valid
      })
      if (!valid) return false;
      CreateRole(this.formRole).then(res => {
        if (res.data.status == "success") {
          notifySuccess('add', 'Tạo nhóm quyền thành công.')
        }
        this.getRoles()
        this.closeDialog()
      }).catch(err => {
        notifyErr()
      })
    },
    chooseAllPermissionGroup(group, roleId) {
      let indexGroup = this.groupPermission.findIndex(item => item.group === group)
      if (indexGroup < 0) return notifyErr('Không tồn tại nhóm này.')
      let groupCurrent = this.groupPermission[indexGroup]
      for (let i = 0; i < groupCurrent.child.length; i++) {
        let permission = groupCurrent.child[i]

        if (permission.permission_role.length <= 0) {
          permission.permission_role.push({role_id: roleId})
          continue
        }
        if (permission.permission_role.length > 0) {
          if (!event.target.checked) {
            let indexRole = permission.permission_role.findIndex(item => item.role_id === roleId)
            permission.permission_role.splice(indexRole, 1)
            continue
          }
          if (!permission.permission_role.find(item => item.role_id === roleId)) {
            permission.permission_role.push({role_id: roleId})
          }
        }
      }
      this.groupPermission[indexGroup] = groupCurrent
    },
    openDialog(id) {
      if (!id) {
        return this.setup.dialogVisible = true
      }
      this.currentRole = this.roles.find(el => el.id === id);
      if (!this.currentRole) return notifyErr('Thông tin không tồn tại.')
      this.setup.action = "edit"
      this.setup.dialogVisible = true
      this.formRole = {
        name: this.currentRole.name,
        id: this.currentRole.id,
        is_teacher: this.currentRole.is_teacher.toString(),
        description: this.currentRole.description,
      }
    },
    closeDialog() {
      this.currentRole = {}
      this.setup.action = 'create'
      this.setup.dialogVisible = false
      this.$refs.roleForm.resetFields()
      this.formRole = {
        name: '',
        description: ''
      }
    },
    async updateRole() {
      let valid = await this.$refs.roleForm.validate((valid) => {
        return !valid
      })
      if (!valid) return false;
      UpdateRole(this.formRole).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
          this.getRoles()
          this.getPermissions()
          this.closeDialog()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    updateRolePermission() {
      let checked = [], inputs = []
      this.permissions.map(el => {
        let group = this.groupPermission.find(item => item.group == el.route_name.split('.')[0])
        if (group) {
          let permission = group.child.find(per => per.id === el.id)
          el.route_description = permission.route_description
        }
        inputs.push({routeName: el.id, input: document.getElementsByName(el.route_name)})
      })
      if (inputs.length < 0) return false
      inputs.map(inputsChild => {
        let obj = {};
        obj[inputsChild.routeName] = []
        for (let i = 0; i < inputsChild.input.length; i++) {
          if (!inputsChild.input[i].checked) continue
          obj[inputsChild.routeName].push(inputsChild.input[i].value)
        }
        checked.push(obj)
      })
      let params = [];
      checked.map(el => {
        for (let item in el) {
          let permissionCurrent = this.permissions.find(permission => permission.id == item)
          params.push({
            permission_id: item,
            role_ids: el[item],
            route_name: permissionCurrent.route_name,
            route_description: permissionCurrent.route_description
          })
        }
      })
      if (params.length <= 0) return notifyErr('Vui lòng tải lại trang.')
      UpdateRolePermission({data: params}).then(el => {
        if (el.data.status === 'success') {
          notifySuccess('add', 'Cập nhật quyền thành công.')
        }
      }).catch(err => {
        notifyErr(err.response.data.status)
      })
    },
    deleteRole() {
      let id = this.currentRole.id
      DeleteRole({}, id).then(res => {
        if (res.data.status === "success") {
          notifySuccess('delete')
          this.getRoles()
          this.getPermissions()
          this.closeDialog()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    checkedAll(permission, roleId, group) {
      let data = []
      for (let i = 0; i < permission.length; i++) {
        let item = permission[i];
        if (!item.permission_role.find(item => item.role_id === roleId)) continue
        data.push(item)
      }
      return data.length === permission.length
    },
    openCollapse(row, expandedRows) {
      if (!expandedRows) return false
      let indexRowCurrent = this.expandRowKeys.findIndex(item => item == row.group)
      if (indexRowCurrent > -1) {
        this.expandRowKeys.splice(indexRowCurrent, 1)
        return false
      }
      this.expandRowKeys.push(row.group)
    },
    openAllCollapse() {
      this.expandRowKeys = []
      if (this.setup.openAll) {
        this.groupPermission.map(el => {
          this.expandRowKeys.push(el.group)
        })
      }
    }
  }
}

</script>
<script setup>
import {ref} from "vue";

const dialogAdd = ref()
</script>
<style scoped lang="scss">
.table {
  &-wrapper {
    max-height: 75vh;
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    th {
      position: sticky;
      background-color: #6f42c1;
      width: 10%;
      top: -0.01rem;
      color: #ffffff;
      padding: .2rem;
      border-color: white;
      border-width: 1px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-track {
      width: 5px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
    }

    tr:nth-of-type(even) td {
      background-color: #ffffff;
    }
  }

  ::v-deep &-bordered {
    td {
      border-color: #6f42c1 !important;
    }

    th {
      border-color: #ffffff !important;
      border-width: 1px;
    }
  }

  &-sticky {
    position: sticky;
    left: 0;
    color: #000000;
  }
}


table thead th:first-child {
  position: sticky;
  left: 0;
  background-color: #6f42c1;
  color: #ffffff;
  z-index: 2;
}

</style>


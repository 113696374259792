<template>


  <el-tabs type="border-card">
    <el-tab-pane label="Tạo tệp">
      <el-form :model="form" label-position="top" :rules="formRule" ref="formFile" v-loading="loading">
        <el-upload
            v-if="form.type != 2 "
            v-model:file-list="setup.fileList"
            class="upload-demo"
            limit="1"
            action="/"
            ref="uploadFile"
            :before-upload="beforeAvatarUpload"
        >
          <el-button v-if="setup.fileList.length  < 1" type="primary" :icon="UploadFilled">Chon tệp</el-button>
        </el-upload>
        <el-form-item label="Tên tệp" prop="name">
          <el-input v-model="form.name" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="Mã code" prop="code">
          <el-input v-model="form.code" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="Mục đích" prop="purpose_of_uses">
          <el-select v-model="form.purpose_of_uses" placeholder="Vui lòng chọn loại mục đích" class="w-100">
            <el-option v-for="(item, index) in CONFIG_APP.type_of_purpose" :key="index" :label="item" :value="index"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Loai tệp" prop="type">
          <el-select v-model="form.type" placeholder="Vui lòng chọn loại file" class="w-100" :disabled="isEdit">
            <el-option v-for="(item, index) in CONFIG_APP.type" :key="index" :label="item" :value="index"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="form.type == 2? 'Link youTube': 'Link ảnh'" v-if="form.type != 1 " prop="url">
          <el-input v-model="form.url" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="Học viên cần nộp bài kiểm tra không.">
          <el-radio-group v-model="form.is_submission_available">
            <el-radio :label="1">Cần nộp bài</el-radio>
            <el-radio :label="0">Không cần nộp</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Học viên có thể bỏ qua bài kiểm tra không ?" v-if="form.is_submission_available==1">
          <el-radio-group v-model="form.is_skip">
            <el-radio :label="1">Bỏ qua</el-radio>
            <el-radio :label="0">Không được bỏ qua</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="Markdown" v-if="form.type == 3">
      <div class="py-3">
        <span class="text-danger" v-if="errContent">Nội dung không được để trống.</span>
        <md-editor v-model="form.content" language="en-US"  @onUploadImg="onUploadImg"/>

      </div>
    </el-tab-pane>

  </el-tabs>
</template>
<script>
import {textInputValidate} from "@/validator";
import {CreateFile, UploadFile} from "@/services/common";
import configs from "@/configs";
import {isImage, notifyErr, notifySuccess, notifyWarning} from "@/mixins/utils";
import {BASE_URL} from "@/constants/endPoints";

export default {
  name: 'InfoFile',
  expose: ['validForm', 'setFileList', 'clearValidateForm'],
  props: {
    form: Object,
    isEdit: {type: Boolean, default: false}
  },
  components:{
    MdEditor
  },
  data() {
    return {
      formRule: {
        name: [textInputValidate],
        code: [textInputValidate],
        purpose_of_uses: [textInputValidate],
        type: [textInputValidate],
      },
      setup: {
        fileList: [],
      },
      loading: false,
      errContent: false,
      file: null
    }
  },
  watch: {
    file() {
      if (!this.file) return false;
      this.createFile()
    }
  },
  methods: {
    async createFile() {
      this.loading = true
      let formData = new FormData
      formData.append('file', this.file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          if (this.form.type != 3) {
            this.form.url = e.data.data.filePath
          }
          if (this.form.type == 3) {
            this.form.url = encodeURI(configs.baseUrlImage + e.data.data.filePath)
          }
          path = e.data.data.filePath
        }
        this.loading = false
      })
    },
    async beforeAvatarUpload(uploadFile) {
      if (this.form.type == 3) {
        if (!isImage(uploadFile)) return notifyWarning('Vui lòng chọn tệp ảnh.')
      }
      this.setup.fileList.push(uploadFile)
      this.file = uploadFile
    },
    async validForm() {
      let valid = await this.$refs.formFile.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (this.form.type != 3) return valid
      let validContent = true
      if (this.form.content == '') validContent = false
      this.errContent = this.form.content == ''
      if (valid && validContent) return true
      if (!valid || !validContent) return false
    },
    setFileList(url) {
      this.setup.fileList = [url]
    },
    clearValidateForm() {
      this.$refs.formFile.clearValidate()
    },
    changeType() {
      if (this.form.type == 2) {
        this.formRule.url = [textInputValidate]
        this.form.url = ''
        this.errContent = false
      } else {
        this.$refs.uploadFile.clearFiles()
        this.formRule.url = ''
      }
      this.setup.fileList = []
      this.form.url = ''
    },
    async uploadFile(file) {
      let formData = new FormData
      formData.append('file', file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          path = e.data.data.filePath
        }
        return path
      })
    },
    async handleUploadImage(event, insertImage, files) {
      let formData = new FormData
      formData.append('file', files[0])
      let path = await UploadFile(formData).then(async e => {
        if (e.data.status == 'success') return encodeURI(configs.baseUrlImage + e.data.data.filePath)
      })
      insertImage({
        url: path,
        desc: 'desc'
      });
    },
    async onUploadImg  (files, callback) {

      let formData = new FormData
      formData.append('file', files[0])
      formData.append('folder', 'markdown')
      let res = []
    await UploadFile(formData).then(async e => {
        if (e.data.status == 'success') {
          if (window.location.href.search(process.env.VUE_APP_URL_PRODUCT) !== -1) {
            res.push(encodeURI(configs.baseUrlImageProduct + e.data.data.filePath))
          } else if (window.location.href.search(process.env.VUE_APP_URL_DEV) !== -1) {
            res.push(encodeURI(configs.baseUrlImage + e.data.data.filePath))
          } else if (window.location.href.search(process.env.VUE_APP_URL_WEB_ONLINE) !== -1) {
            res.push(encodeURI(configs.baseUrlImageOnline + e.data.data.filePath))
          } else {
            res.push(encodeURI(configs.baseUrlImage + e.data.data.filePath))
          }
        }

      })

      callback(res.map((item) => item));
    }
  }
}
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {View, UploadFilled, Search} from '@element-plus/icons-vue'
import {ref} from "vue";
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
const uploadFile = ref('')
</script>

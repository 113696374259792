<template>
  <div>
    <div class="text-right mb-2 pr-3" v-show="showFiles">
      <handle-button
        type="button"
        label="Lưu lại"
        typeButton="primary"
        @click="saveData"
      />
    </div>
    <el-row :gutter="25" class="p-3">
      <el-col :span="12">
        <el-table
          :data="tableData"
          style="width: 100%"
          fixed
          v-loading="loading"
          border
          highlight-current-row
        >
          <el-table-column fixed prop="id" label="ID" min-width="50" />
          <el-table-column
            fixed
            prop="title"
            label="Khung giờ học"
            min-width="150"
          />
          <el-table-column fixed prop="code" label="Mã Code" min-width="150" />
          
          <el-table-column label="Thêm File" min-width="50">
            <template #default="scope">
              <handle-button
                type="button"
                icon="fa fa-plus"
                typeButton="primary"
                @click="getDetailMaterial(scope.row.id)"
              />
            </template>
          </el-table-column>
        </el-table>
        <div class="pb-3 text-center">
          <pagination
            v-show="isShowPagination"
            :total="total"
            :page-index="pageIndex"
            @currentPageChange="nextPage"
          ></pagination>
        </div>
      </el-col>

      <el-col :span="12">
        <div v-show="showFiles">
          <el-table
            :data="tableDataFiles"
            style="width: 100%"
            ref="multipleTableRef"
            fixed
            @selection-change="handleSelectionChange"
            v-loading="loadingFile"
            border
          >
            <el-table-column type="selection" width="50" />
            <el-table-column fixed prop="id" label="ID" min-width="50" />
            <el-table-column
              fixed
              prop="title"
              label="Tên tệp"
              min-width="150"
            />
            <el-table-column prop="code" label="Mã code" min-width="100" />
          </el-table>
          <div class="pb-3 text-center">
            <pagination
              v-show="isShowPaginationFile"
              :total="totalFile"
              :page-index="pageIndexFile"
              @currentPageChange="nextPageFile"
            ></pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import { default as CONFIG_APP, default as configs } from "@/configs";
import { NameRouter } from "@/constants/nameRouter";
import { formatDate, notifyErr, notifySuccess } from "@/mixins/utils";
import { GetFiles } from "@/services/common";
import { DetailMaterial, GetMaterial, UpdateMaterial } from "@/services/course";

export default {
  name: "createLession",
  components: {
    pagination,
  },
  data() {
    return {
      multipleSelection: [],
      tableData: [],
      dataSelection: [],
      tableDataFiles: [],
      isShowPagination: false,
      isShowPaginationFile: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      currentPageFile: 1,
      pageIndexFile: 1,
      totalFile: 0,
      loading: false,
      loadingFile: false,
      dialogFormVisible: false,
      nameRouter: NameRouter,
      showFiles: false,
      currentLession: "",
      form: {
        url: "",
        name: "",
        type: "",
      },
      setup: {
        keyword: null,
        roadmap_id: null,
        module_id: null,
        course_id: null,
      },
      isShowModule: false,
    };
  },
  created() {
    this.getMaterials();
    this.getListFile();
  },
  watch: {
    "setup.roadmap_id"() {
      this.setup.module_id = null;
      this.setup.course_id = null;
      let roadmap = this.$refs.roadmapSelectBox.getRoadmapById(
        this.setup.roadmap_id
      );
      let hasModule = 1;
      this.isShowModule = false;
      if (roadmap?.type === hasModule) {
        this.isShowModule = true;
        this.$refs.moduleSelectBox.searchByRoadmap(this.setup.roadmap_id);
      }
      this.$refs.courseSelectBox.search({ roadmap_id: this.setup.roadmap_id });
    },
    "setup.module_id"() {
      this.setup.course_id = null;
      this.$refs.courseSelectBox.search({ module_id: this.setup.module_id });
    },
  },
  methods: {
    getMaterials() {
      this.loading = true;
      let perPage = {
        per_page: configs.itemOnPage,
        page: this.currentPage,
        ...this.setup,
      };

      GetMaterial(perPage)
        .then((e) => {
          if (e.data.status == "success") {
            let dataTable = [];
            e.data.data.materials.data.map((e) => {
              dataTable.push({
                id: e.id,
                title: e.name,
                code: e.code,
                purpose_of_uses: CONFIG_APP.type_of_purpose[e.purpose_of_uses],
                isActive: e.is_active,
                duration: e.duration && e.duration > 0 ? e.duration / 60 : 0,
              });
            });
            this.tableData = dataTable;
            this.total = e.data.data.materials.total;
            this.isShowPagination = e.data.data.materials.total > 0;
            this.selected = this.modelValue;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          notifyErr();
        });
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getMaterials(index);
    },

    getListFile() {
      this.loadingFile = true;
      let perPage = {
        per_page: configs.itemOnPage,
        page: this.currentPageFile,
        ...this.setup,
      };
      GetFiles(perPage)
        .then((e) => {
          if (e.data.status == "success") {
            let dataTable = [];
            let extension = (url) => {
              let extension = "";
              if (!url) return extension;
              let arr = url.split(".");
              if (url.length < 1) return extension;
              extension = arr[arr.length - 1];
              if (arr.length > 1) return extension;
            };

            e.data.data.listAllFile.data.map((e) => {
              dataTable.push({
                id: e.id,
                title: e.name,
                code: e.code,
                purpose_of_uses_str:
                  CONFIG_APP.type_of_purpose[e.purpose_of_uses],
                purpose_of_uses: e.purpose_of_uses,
                url: e.full_url,
                type: e.type,
                path: e.url,
                pathUrl: e.path,
                content: e.content,
                is_submission_available:
                  e.is_submission_available === configs.submissionFile,
                createdAt: e.created_at ? formatDate(e.created_at) : "",
                extension: e.type === this.typeOfFile ? extension(e.url) : "",
              });
            });
            this.tableDataFiles = dataTable;
            this.totalFile = e.data.data.listAllFile.total;
            this.isShowPaginationFile = e.data.data.listAllFile.total > 0;
          }
          this.loadingFile = false;
        })
        .catch((err) => {
          this.loadingFile = false;
          notifyErr();
        });
    },
    nextPageFile(index) {
      this.currentPageFile = index;
      this.pageIndexFile = index;
      this.getListFile(index);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getDetailMaterial(id) {
      let dataChecked = [];

      let data = await DetailMaterial(id)
        .then((e) => {
          let res = e?.data?.data?.material;
          this.currentLession = res;
          return res;
        })
        .catch((err) => (data = []));

      for (let index = 0; index < data?.material_file?.length; index++) {
        dataChecked.push({ id: data?.material_file[index].file_id });
      }
      this.showFiles = true;
      this.dataSelection = dataChecked;
      this.$refs.multipleTableRef.clearSelection();
      this.toggleSelection();
    },
    toggleSelection(table) {
      this.tableDataFiles.forEach((e) => {
        this.dataSelection.find((item) => {
          if (item.id == e.id) {
            this.$refs.multipleTableRef.toggleRowSelection(e);
          }
        });
      });
    },
    saveData() {
      //   console.log(this.currentLession, this.multipleSelection);
      let { id, code, name, course_id, duration, is_active, purpose_of_uses, sort_order } =
        this.currentLession;

      let files = this.multipleSelection.map((item, index) => {
        return { id: item.id, sort_order: index + 1 };
      });

      let data = {
        name,
        course_id,
        exams:[],
        duration,
        sort_order,
        is_active,
        files,
        code,
      purpose_of_uses
      };
      UpdateMaterial(id, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.showFiles = false
            notifySuccess("edit")
          }
        })
        .catch((error) => {
           notifyWarning()
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

import { HTTP_REQUEST_AUTH } from "./requestAxios";
import { USERS } from "@/constants/endPoints";

export const GetListUser = (params) =>
  HTTP_REQUEST_AUTH.get(USERS.GET_LIST_USER, { params: params });

export const GetListTeacher = (params) =>
  HTTP_REQUEST_AUTH.get(USERS.GET_LIST_TEACHER, { params: params });

export const GetUser = (params) =>
  HTTP_REQUEST_AUTH.get(USERS.GET_USER, { params: params });

export const CreatedUser = (params) =>
  HTTP_REQUEST_AUTH.post(USERS.CREATE_USER, params);

export const DeleteUser = (params) =>
  HTTP_REQUEST_AUTH.post(USERS.DELETE_USER, params);

export const UpdateUser = (params) =>
  HTTP_REQUEST_AUTH.post(USERS.UPDATE_USER, params);

export const RemoveTeacherFromBatch = (params) =>
  HTTP_REQUEST_AUTH.post(USERS.REMOVE_TEACHER_FROM_BATCH, params);

export const ResetPasswordUser = (params, id) =>
  HTTP_REQUEST_AUTH.post(USERS.RESET_PASSWORD_USER, params);

export const CreateRole = (params) =>
  HTTP_REQUEST_AUTH.post(USERS.CREATE_ROLE, params);
export const UpdateRole = (params, id) =>
  HTTP_REQUEST_AUTH.put(USERS.CREATE_ROLE + "/" + params.id, params);
export const ListRole = (params) =>
  HTTP_REQUEST_AUTH.get(USERS.CREATE_ROLE, { params: params });
export const DeleteRole = (params, id) =>
  HTTP_REQUEST_AUTH.delete(USERS.CREATE_ROLE + "/" + id, params);

export const ListPermission = (params) =>
  HTTP_REQUEST_AUTH.get(USERS.LIST_PERMISSION, { params: params });
export const UpdatePermission = (params) =>
  HTTP_REQUEST_AUTH.put(USERS.UPDATE_PERMISSION + "/" + params.id, params);

export const UpdateRolePermission = (params) =>
  HTTP_REQUEST_AUTH.post(USERS.UPDATE_ROLE_PERMISSION, params);

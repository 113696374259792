import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {EXAM} from "@/constants/endPoints";

export const AllSubject = (params) => HTTP_REQUEST_AUTH.get(EXAM.GET_ALL_SUBJECT, {params: params});

export const ListQuestion = (params) => HTTP_REQUEST_AUTH.get(EXAM.QUESTIONS, {params: params});

export const DetailQuestion = (id) => HTTP_REQUEST_AUTH.get(EXAM.QUESTIONS + '/' + id);

export const UpdateQuestion = (params, id) => HTTP_REQUEST_AUTH.put(EXAM.QUESTIONS + '/' + id, params);

export const CreateStudent = (params) => HTTP_REQUEST_AUTH.post(EXAM.CREATE_QUESTION, params);

export const CreatedExam = (params) => HTTP_REQUEST_AUTH.post(EXAM.CREATE_EXAM, params);

export const DetailExam = (params) => HTTP_REQUEST_AUTH.get(EXAM.DETAIL_EXAM, {params: params});

export const UpdateExam = (params) => HTTP_REQUEST_AUTH.post(EXAM.UPDATE_EXAM, params);

export const GetExams = (params) => HTTP_REQUEST_AUTH.get(EXAM.LIST_EXAM, {params: params});

export const ExamReport = (params, id) => HTTP_REQUEST_AUTH.get(EXAM.EXAM_REPORT + "/" + id, {params: params});
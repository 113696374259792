<template>
  <div id="infoBatch">
    <div class="p-3 bg-white infoBatch">
      <div class="text-center mb-3"><h5>Thông tin lớp học</h5></div>

      <el-row :gutter="20">
        <el-col :span="12">
          <div class="pb-2">
            <span>Tên lớp học:</span> {{ batch.name }}
          </div>

          <div class="pb-2">
            <span>Tổng số học sinh:</span> {{ tableData.length }}
          </div>
          <div class="pb-2">
            <span>Học viên ngừng hoạt động:</span> {{ studentInactive }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="pb-2">
            <span>Mã lớp:</span> {{ batch.code }}
          </div>
          <div class="pb-2">
            <span>Thời gian học:</span> {{ batch.start_date }} - {{ batch.end_date }}
          </div>
          <div class="pb-2">
            <span>Thời gian buổi học:</span> {{ batch.start_time }} - {{ batch.end_time }}
          </div>
          <div class="pb-2">
            <span>Lộ trình học:</span> {{ roadmap.title }}
          </div>

        </el-col>
      </el-row>
      <div class="buttonEdit">
        <el-button @click="setup.dialogAdd = true"><i class="fa fa-edit"></i></el-button>
      </div>
    </div>
    <div class="p-3 my-3 bg-white">
      <div class="d-flex justify-content-between">
        <div class="justify-content-start">
          <div class="text-center "><h5>Danh sách giáo viên</h5></div>
        </div>
        <div class="justify-content-end">
          <handle-button
              eventCode="batches.assign-teacher"
              typeButton="btn-outline-dark ml-3 py-1"
              type="link" label="Thêm giáo viên" icon="fa fa-plus"
              :to="{ name: nameRouter.PAGES.ADD_TEACHER_TO_BATCH, params:{id: idBatch}}"/>
        </div>
      </div>
      <el-table
          :data="tableTeachers"
          style="width: 100%"
          ref="studentTable"
          fixed
          border
      >
        <el-table-column fixed prop="username" label="Tên đăng nhập" min-width="150"/>
        <el-table-column prop="name" label="Họ tên" min-width="150"/>
        <el-table-column prop="email" label="email" min-width="150"/>
        <el-table-column prop="type" label="Nhiệm vụ" min-width="150"/>
        <el-table-column
            fixed="right"
            label="Quyền hạn"
            min-width="140">
          <template #default="scope">
            <el-button
                type="primary"
                size="small">
              <i class="fa fa-user"></i><span class="pl-2 text-white">{{ scope.row.roleLevel }}</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            min-width="140">
          <template #default="scope">
            <el-button
                type="danger"
                @click="openTeacherConfirm(scope.row.id)"
                size="small">
              <i class="fa fa-trash"></i><span class="pl-2 text-white"> Loại bỏ</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="p-3 my-3 bg-white">
      <div class="d-flex  justify-content-between">
        <div class="justify-content-start">
          <div class="text-center pt-3"><h5>Danh sách học viên</h5></div>
        </div>
        <div class="justify-content-end pt-3">
          <handle-button
              eventCode="batches.assign-batch"
              typeButton="btn-outline-dark ml-3 py-1"
              type="link" label="Thêm học sinh" icon="fa fa-plus"
              :to="{ name: nameRouter.PAGES.ADD_STUDENT_TO_BATCH, params:{id: idBatch}}"/>
        </div>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          ref="studentTable"
          fixed
          border
          v-loading.fullscreen="loading">
        <el-table-column label="ID" prop="id" width="55"/>
        <el-table-column prop="username" label="Tên đăng nhập"/>
        <el-table-column prop="name" label="Họ tên"/>
        <el-table-column prop="email" label="email"/>
        <el-table-column prop="phone" label="SDT"/>
        <el-table-column
            fixed="right"
            label="TT hoạt động"
            width="140">
          <template #default="scope">
            <el-button
                type="success"
                size="small" v-if="scope.row.isActive">
              {{ CONFIG_APP.status.activeStr }}
            </el-button>
            <el-button
                type="warning"
                size="small" v-if="!scope.row.isActive">
              {{ CONFIG_APP.status.inActiveStr }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            size="small"
            width="140">
          <template #default="scope">
            <handle-button
                type-button="danger"
                event-code="batches.remove-teacher-from-batch"
                label="Loại bỏ"
                icon="fa fa-trash"
                @click="openConfirm(scope.row.id)"/>
          </template>
        </el-table-column>
      </el-table>
      <confirm v-model="setup.confirmDialog" @close="closeDialog"
               @deleteService="removeContent"></confirm>

      <confirm v-model="setup.teacherRemoveConfirm" @close="closeDialog"
               @deleteService="removeTeacherBatches"></confirm>
    </div>
    <el-dialog
        v-model="setup.dialogAdd"
        width="40%"
        @close="closeDialog"
    >
      <template #title>
        <div class="text-center text-primary">
          <h4>Cập nhật lớp học</h4>
        </div>
      </template>
      <el-form
          @submit="updateBatch"
          ref="formAdd"
          class="login-form"
          :model="form"
          label-position="top"
          :rules="rulesForm"
      >
        <el-form-item prop="name" label="Tên lớp">
          <el-input
              v-model="form.batch_name"
              placeholder="Tên lớp"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code" label="Mã lớp">
          <el-input
              v-model="form.code"
              placeholder="Mã lớp"
          ></el-input>
        </el-form-item>
        <el-form-item prop="roadmap_id" label="Lộ trình học">
          <el-input
              v-model="roadmap.title"
              placeholder="Tên lộ trinh"
              :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item prop="start_date" label="Ngày bắt đầu">
          <el-date-picker
              v-model="form.start_date"
              class="w-100"
              type="date"
              :disabled-date="(time) => disabledEndDate(time, form.end_date)"
              placeholder="Ngày bắt đầu"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item prop="end_date" label="Ngày kết thúc">
          <el-date-picker
              v-model="form.end_date"
              :disabled-date="(time) => disabledStartDate(time, form.start_date) "
              class="w-100"
              type="date"
              placeholder="Ngày kết thúc"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item prop="time" label="Thời gian một buổi học">
          <el-time-picker
              v-model="form.time"
              is-range
              range-separator="Đến"
              start-placeholder="Bắt đầu"
              end-placeholder="Kết thúc"
              format="HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="Trạng thái" prop="isActive">
          <el-radio-group v-model="form.is_active">
            <el-radio :label="CONFIG_APP.status.active">{{ CONFIG_APP.status.activeStr }}</el-radio>
            <el-radio :label="CONFIG_APP.status.inActive">{{ CONFIG_APP.status.inActiveStr }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="dialog-footer text-right">
          <el-button type="danger" :icon="Close" @click="closeDialog">Hủy</el-button>
          <el-button type="primary" :icon="Plus" @click="updateBatch">Cập nhật</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>

</template>
<script>
import {BatchDetail, BatchUpdate, RemoveStudentBatch} from "@/services/batches";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import confirm from "@/components/confirm";
import {checkSpecialCharacter, textInputValidate} from "@/validator";
import {RemoveTeacherFromBatch} from "@/services/users";
import moment from "moment";


export default {
  name: "BatchDetail",
  mixins: [setBreadcrumb],
  data() {
    return {
      idBatch: this.$route.params.id,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmap: {},
      loading: false,
      selected: [],
      batch: {},
      tableTeachers: [],
      removeStudentId: '',
      setup: {
        dialogAdd: false,
        confirmDialog: false,
        teacherRemoveConfirm: false,
      },
      rulesForm: {
        end_date: [textInputValidate],
        start_date: [textInputValidate],
        batch_name: [textInputValidate, checkSpecialCharacter],
        roadmap_id: [textInputValidate],
        code: [textInputValidate],
        time:[textInputValidate]
      },
      form: {title: '', code: '', end_date: '', start_date: '', description: '', time: null},
      removeTeacherId: '',
      studentInactive: 0
    };
  },
  components: {
    confirm
  },
  async created() {
    this.getBatch()
  },
  methods: {
    getBatch() {
      this.loading = true
      let byId = {batch_id: this.$route.params.id}
      BatchDetail(byId).then(e => {
        if (e.data.status == 'success') {
          let batch = e.data.data.data
          this.batch = {
            name: batch.batch_name,
            code: batch.code,
            start_date: formatDate(batch.start_date),
            end_date: formatDate(batch.end_date),
            start_time: batch.start_time ?? '08:00:00',
            end_time: batch.end_time ?? '17:30:00',
          }
          this.form = {
            batch_id: batch.id,
            batch_name: batch.batch_name,
            code: batch.code,
            is_active: batch.is_active,
            roadmap_id: batch.roadmap.id,
            end_date: batch.end_date,
            start_date: batch.start_date,
            time: [batch.start_date + batch.start_time,batch.start_date + batch.end_time],
            description: batch.description,
          }
          this.roadmap = batch.roadmap
          let dataTable = []
          let tableTeachers = []
          let studentList = batch.student
          let teachers = batch.teacher
          studentList.map(e => {
            let item = e.students
            if (item.is_active == configs.status.inActive) {
              this.studentInactive++
            }
            dataTable.push({
              id: item.id,
              username: item.username,
              email: item.email,
              name: item.name,
              isActive: item.is_active == configs.status.active,
              phone: item.phone_number,
            })
          })
          teachers.map(e => {
            let item = e.teacher
            tableTeachers.push({
              id: item.id,
              username: item.username,
              email: item.email,
              name: item.name,
              type: configs.typeTeacher.find(el => el.key == e.type) ? configs.typeTeacher.find(el => el.key == e.type).value : '',
              roleLevel: item.role_level ? configs.roles[item.role_level] : '',
            })
          })
          this.tableData = dataTable
          this.tableTeachers = tableTeachers
          this.loading = false
        }
      }).catch(err => {
        notifyErr()
        this.loading = false
      })

    },
    openConfirm(id) {
      // if (this.tableData.length < 2) return notifyErr('Không được xóa hết')
      let item = this.tableData.findIndex(el => el.id === id);
      if (item < 0) return notifyErr('Không tồn tại học sinh này')
      this.removeStudentId = id
      this.setup.confirmDialog = true
    },
    removeContent() {
      let obj = {
        batch_id: this.idBatch,
        student_ids: [this.removeStudentId]
      }
      let item = this.tableData.findIndex(el => el.id === this.removeStudentId);
      if (item < 0) return false
      RemoveStudentBatch(obj).then(res => {
        if (res.data.status == 'success') {
          this.tableData.splice(item, 1)
          notifySuccess('add', 'Loại bỏ học viên khỏi lớp thành công')
          this.closeDialog()
        }
      }).catch(err => {
        notifyErr()
      })
    },
    closeDialog() {
      this.setup.confirmDialog = false
      this.setup.teacherRemoveConfirm = false
      this.setup.dialogAdd = false
      this.removeStudentId = ''
      this.removeTeacherId = ''
    },
    disabledStartDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() < dateNew.getTime()
      }
    },
    disabledEndDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() > dateNew.getTime()
      }
    },
    async updateBatch(event) {
      let valid = await this.$refs.formAdd.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return notifyErr('Vui lòng điền đầy đủ thông tin.');
      this.form['start_time'] = moment(this.form.time[0]).format('HH:mm:ss');
      this.form['end_time'] = moment(this.form.time[1]).format('HH:mm:ss');
      BatchUpdate(this.form).then(res => {
        notifySuccess('edit')
        this.closeDialog()
        this.getBatch()
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
      event.preventDefault()
    },
    openTeacherConfirm(id) {
      this.removeTeacherId = id;
      this.setup.teacherRemoveConfirm = true
    },
    removeTeacherBatches() {
      RemoveTeacherFromBatch({teacher_id: this.removeTeacherId, batch_id: this.$route.params.id}).then(res => {
        notifySuccess('add', 'Loại bỏ giáo viên khỏi lớp thành công.')
        this.closeDialog()
        this.getBatch()
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {Plus, Close} from "@element-plus/icons-vue";
import HandleButton from "@/components/button";
</script>
<style scoped lang="scss">
.infoBatch {
  position: relative;

  .buttonEdit {
    position: absolute;
    top: 4%;
    right: 1%;
  }
}
</style>
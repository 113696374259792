<template>
  <div class="bg-white p-3 mb-4">
    <el-calendar ref="calendar" class="calendar">
      <template #header="{ date }">
        <span>{{ date }}</span>
        <el-button-group>
          <el-button size="small" @click="selectDate('prev-month',date)">
            Tháng trước
          </el-button>
          <el-button size="small" @click="selectDate('today',date)">Hôm nay</el-button>
          <el-button size="small" @click="selectDate('next-month',date)">
            Tháng sau
          </el-button>
        </el-button-group>
      </template>
      <template #dateCell="{data}">
        <el-popover placement="auto" :width="400" trigger="contextmenu">
          <template #reference>
            <ul class="list-data" @click="viewDetail(data.day)">
              <li style="text-align: center; font-weight: 500">{{ data.day.split('-').pop() }}</li>
              <li class="data-content"
                  v-for="item in getDataInDay(data.day)"
                  :key="item"
              >
                <div style="display:flex; align-items:center; gap:4px">
                  <span>{{ item.title }}</span>
                </div>
              </li>
            </ul>
          </template>
          <div class="more-info" v-if="getDataInDay(data.day).length > 0">
            <div class="header">Thông tin lịch trình</div>
            <div v-for="item in getDataInDay(data.day)"
                 :key="item"
                 style="width: 100%"
            >
              <router-link :to="{name: NameRouter.PAGES.DETAIL_SCHEDULE, params: {id: item.id}}">
                <div style="font-size:16px; font-weight: 500">
                  Bắt đầu: {{ item.start_time.split(" ")[1] }} - Kết thúc: {{ item.end_time.split(" ")[1] }}
                </div>
                <div style="font-size: 14px">{{ item.title }}</div>
              </router-link>
            </div>
            <div>
              <el-button type="primary" size="small" @click="viewDetail(data.day)">Chi tiết lịch trình</el-button>
            </div>
          </div>
          <div class="more-info" v-else>
            <div class="title">Không có thông tin lịch trình</div>
            <el-row>
              <el-button type="primary" size="small" @click="createSchedule(data.day)">Tạo lịch trình</el-button>
            </el-row>
          </div>
        </el-popover>
      </template>
    </el-calendar>

  </div>

</template>

<script>
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {GetListSchedule} from "@/services/schedule";
import configs from "@/configs";
import {NameRouter} from "@/constants/nameRouter";
import moment from "moment";


export default {
  name: "ListSchedule",
  mixins: [setBreadcrumb],
  components: {},

  created() {
    this.getListSchedule();
  },
  data() {
    return {
      visible: false,
      tableList: [],
      configs: configs,
      currentPage: 1,
      pageIndex: 1,
      NameRouter: NameRouter,
    }
  },
  methods: {
    getDataInDay(date) {
      let newData = this.tableList.filter(item => item.start_time.includes(date));
      return newData;
    },
    getListSchedule(params) {
      this.loading = true;
      let start_day = moment().startOf('month').format('YYYY-MM-DD');
      let end_day = moment().endOf('month').format('YYYY-MM-DD');
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage, start_day, end_day}
      }
      GetListSchedule(params).then(res => {
        if (res.data.status === 'success') {
          this.tableList = res.data.data.schedules;
        }
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
    viewDetail(day) {
      let data = this.getDataInDay(day);
      if (data.length > 0) {
        this.$router.push({name: NameRouter.PAGES.DETAIL_DAY_SCHEDULE, params: {id: day}})
      } else {
        this.$router.push({name: NameRouter.PAGES.CREATE_SCHEDULE_IN_DAY, params: {id: day}})
      }
    },
    createSchedule(day) {
      this.$router.push({name: NameRouter.PAGES.CREATE_SCHEDULE_IN_DAY, params: {id: day}})
    },
    selectDate(val, date) {
      this.$refs.calendar.selectDate(val);
      let arrDate = date.split(" ");
      let year = arrDate[0];
      let month = arrDate[3];
      let newDate;
      let start_time;
      let end_time;

      if (val == 'today') {
        let cMonth = (new Date()).toLocaleDateString("en-CA").split('-')
        cMonth.pop()
        newDate = cMonth.join("-")
        let start_day = moment(new Date(newDate)).startOf('month').format('YYYY-MM-DD');
        let end_day = moment(new Date(newDate)).endOf('month').format('YYYY-MM-DD');
      } else if (val == 'next-month') {
        year = month == 12 ? Number(year) + 1 : year
        month = month == 12 ? 1 : Number(month) + 1
        newDate = year + '-' + month
        let start_day = moment(new Date(newDate)).startOf('month').format('YYYY-MM-DD');
        let end_day = moment(new Date(newDate)).endOf('month').format('YYYY-MM-DD');
      } else {
        year = month == 1 ? Number(year) - 1 : year
        month = month == 1 ? 12 : Number(month) - 1
        newDate = year + '-' + month
        let start_day = moment(new Date(newDate)).startOf('month').format('YYYY-MM-DD');
        let end_day = moment(new Date(newDate)).endOf('month').format('YYYY-MM-DD');
      }

      let params = {per_page: configs.itemOnPage, page: this.currentPage, start_day, end_day}

      GetListSchedule(params).then(res => {
        if (res.data.status === 'success') {
          this.tableList = res.data.data.schedules;
        }
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    }

  }
}
</script>


<style scoped>
>>> .current {
  height: 120px;
}

>>> .el-calendar-table .el-calendar-day {
  height: 120px;
}

.list-data {
  width: 100%;
  height: 100%;
  padding: 2px;
  margin: 0;
  list-style-type: none;
  overflow-y: hidden;
}

.data-content {
  padding: 2px;
  font-size: 14px;
}

.more-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.header {
  color: #616161;
  font-size: 16px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #383966;
}

.time {
  color: #242424;
}

</style>

<script setup>
import {
  Delete,
} from '@element-plus/icons-vue'
</script>
import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {ROADMAP} from "@/constants/endPoints";

export const GetAllRoadmap = (params) => HTTP_REQUEST_AUTH.get(ROADMAP.GET_ALL_ROAD_MAP, {params: params});

export const GetRoadmap = (params) => HTTP_REQUEST_AUTH.get(ROADMAP.GET_ROADMAP, {params: params});

export const GetRoadmapDetail = (id, params) => HTTP_REQUEST_AUTH.get(ROADMAP.GET_ROADMAP + '/' + id, {params: params});

export const CreateRoadmap = (params) => HTTP_REQUEST_AUTH.post(ROADMAP.CREATE_ROADMAP, params);

export const UpdateRoadmap = (id, params) => HTTP_REQUEST_AUTH.put(ROADMAP.UPDATE_ROADMAP + '/' + id, params);
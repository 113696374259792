<template>
  <div>
    <el-form :rules="ruleForm" label-position="top" :model="infoForm" ref="formModule">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="text-bold">Ảnh đại diện
            <span class="text-danger">*</span>
          </div>
          <div class="py-3">
            <el-form-item prop="image">
              <el-upload
                  ref="avatar"
                  :limit="1"
                  class="avatar-uploader"
                  :class="imageUrl? 'disable-event': '' "
                  :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar">
                <el-icon v-if="!imageUrl" class="avatar-uploader-icon">
                  <Plus/>
                </el-icon>
              </el-upload>
            </el-form-item>
            <div v-if="imageUrl" class="ml-lg-5 pl-lg-5 pl-md-5">
              <el-button type="danger" size="small" class="p-1">
                <el-icon>
                  <Delete/>
                </el-icon>
                <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>
              </el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <el-form-item label="Tên module" prop="title">
            <el-input type="text" v-model="infoForm.title"/>
          </el-form-item>
          <el-form-item label="Mã code" prop="code">
            <el-input type="text" v-model="infoForm.code"/>
          </el-form-item>
          <el-form-item label="Thời gian(phút)" prop="duration">
            <el-input type="text" v-model="infoForm.duration"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div :class="{'hasErr': descriptionValid}">
            <editorTiny v-model="infoForm.description"/>
            <small v-if="descriptionValid" style="color: #f56c6c">{{ messages.NULL }}</small>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {checkIsInteger, textInputValidate} from "@/validator";
import editorTiny from "@/components/editor";
import {UploadFile} from "@/services/common";
import {isImage, notifyFiled} from "@/mixins/utils";

export default {
  expose: ['validForm', 'setImage'],
  name: 'Info-Module',
  components: {
    editorTiny
  },
  props: {
    infoForm: Object
  },
  data() {
    return {
      ruleForm: {
        title: [textInputValidate],
        code:[textInputValidate],
        duration: [textInputValidate, checkIsInteger],
        description: [textInputValidate],
        image: [textInputValidate]
      },
      descriptionValid: false,
      imageUrl: ''
    }
  },
  methods: {
    async uploadFile() {
      let formData = new FormData
      formData.append('file', this.file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          this.infoForm.image = e.data.data.filePath
          path = e.data.data.filePath
        }
        return path
      })
    },
    beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled()
        return false
      }
      this.imageUrl = URL.createObjectURL(uploadFile)
      this.file = uploadFile
      this.uploadFile()
      return true
    },
    handleRemove() {
      this.$refs.avatar.clearFiles()
      this.imageUrl = ''
      this.infoForm.image = ''
    },
    async validForm() {
      this.descriptionValid = this.infoForm.description == ''
      let valid = await this.$refs.formModule.validate((valid, fields) => {
        if (!valid || !this.infoForm.description) {
          return false;
        }
      });
      return valid
    },
    setImage(url) {
      this.imageUrl = url
    }
  }
}
</script>
<script setup>
import messages from "@/constants/messages";
import config from "@/configs";
import {Delete, Plus} from '@element-plus/icons-vue'
</script>
<style lang="scss" scoped>
.avatar-uploader .avatar {
  max-width: 300px;
  max-height: 300px;
  min-height: 250px;
  min-width: 250px;
  display: block;
  position: relative;
}

.action-image {
  background: #000000b5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  pointer-events: none;
  display: none;

  svg {
    color: white;
  }

  .action-delete {
    font-size: 18px;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
}

::v-deep .el-upload:hover {
  .action-image {
    display: block;
  }
}

.hasErr {
  ::v-deep .tox {
    border: 1px solid red;
  }
}

::v-deep .is-error {
  .el-upload {
    border-color: red;
  }
}
</style>
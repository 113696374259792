<template>
  <div>
    <el-form :rules="blogForm" label-position="top" :model="infoBlog" ref="formBlog">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-row :gutter="10">
            <el-col :span="10">
              <div class="text-bold">Ảnh đại diện
                <span class="text-danger">*</span>
              </div>
              <div class="py-3">
                <el-form-item prop="avatar">
                  <el-upload
                      ref="avatar"
                      :limit="1"
                      class="avatar-uploader"
                      :class="imageUrl? 'disable-event': '' "
                      :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar">
                    <el-icon v-if="!imageUrl">
                      <Plus/>
                    </el-icon>
                  </el-upload>
                </el-form-item>
                <div v-if="imageUrl" class="text-center" @click="handleRemove()">
                  <el-button type="danger" :icon="Delete">
                    Xóa ảnh
                  </el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="14" class="py-3">
              <el-form-item label="Tên bài viết" prop="title">
                <el-input type="text" v-model="infoBlog.title" @change="autoRenderSlug"/>
              </el-form-item>
              <el-form-item label="Slug" prop="slug">
                <el-input type="text" v-model="infoBlog.slug"/>
              </el-form-item>
              <el-form-item label="Mã code" prop="code">
                <el-input type="text" v-model="infoBlog.code"/>
              </el-form-item>
              <el-form-item label="Hash Tag" prop="tag">
                <el-tag
                    :key="i"
                    v-for="i in infoBlog.tag"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(i)"
                    effect="dark"
                >
                  {{ i }}
                </el-tag>
                <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    v-on:keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" @click="showInput" size="small">+</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Mô tả ngắn" prop="description">
                <el-input v-model="infoBlog.description" type="textarea"/>
              </el-form-item>
              <el-form-item label="Độ ưu tiên" prop="priority">
                <el-radio-group v-model="infoBlog.priority">
                  <el-radio v-for="item in configs.blogPriority" :key="item.value" :label="item.value" size="lage"
                            border>
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Hoạt động" prop="activity">
                <el-radio-group v-model="infoBlog.is_active">
                  <el-radio v-for="item in configs.blogActive" :key="item.value" :label="item.value" size="lage" border>
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Trạng thái" prop="status">
                <el-radio-group v-model="infoBlog.status" disabled>
                  <el-radio v-for="item in configs.blogStatus" :key="item.value" :label="item.value" size="lage" border>
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="14">
          <div :class="{'hasErr': descriptionValid}">
            <editorTiny v-model="infoBlog.content"/>
            <small v-if="descriptionValid" style="color: #f56c6c">{{ messages.NULL }}</small>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {textInputValidate} from "@/validator";
import editorTiny from "@/components/editor";
import {UploadFile} from "@/services/common";
import {convertToSlug, isImage, notifyFiled} from "@/mixins/utils";
import configs from "@/configs";

export default {
  expose: ['validBlog', 'setImage'],
  name: 'InfoBlog',
  components: {
    editorTiny
  },
  props: {
    infoBlog: Object
  },
  data() {
    return {
      configs: configs,
      blogForm: {
        title: [textInputValidate],
        code: [textInputValidate],
        description: [textInputValidate],
        avatar: [textInputValidate],
        slug:[textInputValidate]
      },
      descriptionValid: false,
      imageUrl: '',
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  methods: {
    autoRenderSlug() {
      if(this.infoBlog.slug == ""){
        this.infoBlog.slug = convertToSlug(this.infoBlog.title);
      }
    },
    async uploadFile() {
      let formData = new FormData
      formData.append('file', this.file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          this.infoBlog.avatar = e.data.data.filePath
          path = e.data.data.filePath
        }
        return path
      })
    },
    beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled()
        return false
      }
      this.imageUrl = URL.createObjectURL(uploadFile)
      this.file = uploadFile
      this.uploadFile()
      return true
    },
    handleRemove() {
      this.$refs.avatar.clearFiles()
      this.imageUrl = ''
      this.infoBlog.avatar = ''
    },
    async validBlog() {
      this.descriptionValid = this.infoBlog.content == ''
      let valid = await this.$refs.formBlog.validate((valid, fields) => {
        if (!valid || !this.infoBlog.content) {
          return false;
        }
      });
      if (this.infoBlog.content == '') {
        valid = false;
      }
      return valid
    },
    handleClose(tag) {
      this.infoBlog.tag.splice(this.infoBlog.tag.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.infoBlog.tag.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    setImage(url) {
      this.imageUrl = url
    },

  }
}
</script>
<script setup>
import messages from "@/constants/messages";
import {Delete, Plus} from '@element-plus/icons-vue'
</script>
<style lang="scss" scoped>
.avatar-uploader .avatar {
  width: 100%;
  display: block;
  position: relative;
}

.el-icon {
  min-width: 150px;
  min-height: 150px;
  max-height: 250px;
  max-width: 250px;
}

.action-image {
  background: #000000b5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  pointer-events: none;
  display: none;

  svg {
    color: white;
  }

  .action-delete {
    font-size: 18px;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
}

::v-deep .el-upload:hover {
  .action-image {
    display: block;
  }
}

.hasErr {
  ::v-deep .tox {
    border: 1px solid red;
  }
}

::v-deep .is-error {
  .el-upload {
    border-color: red;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

</style>
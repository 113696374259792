<template>
  <div class="bg-white p-3 rounded">
    <el-tabs vel-tabs type="border-card" v-model="setup.activeName">
      <el-tab-pane label="Thêm mới khóa học" name="first">
        <div class=" my-3">
          <infoCourse :form="form" ref="courseInfoForm"></infoCourse>
          <div class="text-center py-3">
            <handle-button
                label="Tạo Khóa học"
                eventCode="courses.store"
                icon="fa fa-plus"
                typeButton="primary"
                @click="createdContentCourse"/>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Thêm bài kiểm tra" name="exams">
        <examsCourse ref="exams" v-model="form.examIds"></examsCourse>
        <div class="text-center py-3">
          <handle-button
              label="Tạo Khóa học"
              eventCode="courses.store"
              icon="fa fa-plus"
              typeButton="primary"
              @click="createdContentCourse"/>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

import infoCourse from "@/views/Course/infoCourse";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {CreateCourse, CreateMaterial, CreateSection} from "@/services/course";
import examsCourse from "@/components/exams-course";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'CreateCourse',
  components: {
    examsCourse,
    infoCourse
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      form: {
        subject: '',
        summary: '',
        description: '',
        whatLearn: [],
        requirement: [],
        examIds: [],
        type: 1,
        avatar: '',
        name: '',
        roadmapId: '',
        position: '',
        isActive: 1,
        isFree: false,
      },
      nameRouter: NameRouter,
      sectionForm: [
        {
          name: '',
          duration: '',
          position: '',
          isActive: 1,
          id: '1',
        }
      ],
      file: {},
      materials: [{
        id: 1,
        name: '',
        position: '',
        isActive: true,
        file: '',
      }],
      imageUrl: '',
      descriptionValid: false,
      setup: {
        activeName: 'first',
      }
    }
  },
  methods: {
    addCreateSection() {
      let id = new Date().getTime();
      let sectionNew = {
        id: id,
        name: '',
        duration: '',
        position: '',
        isActive: 1,
        materials: [{
          id: id + 2,
          name: '',
          position: '',
          isActive: true,
          file: ''
        }]
      }
      this.sectionForm.push(sectionNew)
    },
    async createdContentCourse() {
      let validExam = await this.$refs.exams.validExam()
      let validCourse = await this.$refs.courseInfoForm.validForm()
      if (!validCourse) return false
      if (!validExam) {
        this.setup.activeName = 'exams'
        return false
      }
      await this.createdCourse();


      event.preventDefault()
    },
    async createdCourse() {
      let exams = []
      this.form.examIds.map((item, index) => {
        exams.push({id: item, sort_order: index})
      })
      let course = {
        subject_id: this.form.subject,
        title: this.form.name,
        code: this.form.code,
        summary: this.form.summary,
        duration: this.form.duration * 60,
        version: this.form.version,
        description: this.form.description,
        level: this.form.level,
        requirements: this.form.requirement,
        what_learns: this.form.whatLearn,
        roadmap_id: this.form.roadmapId,
        course_image: this.form.avatar,
        is_active: this.form.isActive,
        is_free: this.form.isFree ? 1 : 0,
        type: this.form.type,
        exams: exams,
        sort_order: this.form.position,
      }
      let idCourse = ''
      await CreateCourse(course).then(res => {
        if (res.data.status === 'success') {
          console.log("hello")
          idCourse = res.data.data.id
          notifySuccess('add')
          this.$router.push(NameRouter.PAGES.COURSE)
        }
      }).catch(err => {
        return notifyErr()
      })
      return idCourse
    },
    async createSection(courseId) {
      let sections = [];
      this.sectionForm.map(e => {
        sections.push({
          name: e.name,
          sort_order: e.position,
          is_active: e.isActive,
          course_id: courseId
        })
      })
      let isPass = false
      CreateSection({sections: sections}).then(async res => {
        isPass = await res.data.status === 'success'
      }).catch(err => {
        notifyErr()
      })
      return isPass
    },
    createMaterial(courseId) {
      let materials = [];
      this.materials.map(e => {
        let files = []
        e.file.map((item, index) => {
          files.push({
            id: item,
            sort_order: index
          })
        })
        materials.push({
          name: e.name,
          sort_order: e.position,
          is_active: e.isActive,
          duration: e.duration,
          course_id: courseId,
          files: files
        })
      })
      let isSuccess = false
      CreateMaterial({materials: materials}).then(async res => {
        return isSuccess = res.data.status == 'success'
      }).catch(err => {
        notifyErr('Thêm bài học lỗi.')
      })
    },
    validSection() {
      let sectionFail = [];
      this.sectionForm.map((item, index) => {
        let valid = this.$refs.section[index].$refs.sectionRule.validate((valid, fields) => {
          if (!valid) {
            return false;
          }
        })
        if (!valid) sectionFail.push(valid)
      });
      let fail = sectionFail.length > 0
      return !fail
    },
    validMaterial() {
      return this.$refs.materialCourse.materialValid()
    },
    reloadExam() {
      this.$refs.exams.getListExam()
    }
  }
}
</script>
<script setup>
import {Delete, Plus} from '@element-plus/icons-vue'
import {ref} from "vue";
import messages from "@/constants/messages";
import configs from "@/configs";

const avatar = ref()
</script>
<style scoped lang="scss">


</style>

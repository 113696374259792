<template>
  <div id="managementBatches" class="bg-white py-2">
    <div class="text-right pb-2">
      <router-link
          :to="{ name: nameRouter.PAGES.EXAM_CREATE}"
          class="btn btn-primary py-1" style="font-size: 12px"
          target="_blank"><i class="fa fa-plus"></i> Tạo bài thi
      </router-link>
      <button class="btn btn-warning mx-3 py-1" style="font-size: 12px" @click="reloadExam"> Làm mới</button>
    </div>
    <div class="py-3" v-if="isValid">
      <small v-if="isValid" class="text-danger">Vui lòng chọn bài kiểm tra</small>
    </div>
    <el-table
        ref="exams"
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        fixed
        @select="handleSelected"
        @selection-change="handleSelectAll"
    >
      <el-table-column type="selection" width="55"/>
      <el-table-column fixed prop="title" label="Tiêu đề" min-width="150"/>
      <el-table-column prop="duration" label="Thời gian thi" min-width="100"/>
      <el-table-column prop="examQuestionCount" label="Số câu hỏi" min-width="120"/>
      <el-table-column prop="createdAt" label="Ngày tạo" min-width="120"/>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
</template>

<script>
import {formatDate, notifyErr} from "@/mixins/utils";
import pagination from "@/components/pagination";
import configs from "@/configs";
import {GetExams} from "@/services/exam";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: "ExamsCourse",
  expose: ['validExam', 'getListExam'],
  components: {
    pagination
  },
  props: {
    modelValue: Array
  },
  emits: ['update:modelValue'],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogAdd: false,
      selected: [],
      isValid: false,
      nameRouter: NameRouter
    };
  },
  watch: {
    selected() {
      this.$emit('update:modelValue', this.selected)
    },
    tableData: {
      handler(newTable) {
        let that = this
        that.$nextTick(function () {
          that.toggleSelection()
        })
      },
      immediate: true
    }
  },

  created() {
    this.getListExam()
  },
  methods: {
    getListExam() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage}
      GetExams(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.exams.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.title,
              duration: e.duration,
              examQuestionCount: e.exam_question_count,
              createdAt: e.created_at ? formatDate(e.created_at) : '',
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.exams.total
          this.isShowPagination = e.data.data.exams.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getListExam(index);
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.selected.push(row.id)
      }
      this.$emit('update:modelValue', this.selected)
    },
    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.selected.findIndex(id => e.id == id) > -1) return
          this.selected.push(e.id)
        })
        this.$emit('update:modelValue', this.selected)
      }
    },
    toggleSelection(table) {
      if (this.selected.length < 1) {
        return false
      }
      this.selected.forEach(e => {
        let item = this.tableData.find(item => e == item.id)
        if (!item) return false;
        this.$refs.exams.toggleRowSelection(item);
      })
    },
    validExam() {
      this.isValid = !this.selected.length < 1
      return this.isValid
    },
    reloadExam() {
      this.getListExam()
    }
  }

}
</script>

<style scoped>

</style>

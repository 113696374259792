<template>
  <div id="managementBatches" class="bg-white p-3">
    <div style="margin-bottom: 20px">
      Chi tiết lịch trình ngày {{ detailDate }}
    </div>
    <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
      <el-tab-pane label="Thông tin" name="info">
        <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed>
          <el-table-column fixed prop="id" label="ID" width="50"/>
          <el-table-column fixed prop="title" label="Tên tiêu đề" width="250"/>
          <el-table-column label="Lớp" width="150">
            <template #default="scope">
              <span>{{ scope.row.batch.batch_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Phòng" width="150">
            <template #default="scope">
              <span>{{ scope.row.room.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Bắt đầu" width="120">
            <template #default="scope">
              <span>{{ scope.row.start_time.split(" ")[1] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Kết thúc" width="120">
            <template #default="scope">
              <span>{{ scope.row.end_time.split(" ")[1] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              width="400"
              label="Hành động"
          >
            <template #default="scope">
              <div class="d-flex">
                <el-button
                    size="small"
                    :icon="Edit"
                    type="primary"
                    @click="viewDetail(scope.row.id)"
                >
                  Chi tiết
                </el-button>
                <el-button
                    size="small"
                    :icon="Delete"
                    type="danger"
                    @click="openConfirmDelete(scope.row.id)"
                >
                  Xóa
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb-3 text-center">
          <pagination
              v-show="isShowPagination"
              :total="total"
              :page-index="pageIndex"
              @currentPageChange="nextPage"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Thông tin chi tiết" name="info-detail">
        <info-schedule :form="form" ref="formSchedule"/>
        <div class="text-center py-3 px-3">
          <handle-button
              label="Cập nhật lịch trình"
              eventCode=""
              icon="fa fa-plus"
              typeButton="primary"
              @click="updateSchedule"/>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  <confirm v-model="isOpenConfirm" @close="closeConfirmDeleteSchedule"
           @deleteService="removeSchedule"></confirm>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import confirm from "@/components/confirm";
import moment from "moment/moment";
import {GetListSchedule, DeleteSchedule, GetDetailSchedule, UpdateSchedule} from "@/services/schedule";
import infoSchedule from "@/views/Schedule/infoSchedule";


export default {
  name: "DetailDaySchedule",
  components: {
    pagination,
    confirm,
    infoSchedule
  },
  mixins: [setBreadcrumb],

  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      configs: configs,
      nameRouter: NameRouter,
      isOpenConfirm: false,
      idDelete: null,
      setup: {
        activeName: 'info',
      },
      form: {},
      idEditEvent: "",
    };
  },

  computed: {
    detailDate() {
      let arrayId = this.$route.params.id.split("-")
      return arrayId[2] + '-' + arrayId[1] + '-' + arrayId[0];
    }
  },
  created() {
    this.getScheduleDetailInDay();
  },
  methods: {
    async getScheduleDetailInDay(params) {
      this.loading = true;
      let start_day = moment(new Date(this.$route.params.id)).startOf('month').format('YYYY-MM-DD');
      let end_day = moment(new Date(this.$route.params.id)).endOf('month').format('YYYY-MM-DD');
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage, start_day, end_day}
      }
      GetListSchedule(params).then(res => {
        if (res.data.status === 'success') {
          let tableList = res.data.data.schedules.filter(i => i.start_time.includes(this.$route.params.id))
          this.tableData = tableList
          this.loading = false;
        }
      }).catch(err => {
        this.loading = false;
        return notifyErr(err.response.data.message)
      })
    },
    openConfirmDelete(id) {
      this.isOpenConfirm = true
      this.idDelete = id
    },
    closeConfirmDeleteSchedule() {
      this.isOpenConfirm = false
      this.idDelete = null

    },
    async removeSchedule() {
      DeleteSchedule(this.idDelete).then(res => {
        this.loading = true;
        if (res.data.status === 'success') {
          this.isOpenConfirm = false
          notifySuccess("delete")
          this.getScheduleDetailInDay();
        }
        this.loading = false;
      }).catch(e => {
        return notifyErr()
      })

    },
    async getCurrentSchedule() {
      try {
        let res = await GetDetailSchedule(this.idEditEvent)
        let data = res.data.data.schedule;

        this.form = {
          title: data.title,
          description: data.description,
          room_id: data.room_id,
          batch_id: data.batch_id,
          course_id: data.course_id,
          material_id: data.material_id,
          section_id: data.section_id,
          trainer_id: data.trainer_id,
          trainer_assistant_id: data.trainer_assistant_id,
          day: new Date(data.start_time),
          time: [new Date(data.start_time), new Date(data.end_time)]
        }
      } catch (err) {

      }
    },
    async updateSchedule() {
      try {
        let day = this.form.day.toLocaleDateString('en-CA');
        let timeStart = this.form.time[0].toLocaleTimeString();
        let timeEnd = this.form.time[1].toLocaleTimeString();
        this.form.start_time = day + " " + timeStart
        this.form.end_time = day + " " + timeEnd
        await UpdateSchedule(this.idEditEvent, this.form);
        this.$router.go();
        notifySuccess("edit")
      } catch (err) {
        return notifyErr(err.response.data.message)
      }
    },
    viewDetail(id) {
      this.idEditEvent = id;
      this.setup.activeName = "info-detail";
      this.getCurrentSchedule();
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getDetailDayEvent(index);
    },
  }
}

</script>
<script setup>
import {
  Delete,
  Edit
} from '@element-plus/icons-vue'

</script>

import { NameRouter } from "@/constants/nameRouter";
import CourseIndex from "@/views/Course";
import ListCourse from "@/views/Course/listCourse";
import CourseDetail from "@/views/Course/courseDetail";
import CreateCourse from "@/views/Course/createCourse";
import ManagementFile from "@/views/ManagerFile";
import ManagementSection from "@/views/ManagementSection";
import CreateSection from "@/views/ManagementSection/createSection";
import DetailSection from "@/views/ManagementSection/detailSection";
import ListSection from "@/views/ManagementSection/listSection";
import ManagementRoadmap from "@/views/ManagementRoadmap";
import CreateRoadmap from "@/views/ManagementRoadmap/createRoadmap";
import RoadmapDetail from "@/views/ManagementRoadmap/roadmapDetail";
import ListRoadmap from "@/views/ManagementRoadmap/listRoadmap";
import ManagementModule from "@/views/ManagementModule";
import CreateModule from "@/views/ManagementModule/createModule";
import DetailModule from "@/views/ManagementModule/detailModule";
import ListModule from "@/views/ManagementModule/listModule";
import ManagementMaterial from "@/views/ManagementMaterial";
import MaterialList from "@/views/ManagementMaterial/materialList";
import DetailMaterial from "@/views/ManagementMaterial/detailMaterial";
import CreateMaterial from "@/views/ManagementMaterial/createMaterial";
import ListFile from "@/views/ManagerFile/listFile";
import CreatedFile from "@/views/ManagerFile/createdFile";
import DetailFile from "@/views/ManagerFile/detailFile";
import ListSubject from "@/views/MangementSubject/listSubject";
import ManagementDocument from "@/views/document";
import FolderManagement from "@/views/FolderManagement";
import TreeFolder from "@/views/FolderManagement/treeFolder";
import CreateLession from "@/views/FolderManagement/createLession";

export default [
  {
    path: "/" + NameRouter.PAGES.DOCUMENT_MANAGEMENT,
    name: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
    alias: NameRouter.ALIAS.DOCUMENT_MANAGEMENT,
    meta: {
      icon: "fas fa-coins",
      alias: NameRouter.ALIAS.DOCUMENT_MANAGEMENT,
      parent: true,
    },
    component: ManagementDocument,
    children: [
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_FOLDER,
        name: NameRouter.PAGES.MANAGEMENT_FOLDER,
        alias: NameRouter.ALIAS.MANAGEMENT_FOLDER,
        meta: {
          icon: "fas fa-folder",
          alias: NameRouter.ALIAS.MANAGEMENT_FOLDER,
          parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
          documentManager: true,
          code: "",
        },
        component: FolderManagement,
        redirect: (to) => {
          return { name: NameRouter.PAGES.INFO_TREE_FOLDER };
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.INFO_TREE_FOLDER,
            name: NameRouter.PAGES.INFO_TREE_FOLDER,
            alias: NameRouter.ALIAS.INFO_TREE_FOLDER,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_FOLDER,
              alias: NameRouter.ALIAS.INFO_TREE_FOLDER,
              documentManager: true,
              code: "roadmaps.index",
            },
            component: TreeFolder,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_ROADMAP,
        name: NameRouter.PAGES.MANAGEMENT_ROADMAP,
        alias: NameRouter.ALIAS.MANAGEMENT_ROADMAP,
        meta: {
          icon: "fas fa-toolbox",
          alias: NameRouter.ALIAS.MANAGEMENT_ROADMAP,
          parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
          documentManager: true,
          code: "roadmaps.index",
        },
        component: ManagementRoadmap,
        redirect: (to) => {
          return { name: NameRouter.PAGES.LIST_ROADMAP };
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_ROADMAP,
            name: NameRouter.PAGES.LIST_ROADMAP,
            alias: NameRouter.ALIAS.LIST_ROADMAP,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_ROADMAP,
              alias: NameRouter.ALIAS.LIST_ROADMAP,
              documentManager: true,
              code: "roadmaps.index",
            },
            component: ListRoadmap,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_ROADMAP,
            name: NameRouter.PAGES.CREATE_ROADMAP,
            alias: NameRouter.ALIAS.CREATE_ROADMAP,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_ROADMAP,
              parentName: NameRouter.PAGES.MANAGEMENT_ROADMAP,
              documentManager: true,
              code: "roadmaps.store",
            },
            component: CreateRoadmap,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_ROADMAP + "/:id",
            name: NameRouter.PAGES.DETAIL_ROADMAP,
            alias: NameRouter.ALIAS.DETAIL_ROADMAP,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.DETAIL_ROADMAP,
              parentName: NameRouter.PAGES.MANAGEMENT_ROADMAP,
              isDetail: true,
              documentManager: true,
              code: "roadmaps.show",
            },
            component: RoadmapDetail,
          },
          {
            path: "/" + NameRouter.PAGES.SUBJECTS,
            name: NameRouter.PAGES.SUBJECTS,
            alias: NameRouter.ALIAS.SUBJECTS,
            meta: {
              icon: "fas fa-file-invoice",
              parentName: NameRouter.PAGES.MANAGEMENT_ROADMAP,
              alias: NameRouter.ALIAS.SUBJECTS,
              documentManager: true,
              code: "subjects.index",
            },
            component: ListSubject,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_MODULE,
            name: NameRouter.PAGES.CREATE_MODULE,
            alias: NameRouter.ALIAS.CREATE_MODULE,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_ROADMAP,
              alias: NameRouter.ALIAS.CREATE_MODULE,
              documentManager: true,
              code: "modules.store",
            },
            component: CreateModule,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_COURSE,
            name: NameRouter.PAGES.CREATE_COURSE,
            alias: NameRouter.ALIAS.CREATE_COURSE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_COURSE,
              parentName: NameRouter.PAGES.MANAGEMENT_ROADMAP,
              documentManager: true,
              code: "courses.store",
            },
            component: CreateCourse,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_MODULE,
        name: NameRouter.PAGES.MANAGEMENT_MODULE,
        alias: NameRouter.ALIAS.MANAGEMENT_MODULE,
        meta: {
          icon: "fas fa-sitemap",
          alias: NameRouter.ALIAS.MANAGEMENT_MODULE,
          parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
          documentManager: true,
          code: "modules.index",
        },
        component: ManagementModule,
        redirect: (to) => {
          return { name: NameRouter.PAGES.LIST_MODULE };
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_MODULE,
            name: NameRouter.PAGES.LIST_MODULE,
            alias: NameRouter.ALIAS.LIST_MODULE,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_MODULE,
              alias: NameRouter.ALIAS.LIST_MODULE,
              documentManager: true,
              code: "modules.index",
            },
            component: ListModule,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_MODULE,
            name: NameRouter.PAGES.CREATE_MODULE,
            alias: NameRouter.ALIAS.CREATE_MODULE,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_MODULE,
              alias: NameRouter.ALIAS.CREATE_MODULE,
              documentManager: true,
              code: "modules.store",
            },
            component: CreateModule,
          },

          {
            path: "/" + NameRouter.PAGES.DETAIL_MODULE + "/:id",
            name: NameRouter.PAGES.DETAIL_MODULE,
            alias: NameRouter.ALIAS.DETAIL_MODULE,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_MODULE,
              alias: NameRouter.ALIAS.DETAIL_MODULE,
              isDetail: true,
              documentManager: true,
              code: "modules.show",
            },
            component: DetailModule,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_COURSE,
            name: NameRouter.PAGES.CREATE_COURSE,
            alias: NameRouter.ALIAS.CREATE_COURSE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_COURSE,
              parentName: NameRouter.PAGES.COURSE_INDEX,
              documentManager: true,
              code: "courses.store",
            },
            component: CreateCourse,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.COURSE_INDEX,
        name: NameRouter.PAGES.COURSE_INDEX,
        alias: NameRouter.ALIAS.COURSE_INDEX,
        meta: {
          icon: "fas fa-file-invoice",
          parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
          alias: NameRouter.ALIAS.COURSE,
          documentManager: true,
          code: "courses.index",
        },
        redirect: (to) => {
          return { name: NameRouter.PAGES.COURSE };
        },
        component: CourseIndex,
        children: [
          {
            path: "/" + NameRouter.PAGES.COURSE,
            name: NameRouter.PAGES.COURSE,
            alias: NameRouter.ALIAS.COURSE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.COURSE,
              parentName: NameRouter.PAGES.COURSE_INDEX,
              documentManager: true,
              code: "courses.index",
            },
            component: ListCourse,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_COURSE + "/:id",
            name: NameRouter.PAGES.DETAIL_COURSE,
            alias: NameRouter.ALIAS.DETAIL_COURSE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.DETAIL_COURSE,
              isDetail: true,
              parentName: NameRouter.PAGES.COURSE_INDEX,
              documentManager: true,
              code: "courses.show",
            },
            component: CourseDetail,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_COURSE,
            name: NameRouter.PAGES.CREATE_COURSE,
            alias: NameRouter.ALIAS.CREATE_COURSE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_COURSE,
              parentName: NameRouter.PAGES.COURSE_INDEX,
              documentManager: true,
              code: "courses.store",
            },
            component: CreateCourse,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_MATERIAL,
            name: NameRouter.PAGES.CREATE_MATERIAL,
            alias: NameRouter.ALIAS.CREATE_MATERIAL,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_MATERIAL,
              parentName: NameRouter.PAGES.MANAGEMENT_MATERIAL,
              documentManager: true,
              code: "materials.store",
            },
            component: CreateMaterial,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_SECTION,
        name: NameRouter.PAGES.MANAGEMENT_SECTION,
        alias: NameRouter.ALIAS.MANAGEMENT_SECTION,
        meta: {
          icon: "far fa-file",
          alias: NameRouter.ALIAS.MANAGEMENT_SECTION,
          documentManager: true,
          code: "modules.index",
        },
        redirect: (to) => {
          return { name: NameRouter.PAGES.LIST_SECTION };
        },
        component: ManagementSection,
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_SECTION,
            name: NameRouter.PAGES.LIST_SECTION,
            alias: NameRouter.ALIAS.LIST_SECTION,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              alias: NameRouter.ALIAS.LIST_SECTION,
              documentManager: true,
              code: "sections.index",
            },
            component: ListSection,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_SECTION,
            name: NameRouter.PAGES.CREATE_SECTION,
            alias: NameRouter.ALIAS.CREATE_SECTION,
            meta: {
              icon: "far fa-file",
              documentManager: true,
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              alias: NameRouter.ALIAS.CREATE_SECTION,
              code: "sections.store",
            },
            component: CreateSection,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_SECTION + "/:id",
            name: NameRouter.PAGES.DETAIL_SECTION,
            alias: NameRouter.ALIAS.DETAIL_SECTION,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.DETAIL_SECTION,
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              isDetail: true,
              documentManager: true,
              code: "sections.show",
            },
            component: DetailSection,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_MATERIAL,
            name: NameRouter.PAGES.CREATE_MATERIAL,
            alias: NameRouter.ALIAS.CREATE_MATERIAL,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_MATERIAL,
              parentName: NameRouter.PAGES.MANAGEMENT_MATERIAL,
              documentManager: true,
              code: "materials.store",
            },
            component: CreateMaterial,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_MATERIAL,
        name: NameRouter.PAGES.MANAGEMENT_MATERIAL,
        alias: NameRouter.ALIAS.MANAGEMENT_MATERIAL,
        meta: {
          icon: "fas fa-file-alt",
          alias: NameRouter.ALIAS.MANAGEMENT_MATERIAL,
          parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
          documentManager: true,
          code: "materials.index",
        },
        component: ManagementMaterial,
        redirect: (to) => {
          return { name: NameRouter.PAGES.LIST_MATERIAL };
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_MATERIAL,
            name: NameRouter.PAGES.LIST_MATERIAL,
            alias: NameRouter.ALIAS.LIST_MATERIAL,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_MATERIAL,
              icon: "far fa-file",
              alias: NameRouter.ALIAS.LIST_MATERIAL,
              documentManager: true,
              code: "materials.index",
            },
            component: MaterialList,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_MATERIAL + "/:id",
            name: NameRouter.PAGES.DETAIL_MATERIAL,
            alias: NameRouter.ALIAS.DETAIL_MATERIAL,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.DETAIL_MATERIAL,
              parentName: NameRouter.PAGES.MANAGEMENT_MATERIAL,
              isDetail: true,
              code: "materials.show",
            },
            component: DetailMaterial,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_MATERIAL,
            name: NameRouter.PAGES.CREATE_MATERIAL,
            alias: NameRouter.ALIAS.CREATE_MATERIAL,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_MATERIAL,
              parentName: NameRouter.PAGES.MANAGEMENT_MATERIAL,
              documentManager: true,
              code: "materials.store",
            },
            component: CreateMaterial,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_FILE,
            name: NameRouter.PAGES.CREATE_FILE,
            alias: NameRouter.ALIAS.CREATE_FILE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_FILE,
              parentName: NameRouter.PAGES.MANAGEMENT_MATERIAL,
              documentManager: true,
            },
            component: CreatedFile,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.CREATE_LESSION,
        name: NameRouter.PAGES.CREATE_LESSION,
        alias: NameRouter.ALIAS.CREATE_LESSION,
        meta: {
          icon: "fas fa-folder",
          alias: NameRouter.ALIAS.CREATE_LESSION,
          parentName: NameRouter.PAGES.CREATE_LESSION,
          documentManager: true,
          code: "",
        },
        component: FolderManagement,
        redirect: (to) => {
          return { name: NameRouter.PAGES.CREATE_LESSION };
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.CREATE_LESSION,
            name: NameRouter.PAGES.CREATE_LESSION,
            alias: NameRouter.ALIAS.CREATE_LESSION,
            meta: {
              icon: "far fa-file",
              parentName: NameRouter.PAGES.CREATE_LESSION,
              alias: NameRouter.ALIAS.CREATE_LESSION,
              documentManager: true,
              code: "roadmaps.index",
            },
            component: CreateLession,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_FILE,
        name: NameRouter.PAGES.MANAGEMENT_FILE,
        alias: NameRouter.ALIAS.MANAGEMENT_FILE,
        meta: {
          icon: "fas fa-archive",
          alias: NameRouter.ALIAS.MANAGEMENT_FILE,
          parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
          documentManager: true,
          code: "files.get-list-file",
        },
        component: ManagementFile,
        redirect: (to) => {
          return { name: NameRouter.PAGES.LIST_FILE };
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_FILE,
            name: NameRouter.PAGES.LIST_FILE,
            alias: NameRouter.ALIAS.LIST_FILE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.LIST_FILE,
              parentName: NameRouter.PAGES.DOCUMENT_MANAGEMENT,
              documentManager: true,
              code: "files.get-list-file",
            },
            component: ListFile,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_FILE,
            name: NameRouter.PAGES.CREATE_FILE,
            alias: NameRouter.ALIAS.CREATE_FILE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.CREATE_FILE,
              parentName: NameRouter.PAGES.MANAGEMENT_FILE,
              documentManager: true,
              code: "files.store-file",
            },
            component: CreatedFile,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_FILE + "/:id",
            name: NameRouter.PAGES.DETAIL_FILE,
            alias: NameRouter.ALIAS.DETAIL_FILE,
            meta: {
              icon: "far fa-file",
              alias: NameRouter.ALIAS.DETAIL_FILE,
              parentName: NameRouter.PAGES.MANAGEMENT_FILE,
              isDetail: true,
              documentManager: true,
              code: "files.update-file",
            },
            component: DetailFile,
          },
        ],
      },
    ],
  },
];

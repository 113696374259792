<template>
  <div>
    <el-tabs type="border-card" v-model="setup.activeName">
      <el-tab-pane label="Thông tin chi tiết" name="infoDetail">
        <el-form ref="contentForm" label-position="top" :model="form" :rules="addContentRule" class="bg-white p-3 mh-100">
          <el-row>
            <el-col :span="6">
              <div class="text-bold">
                Ảnh đại diện<span class="text-danger">*</span>
              </div>
              <div class="py-3">
                <el-form-item prop="avatar">
                  <el-upload :limit="1" class="avatar-uploader" :class="imageUrl ? 'disable-event' : ''"
                    :before-upload="beforeAvatarUpload" ref="avatar">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar" />
                    <el-icon v-if="!imageUrl" class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
                <div class="ml-lg-5 pl-lg-5 pl-md-5" v-if="imageUrl">
                  <el-button type="danger" size="small" class="p-1">
                    <el-icon>
                      <Delete />
                    </el-icon>
                    <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>
                  </el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="18">
              <el-form-item class="w-100" prop="name" label="Họ tên">
                <el-input v-model="form.name" placeholder="Họ tên" />
              </el-form-item>
              <el-form-item class="w-100" prop="dob" label="Ngày sinh">
                <el-date-picker v-model="form.dob" type="date" placeholder="Pick a day" :size="size" />
              </el-form-item>
              <el-form-item label="Giới tính">
                <el-radio-group v-for="(item, index) in configs.genderInfo" :key="item.key" v-model="form.gender"
                  class="mr-4">
                  <el-radio :label="item.key" size="lage" border>
                    {{ item.value }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="w-100" prop="email" label="Email">
                <el-input v-model="form.email" :disabled="isDetail" placeholder="Email" />
              </el-form-item>
              <el-form-item class="w-100" prop="username" label="Tên đăng nhập">
                <el-input v-model="form.username" :disabled="isDetail" placeholder="Tên đăng nhập" />
              </el-form-item>
              <el-form-item class="w-100" prop="phone_number" label="Số điện thoại">
                <el-input v-model="form.phone_number" placeholder="Số điện thoại" />
              </el-form-item>
              <el-form-item class="w-100" prop="address" label="Địa chỉ">
                <el-input v-model="form.address" placeholder="Địa chỉ" />
              </el-form-item>
              <el-form-item label="Trạng thái hoạt động">
                <el-radio-group v-model="form.is_active">
                  <el-radio :label="configs.status.active" size="lage" border>
                    {{ configs.status.activeStr }}
                  </el-radio>
                  <el-radio :label="configs.status.inActive" size="lage" border>
                    {{ configs.status.inActiveStr }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Chuyển lớp / Bảo lưu">
                <el-dialog v-model="dialogVisible" title="Chọn lớp" width="30%" height="100%">
                  <batchSelect :is-multiple="false" style="padding-bottom: 20px;"></batchSelect>
                  <el-input v-model="textarea" style="padding-bottom: 20px;" :rows="2" type="textarea"
                    placeholder="Lý do" />
                  <el-radio-group v-model="radio1" class="ml-4" style="margin-left: 0px !important;">
                    <el-radio label="1" size="large">Giữ lại điểm</el-radio>
                    <el-radio label="2" size="large">Không giữ lại điểm</el-radio>
                  </el-radio-group>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button type="primary" @click="dialogVisible = false">Hủy</el-button>
                      <el-button type="primary" @click="openOne">
                        Đồng ý
                      </el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-button class="btn-rotate" text @click="dialogVisible = true">
                  <handle-button label="Chuyển lớp" class="py-1 btn btn-primary" width="100%" icon="fa fa-rotate"
                    typeButton="primary" />
                </el-button>
                <el-dialog v-model="dialogVisibleSeccond" title="Bảo lưu" width="30%">
                  <el-date-picker style="width: 100%;" v-model="value1" type="daterange" range-separator="Đến"
                    start-placeholder="Bắt đầu" end-placeholder="Kết thúc" />
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button type="primary" @click="dialogVisibleSeccond = false">Hủy</el-button>
                      <el-button type="primary" v-on:click="openSeccond">
                        Đồng ý
                      </el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-button class="btn-rotate" text @click="dialogVisibleSeccond = true">
                  <handle-button label="Bảo lưu" class="py-1 btn btn-primary" icon="fa fa-book" typeButton="primary" />
                </el-button>
              </el-form-item>
              <div class="dialog-footer d-flex justify-content-center">
                <handle-button label=" Cập Nhật" class="py-1 btn btn-primary" icon="fa fa-save" typeButton="primary"
                  event-code="students.update-student" @click="updateStudent" />
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Thông tin cơ bản" name="infoBasic">
        <infoStudent :form="studentInfoExtend"></infoStudent>
      </el-tab-pane>
      <el-tab-pane label="Lịch sử điểm danh" name="historyRollCall">
        <rollCallStudent />
      </el-tab-pane>
      <el-tab-pane label="Lịch sử điểm" name="historyPoint">
        <historyPointStudent />
      </el-tab-pane>
      <el-tab-pane label="Lịch sử chuyển lớp" name="historyClass">
        <historyClassStudent />
      </el-tab-pane><el-tab-pane label="Lịch sử bảo lưu" name="historyReserve">
        <historyReserve />
      </el-tab-pane>
      <el-tab-pane label="Điểm module" name="pointStudent">
        <pointStudent />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { textInputValidate, ValidService } from "@/validator";
import {
  notifyErr,
  isImage,
  notifyFiled,
  formatDataServe,
  notifySuccess, setBreadcrumb,
} from "@/mixins/utils";
import { UploadFile } from "@/services/common";
import { GetStudent, UpdateStudentInfo } from "@/services/students";
import infoStudent from "@/views/Students/infoStudent";
import rollCallStudent from "@/views/Students/rollCallStudent"
import historyPointStudent from "@/views/Students/historyPointStudent";
import historyClassStudent from "@/views/Students/historyClassStudent";
import historyReserve from "@/views/Students/historyReserve";
import pointStudent from "@/views/Students/pointStudent";
import { isReservedProp } from "@vue/shared";

export default {

  expose: ["validForm", "setImage"],
  name: "DetailStudent",
  components: {
    infoStudent,
    rollCallStudent,
    historyPointStudent,
    batchSelect,
    pointStudent
  },
  mixins: [setBreadcrumb],

  // Sử dụng id để cập nhật bảo lưu của học viên
  // Ví dụ: gọi API để lấy thông tin học viên với id này

  data() {
    return {
      validation: false,
      imageUrl: "",
      form: {},
      studentInfoExtend: { hometown: "", certificate: [], education: [], school: [], note: "" },
      addContentRule: {
        name: [textInputValidate],
        email: [textInputValidate],
        dob: [textInputValidate],
        username: [textInputValidate],
        avatar: [textInputValidate],
        gender: [textInputValidate, ValidService.checkGender],
        phone_number: [textInputValidate, ValidService.checkPhoneNumber],
        address: [textInputValidate],
      },
      setup: {
        activeName: 'infoDetail'
      },
      isDetail: true,
    };
  },
  created() {
    let $queryRoute = this.$route.query
    if (this.$route.query?.activeName) {
      this.setup.activeName = this.$route.query?.activeName
    } else {
      this.setup.activeName = 'infoDetail'
    }
    this.getStudent();
  },

  methods: {

    // get student
    async getStudent() {
      this.loading = true;
      GetStudent({ student_id: this.$route.params.id })
        .then((res) => {
          this.form = res.data.data
          if (res.data.data.student_info) {
            this.studentInfoExtend = {
              student_id: this.form.student_info.id,
              hometown: this.form.student_info.hometown,
              certificate: this.form.student_info.certificate?.split(";") || [],
              education: this.form.student_info.education.split(";") || [],
              school: this.form.student_info.school,
              note: this.form.student_info.note,
            }
          }
          this.setImage(this.form.full_avatar_url);
          this.loading = false;
        })
        .catch((err) => {
          notifyErr(err.response?.message);
          this.loading = false;
        });
    },
    // update Student
    async updateStudent() {
      event.preventDefault();
      let valid = await this.validForm();
      if (!valid) return notifyErr("Vui lòng điền đầy đủ thông tin.");
      let params = {
        student_id: this.$route.params.id,
        name: this.form.name,
        phone_number: this.form.phone_number,
        avatar: this.form.avatar,
        dob: formatDataServe(this.form.dob),
        gender: this.form.gender,
        is_active: this.form.is_active,
        address: this.form.address,
      };
      UpdateStudentInfo(params)
        .then((res) => {
          if (res.data.status == "success") {
            notifySuccess("edit");
          }
        })
        .catch((err) => {
          notifyErr(err.response.data.message);
        });
    },

    async validForm() {
      return await this.$refs.contentForm.validate((valid, fields) => {
        return !valid;
      });
    },
    handleRemove() {
      this.$refs.avatar.clearFiles();
      this.imageUrl = "";
    },
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled();
        return false;
      }
      this.imageUrl = URL.createObjectURL(uploadFile);
      this.file = await uploadFile;
      await this.uploadFile();
      return true;
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      let path = "";
      UploadFile(formData).then((e) => {
        if (e.data.status == "success") {
          this.form.avatar = e.data.data.filePath;
          path = e.data.data.filePath;
        }
        return path;
      });
    },
    setImage(url) {
      this.imageUrl = url;
    },
  },

};


</script>
<script setup>
import configs from "@/configs";
import { Delete, Plus, Refresh } from "@element-plus/icons-vue";
import batchSelect from "../Batches/batchSelect.vue";
import { ref } from 'vue'

import { ElMessage, ElMessageBox } from 'element-plus'

const textarea = ref('')

const radio1 = ref('1')

const openOne = () => {
  ElMessageBox.confirm(
    'Bạn có chắc chắn về việc chuyển lớp học viên?',
    'Chú ý',
    {
      confirmButtonText: 'Đồng ý',
      cancelButtonText: 'Hủy',
      type: 'warning',
    }
  )
    .then(() => {

      ElMessage({
        type: 'success',
        message: 'Đã chuyển lớp cho học viên thành công',
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: 'Đã hủy việc chuyển lớp cho học viên',
      })
    })
}

const openSeccond = () => {
  ElMessageBox.confirm(
    'Bạn có chắc chắn về việc bảo lưu học viên?',
    'Chú ý',
    {
      confirmButtonText: 'Đồng ý',
      cancelButtonText: 'Hủy',
      type: 'warning',
    }
  )

    .then(() => {
      // console.log("hhh");
      ElMessage({
        type: 'success',
        message: 'Bảo lưu hoàn thành',
      })

    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: 'Đã hủy việc bảo lưu',
      })
    })
}

const value1 = ref('')


const dialogVisible = ref(false)
const dialogVisibleSeccond = ref(false)


</script>
<style scoped lang="scss">
.avatar-uploader .avatar {
  max-width: 280px;
  max-height: 300px;
  min-height: 250px;
  min-width: 250px;
  display: block;
  position: relative;
}

.action-image {
  background: #000000b5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  pointer-events: none;
  display: none;

  svg {
    color: white;
  }

  .action-delete {
    font-size: 18px;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
}

::v-deep .el-upload:hover {
  .action-image {
    display: block;
  }
}

.hasErr {
  ::v-deep .tox {
    border: 1px solid red;
  }
}

.btn-rotate {
  padding: 0;
}
</style>

<template>
  <div id="addStudentsToBatch">
    <div class="bg-white p-3 ">
      <div class="mb-4">
        <el-form label-position="top" :model="checkinForm" ref="checkinFormRef">
          <el-row :gutter="20">
            <el-col :span="8">
              <batch-select :isMultiple="false" v-model="checkinForm.batchId"></batch-select>
            </el-col>
            <el-col :span="8" style="align-self: center" class="pt-4">
              <el-button type="primary" class="mt-2" @click="searchBatch"
              ><i class="fa fa-search"></i> Tìm lớp điểm danh
              </el-button>

            </el-col>

          </el-row>
        </el-form>
      </div>
      <div class="p-3" v-if="checkinForm.batchId">
        Sĩ số: {{ `${countCheckIn}/${tableData.length}` }}
      </div>
      <div>
        <div class="text-center pt-3"><h5>Danh sách học sinh</h5></div>
        <el-table
          class="px-3 pb-3"
          :data="tableData"
          style="width: 100%"
          :disabled="disableTable"
          ref="studentTable"
          fixed
          border
          @select="handleSelected"
          @selection-change="handleSelectAll"
          v-loading="loading"
        >
          <el-table-column fixed type="selection" width="55" :selectable="checkedIn"/>
          <el-table-column fixed prop="id" label="ID học sinh" width="150"/>
          <el-table-column prop="username" label="Tên đăng nhập"/>
          <el-table-column prop="name" label="Họ tên"/>
          <el-table-column label="Nghỉ có phép" width="250">
            <template #default="scope">
              <el-select v-model="scope.row.status" class="m-2" placeholder="Lựa chọn" size="small">
                <el-option
                  v-for="item in optionsLeave"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Trạng thái" width="200">
            <template #default="scope">
              <el-button
                type="success"
                v-if="scope.row.isCheckin"
                size="small"
              >Đã điểm danh
              </el-button>
              <el-button
                type="danger"
                v-if="!scope.row.isCheckin"
                size="small"
              >Chưa điểm danh
              </el-button>
            </template>
          </el-table-column>

        </el-table>
        <div class="pb-3 text-center ">
          <handle-button
            label="Điểm danh"
            eventCode="students.create-student"
            icon="fa fa-plus"
            typeButton="primary"
            v-if="tableData.length > 0 "
            @click="checkinAtBatch"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {StudentCheckInBatch, StudentInBatch,} from "@/services/batches";
import {formatDataServe, notifyErr, notifySuccess, notifyWarning,} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import "vue-select/src/scss/vue-select.scss";
import configs from "@/configs";
import batchSelect from "@/views/Batches/batchSelect";
import moment from "moment";
import selectStatus from "@/views/Batches/selectStatus.vue";

export default {
  name: "CheckInOnBatch",
  components: {
    batchSelect
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
      loading: false,
      selected: [],
      selectedCheckout: [],
      batches: [],
      checkinForm: {
        dateSelect: "",
        batchId: "",
      },
      dateDefault: new Date(),
      keyword: "",
      hasNextPage: false,
      messageErr: "",
      checked: [],
      dateNow: true,
      setup: {
        dialogVisible: false,
        tabRollCall: 1,
      },
      disableTable: true,
      countCheckIn: 0,
      config: configs,
      typeRollCall: '',
      listStudentLate: [],
      listStudentChecked: [],
      checkedOutStudent: [],
      optionsLeave: [
        {id: 1, name: "Đi học đủ"},
        {id: 9, name: "Nghỉ nửa ngày"},
        {id: 5, name: "Nghỉ cả ngày"},
      ],
    };
  },
  watch: {
    tableData: {
      handler(newTable) {
        let that = this;
        that.$nextTick(function () {
          that.toggleSelection();
        });
        this.checkedIn();
      },
      immediate: true,
    },
    "checkinForm.dateSelect": {
      handler(newTable) {
        this.tableData = [];
      },
      immediate: true,
    },
  },

  methods: {
    async checkinAtBatch(params = "") {
      if (!params) {
        if (this.selected.length < 1) return notifyWarning("Vui lòng chọn học viên.");
        let data = [];
        let error = false
        this.selected.map((e) => {
          let itemRollCall = this.tableData.find(item => item.id === e)
          if (!itemRollCall.status) return error = itemRollCall.username
          data.push({id: e, status: itemRollCall.status})
        });
        if (error) return notifyErr('Chưa chọn trạng thai đi học của học viên: ' + error)
        params = {
          students: data,
          batch_id: this.checkinForm.batchId,
          typeRollCall: this.setup.tabRollCall,
        };

      }
      StudentCheckInBatch(params).then((e) => {
        if (e.data.status === "success") {
          notifySuccess("add", "Điểm danh thành công.");
          this.searchBatch();
        }
      }).catch((err) => {
        if (!err.response) return false
        notifyErr(err.response.data.message);
      });
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex((id) => row.id === id);
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1);
      }
      if (indexItem < 0) {
        this.selected.push(row.id);
      }
    },
    handleSelectAll(val) {
      if (val) {
        val.map((e) => {
          if (this.selected.findIndex((id) => e.id == id) > -1) return;
          this.selected.push(e.id);
        });
      }
    },
    toggleSelection(table) {
      if (this.checked.length < 1) return false;
      this.checked.forEach((e) => {
        let item = this.tableData.find((item) => e == item.id);
        if (!item) return false;
        this.$refs.studentTable.toggleRowSelection(item);
      });
    },
    searchBatch() {
      let today = new Date();
      if (!this.checkinForm.batchId) return false;
      this.tableData = [];
      this.selected = [];
      this.loading = true;
      this.checked = [];
      StudentInBatch({
        batch_id: this.checkinForm.batchId,
        date_select: moment(today).format("YYYY-MM-DD"),
      })
        .then((e) => {
          let dataTable = [];
          if (e.data.status === "success") {
            let students = e.data.data.listStudentBatch;
            this.countCheckIn = 0;
            students.map((e) => {
              let item = e.students;
              let student = {
                id: item?.id,
                username: item?.username,
                email: item?.email,
                name: item?.name,
                phone: item?.phone_number,
                isCheckin: item?.roll_call_report.length > 0,
                hasCheckin: item?.roll_call_report.length > 0,
                offLearnId: item?.offReason?.id,
                acceptedStatus: item?.offReason?.accepted_status,
                offLearnRequest: item?.offReason?.type_off_request ? configs.offLearnRequest[item?.offReason?.type_off_request] : '',
                timeCheckIn: '',
                timeCheckOut: '',
                status: item?.roll_call_report.length > 0 ? item?.roll_call_report[0]?.roll_call_report_status[0]?.status_id : '',
                date: '',
              }
              this.countCheckIn = item?.roll_call_report.length > 0 ? this.countCheckIn + 1 : this.countCheckIn;
              if (item?.roll_call_report.length > 0) {
                this.checked.push(item?.id)
                this.selected.push(item?.id)
                student.timeCheckIn = item?.roll_call_report[0]?.time_check_in
                student.timeCheckOut = item?.roll_call_report[0]?.time_check_out
                student.date = item?.roll_call_report[0].day
              }
              dataTable.push(student);
            });
            if (this.checkinForm.dateSelect !== formatDataServe(new Date())) {
              this.dateNow = false;
              this.disableTable = true;
            } else {
              this.dateNow = true;
            }
            if (this.checked.length > 0) {
              this.toggleSelection();
            }
            this.tableData = dataTable;

          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err)
          this.loading = false;
        });
    },
    disabledDate(date) {
      let dateNew = new Date();
      return date.getTime() > dateNew.getTime();
    },
    checkedIn(row, index) {
      if (!row) return false;
      return true;
    },
  },
};

</script>
<script setup>
import {ref} from "vue";

const checkinFormRef = ref();
</script>
<style scoped lang="scss"></style>

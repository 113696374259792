import {NameRouter} from "@/constants/nameRouter";
import PermissionDenied from '@/views/ErrorPage/403'

export default [
    {
        path: "/" + NameRouter.ERROR.PERMISSION_DENIED,
        name: NameRouter.ERROR.PERMISSION_DENIED,
        component: PermissionDenied,
        meta: {
            isErrorPage: true,
            code: '403',
            alias: NameRouter.ERROR.PERMISSION_DENIED,
        }
    },
];
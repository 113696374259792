<template>
  <div class="search bg-white p-3 mb-4">
    <div class="row">
      <div class="col-4">
        <courseSelect v-model="searchParams.course_id" :is-multiple="false"></courseSelect>
      </div>

      <div class="col-3">
        <label for="search-student-name">Tìm kiếm chủ đề</label>
        <el-input type="text" id="search-student-name" v-model="searchParams.keyword"/>
      </div>
      <div class="col-3 pt-4" style="align-self: center">
        <div class="text-center">
          <el-button type="primary mr-2" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm</el-button>
          <el-button type="primary" @click="reload" :icon="Refresh">Làm mới</el-button>
        </div>
      </div>
    </div>
  </div>
  <div id="management-topic" class="bg-white p-2">
    <el-table :data="tableData" style="width: 100%" fixed v-loading.fullscreen.lock="loading" border>
      <el-table-column fixed prop="id" label="Id" min-width="50"/>
      <el-table-column prop="nameStudent" label="Họ tên" min-width="150"/>
      <el-table-column prop="courseName" label="Tên khóa học" min-width="150"/>
      <el-table-column prop="title" label="Tiêu đề" min-width="200"/>
      <el-table-column prop="createdAt" label="Ngày tạo" min-width="150"/>
      <el-table-column label="Hành động" min-width="120">
        <template #default="scope">
          <handle-button
            label="Chi tiết"
            eventCode="comments.index"
            icon="fa fa-plus"
            typeButton="primary"
            @click="detailTopic(scope.row)"
          />
          <handle-button
            label="Xóa"
            eventCode="topics.delete-topic"
            icon="fa fa-minus"
            typeButton="danger"
            @click="openConfirm(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
  <confirm
    v-model="setup.isConfirmed"
    @close="setup.isConfirmed = false"
    @deleteService="deleteTopic"
  ></confirm>
</template>
<script>
import confirm from "@/components/confirm";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {ListTopic,DeleteTopic} from "@/services/common";
import courseSelect from "@/views/Course/courseSelect";

export default {
  name: "ListTopic",
  mixins: [courseSelect,setBreadcrumb],
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogVisible: false,
      dialogAdd: false,
      nameRouter: NameRouter,
      roadmaps: [],
      assignmentId: this.$route.params.id,
      searchParams: {
        course_id: null,
        keyword: null,
      },
      setup: {
        isConfirmed: false,
        deleteId: "",
      },
    };
  },

  created() {
    let query = this.$router.currentRoute?.value?.query;
    let {course_id, keyword, per_page, page} = query;
    if (course_id|| keyword || per_page || page) {
      console.log(query)
      this.searchParams = query
      this.getListTopic(query)
    } else {
      this.getListTopic()
    }


  },
  methods: {
    getListTopic(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      ListTopic(params).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.listTopicAdmin.data.map(e => {
            dataTable.push({
              id: e.id,
              nameStudent: e.student ? e.student.name : '',
              username: e.student ? e.student.username : '',
              idStudent: e.student_id,
              courseName: e.course ? e.course.title : '',
              urlSource: e.link_source_code,
              createdAt: formatDate(e.created_at),
              title: e.title,
              student: e.student,
              content: e.content,
              course: e.course,
              commentCount: e.comment_count,
            })
          })
          this.tableData = dataTable;
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    reload() {
      this.searchParams={
        course_id: null,
        keyword: null,
      }
      this.$router.push({path: location.pathname, query: {}})
      this.getListTopic();
    },
    searchData() {
      let params = this.searchParams;
      params.per_page = configs.itemOnPage;
      params.page = 1;

      this.$router.push({path: location.pathname, query: params})
      this.getListTopic(params)
    },
    detailTopic(data) {
      this.$helpers.setContentTopic(data);
      this.$router.push({
        name: NameRouter.PAGES.TOPIC_DETAIL,
        params: {id: data.id},
      });
    },
    openConfirm(id) {
      this.setup.deleteId = id;
      this.setup.isConfirmed = true;
    },
    deleteTopic() {
      DeleteTopic(this.setup.deleteId)
        .then((e) => {
          if (e.data.status == "success") {
            this.getListTopic();
            this.setup.isConfirmed = false;
            notifySuccess("delete");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          notifyErr("error");
        });
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = this.searchParams;
      params.per_page = configs.itemOnPage;
      params.page = this.currentPage;
      this.getListTopic(params);
    },
  }
}
</script>
<script setup>
import {Refresh, Edit} from '@element-plus/icons-vue'
import Configs from "@/configs";
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

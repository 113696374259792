<template>
  <el-table style="width: 100%" :data="sections" border>
    <el-table-column prop="name" label="Tên"/>
    <el-table-column prop="sort_order" label="Vị trí">
      <template #default="scope">
        <div>
          <input type="text" class="form-control" v-model="scope.row.sort_order" :id="'input-'+ scope.row.id"
                 @change="changePosition(scope.row.id)">
          <div :id="'position-'+ scope.row.id" class="invalid-feedback"></div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Đang hoạt động" width="160">
      <template #default="scope">
        <div class="pl-4">
          <el-checkbox :checked="CONFIG_APP.status.active === scope.row.is_active" disabled/>
        </div>
      </template>
    </el-table-column>
    <el-table-column fixed="right">
      <template #default="scope">
        <div class="d-flex">
          <handle-button
              label=""
              eventCode="sections.show"
              icon="fa fa-check"
              typeButton="primary"
              @click="updateSection(scope.row.id)"/>
          <handle-button
              label=""
              eventCode="sections.destroy"
              icon="fa fa-trash"
              typeButton="danger"
              @click="deleteSection(scope.row.id)"/>
          <handle-button
              type="link"
              label=""
              eventCode="sections.show"
              icon="fa fa-eye"
              typeButton="primary"
              :to="{name: NameRouter.PAGES.DETAIL_SECTION, params:{id: scope.row.id}}"/>
        </div>

      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import {checkIsInteger, textInputValidate} from "@/validator";
import {ref} from "vue";
import Messages from "@/constants/messages";
import {notifyErr} from "@/mixins/utils";

export default {

  name: 'section-course',
  props: {
    section: Object,
    sections: Array,
    index: Number,
  },
  setup() {
    const materialTable = ref(null);
    return {materialTable};
  },
  data() {
    return {
      sectionRule: {
        name: [textInputValidate],
        position: [textInputValidate, checkIsInteger],
      },
      messageErr: '',
      isPass: true
    }
  },
  methods: {
    deleteSection(item) {
      this.$emit('deleteSection', item)
    },
    changePosition(id) {
      let message = ''
      let section = this.sections.find(item => id == item.id)
      let element = document.getElementById('position-' + id)
      if (!section) return notifyWarning('Không tồn tại chương này.')
      if (!section.sort_order) message = Messages.NULL
      if (section.sort_order) message = ''
      if (section.sort_order && isNaN(section.sort_order)) message = Messages.NUMBER
      if (section.sort_order && !isNaN(section.sort_order)) message = ''
      let elementInput = document.getElementById('input-' + id)
      element.innerText = message
      if (message !== '') {
        elementInput.classList.add('is-invalid')
      } else {
        elementInput.classList.remove('is-invalid')
      }
    },
    updateSection(id) {
      let section = this.sections.find(item => id == item.id);
      if (!section) return notifyWarning('Không tồn tại chương này.')
      if (!section.sort_order || isNaN(section.sort_order) || !section.name) return notifyErr('Nhập liệu lỗi.')
      this.$emit('updateSection', {id: id, section: section})
    }
  }
}
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {NameRouter} from "@/constants/nameRouter";
import {Delete, Plus, Check} from "@element-plus/icons-vue";
</script>
<style lang="scss">
.hasErr {
  ::v-deep .el-input {
    box-shadow: 0 0 0 1px red;
  }
}
</style>

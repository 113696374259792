<template>
  <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm" class="bg-white p-3">
    <el-row>
      <el-form-item class="w-100" prop="day" label="Chọn ngày">
        <el-date-picker
            class="w-100"
            v-model="form.day"
            type="daterange"
            start-placeholder="Ngày bắt đầu"
            end-placeholder="Ngày kết thúc"
        />
      </el-form-item>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item class="w-100" prop="event_name" label="Tên sự kiện">
          <el-input v-model="form.event_name" placeholder="Tên sự kiện"/>
        </el-form-item>
        <el-form-item class="w-100" prop="date_loop" label="Số lần lặp">
          <el-select class="w-100" v-model="form.date_loop" placeholder="Chọn số lần lặp">
            <el-option
                v-for="item in configs.dateLoop"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="w-100" prop="type_loop_date" label="Lặp theo (tháng / năm)"
                      v-if="form.date_loop == configs.dateLoop[1].value && form.day_or_date == configs.dayOrDate[0].value ">
          <el-select class="w-100" v-model="form.type_loop_date" placeholder="Chọn lặp theo (tháng / năm )">
            <el-option
                v-for="item in configs.typeLoopDate"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="w-100" prop="type_loop_date" label="Lặp theo (tháng / năm)"
                      v-if="form.date_loop == configs.dateLoop[1].value && form.day_or_date == configs.dayOrDate[1].value ">
          <el-select class="w-100" v-model="form.type_loop_date" placeholder="Chọn lặp theo (tháng / năm )">
            <el-option
                label="Lặp theo năm"
                value="2"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item class="w-100" prop="event_is_day_off" label="Kiểu ngày">
          <el-select class="w-100" v-model="form.event_is_day_off" placeholder="Chọn kiểu ngày">
            <el-option
                v-for="item in configs.isDayOff"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="w-100" prop="day_or_date" label="Lặp theo (thứ / ngày)"
                      v-if="form.date_loop == configs.dateLoop[1].value ">
          <el-select class="w-100" v-model="form.day_or_date" placeholder="Chọn lặp theo (thứ / ngày )">
            <el-option
                v-for="item in configs.dayOrDate"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import {textInputValidate} from "@/validator";
import configs from "@/configs";

export default {
  expose: ['validForm'],
  name: "InfoEvent",
  components: {},
  props: {
    isDetail: {type: Boolean, default: false},
    form: {},
  },
  data() {
    return {
      value1: "",
      validation: false,
      configs: configs,
      addContentRule: {
        day: [textInputValidate],
        event_name: [textInputValidate],
        date_loop: [textInputValidate],
        day_or_date: [textInputValidate],
        type_loop_date: [textInputValidate],
        event_is_day_off: [textInputValidate]
      },
    }
  },
  methods: {
    async validForm() {
      return await this.$refs.contentForm.validate((valid, fields) => {
        return !valid;
      })
    },
  }
}
</script>
<script setup>
import {Delete, Plus, Refresh} from '@element-plus/icons-vue'


</script>
import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {ROOM} from "@/constants/endPoints";

export const CreateRoom = (params) => HTTP_REQUEST_AUTH.post(ROOM.CREATE_ROOM, params);
export const GetListRoom = (params) => HTTP_REQUEST_AUTH.get(ROOM.GET_LIST_ROOM, {params: params});
export const DeleteRoom = (id) => HTTP_REQUEST_AUTH.delete(ROOM.DELETE_ROOM + "/" + id);
export const GetDetailRoom = (id) => HTTP_REQUEST_AUTH.get(ROOM.GET_DETAIL_ROOM+"/"+id);
export const UpdateRoom = (id, params) => HTTP_REQUEST_AUTH.put(ROOM.UPDATE_ROOM+"/"+id,params)



<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card" v-loading="loading">
    <el-tab-pane label="Thêm mới module" name="first">
      <infoSection ref="sectionForm" :form="form"></infoSection>
      <div class="text-center py-4">
        <el-button type="primary" :icon="Check" @click="updateSection">Cập nhật</el-button>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Thêm mới nội dung" name="content">
      <div class="text-end py-4">
        <el-button type="primary" :icon="Plus" @click="setup.contentDialog = true">Thêm nội dung</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          ref="modules"
          fixed>
        <el-table-column prop="name" label="Tên bài hoc học"/>
        <el-table-column label="Vị trí">
          <template #default="scope">
            <el-input v-model="scope.row.position" @change="validPosition(scope.row.id)"/>
            <span class="text-danger" :id="'position-'+ scope.row.id"></span>
          </template>
        </el-table-column>
        <el-table-column label="Hoạt động" width="100" prop="position">
          <template #default="scope">
            <el-button type="danger" size="small" :icon="Delete" @click="openConfirm(scope.row.id)">Xóa</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <el-dialog v-model="setup.contentDialog" @close="closeDialog">
    <template #title>
      <div class="border-bottom py-2"><b>Thêm nội dung</b></div>
    </template>
    <el-form label-position="top" :model="addContent" :rules="addContentRule" ref="contentForm">
      <el-form-item class="w-100" prop="id">
        <materialSelect v-model="addContent.id" :idOld="idOld" :is-multiple="false"
                        ref="materialSelectBox"></materialSelect>
      </el-form-item>
      <el-form-item label="Ví trí" prop="sort_order">
        <el-input v-model="addContent.sort_order"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">Đóng</el-button>
        <el-button type="primary" @click="contentNew">Lưu</el-button>
      </div>
    </template>
  </el-dialog>
  <confirm v-model="setup.confirmDialog" @close="closeDialog('confirmDialog')" @deleteService="removeContent"></confirm>
</template>
<script>
import infoSection from "@/views/ManagementSection/infoSection";
import {DetailSection, UpdateSection} from "@/services/course";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import messages from "@/constants/messages";
import confirm from "@/components/confirm";
import {checkIsInteger, textInputValidate} from "@/validator";
import materialSelect from "@/views/ManagementMaterial/materialSelect";

export default {
  name: 'CreatSection',
  components: {
    infoSection,
    confirm,
    materialSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      materials: [],
      form: {
        course_id: null,
        name: '',
        duration: '',
        is_active: 1,
        sort_order: '',
      },
      sectionsId: this.$route.params.id,
      tableData: [],
      idOld: [],
      addContent: {
        id: null,
        sort_order: ''
      },
      addContentRule: {
        id: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger],
      },
      setup: {
        contentDialog: false,
        confirmDialog: false,
        activeName: 'first',
      },
      loading: false
    }
  },
  created() {
    this.detailSection()
  },
  methods: {
    async updateSection() {
      let valid = await this.$refs.sectionForm.validForm()

      if (!valid) return false

      let content = [];
      let error = false
      this.tableData.map((item, index) => {
        if (!item.position || isNaN(item.position)) {
          this.setup.activeName = 'content'
          notifyErr('Có lỗi nhập liệu.')
          error = true
          return false
        }
        content.push({id: item.id, sort_order: item.position})
      })
      if (error) return false
      if (this.addContent.id || this.addContent.sort_order) {
        content.push(this.addContent)
      }
      this.form.materials = content

      UpdateSection(this.sectionsId, this.form).then(async res => {
        if (res.data.status === 'success') {
          notifySuccess('edit')
        }
      }).catch(err => {
        notifyErr()
      })
    },
    async detailSection() {
      this.loading = true
      DetailSection(this.sectionsId).then(async res => {
        if (res.data.status === 'success') {
          let item = res.data.data.section
          this.form = {
            course_id: item.course_id,
            name: item.name,
            code:item.code,
            sort_order: item.sort_order,
            is_active: item.is_active,
            titleCourse: item.course.title
          }
          let materials = []
          let materialOld = []
          item.section_material.map(e => {
            materialOld.push(e.material.id)
            materials.push({
              id: e.material.id,
              name: e.material.name,
              position: e.material.sort_order,
            })
            this.tableData = materials
            this.idOld = materialOld
          })
          this.loading = false
          this.$refs.sectionForm.queryCourseByName(item.course.title)
        }
      }).catch(err => {
        this.loading = false
        notifyErr()
      })
    },
    closeDialog(type) {
      if (type) return this.setup.confirmDialog = false
      this.setup.contentDialog = false
      this.$refs.materialSelectBox.resetData()
      this.addContent = {
        id: null,
        sort_order: '',
      }
    },
    validPosition(id) {
      let item = this.tableData.find(el => id == el.id)
      if (!item.position) {
        return document.getElementById('position-' + id).innerText = messages.REQUIRE
      }
      if (item.position && isNaN(item.position)) {
        document.getElementById('position-' + id).innerText = messages.NUMBER
      }
      if (item.position && !isNaN(item.position)) {
        document.getElementById('position-' + id).innerText = ''
      }
    },
    removeContent() {
      let index = this.tableData.findIndex(item => item.id === this.removeContentId)
      this.tableData.splice(index, 1)
      this.updateSection()
      this.setup.confirmDialog = false
    },
    async contentNew() {
      let valid = await this.$refs.contentForm.validate((valid, fields) => {
        if (!valid || !this.addContent.id) {
          return false;
        }
      })
      if (!valid) return false;
      await this.updateSection()
      this.closeDialog()
    },
    openConfirm(id) {
      this.setup.confirmDialog = true
      this.removeContentId = id
    },
  }

}
</script>
<script setup>
import {Check} from "@element-plus/icons-vue";
</script>

<style scoped lang="scss">
::v-deep .is-error {
  .vs__dropdown-toggle {
    border-color: red;
  }
}
</style>

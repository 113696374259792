import {createRouter, createWebHistory} from "vue-router";
import {getPermission, getRole, getTokenCookies, logout, roleAdmin} from "@/plugin/helper";
import Auth from "./routers/auth";
import Home from "./routers/home";
import {NameRouter} from "@/constants/nameRouter";

const routes = [
    ...Auth,
    ...Home,
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    let role = {}, permission = []
    document.title = 'LEARNING MANAGEMENT SYSTEM';
    if (getPermission()) {
        role = getRole()
        permission = getPermission()
    }
    if (!role) {
        logout()
    }
    let passLogin = [NameRouter.AUTH.LOGIN, NameRouter.AUTH.SEND_MAIL, NameRouter.AUTH.RESET_PASSWORD, NameRouter.ERROR.PERMISSION_DENIED]
    if (!getTokenCookies() && !passLogin.find(el => to.name == el)) {
        return next({name: NameRouter.AUTH.LOGIN})
    } else {
        if (!permission && !passLogin.find(el => to.name == el)) {
            return next({name: NameRouter.AUTH.LOGIN});
        }
        if (role.is_admin === roleAdmin) {
            return next();
        }
        if (permission && !permission.find(el => el.permission.route_name == to.meta.code) && to.path != '/' && !passLogin.find(el => to.name == el)) {
            return next({name: NameRouter.ERROR.PERMISSION_DENIED});
        }
        next();
    }
});


export default router;

import { HTTP_REQUEST_AUTH } from "./requestAxios";
import { STUDENTS } from "@/constants/endPoints";

export const GetListStudents = (params) =>
  HTTP_REQUEST_AUTH.get(STUDENTS.GET_LIST_STUDENT, { params: params });

export const GetStudent = (params) =>
  HTTP_REQUEST_AUTH.post(STUDENTS.GET_STUDENT, params);

export const CreatedStudent = (params) =>
  HTTP_REQUEST_AUTH.post(STUDENTS.CREATE_STUDENT, params);

export const ImportStudent = (params) =>
  HTTP_REQUEST_AUTH.post(STUDENTS.IMPORT_STUDENT, params);

export const GetInfoAllStudents = (params) =>
  HTTP_REQUEST_AUTH.get(STUDENTS.INFO_STUDENT, { params: params });

export const UpdateStudents = (params, id) =>
  HTTP_REQUEST_AUTH.post(STUDENTS.UPDATE_STUDENT_STATUS + "/" + id, params);

export const UpdateStudentInfo = (params) =>
  HTTP_REQUEST_AUTH.post(STUDENTS.UPDATE_STUDENT_INFO, params);

export const CreateStudentInfo = (params) =>
  HTTP_REQUEST_AUTH.post(STUDENTS.CREATE_STUDENT_INFO, params);

export const UpdateStudentInfoExtend = (params, id) =>
  HTTP_REQUEST_AUTH.put(STUDENTS.UPDATE_STUDENT_INFO_EXTEND + "/" + id, params);
export const resetPasswordStudent = (payload) => HTTP_REQUEST_AUTH.post(STUDENTS.RESET_PASSWORD, payload);

<template>
  <div class="w-100" :class="{'hasErr': validation}">
    <label style="color: #606266" v-if="showLabel"> <span class="text-danger">*</span> Lớp học</label>
    <vSelect
        v-model="batchId"
        placeholder="Lựa chọn lớp học"
        :disabled="disableSelect"
        :options="batchList"
        label="batch_name"
        :class="{'is-invalid': validation}"
        :reduce="(question) => question.id"
        class="w-100"
        :multiple="isMultiple"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"

    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import CONFIG_APP from "@/configs";
import configs from "@/configs";
import {GetListBatches} from "@/services/batches";
import {formatDate, notifyErr} from "@/mixins/utils";

export default {
  expose: ['resetData', 'queryByName', 'searchByRoadmap', 'batchCurrent'],
  name: "Question-select",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn lớp học.'},
    validation: Boolean,
    modelValue: Array,
    idOld: {type: Array, default: null},
    showLabel: {type: Boolean, default: true},
    isMultiple: {type: Boolean, default: true},
    addOption: {type: Boolean, default: false}
  },
  data() {
    return {
      batchId: [],
      hasNextPage: false,
      disableSelect: false,
      batchList: [],
      page: 1,
      roadmap_id: null
    }
  },
  computed: {
    per_page: () => {
      return 900
    }
  },
  watch: {
    batchId() {
      this.$emit('selected', this.batchId)
      this.$emit('update:modelValue', this.batchId)
    },
    batchList() {
      this.batchId = this.modelValue
    }
  },
  created() {
    this.getBatches()
  },

  methods: {
    getBatches(params) {
      this.loading = true
      if (!params) {
        params = {per_page: 100, page: this.crrentPage, is_active: 1}
      }
      let batchList = []
      GetListBatches(params).then(e => {
        if (e.data.status == 'success') {
          batchList = e.data.data.allBatch.data;
          if (this.addOption) {
            batchList.unshift({id: null, batch_name: 'Chọn tất cả'})
            batchList.push({id: 0, batch_name: 'Chưa xếp lớp'})
          }
          this.batchList = batchList;
        }
        this.disableSelect = false;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      let params = {per_page: this.per_page, page: 1, keyword: query, roadmap_id: this.roadmap_id}
      this.getBatches(params)
    },
    resetData() {
      this.batchId = null
    },
    queryByName(name) {
      this.queryKeyword(name)
    },

    searchByRoadmap(idRoadmap) {
      this.disableSelect = true
      this.roadmap_id = idRoadmap
      this.hasNextPage = true
      let params = {per_page: this.per_page, page: 1, roadmap_id: idRoadmap}
      this.getBatches(params)
    },
    batchCurrent() {
      let batchCurrent = {}
      if (!this.batchId) return batchCurrent
      return batchCurrent = this.batchList.find(item => item.id === this.batchId)
    }
  },

}
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}
</style>

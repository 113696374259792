<template>
  <div id="managementFile" class="bg-white p-2">
    <div class="box-search">
      <div class="">
        <i>Tìm kiếm theo mục đích</i>
      </div>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="setup.keyword" placeholder="Tìm kiếm tên bài học"/>
        </el-col>
        <el-col :span="6">
          <el-input v-model="setup.code" placeholder="Tìm kiếm mã code"/>
        </el-col>
        <el-col :span="6">
          <roadmap-select v-model="setup.roadmap_id" ref="roadmapSelectBox"/>
        </el-col>
        <el-col :span="6" v-show="isShowModule">
          <select-module :show-label="false" ref="moduleSelectBox" v-model="setup.module_id"/>
        </el-col>
        <el-col :span="6">
          <course-select :show-label="false" ref="courseSelectBox" v-model="setup.course_id"/>
        </el-col>
        <el-col :span="5">
          <handle-button event-code="sections.index" @click="searchData" typeButton="primary" label="Tìm kiếm"
                         icon="fa fa-search"/>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border>
      <el-table-column fixed prop="id" label="Id" min-width="50"/>
      <el-table-column fixed prop="title" label="Tên Chương" min-width="150"/>
      <el-table-column fixed prop="code" label="Mã Code" min-width="150"/>
      <el-table-column fixed prop="courseName" label="Tên khóa học" min-width="150"/>
      <el-table-column fixed prop="moduleName" label="Tên module" min-width="150"/>
      <el-table-column prop="isActive" label="Đang hoạt động" width="150">
        <template #default="scope">
          <div class="pl-3">
            <el-checkbox :checked="scope.row.isActive === CONFIG_APP.status.active" disabled></el-checkbox>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="Thời gian tạo" min-width="100"/>
      <el-table-column label="Hành động" min-width="120">
        <template #default="scope">
          <handle-button
              type="link"
              label="Xem nội dung"
              eventCode="sections.show"
              icon="fa fa-eye"
              typeButton="primary"
              :to="{name: nameRouter.PAGES.DETAIL_SECTION, params: {id: scope.row.id}}"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListSection} from "@/services/course";
import roadmapSelect from "@/components/roadmapSelect";
import selectModule from "@/views/ManagementModule/selectModule";
import courseSelect from "@/views/Course/courseSelect";

export default {
  name: "ManagementFile",
  mixins: [setBreadcrumb],
  components: {
    pagination,
    roadmapSelect,
    selectModule,
    courseSelect
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogFormVisible: false,
      nameRouter: NameRouter,
      form: {
        url: '',
        name: '',
        type: '',
      },
      setup: {
        keyword: null,
        roadmap_id: null,
        module_id: null,
        course_id: null
      },
      isShowModule: false
    };
  },
  created() {
    this.getListSection()
  },
  watch: {
    'setup.roadmap_id'() {
      this.setup.module_id = null
      this.setup.course_id = null
      let roadmap = this.$refs.roadmapSelectBox.getRoadmapById(this.setup.roadmap_id)
      let hasModule = 1
      this.isShowModule = false
      if (roadmap?.type === hasModule) {
        this.isShowModule = true
        this.$refs.moduleSelectBox.searchByRoadmap(this.setup.roadmap_id)
      }
      this.$refs.courseSelectBox.search({roadmap_id: this.setup.roadmap_id})
    },
    'setup.module_id'() {
      this.setup.course_id = null
      this.$refs.courseSelectBox.search({module_id: this.setup.module_id})
    }
  },
  methods: {
    getListSection() {
      this.loading = true
      let params = {per_page: configs.itemOnPage, page: this.currentPage, keyword: this.setup.keyword, course_id: this.setup.course_id}
      GetListSection(params).then(e => {
        if (e.data.status === 'success') {
          let dataTable = [];
          e.data.data.sections.data.map(e => {
            dataTable.push({
              id: e.id,
              code: e.code,
              title: e.name,
              position: e.sort_order,
              type: e.type,
              course: e.course_id,
              courseName: e.course.title,
              isActive: e.is_active,
              createdAt: e.created_at ? formatDate(e.created_at) : '',
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.sections.total
          this.isShowPagination = e.data.data.sections.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getListSection(index);
    },
    searchData() {
      this.currentPage = 1
      this.getListSection();
    },
  }

}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {View, Search} from '@element-plus/icons-vue'
</script>

<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

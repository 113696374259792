<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card" v-loading.fullscreen.lock="loading">
    <el-tab-pane label="Thêm mới lộ trình" name="first">
      <infoRoadmap ref="roadmap" :infoRoadmap="formRoadmap" @resetContent="resetContent"></infoRoadmap>
      <div class="text-center py-4">
        <el-button type="success" size="small" :icon="Check" @click="updateRoadmap">Cập nhật</el-button>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Nội dung" name="content">
      <div class="text-end py-4">
        <el-button type="primary" :icon="Plus" @click="setup.contentRoadmap = true">Thêm nội dung</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          border
          ref="modules"
          fixed>
        <el-table-column
            label="Hình ảnh"
            width="250"
            >
          <template #default="scope">
            <el-image :src="scope.row.avatar">
              <div slot="placeholder" class="image-slot">
                Loading<span class="dot">...</span>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" :label="formRoadmap.type == notModule? 'Tên Khóa học': 'Tên module'" />
        <el-table-column prop="code" label="Mã code" width="180" />
        <el-table-column label="Vị trí" width="250">
          <template #default="scope">
            <el-input v-model="scope.row.position" @change="validPosition(scope.row.id)" />
            <span class="text-danger" :id="'position-'+ scope.row.id"></span>
          </template>
        </el-table-column>
        <el-table-column label="Hành động" width="130">
          <template #default="scope">
            <handle-button
                label="Loại bỏ"
                eventCode="roadmaps.delete"
                icon="fa fa-trash"
                typeButton="danger"
                @click="openConfirm(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <el-dialog v-model="setup.contentRoadmap" @close="closeDialog">
    <template #title>
      <div class="border-bottom py-2"><b>Thêm nội dung</b></div>
    </template>
    <el-form label-position="top" :model="addContent" :rules="addContentRule" ref="contentForm">
      <el-form-item class="w-100" prop="id">
        <selectModule v-model="addContent.id" :idOld="idOld" ref="moduleSelect"
                      v-if="formRoadmap.type == hasModule"></selectModule>
        <courseSelect v-model="addContent.id" :idOld="idOld" ref="courseSelectBox"
                      v-if="formRoadmap.type == notModule"></courseSelect>
      </el-form-item>
      <el-form-item label="Ví trí" prop="sort_order">
        <el-input v-model="addContent.sort_order"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">Đóng</el-button>
        <handle-button
            label="Lưu"
            eventCode="roadmaps.update"
            icon="fa fa-check"
            typeButton="primary"
            @click="contentNew"
        />
      </div>
    </template>
  </el-dialog>
  <confirm v-model="setup.confirmDialog" @close="closeDialog('confirmDialog')" @deleteService="removeContent"></confirm>
</template>

<script>
import infoRoadmap from "@/views/ManagementRoadmap/infoRoadmap";
import {GetRoadmapDetail, UpdateRoadmap} from "@/services/roadmap"
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import selectModule from "@/views/ManagementModule/selectModule";
import courseSelect from "@/views/Course/courseSelect";
import confirm from "@/components/confirm";
import {checkIsInteger, textInputValidate} from "@/validator";
import messages from "@/constants/messages";

export default {
  name: "roadmapDetail",
  components: {
    infoRoadmap,
    confirm,
    selectModule,
    courseSelect
  },
  data() {
    return {

      hasModule: 1,
      notModule: 2,
      formRoadmap: {
        title: '',
        code:'',
        duration: '',
        sort_order: '',
        version: '',
        description: '',
        image: '',
        is_active: 1,
        type: 1
      },
      addContent: {
        id: null,
        sort_order: ''
      },
      addContentRule: {
        id: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger]
      },
      tableData: [],
      currentRoadmap: '',
      loading: true,
      idOld: [],

      removeContentId: '',
      setup: {
        activeName: 'first',
        confirmDialog: false,
        contentRoadmap: false,
      }
    }
  },
  created() {
    this.getRoadmap()
  },
  mixins: [setBreadcrumb],
  methods: {
    updateRoadmap() {
      let vailForm = this.$refs.roadmap.validRoadmap()
      if (!vailForm) return false
      let valid = this.tableData.length > 0

      if (!valid) {
        this.setup.activeName = 'content'
        return false
      }
      let content = [];
      let error = false
      this.tableData.map((item, index) => {
        if (!item.position || isNaN(item.position)) {
          this.activeName = 'content'
          notifyErr('Có lỗi nhập liệu.')
          error = true
          return false
        }
        content.push({id: item.id, sort_order: item.position, is_new: true})
      })
      if (error) return false
      if (this.addContent.id || this.addContent.sort_order) {
        content.push(this.addContent)
      }
      if (this.formRoadmap.type === this.hasModule) {
        this.formRoadmap.modules = content
      }
      if (this.formRoadmap.type === this.notModule) {
        this.formRoadmap.courses = content
      }
      event.preventDefault()

      UpdateRoadmap(this.currentRoadmap, this.formRoadmap).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
          this.getRoadmap()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    getRoadmap() {
      this.loading = true
      this.currentRoadmap = this.$route.params.id
      GetRoadmapDetail(this.currentRoadmap).then(res => {
        this.loading = false
        if (res.data.status == 'success') {
          let roadmap = res.data.data.roadmap
          this.formRoadmap = {
            title: roadmap.title,
            code:roadmap.code,
            version: roadmap.version,
            duration: roadmap.duration,
            is_active: roadmap.is_active,
            sort_order: roadmap.sort_order,
            description: roadmap.description,
            type: roadmap.type,
            image: roadmap.image,
          }
          let dataTable = []
          let idOld = []
          if (roadmap.type == this.hasModule) {
            roadmap.roadmap_module.map(el => {
              let e = el.module
              idOld.push(e.id)
              dataTable.push({
                id: e.id,
                title: e.title,
                avatar: e.full_image_url,
                duration: e.duration && e.duration > 0 ? (e.duration / 60) : 0,
                position: el.sort_order
              })
            })
          }
          if (roadmap.type == this.notModule) {
            roadmap.roadmap_course.map(el => {
              let e = el.course
              idOld.push(e.id)
              dataTable.push({
                id: e.id,
                title: e.title,
                avatar: e.full_course_image_url,
                duration: e.duration && e.duration > 0 ? (e.duration / 60) : 0,
                summary: e.description,
                level: e.level,
                position: el.sort_order
              })
            })
          }
          this.idOld = idOld
          this.tableData = dataTable
          let url = roadmap.full_image_url
          this.$refs.roadmap.setImage(url)
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })

    },
    resetContent() {
      if (this.formRoadmap.type == this.hasModule) {
        this.moduleId = []
      }
      if (this.formRoadmap.type == this.notModule) {
        this.courseId = []
      }
    },
    async contentNew() {
      let valid = this.$refs.contentForm.validate((valid, fields) => {
        if (!valid || !this.addContent.id) {
          return false;
        }
      })
      if (!valid) return false;
      this.updateRoadmap()
      this.closeDialog()
    },
    closeDialog(type) {
      if (type) return this.setup.confirmDialog = false

      this.setup.contentRoadmap = false
      if (this.formRoadmap.type == this.hasModule) {
        this.$refs.moduleSelect.resetData()
      }
      if (this.formRoadmap.type == this.notModule) {
        this.$refs.courseSelectBox.resetData()
      }
      this.addContent = {
        id: null,
        sort_order: '',
      }
    },
    openConfirm(id) {
      this.setup.confirmDialog = true
      this.removeContentId = id
    },
    removeContent() {
      let index = this.tableData.findIndex(item => item.id === this.removeContentId)
      this.tableData.splice(index, 1)
      this.updateRoadmap()
      this.setup.confirmDialog = false
    },
    validPosition(id) {
      let item = this.tableData.find(el => id == el.id)
      if (!item.position) {
        return document.getElementById('position-' + id).innerText = messages.REQUIRE
      }
      if (item.position && isNaN(item.position)) {
        document.getElementById('position-' + id).innerText = messages.NUMBER
      }
      if (item.position && !isNaN(item.position)) {
        document.getElementById('position-' + id).innerText = ''
      }
    }
  }
}
</script>
<script setup>
import {
  Check,
  Plus,
  Delete,
} from '@element-plus/icons-vue'
</script>
<style scoped lang="scss">
::v-deep .is-error {
  .vs__dropdown-toggle {
    border-color: red;
  }
}
</style>
<template>
  <div>
    <el-tabs v-model="setup.activeName" class="p-3" type="border-card">
      <el-tab-pane label="Biểu đồ lớp" name="chart">
        <div class=" py-2 ">
          <div class="box-search">
            <el-row :gutter="10">
              <el-col :span="8">
                <batch-select :show-label="false" :is-multiple="false" v-model="search.batch_id"
                              ref="batchSelectBox"></batch-select>
              </el-col>
              <el-col :span="8">
                <div class="d-flex justify-content-between">
                  <el-date-picker
                      :disabled="!this.batchCurrent || setup.cycle"
                      v-model="search.start_date"
                      class="w-50 mx-1"
                      type="date"
                      :disabled-date="(time) => disabledEndDate(time, search.end_date)"
                      placeholder="Ngày bắt đầu"
                      format="DD/MM/YYYY"
                      value-format="YYYY-MM-DD"
                  />
                  <el-date-picker
                      :disabled="!this.batchCurrent || setup.cycle"
                      v-model="search.end_date"
                      :disabled-date="(time) => disabledStartDate(time, search.start_date)"
                      class="w-50 mx-1"
                      type="date"
                      placeholder="Ngày kết thúc"
                      format="DD/MM/YYYY"
                      value-format="YYYY-MM-DD"
                  />
                </div>
              </el-col>
              <el-col :span="4" class="">
                <el-select
                    placeholder="Chọn theo kỳ"
                    v-model="setup.cycle"
                    :disabled="!this.batchCurrent || this.search.start_date || this.search.end_date">
                  <el-option :value="null" label="Chọn theo kỳ"/>
                  <el-option value="today" label="Hôm nay"/>
                  <el-option value="yesterday" label="Hôm qua"/>
                  <el-option value="week" label="Trong tuần"/>
                  <el-option value="month" label="Trong tháng"/>
                  <el-option value="monthPre" label="Tháng trước"/>
                  <el-option value="all" label="Cả khóa"/>
                </el-select>
              </el-col>
              <el-col :span="4" class="">
                <div class="d-flex">
                  <button class="mx-3 py-1 btn btn-primary" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm
                  </button>
                  <button class="btn btn-warning mx-3 py-1" @click="reload"> Làm mới</button>
                </div>
              </el-col>
            </el-row>
          </div>

          <el-table :data="tableData" border v-if="tableData.length > 0" class="my-3">
            <el-table-column
                v-for="item in labelTable"
                :label="item.label"
                :sortable="item.sortable"
                :prop="item.prop"
                :key="item.label"/>
          </el-table>
          <el-empty v-if="tableData.length <= 0" style="height: 70vh"/>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>
<script>

import {HistoriesHomeworks, RollCallMonth, RollCallStatus} from "@/services/reportMaterial";
import batchSelect from "@/views/Batches/batchSelect";
import {formatDataServe, getToday, notifyErr} from "@/mixins/utils";
import moment from "moment";
import configs from "@/configs";

export default {
  name: 'historiesHomework',
  components: {
    batchSelect,
  },

  created() {
    this.getStatus()
  },
  watch: {
    'search.batch_id'() {
      this.batchCurrent = this.$refs.batchSelectBox.batchCurrent()
      this.search.end_date = null
      this.search.start_date = null
      this.setup.cycle = null
    }
  },
  data() {
    return {
      studentRollCalls: [],
      loading: false,
      search: {
        batch_id: null,
        end_date: null,
        start_date: null
      },
      batchCurrent: null,
      status: [],
      lineRef: null,
      lineConfig: {
        data: [],
        height: 600,
        width: 50,
        autoFit: true,
        xField: "year",
        yField: "value",
        smooth: true,
        meta: {
          value: {
            min: 0,
          }
        }
      },
      setup: {
        cycle: null,
        activeName: 'chart'
      },
      tableData: [],
      labelTable: []
    }
  },
  methods: {
    getHistories(params) {
      for (let key in this.search) {
        if (!this.search.batch_id) {
          return notifyErr('Chưa đủ điều kiện thỏa mãn.')
        }
      }
      if (!params) params = this.search;

      HistoriesHomeworks(params).then(res => {
        if (res.data.status === 'success') {
          let countByStatus = res.data.data.countStatusTotalClass
          let chartData = [], xField = this.lineConfig.xField, yField = this.lineConfig.yField
          for (let item in countByStatus) {
            let chart = {}
            chart[xField] = configs.labelStatus[item]
            chart[yField] = countByStatus[item]
            chartData.push(chart)
          }
          let countEachStudent = res.data.data.countStatusEachStudent
          let tableData = [];
          let labelTable = [
            {label: 'Id', prop: 'student_id', sortable: false},
            {label: 'Tên học sinh', prop: 'student_name', sortable: false}
          ];
          countEachStudent.map(item => {
            let student = {}
            student.student_name = item.name
            student.student_id = item.id
            let totalNotDo = parseInt(item.not_do_homework)
            let totalDo = parseInt(item.did_homework)
            student.countLearned = res.data?.data?.countLearned
            student.dayOffPersent = student.countLearned ? ((totalNotDo / student.countLearned) * 100).toFixed() + '%' : 0;
            student.OkDayPersent = student.countLearned ? ((totalDo / student.countLearned) * 100).toFixed() + '%' : 0;
            for (let key in configs.labelHomework) {
              if (item[key] === null || item[key] === undefined) continue
              student[key] = item[key]
            }
            tableData.push(student)
          })
          let label = {}
          for (let key in configs.labelHomework) {
            label = {label: configs.labelHomework[key], prop: key, sortable: true}
            labelTable.push(label)
          }
          labelTable.push({label: 'Tổng ngày học', prop: 'countLearned', sortable: false})
          labelTable.push({label: 'Không làm bài / ngày học', prop: 'dayOffPersent', sortable: false})
          labelTable.push({label: 'Làm bài/ ngày học', prop: 'OkDayPersent', sortable: false})
          this.tableData = tableData
          this.labelTable = labelTable
          this.lineConfig.data = chartData
          if (!this.setup.cycle) {
            let startDate = moment(this.batchCurrent.start_date)
            let endDate = moment(this.search.end_date)
            this.lineConfig.meta.value.max = endDate.diff(startDate, 'd')
          }
        }
      }).catch(err => {
        console.log(err)
        notifyErr(err.response.data.message)
      })
    },
    searchData() {
      if (!this.setup.cycle) return this.getHistories()
      this.selectCycle()
    },
    reload() {
    },
    disabledStartDate(date, departureDate) {
      if (this.batchCurrent && !departureDate) {
        let startBatch = new Date(this.batchCurrent.start_date);
        return date.getTime() < startBatch.getTime()
      }
      if (departureDate && !this.batchCurrent) {
        let dateNew = new Date(departureDate);
        return date.getTime() < dateNew.getTime()
      }
      if (this.batchCurrent && departureDate) {
        let endBatch = new Date(this.batchCurrent.end_date);
        let dateNew = new Date(departureDate);
        return date.getTime() >= endBatch.getTime() || date.getTime() < dateNew.getTime()
      }
    },
    disabledEndDate(date, departureDate) {
      if (this.batchCurrent && !departureDate) {
        let startBatch = new Date(this.batchCurrent.start_date);
        return date.getTime() < startBatch.getTime()
      }
      if (departureDate && !this.batchCurrent) {
        let dateNew = new Date(departureDate);
        return date.getTime() > dateNew.getTime()
      }
      if (this.batchCurrent && departureDate) {
        let startBatch = new Date(this.batchCurrent.start_date);
        let dateNew = new Date(departureDate);
        return date.getTime() <= startBatch.getTime() || date.getTime() > dateNew.getTime()
      }
    },
    getStatus() {
      RollCallStatus().then(res => {
        if (res.data.status === 'success') {
          this.status = res.data.data.rollCallMasterStatus;
        }
      }).catch()
    },
    selectCycle() {
      let {start_date, end_date, id} = this.batchCurrent
      let param = {batch_id: id}
      if (this.setup.cycle === 'today') {
        start_date = getToday()
        end_date = getToday()
      }
      if (this.setup.cycle === 'yesterday') {
        start_date = moment().subtract(1, 'day')
        end_date = moment().subtract(1, 'day')
      }
      if (this.setup.cycle === 'week') {
        start_date = moment().clone().startOf('isoWeek');
        end_date = moment().clone().endOf('isoWeek');
      }
      if (this.setup.cycle === 'month') {
        start_date = moment().startOf('month');
        end_date = moment().endOf('month');
      }
      if (this.setup.cycle === 'monthPre') {
        start_date = moment().subtract(1, 'months').startOf('month');
        end_date = moment().subtract(1, 'months').endOf('month');
      }

      param.end_date = formatDataServe(end_date);
      param.start_date = formatDataServe(start_date)
      let startDate = moment(this.batchCurrent.start_date)
      let endDate = moment(end_date)
      this.lineConfig.meta.value.max = endDate.diff(startDate, 'd')
      this.getHistories(param)
    }
  }
}
</script>
<style>

canvas {
  height: 60vh !important;
}

.fs {
  font-size: 20px;
}
</style>

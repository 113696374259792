import { NameRouter } from "@/constants/nameRouter";

export default [
  {
    path: "/" + NameRouter.AUTH.LOGIN,
    name: NameRouter.AUTH.LOGIN,
    component: () => import("@/views/Auth/login.vue"),
  },
  {
    path: "/" + NameRouter.AUTH.SEND_MAIL,
    name: NameRouter.AUTH.SEND_MAIL,
    meta: { authorize: [] },
    component: () => import("@/views/Auth/sendMail"),
  },
  {
    path: "/" + NameRouter.AUTH.REGISTER,
    name: NameRouter.AUTH.REGISTER,
    meta: { authorize: [] },
    component: () => import("@/views/Auth/register.vue"),
  },
  {
    path: "/" + NameRouter.AUTH.RESET_PASSWORD,
    name: NameRouter.AUTH.RESET_PASSWORD,
    meta: { authorize: [] },
    component: () => import("@/views/Auth/resetPassword"),
  },
];

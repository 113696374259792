<template>
    <div class="search bg-white p-3 mb-4">
        <div class="row">
            <div class="py-2 text-end">
                <el-row :gutter="20" :align="10">
                    <el-col :span="6" class="pb-3">
                        <el-input v-model="searchParams.keyword" placeholder="Tìm kiếm tên bài kiểm tra" />
                    </el-col>
                    <el-col :span="6" class="pb-3">
                        <el-input v-model="searchParams.student_name" placeholder="Tìm kiếm tên học sinh " />
                    </el-col>
                    <el-col :span="6" class="pb-3">
                        <batchSelect v-model="searchParams.batch_id" :show-label="false" :is-multiple="false"></batchSelect>
                    </el-col>
                    <el-col :span="6" class="pb-3">
                        <courseSelect v-model="searchParams.course_id" :showLabel="false" />
                    </el-col>
                    <el-col :span="6" class="pb-3">
                        <el-select placeholder="Tìm kiếm trạng thái" class="w-100" v-model="searchParams.is_hackathon">
                            <el-option label="Tất cả" :value="null"></el-option>
                            <el-option label="Bài kiểm tra Hackathon" :value="0"></el-option>
                            <el-option label="Bài kiểm tra cuối module" :value="1"></el-option>
                        </el-select>
                    </el-col>
                    <el-button type="primary mr-2" @click="searchData" :icon="Search">Tìm kiếm</el-button>
                    <el-button type="primary" @click="reload()" :icon="Refresh">Làm mới</el-button>
                </el-row>
            </div>
        </div>
    </div>
    <div id="managementStudent" class="bg-white p-3">
        <div class="py-2 text-end">
        </div>
        <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border>
            <!-- <el-table-column prop="index" label="TT " width="50" /> -->
            <el-table-column prop="student_id" label="ID " width="50" />
            <el-table-column prop="student_name" label="Họ tên" min-width="170" />
            <el-table-column prop="batch_name" label="Tên lớp" min-width="170" />
            <el-table-column prop="course_name" label="Tên Module" min-width="170" />
            <el-table-column prop="created_at" label="Ngày tạo" min-width="150" />
            <el-table-column prop="point" label="Điểm" min-width="100" />
            <el-table-column prop="title" label="Tên bài thi" min-width="170" />
            <el-table-column prop="is_test_hackathon_title" label="Bài thi" min-width="170" />
            <el-table-column prop="reviewer_name" label="Người nhận xét" min-width="150" />
            <el-table-column prop="content_review" label="Nội dung nhận xét" min-width="200" />
            <el-table-column fixed="right" width="370">
                <template #default="scope">
                    <handle-button label="Sửa điểm học sinh" type-button="primary" icon="fa fa-wrench"
                        @click="pointStudent(scope.row)" />
                    <handle-button label="Xóa" type-button="danger" icon="fa fa-trash" text
                        @click="deletePointStd(scope.row.id)" />
                </template>
            </el-table-column>
        </el-table>
        <el-form ref="ruleFormRef" class="login-form" :model="ruleFormPoint" label-position="left" :rules="rulesFormPoint">
            <el-dialog v-model="centerDialogVisible" title="Sửa điểm học sinh" width="30%" center>
                <div class="py-2">
                    <h6 class="tabpointstd">
                        Tên người chấm : {{ userName }}
                    </h6>
                </div>
                <div class="py-2">
                    <h6 class="tabpointstd">Tên học viên : {{ ruleFormPoint.student_name }}</h6>
                </div>
                <div class="py-2">
                    <h6 class="tabpointstd"> Tên lớp : {{ ruleFormPoint.batch_name }}</h6>
                </div>
                <h6 class="tabpointstd">Lộ trình học :</h6>
                <roadmapSelect v-model="ruleFormPoint.roadmap_id" :isDisabled="true" />
                <el-form-item prop="course_id">
                    <courseSelect v-model="ruleFormPoint.course_id" ref="courseSelectRef" :disableSelect="true" />
                </el-form-item>
                <el-form-item v-if="isGuest" prop="nameGuest" class="py-3">
                    <h6 class="tabpointstd">Tên chuyên gia :</h6>
                    <el-input placeholder="Tên chuyên gia" required v-model="ruleFormPoint.nameGuest" />
                </el-form-item>
                <el-form-item prop="is_test_hackathon" class="mb-2 flex items-center text-sm">
                    <el-radio-group v-model="ruleFormPoint.is_test_hackathon" disabled="true">
                        <el-radio label="0" size="large" class="tabpointstd">Bài kiểm tra Hackathon</el-radio>
                        <el-radio label="1" size="large" class="tabpointstd">Bài cuối module</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="point" prop="point"  v-if="ruleFormPoint.is_test_hackathon === '1'">
                    <h6 style="margin: 0;" class="tabpointstd">
                        Điểm học viên :
                    </h6>
                    <el-input-number size="small" v-model="ruleFormPoint.point" :min="0" :max="100" maxlength="100" />
                </el-form-item>
                 <div v-if="ruleFormPoint.is_test_hackathon === '0'">
            <el-form-item prop="point1" style="margin-top: 20px;">
              <h6 style="margin: 0;" class="tabpointstd">
                Điểm thực hành :
              </h6>
              <el-input-number size="small" v-model="ruleFormPoint.point1" :min="0" :max="100" maxlength="100" required />
            </el-form-item>
            <el-form-item prop="point2" style="margin-top: 20px;">
              <h6 style="margin: 0;" class="tabpointstd">
                Điểm cơ bản:
              </h6>
              <el-input-number size="small" prop="point2" v-model="ruleFormPoint.point2" :min="0" :max="100"
                maxlength="100" required />
            </el-form-item>
            <el-form-item prop="point3" style="margin-top: 20px;">
              <h6 style="margin: 0;" class="tabpointstd">
                Điểm nâng cao:
              </h6>
              <el-input-number size="small" prop="point3" v-model="ruleFormPoint.point3" :min="0" :max="100"
                maxlength="100" required />
            </el-form-item>
          </div>
                <el-form-item prop="content_review" style="margin-top: 20px;">
                    <h6 class="tabpointstd">Nhận xét về học viên :</h6>
                    <el-input v-model="ruleFormPoint.content_review" :rows="2" autocomplete="off" type="textarea"
                        placeholder="Nhận xét" />
                </el-form-item>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="cancelScoring()">Hủy</el-button>
                        <el-button type="primary" @click="savePointStd">
                            Lưu
                        </el-button>
                    </span>
                </template>
            </el-dialog>
        </el-form>
        <div class="pb-3 text-center">
            <pagination v-show="isShowPagination" :total="total" :page-index="pageIndex" @currentPageChange="nextPage" />
        </div>
    </div>
    <confirm v-model="setup.confirmDialog" @deleteService="deletePoint" v-if="!showCheckbox"></confirm>
    <confirm v-model="setup.confirmChangePoint" @deleteService="changePoint"
        message="Có chắc chắn thay đổi điểm hay không ?" v-if="!showCheckbox"></confirm>
</template>
<script>
import pagination from "@/components/pagination.vue";
import roadmapSelect from "@/components/roadmapSelect.vue";
import courseSelect from "@/views/Course/courseSelect.vue";
import configs from "@/configs";
import confirm from "@/components/confirm.vue";
import {
    formatDate,
    setBreadcrumb,
} from "@/mixins/utils";
import {
    checkSpecialCharacter,
    textInputValidate,
    ValidService,
} from "@/validator";
import { NameRouter } from "@/constants/nameRouter";
import batchSelect from "@/views/Batches/batchSelect";
import { notifyErr, notifySuccess, formatDateTime } from "@/mixins/utils";
import { ElMessage, ElMessageBox } from 'element-plus'
import { CoursePointStudentGet, CoursePointStudentDelete, CoursePointStudentUpdate } from '@/services/course'
export default {
    expose: ['validForm'],
    name: "StudentList",
    components: {
        batchSelect,
        roadmapSelect,
        courseSelect,
        confirm,
    },
    mixins: [setBreadcrumb],
    data() {
        return {
            validation: false,
            username: '',
            tableData: [],
            isShowPagination: false,
            currentPage: 1,
            pageIndex: 1,
            total: 0,
            loading: false,
            dialogDetail: false,
            model: {
                email: "",
                password: "",
                username: "",
                name: "",
                phone_number: "",
                is_active: configs.status.active,
            },
            rulesForm: {
                email: [textInputValidate, ValidService.checkEmail],
                password: [textInputValidate],
                username: [textInputValidate, checkSpecialCharacter],
                name: [textInputValidate, checkSpecialCharacter],
                phone_number: [textInputValidate, ValidService.checkPhone],
            },
            ruleFormPoint: {
                userName: this.$helpers.getRole().name,
                name: "",
                class: "",
                student_id:"",
                roadmap_id: "",
                course_id: "",
                content_review: "",
                nameGuest: "",
                point: "",
                is_test_hackathon: "",
            },
            rulesFormPoint: {
                content_review: [textInputValidate],
                course_id: [textInputValidate],
                timeExample: [textInputValidate],
                nameGuest: [textInputValidate],
                is_test_hackathon: [textInputValidate],
            },
            setup: {
                dialogAdd: false,
                idDelete: null,
                confirmDialog: false,
                confirmChangePoint: false,
                ChangePoint: null,
                idChange: null,
                student_id:null,
            },
            nameRouter: NameRouter,
            currentId: "",
            configs: configs,
            nameMaster: "",
            isGuest: this.$helpers.getRole().name == "Guest",
            searchParams: {
                batch_id: null,
                course_id: null,
                keyword: null,
                student_name: null,
                is_hackathon: 0,
            },
        };
    },
    created() {
        let infoUser = this.$helpers.getRole()
        this.userName = infoUser.name
        this.getCoursePointStudent()
    },
    methods: {
        getCoursePointStudent(params) {
            this.loading = true
            params = {
                per_page: configs.itemOnPage,
                page: this.currentPage,
                batch_id: this.searchParams.batch_id,
                course_id: this.searchParams.course_id,
                keyword: this.searchParams.keyword,
                student_name: this.searchParams.student_name
            }
            CoursePointStudentGet(params).then(e => {
                if (e.data.status == 'success') {
                    let dataTable = [];
                    e.data.data.pointOfStudent.data.map((e) => {
                        dataTable.push({
                            batch_id: e.batch.id,
                            id: e.id,
                            batch_name: e.batch.batch_name,
                            course_id: e.course_id,
                            course_name: e.course.title,
                            created_at: formatDateTime(e.created_at),
                            is_test_hackathon: e.is_test_hackathon,
                            is_test_hackathon_title: e.is_test_hackathon === 0 ? "Hackathon" : "Cuối module",
                            reviewer_name: e.reviewer_name,
                            content_review: e.content_review,
                            point: e.point,
                            title: e.title,
                            roadmap_id: e.roadmap.id,
                            roadmap_title: e.roadmap.title,
                            student_id: e.student_id,
                            student_name: e.student.name,
                        })
                    })
                    this.tableData = dataTable;
                    this.total = e.data.data.pointOfStudent.total
                    this.isShowPagination = e.data.data.pointOfStudent.total > 0
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                notifyErr()
            })
        },
        deletePointStd(id) {
            this.setup.confirmDialog = true;
            this.setup.idDelete = id;
        },
        deletePoint() {
            CoursePointStudentDelete(this.setup.idDelete).then(async res => {
                await notifySuccess('delete')
                await this.getCoursePointStudent()
                this.setup.confirmDialog = false
            }).catch(err => {
                notifyErr(err.response.data.message)
            })
        },
        changePoint(point) {
            CoursePointStudentUpdate(this.setup.idChange, { point: this.ruleFormPoint.point, content_review: this.ruleFormPoint.content_review, student_id:this.ruleFormPoint.student_id }).then(async res => {
                await notifySuccess('update')
                await this.getCoursePointStudent()
                this.setup.confirmChangePoint = false
                this.centerDialogVisible = false
            }).catch(err => {
                notifyErr(err.response.data.message)
            })
        },
        savePointStd() {
            this.setup.confirmChangePoint = true;
            this.setup.ChangePoint = point;
            this.setup.student_id=student_id;
        },
        cancelScoring() {
            this.$refs.courseSelectRef.resetData();
            this.centerDialogVisible = false;
            this.ruleFormPoint = '';
            this.resetForm();
        },
        resetForm() {
            this.ruleFormPoint = {
                course_id: '',
                is_test_hackathon: '',
                point: '',
                timeExample: '',
                content_review: ''
            };
        },
        nextPage(index) {
            this.currentPage = index;
            this.pageIndex = index;
            let params = {
                per_page: configs.itemOnPage,
                page: this.currentPage,
                keyword: this.searchParams.keyword,
                batch_id: this.searchParams.batch_id,
                course_id: this.searchParams.course_id,
                student_name: this.searchParams.student_name
            }
            this.getCoursePointStudent(params);
        },
        searchData() {
            let params = {
                per_page: configs.itemOnPage,
                page: 1,
                keyword: this.searchParams.keyword,
                batch_id: this.searchParams.batch_id,
                course_id: this.searchParams.course_id,
                student_name: this.searchParams.student_name
            }
            this.getCoursePointStudent(params)
        },
        reload() {
            location.reload();
        },
        refreshSearch() {
            this.currentPage = 1;
            this.setup = {
                search_name: null,
                search_phone: null,
                search_email: null,
                search_batch: null,
                filter_active: null,
            };
            let params = this.setup;
            params.page = this.currentPage;
            params.per_page = configs.itemOnPage;
            this.$router.push({ path: location.pathname, query: {} })
            // this.getStudents(params);
            this.$refs.batchSelectBox.resetData();
        },
        async pointStudent(user) {
            this.setup.idChange = user.id
            this.centerDialogVisible = true;
            this.roadmap_id = user.roadmapId
            this.ruleFormPoint.student_name = user.student_name
            this.ruleFormPoint.batch_name = user.batch_name
            this.ruleFormPoint.roadmap_id = user.roadmap_id
            this.ruleFormPoint.course_id = user.course_id
            this.ruleFormPoint.is_test_hackathon = user.is_test_hackathon.toString()
            this.ruleFormPoint.id = user.id
            this.ruleFormPoint.point = user.point
            const student = this.tableData.find((item) => item.id === user.id);
            if (student) {
                // Assign the student points to the form fields
                this.ruleFormPoint.content_review = student.content_review;
                // Assign other necessary fields accordingly
            }
        },
    }
}
</script>
<script setup  >

import { Refresh ,Search} from '@element-plus/icons-vue'
import { ref } from 'vue'
const centerDialogVisible = ref(false)
</script>
<style scoped lang="scss">
* {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #333333
}
.pointstudent {
    display: flex;
    width: 50%;
    justify-content: space-between;
    margin-bottom: 20px;
}
#managementStudent {
    .addStudent {
        :deep(.el-dialog__body) {
            padding-top: 0;
        }
    }
}
.tabpointstd {
    font-weight: 400 !important;
}
.examdate {
    display: flex;
    justify-content: space-between;
}
.infoStudent {
    th,
    td {
        text-align: left;
        padding: 0.8rem;
        border-bottom: 1px solid #bbbbbb;
    }
}
.el-textarea__inner {
    line-height: 2;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: inherit;
}
</style>
  
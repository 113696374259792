// import BaseHttpService from './base-http.service';
import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {AUTH} from "@/constants/endPoints";

export const login = (params) => HTTP_REQUEST_AUTH.post(AUTH.LOGIN, params);

export const register = (params) => HTTP_REQUEST_AUTH.post(AUTH.REGISTER, params);

export const SendMail = (payload) => HTTP_REQUEST_AUTH.post(AUTH.SEND_MAIL, payload);

export const resetPassword = (payload) => HTTP_REQUEST_AUTH.post(AUTH.RESET_PASSWORD, payload);

export const GetPermissions = (payload) => HTTP_REQUEST_AUTH.get(AUTH.GET_PERMISSIONS, {params: payload});

export const logout = (payload) => HTTP_REQUEST_AUTH.post(AUTH.LOGOUT, payload);

export const GetCurrentUserInfo = (payload) => HTTP_REQUEST_AUTH.get(AUTH.ME, {params: payload});

export const UpdateUserCurrent = (param) => HTTP_REQUEST_AUTH.post(AUTH.UPDATE_ME, param);

export const changePassword = (params) => HTTP_REQUEST_AUTH.post(AUTH.CHANGE_PASSWORD, params);

<template>
  <el-select :disabled="isDisabled" v-model="idRoadmap" class="w-100" placeholder="Chọn lộ trình học" filterable
    default-first-option :reserve-keyword="false" @change="updateRoadmap">
    <el-option v-for="item in roadmaps" :key="item.id" :label="item.title" :value="item.id" />
  </el-select>
</template>
<script>
import { GetAllRoadmap } from "@/services/roadmap";
import { notifyErr } from "@/mixins/utils";
import configs from "@/configs";

export default {
  name: 'roadmap-select',
  props: {
    modelValue: Number,
    isDisabled: {
      default: false,
    }
  },
  expose: ['getRoadmapById'],
  data() {
    return {
      roadmaps: [],
      idRoadmap: '',
      hasModule: 1,
      notModule: 2,
    }
  },
  watch: {
    idRoadmap() {
      this.checkModule()
    }
  },
  created() {
    this.getRoadmap()
  },
  methods: {
    getRoadmap() {
      GetAllRoadmap().then(e => {
        if (e.data.status == 'success') {
          this.roadmaps = e.data.data.allRoadmap
        }
        this.idRoadmap = this.modelValue
      }).catch(err => {
        notifyErr()
      })
    },
    updateRoadmap() {
      this.$emit('update:modelValue', this.idRoadmap)
    },
    checkModule() {
      let hasModule = false
      let roadmap = this.roadmaps.find(item => item.id === this.idRoadmap)
      if (!roadmap) return this.$emit('hasModule', hasModule)
      if (roadmap.type === this.notModule) hasModule = false
      if (roadmap.type === this.hasModule) hasModule = true
      this.$emit('hasModule', hasModule)
    },
    getRoadmapById(id) {
      return this.roadmaps.find(item => item.id === id)
    },
  }
}
</script>

<template>
  <el-dialog v-model="modelValue" title="Chỉnh sửa câu hỏi" width="50%" draggable @close="closeDialog">
    <el-form ref="contentForm" :model="form" v-loading="loading" label-position="top">
      <el-form-item
          :rules="rulesForm.content"
          label="Nội dung câu hỏi"
          prop="content">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="Nội dung câu hỏi"
            v-model="form.content"
        >
        </el-input>
      </el-form-item>
      <table class="table">
        <thead>
        <tr>
          <th scope="col" style="width: 5%">#</th>
          <th scope="col" style="width: 50%">Câu trả lời</th>
          <th scope="col" style="width: 15%">Câu đúng</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(answer, i) in form.answers" :key="answer.id">
          <th scope="row">{{ i + 1 }}</th>
          <td>
            <el-form-item
                :prop="'answers.' + i + '.content'"
                :id="answer.id"
                :rules="ruleAnswer.content">
              <el-input
                  v-model="answer.content"
                  placeholder="Nội dung câu trả lời."
              >
              </el-input>
            </el-form-item>
          </td>
          <td>
            <el-form-item class="text-center" prop="isCorrectAnswer">
              <el-radio v-model="form.is_correct_answer" v-if="form.type == 1" :label="answer.id">Đúng
              </el-radio>
              <el-checkbox
                  v-model="answer.is_correct_answer"
                  v-if="form.type == 2"
                  disabled
                  :checked="answer.is_correct_answer == isCorrect"
                  :label="answer.id">
                Đúng
              </el-checkbox>
            </el-form-item>
          </td>
        </tr>
        </tbody>
      </table>
      <handle-button
          label="Thêm câu trả lời"
          icon="fa-pen-to-square"
          typeButton="primary"
          @Click="addNewAnswer"
      />
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('update:modelValue', false)">Hủy</el-button>
        <handle-button
            label="Cập nhật"
            eventCode="questions.update"
            icon="fa fa-check"
            typeButton="primary"
            @click="updateQuestion"/>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import {notifyErr, notifySuccess} from "@/mixins/utils";
import {DetailQuestion, UpdateQuestion} from "@/services/exam";
import {textInputValidate} from "@/validator";

export default {
  name: 'CreateSubject',
  expose: ['getQuestionDetail'],
  props: {
    modelValue: {type: Boolean, default: false},
  },
  data() {
    return {
      form: {
        id: '',
        content: '',
        is_correct_answer: 0,
        answers: [{
          id: '',
          content: '',
          is_correct_answer: 0
        }]
      },
      addNewAnswers: [],
      answersLength: 0,
      loading: false,
      rulesForm: {content: [textInputValidate]},
      ruleAnswer: {
        content: [textInputValidate]
      },
      manyAnswer: 2,
      isCorrect: 1,
      currentId: ''
    }
  },
  methods: {
    async updateQuestion() {
      let valid = await this.$refs.contentForm.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      })
      if (!valid) return notifyErr('Có lỗi nhập liệu.')
      UpdateQuestion(this.form, this.currentId).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm mới môn học thành công.')
          this.$emit('resetQuestion')
          this.$emit('update:modelValue', false)
          this.form = {
            name: '',
            version: '',
            is_active: 1
          }
        }
      }).catch(err => {
        notifyErr()
      })
    },
    closeDialog() {
      this.$emit('update:modelValue', false)
      this.form = {}
    },
    getQuestionDetail(id) {
      this.loading = true
      this.currentId = id
      DetailQuestion(id).then(res => {
        if (res.data.status == 'success') {
          let question = res.data.data.question
          let answers = [], idCorrectAnswer = ''
          question.answer.map(el => {
            answers.push({
              id: el.id,
              content: el.content,
              is_correct_answer: el.is_correct_answer
            })
            if (question.type !== this.manyAnswer) {
              if (el.is_correct_answer == this.isCorrect) idCorrectAnswer = el.id
            }
          })
          this.answersLength = answers.length + 1
          this.form = {
            id: question.id,
            content: question.content,
            type: question.type,
            is_correct_answer: idCorrectAnswer,
            answers: answers
          }

          this.loading = false
        }
      }).catch(err => {
        notifyErr();
        this.loading = false
        this.$emit('update:modelValue', false)
      })
    },
    addNewAnswer() {
      this.form.answers.push({
        id: this.answersLength++,
        content: '',
        is_correct_answer: 0
      })
    }
  },
}
</script>

<template>
  <el-empty :image-size="200" description="Rất tiêc! Bạn không có quyền truy cập.">
    <button
      type="button"
      @click="hasHistory()
    ? $router.go(-1)
    : $router.push('/')" class="my-2 btn btn-outline-warning">&laquo;
      Quay lại trang trước
    </button>
  </el-empty>
</template>
<script>
export default {
  name: 'Permission-denied',
  methods: {
    hasHistory () { return window.history.length > 2 }
  }
}
</script>

<template>
  <div id="addStudentsToBatch">
    <el-form :model="form" ref="examForm" label-width="120px" label-position="top" :rules="rulesForm">
      <div class="bg-white p-3">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
                label="Tiêu đề"
                prop="title">
              <el-input
                  v-model="form.title"
                  placeholder="Tiều đề bài kiểm tra"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
                label="Thời gian thi(phút)"
                prop="duration">
              <el-input
                  v-model="form.duration"
                  placeholder="Thời gian thi."
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="align-self: center">
            <handle-button
                label="Thêm câu hỏi"
                eventCode="exams.get-detail-exam"
                icon="fa-plus"
                typeButton="primary"
                @click="setup.contentDialog = true"/>
            <handle-button
                type="link"
                label="Báo cáo bài thi"
                eventCode="exams.exam-report"
                icon="fa-plus"
                typeButton="primary"
                :to="{name: nameRouter.PAGES.EXAM_REPORT,params: { id: idExam }}"/>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="bg-white p-3">
      <el-table
          class=" pb-3"
          :data="tableData"
          style="width: 100%"
          ref="studentTable"
          border
          fixed
          v-loading="loading">
        <el-table-column prop="title" label="Nội dung câu hỏi" min-width="200"/>
        <el-table-column
            label="Loại câu hỏi"
            width="200">
          <template #default="scope">
            <div class="text-bold" v-if="scope.row.type == manyAnswer">Nhiều đáp án</div>
            <div class="text-bold" v-if="scope.row.type == answer">Một đáp án</div>
          </template>
        </el-table-column>
        <el-table-column prop="createAt" label="Ngày tạo" width="200"/>
        <el-table-column
            fixed="right"
            min-width="150">
          <template #default="scope">
            <button class="btn btn-primary py-1 mx-3" style="font-size: 12px" @click="openDialogEditQuestion(scope.row.id)">Xem thông tin</button>
            <button class="py-1 mx-3 btn btn-danger" @click="openConfirm(scope.row.id)" style="font-size: 12px"><i
                class="fa fa-trash"></i> Xóa
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="text-center py-4">
      <el-button type="primary" @click="updateExams"><i
          class="fa fa-plus mr-2"></i> cập nhật
      </el-button>
    </div>
    <el-dialog v-model="setup.contentDialog" @close="closeDialog">
      <template #title>
        <div class="border-bottom py-2"><b>Thêm nội dung</b></div>
      </template>
      <questionSelect v-model="setup.questionId" :idOld="tableData" :validation="validExam" :is-multiple="false"
                      ref="questionSelectBox"></questionSelect>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeDialog">Đóng</el-button>
          <el-button type="primary" @click="contentNew">Lưu</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <confirm v-model="setup.confirmDialog" @close="closeDialog('confirmDialog')" @deleteService="removeContent"></confirm>
  <edit-question v-model="setup.editQuestionDialog" @resetQuestion="getExam" ref="editQuestionDialog"></edit-question>
</template>
<script>
import {DetailExam, UpdateExam} from "@/services/exam";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {textInputValidate, ValidService} from "@/validator";
import Messages from "@/constants/messages";
import questionSelect from "@/views/Exam/questionSelect";
import confirm from "@/components/confirm";
import editQuestion from "@/views/Exam/editQuestion";

export default {
  name: "DetailExam",
  components: {
    questionSelect,
    confirm,
    editQuestion
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      idExam: this.$route.params.id,
      isShowPagination: false,
      nameRouter: NameRouter,
      loading: false,
      form: {title: '', duration: ''},
      rulesForm: {
        title: [textInputValidate],
        duration: [textInputValidate, ValidService.checkNumber]
      },
      keyword: '',
      messageErr: Messages.QUESTION_REQUIRE,
      manyAnswer: 2,
      answer: 1,
      validExam: false,
      questionCurrentDel: '',
      setup: {
        questionId: null,
        contentDialog: false,
        confirmDialog: false,
        editQuestionDialog: false,
      }
    };
  },
  created() {
    this.getExam()
  },
  methods: {
    async getExam() {
      this.loading = true
      DetailExam({exam_id: this.idExam}).then(res => {
        if (res.data.status == 'success') {
          let data = res.data.data.detailExam
          this.form = {
            title: data.title,
            duration: data.duration / 60,
          }
          let table = []
          data.exam_question.map(el => {
            table.push({
              id: el.question ? el.question.id : '',
              type: el.question ? el.question.type : '',
              title: el.question ? el.question.content : '',
              createAt: el.question ? formatDate(el.question.created_at) : ''
            })
          })
          this.tableData = table
          this.loading = false
        }
      })
    },
    async updateExams() {
      let valid = await this.$refs.examForm.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return false
      let listQuestion = []
      if (this.setup.questionId) {
        listQuestion.push(this.setup.questionId)
      }
      this.tableData.map(el => {
        listQuestion.push(el.id)
      })
      let obj = {
        exam_id: this.idExam,
        title: this.form.title,
        duration: this.form.duration * 60,
        question_add_ids: listQuestion
      }
      UpdateExam(obj).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('edit')
          this.getExam()
          this.closeDialog()
        }
        if (e.data.status === 'failed') {
          notifyErr('Tạo bài thi thất bại.')
        }
        this.loading = false
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
        this.loading = false
      })
    },
    closeDialog(type) {
      if (type) {
        this.setup.confirmDialog = false
        this.questionCurrentDel = ''
        return
      }
      this.setup.contentDialog = false
      this.setup.questionId = ''
      this.$refs.questionSelectBox.resetData()
    },
    async contentNew() {
      this.validExam = this.setup.questionId == '' || this.setup.questionId == null
      if (!this.setup.questionId) return false
      await this.updateExams()
      this.closeDialog()
      event.preventDefault();
    },
    async removeContent() {
      let itemIndex = this.tableData.findIndex(el => el.id === this.questionCurrentDel)
      if (itemIndex < 0) return notifyErr('Không tồn tại câu hỏi này.')
      this.loading = true
      this.tableData.splice(itemIndex, 1);
      await this.updateExams()
      this.setup.confirmDialog = false
      this.questionCurrentDel = ''
    },
    openConfirm(id) {
      if (this.tableData.length < 2) return notifyErr('Không được xóa hết.')
      this.questionCurrentDel = id;
      this.setup.confirmDialog = true
    },
    openDialogEditQuestion(id) {
      this.setup.editQuestionDialog = true
      this.$refs.editQuestionDialog.getQuestionDetail(id)
    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
</script>
<style scoped lang="scss">

</style>

<template>
  <div class="bg-white">
    <info-schedule :form="form" ref="formSchedule"/>
    <div class="text-center py-3 px-3">
      <handle-button
          label="Cập nhật lịch trình"
          eventCode="schedules.update"
          icon="fa fa-plus"
          typeButton="primary"
          @click="updateSchedule"/>
    </div>
  </div>
</template>
<script>
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import infoSchedule from "@/views/Schedule/infoSchedule";
import {GetDetailSchedule, UpdateSchedule} from '@/services/schedule'

export default {
  name: "UpdateSchedule",
  mixins: [setBreadcrumb],
  components: {
    infoSchedule
  },
  data() {
    return {
      configs: configs,
      NameRouter: NameRouter,
      form: {},
      loading: false,
    };
  },
  created() {
    this.getCurrentSchedule()
  },

  methods: {
    async getCurrentSchedule() {
      try {
        let res = await GetDetailSchedule(this.$route.params.id)
        let data = res.data.data.schedule;

        this.form = {
          title: data.title,
          description: data.description,
          room_id: data.room_id,
          batch_id: data.batch_id,
          course_id: data.course_id,
          material_id: data.material_id,
          section_id: data.section_id,
          trainer_id: data.trainer_id,
          trainer_assistant_id: data.trainer_assistant_id,
          day: new Date(data.start_time),
          time: [new Date(data.start_time), new Date(data.end_time)]
        }
      } catch (err) {

      }
    },
    async updateSchedule() {
      try {
        let day = this.form.day.toLocaleDateString('en-CA');
        let timeStart = this.form.time[0].toLocaleTimeString();
        let timeEnd = this.form.time[1].toLocaleTimeString();
        this.form.start_time = day + " " + timeStart
        this.form.end_time = day + " " + timeEnd
        await UpdateSchedule(this.$route.params.id, this.form)
        notifySuccess("edit")
      } catch (err) {
        return notifyErr(err.response.data.message)
      }
    },
  },

}

</script>
<script setup>

</script>
<style scoped lang="scss">

</style>
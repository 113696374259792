import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {COURSE, SECTION, MATERIAL, MODULE} from "@/constants/endPoints";

export const GetListCourse = (params) => HTTP_REQUEST_AUTH.get(COURSE.LIST_COURSE, {params: params});
export const GetDetailCourse = (params) => HTTP_REQUEST_AUTH.get(COURSE.DETAIL_COURSE + '/' + params);
export const CreateCourse = (params) => HTTP_REQUEST_AUTH.post(COURSE.CREATE_COURSE, params);
export const DeleteCourse = (id) => HTTP_REQUEST_AUTH.delete(COURSE.UPDATE_COURSE + '/' + id);
export const UpdateCourse = (id, params) => HTTP_REQUEST_AUTH.put(COURSE.UPDATE_COURSE + '/' + id, params);
export const PreviewCourse = (id) => HTTP_REQUEST_AUTH.get(COURSE.PREVIEW_COURSE + '/' + id);

export const CreateMaterial = (params) => HTTP_REQUEST_AUTH.post(MATERIAL.CREATE_MATERIAL, params);
export const GetMaterial = (params) => HTTP_REQUEST_AUTH.get(MATERIAL.LIST_MATERIAL, {params: params});
export const DetailMaterial = (id, params) => HTTP_REQUEST_AUTH.get(MATERIAL.DETAIL_MATERIAL + '/' + id, {params: params});
export const UpdateMaterial = (id, params) => HTTP_REQUEST_AUTH.put(MATERIAL.UPDATE_MATERIAL + '/' + id, params);
export const DeleteMaterial = (id, params) => HTTP_REQUEST_AUTH.delete(MATERIAL.UPDATE_MATERIAL + '/' + id, params);

export const UpdateSection = (id, params) => HTTP_REQUEST_AUTH.put(SECTION.UPDATE_SECTION + '/' + id, params);
export const GetListSection = (params) => HTTP_REQUEST_AUTH.get(SECTION.LIST_SECTION, {params: params});
export const GetMaterialBySection = (params) => HTTP_REQUEST_AUTH.get(SECTION.GET_MATERIAL_BY_SECTION, {params: params});
export const CreateSection = (params) => HTTP_REQUEST_AUTH.post(SECTION.CREATE_SECTION, params);
export const DetailSection = (id, params) => HTTP_REQUEST_AUTH.get(SECTION.DETAIL_SECTION + '/' + id, params);
export const DeleteSection = (id, params) => HTTP_REQUEST_AUTH.delete(SECTION.DETAIL_SECTION + '/' + id);

export const GetListModule = (params) => HTTP_REQUEST_AUTH.get(MODULE.GET_MODULES, {params: params});
export const CreateModule = (params) => HTTP_REQUEST_AUTH.post(MODULE.CREATE_MODULE, params);
export const DetailModule = (id, params) => HTTP_REQUEST_AUTH.get(MODULE.DETAIL_MODULE + '/' + id, {params: params});
export const UpdateModule = (id, params) => HTTP_REQUEST_AUTH.put(MODULE.UPDATE_MODULE + '/' + id, params);

export const GetListSubject = (params) => HTTP_REQUEST_AUTH.get(COURSE.SUBJECTS, {params: params});
export const CreateSubject = (params) => HTTP_REQUEST_AUTH.post(COURSE.SUBJECTS, params);
export const DetailSubject = (id, params) => HTTP_REQUEST_AUTH.get(COURSE.SUBJECTS + '/' + id, {params: params});
export const UpdateSubject = (id, params) => HTTP_REQUEST_AUTH.put(COURSE.SUBJECTS + '/' + id, params);
export const DeleteSubject = (id) => HTTP_REQUEST_AUTH.delete(COURSE.SUBJECTS + '/' + id);

export const CoursePointOfStudent = (params) => HTTP_REQUEST_AUTH.post(COURSE.COURSE_POINT_OF_STUDENT, params);
export const CoursePointStudent = (params) => HTTP_REQUEST_AUTH.post(COURSE.COURSE_POINT_STUDENT, params )
export const CoursePointStudentGet = (params) => HTTP_REQUEST_AUTH.get(COURSE.COURSE_POINT_STUDENT_GET,  {params: params}  )
export const CoursePointStudentDelete = (id) => HTTP_REQUEST_AUTH.delete(COURSE.COURSE_POINT_STUDENT_DELETE + '/' + id);
export const CoursePointStudentUpdate = (id, params) => HTTP_REQUEST_AUTH.post(COURSE.COURSE_POINT_STUDENT_UPDATE + '/' + id , params);

export const ReportPointStudent = (params) => HTTP_REQUEST_AUTH.get(COURSE.REPORT_COURSE , {params: params});

<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
    <el-tab-pane label="Thêm mới chương học" name="first">
      <infoMaterial ref="materialForm" :form="form"></infoMaterial>
      <div class="text-center py-4">
        <handle-button
            label="Tạo bài học"
            eventCode="materials.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createMaterial"/>
      </div>
    </el-tab-pane>

    <el-tab-pane label="Thêm mới nội dung" name="content">
      <fileCheckbox ref="fileSelected" v-model="form.files"></fileCheckbox>
      <div class="text-center py-4">
        <handle-button
            label="Tạo bài học"
            eventCode="materials.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createMaterial"/>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Thêm bài kiểm tra" name="exams">
      <examsCourse ref="exams" v-model="form.examIds"></examsCourse>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import {CreateMaterial} from "@/services/course";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import infoMaterial from "@/views/ManagementMaterial/infoMaterial";
import fileCheckbox from "@/views/ManagerFile/fileCheckbox";
import examsCourse from "@/components/exams-course";

export default {
  name: 'CreatSection',
  components: {
    infoMaterial,
    fileCheckbox,
    examsCourse
  },

  mixins: [setBreadcrumb],
  data() {
    return {
      setup: {
        activeName: 'first'
      },
      form: {
        course_id: null,
        name: '',
        duration: '',
        is_active: 1,
        sort_order: '',
        files: [],
        examIds: []
      }
    }
  },
  methods: {
    async createMaterial() {
      let valid = await this.$refs.materialForm.validForm()
      if (!valid) return false
      let validFile = await this.$refs.fileSelected.validFile()
      if (!validFile) {
        this.setup.activeName = 'content'
        return false
      }

      let materials = []
      let exams = []
      this.form.files.map((el, index) => {
        materials.push({id: el, sort_order: index + 1})
      })
      this.form.examIds.map((el, index) => {
        exams.push({id: el, sort_order: index + 1})
      })
      this.form.files = materials
      this.form.exams = exams
      this.form.duration = this.form.duration * 60

      let obj = {
        course_id: this.form.course_id,
        materials: [this.form],
      }
      CreateMaterial(obj).then(async res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm chương học thành công')
          await this.$router.push(NameRouter.PAGES.LIST_MATERIAL)
        }
      }).catch(err => {
        notifyErr()
      })
    },

  }
}
</script>
<script setup>
import {Check} from "@element-plus/icons-vue";
import FileCheckBox from "@/views/ManagerFile/fileCheckbox";
</script>


<template>
  <div id="managementBatches" class="bg-white p-3">
    <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed border>
      <el-table-column fixed prop="id" label="ID" width="50"/>
      <el-table-column fixed prop="name" label="Tên cơ sở" width="250"/>
      <el-table-column fixed prop="code" label="Code" width="100"/>
      <el-table-column fixed prop="branch_name" label="Tên cơ sở" width="250"/>
      <el-table-column width="600" label="Hành động">
        <template #default="scope">
          <handle-button
              type="link"
              label="Chi tiết"
              eventCode=""
              icon="fa fa-plus"
              typeButton="primary"
              :to="{ name: NameRouter.PAGES.ROOM_DETAIL, params:{id: scope.row.id}}"/>
          <handle-button
              label="Xóa phòng học"
              eventCode="rooms.destroy"
              icon="fa fa-minus"
              typeButton="danger"
              @click="openConfirmDelete(scope.row.id)"
          />
        </template>

      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
    <confirm v-model="confirmDelete" @close="closeConfirmDelete" @deleteService="removeRoom"></confirm>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifyFiled, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListRoom,DeleteRoom} from "@/services/room";
import confirm from "@/components/confirm";


export default {
  name: "listRoom",
  components: {
    pagination,
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      confirmDelete: false,
      idRoomDelete: "",
      configs: configs,
      NameRouter: NameRouter,

    };
  },

  created() {
    this.getListRoom()
  },
  methods: {
    getListRoom(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      GetListRoom(params).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.listRoom.data.map(e=>{
            dataTable.push({
              id:e.id,
              name:e.name,
              branch_name: e.branch[0].name,
              code: e.code
            })
          })

          this.tableData = dataTable
          this.total = e.data.data.listRoom.total
          this.isShowPagination = e.data.data.listRoom.total > 0
        }
        this.loading = false;
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
        this.loading = false
      })

    },
    openConfirmDelete(id) {
      this.confirmDelete = true
      this.idRoomDelete = id

    },
    closeConfirmDelete() {
      this.confirmDelete = false
      this.idRoomDelete = ""
    },

    async removeRoom() {
      DeleteRoom( this.idRoomDelete).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('delete')
          this.confirmDelete = false
          this.idRoomDelete = ""
          this.getListRoom()
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getListRoom(index);
    },
  }

}

</script>

<script setup>
import {
  Delete,
  Close,
  Plus,
} from '@element-plus/icons-vue'
</script>


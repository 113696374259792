<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card" v-loading="loading">
    <el-tab-pane label="Nội dung" name="first">
      <infoMaterial ref="sectionForm" :form="form"></infoMaterial>
      <div class="text-center py-4">
        <handle-button
            label="Cập nhật"
            eventCode="materials.update"
            icon="fa fa-check"
            typeButton="primary"
            @click="updateMaterial"/>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Thêm mới nội dung" name="content">
      <div class="text-end py-4">
        <el-button type="primary" :icon="Plus" @click="setup.contentDialog = true">Thêm nội dung</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%"
          fixed
      >
        <el-table-column fixed prop="title" label="Tên tệp" min-width="150"/>
        <el-table-column prop="type" label="Loại tệp " min-width="120">
          <template #default="scope">
            <el-button
                type="warning"
                size="small">
              {{ CONFIG_APP.type[scope.row.type] }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="Vị trí" min-width="150">
          <template #default="scope">
            <input v-model="scope.row.sort_order" class="form-control" :id="'position-' + scope.row.id"
                   @change="validPosition(scope.row.id)"/>
            <div class="invalid-feedback" :id="'err-' + scope.row.id"></div>
          </template>
        </el-table-column>

        <el-table-column label="Hành động" min-width="120">
          <template #default="scope">
            <div class="d-flex">
              <handle-button
                  label="Xem tệp"
                  eventCode="files.get-list-file"
                  icon="fa fa-eye"
                  typeButton="success"
                  @click="openBrowser(scope.row)"/>
              <handle-button
                  label="Cập nhật"
                  eventCode="materials.update"
                  icon="fa fa-check"
                  typeButton="primary"
                  @click="saveFile(scope.row.id)"/>
              <handle-button
                  label="Loại bỏ"
                  eventCode="materials.update"
                  icon="fa fa-trash"
                  typeButton="danger"
                  @click="openConfirm(scope.row.id)"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="Kiểm tra" name="exams">
      <div class="text-end">
        <handle-button
            label="Thêm bài thi"
            eventCode="exams.index"
            icon="fa fa-plus"
            typeButton="primary"
            @click="setup.examDialog= true"/>
      </div>
      <el-table
          ref="exams"
          v-loading="loading"
          :data="examTable"
          border
          style="width: 100%"
          fixed
      >
        <el-table-column fixed prop="title" label="Tiêu đề" min-width="150"/>
        <el-table-column prop="duration" label="Thời gian thi" width="200"/>
        <el-table-column prop="createdAt" label="Ngày tạo" width="200"/>
        <el-table-column prop="createdAt" label="Vị trí" width="120">
          <template #default="scope">
            <input v-model="scope.row.sort_order" class="form-control" :id="'exam-'+ scope.row.id"
                   @change="changeExam(scope.row.id)"/>
            <div class="invalid-feedback" :id="'err-'+ scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column label="Hoạt động" width="250">
          <template #default="scope">
            <handle-button
                label="Loại bỏ"
                eventCode="materials.update"
                icon="fa fa-trash"
                typeButton="danger"
                @click="openConfirmExam(scope.row.id)"/>
            <handle-button
                label="Cập nhật"
                eventCode="materials.update"
                icon="fa fa-check"
                typeButton="primary"
                @click="saveExams(scope.row.id)"/>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <el-dialog v-model="setup.contentDialog" @close="closeDialog">
    <template #title>
      <div class="border-bottom py-2"><b>Thêm nội dung</b></div>
    </template>
    <el-form label-position="top" :model="addContent" :rules="addContentRule" ref="contentForm">
      <el-form-item class="w-100" prop="id">
        <filesSelect v-model="addContent.id" :idOld="idOld" :is-multiple="false"
                     ref="materialSelectBox"></filesSelect>
      </el-form-item>
      <el-form-item label="Ví trí" prop="sort_order">
        <el-input v-model="addContent.sort_order"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">Đóng</el-button>
        <handle-button
            label="Lưu nội dung"
            eventCode="materials.update"
            icon="fa fa-plus"
            typeButton="primary"
            @click="contentNew"/>
      </div>
    </template>
  </el-dialog>
  <confirm v-model="setup.confirmExam" @deleteService="deleteExam"></confirm>
  <el-dialog v-model="setup.examDialog" @close="closeDialog('examDialog')">
    <el-form label-position="top" :model="exam" :rules="examContentRule" ref="examForm">
      <el-form-item class="w-100" prop="id">
        <examSelect v-model="exam.id" :id-old="examOld" :validation="validExam" ref="examSelected"></examSelect>
      </el-form-item>
      <el-form-item class="w-100" label="Vị trí" prop="sort_order">
        <el-input v-model="exam.sort_order"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog('examDialog')">Đóng</el-button>
        <handle-button
            label="Lưu bài thi"
            eventCode="materials.update"
            icon="fa fa-plus"
            typeButton="primary"
            @click="addExam"/>
      </div>
    </template>
  </el-dialog>
  <confirm v-model="setup.confirmDialog" @close="closeDialog('confirmDialog')" @deleteService="removeContent"></confirm>
</template>
<script>
import {DetailMaterial, UpdateMaterial} from "@/services/course";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import messages from "@/constants/messages";
import confirm from "@/components/confirm";
import {checkIsInteger, textInputValidate} from "@/validator";
import infoMaterial from "@/views/ManagementMaterial/infoMaterial";
import {GetFile} from "@/services/common";
import filesSelect from "@/views/ManagerFile/filesSelect";
import examSelect from "@/views/Exam/examSelect";

export default {
  name: 'DetailMaterial',
  components: {
    infoMaterial,
    confirm,
    filesSelect, examSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {

      materials: [],
      form: {
        course_id: null,
        name: '',
        code: '',
        purpose_of_uses: "",
        duration: '',
        is_active: 1,
        sort_order: '',
      },
      materialId: this.$route.params.id,
      loading: false,
      tableData: [],
      idOld: [],
      addContent: {
        id: null,
        sort_order: ''
      },
      addContentRule: {
        id: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger],
      },
      examContentRule: {
        id: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger],
      },

      validExam: false,
      idExamDelete: '',
      exam: {
        id: null,
        sort_order: ''
      },
      examOld: [],
      examTable: [],
      setup: {
        activeName: 'first',
        examDialog: false,
        confirmExam: false,
        contentDialog: false,
        confirmDialog: false,
      }

    }
  },
  created() {
    this.detailMaterial()
  },
  methods: {
    async updateMaterial(type) {
      if (!type) {
        let valid = await this.$refs.sectionForm.validForm()
        if (!valid) return false
      }
      let content = [];
      let exams = [];
      let error = false
      this.tableData.map((item, index) => {
        if (!item.sort_order || isNaN(item.sort_order)) {
          this.setup.activeName = 'content'
          notifyErr('Có lỗi nhập liệu.')
          error = true
          return false
        }
        content.push({id: item.id, sort_order: item.sort_order})
      })
      this.examTable.map((item, index) => {
        if (!item.sort_order || isNaN(item.sort_order)) {
          this.setup.activeName = 'exams'
          notifyErr('Có lỗi nhập liệu.')
          error = true
          return false
        }
        exams.push({id: item.id, sort_order: item.sort_order})
      })
      if (error) return false
      if (this.addContent.id || this.addContent.sort_order) {
        content.push(this.addContent)
      }
      if (this.exam.id || this.exam.sort_order) {
        exams.push(this.exam)
      }
      this.form.files = content
      this.form.duration = this.form.duration * 60
      this.form.exams = exams
      UpdateMaterial(this.materialId, this.form).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('edit')
          this.detailMaterial()
        }
      }).catch(err => {
        notifyErr()
      })
    },
    async detailMaterial() {
      this.loading = true
      DetailMaterial(this.materialId).then(async res => {
        if (res.data.status === 'success') {
          let item = res.data.data.material
          this.form = {
            course_id: item.course_id,
            code: item.code,
            purpose_of_uses:item.purpose_of_uses,
            name: item.name,
            duration: item.duration ? item.duration / 60 : '',
            sort_order: item.sort_order,
            is_active: item.is_active
          }
          let files = []
          let filesOld = [], examsOld = [], examTable = []
          item.material_file.map(e => {
            filesOld.push({id: e.file_id, sort_order: e.sort_order})
            files.push({
              id: e.file.id,
              title: e.file.name,
              url: e.file.full_url,
              type: e.file.type,
              path: e.file.url,
              sort_order: e.sort_order,
              createdAt: e.file.created_at ? formatDate(e.file.created_at) : '',
            })
          })
          item.material_exam.map(e => {
            examsOld.push({id: e.exam_id, sort_order: e.sort_order})
            examTable.push({
              id: e.exam.id,
              code: e.exam.code,
              title: e.exam.title,
              duration: e.exam.duration ? e.exam.duration / 60 : '',
              type: e.exam.type,
              sort_order: e.sort_order,
              createdAt: e.exam.created_at ? formatDate(e.exam.created_at) : '',
            })
          })
          this.tableData = files
          this.examTable = examTable
          this.idOld = filesOld
          this.examOld = examsOld
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        notifyErr()
      })
    },
    closeDialog(type) {
      this.setup.confirmExam = false
      this.setup.examDialog = false
      this.setup.confirmDialog = false
      this.setup.contentDialog = false
      this.exam = {
        id: null,
        sort_order: '',
      }
      if (type) return this.confirmDialog = false
      this.$refs.materialSelectBox.resetData()
      this.addContent = {
        id: null,
        sort_order: '',
      }

    },
    validPosition(id) {
      let message = ''
      let item = this.tableData.find(el => id == el.id)
      let elementInput = document.getElementById('position-' + id)
      let elementErr = document.getElementById('err-' + id)
      if (!item.sort_order) message = messages.NULL
      if (item.sort_order && isNaN(item.sort_order)) message = messages.NUMBER
      if (item.sort_order && !isNaN(item.sort_order)) message = ''
      elementErr.innerText = message
      if (message) {
        elementInput.classList.add('is-invalid')
      } else {
        elementInput.classList.remove('is-invalid')
      }
    },
    removeContent() {
      let index = this.tableData.findIndex(item => item.id === this.removeContentId)
      this.tableData.splice(index, 1)
      this.updateMaterial()
      this.setup.confirmDialog = false
    },
    async contentNew() {
      let valid = await this.$refs.contentForm.validate((valid, fields) => {
        if (!valid || !this.addContent.id) {
          return false;
        }
      })
      if (!valid) return false;
      await this.updateMaterial('add')
      await this.detailMaterial()
      this.closeDialog()
    },
    openConfirm(id) {
      if (this.tableData.length < 2) return notifyErr('Không được xóa hết tệp.')
      this.setup.confirmDialog = true
      this.removeContentId = id
    },
    openBrowser(row) {
      if (row.type == 2) {
        window.open(row.path, "", "width=300, height=300")
      }
      GetFile({file_path: row.path}).then(el => {
        if (el.data.status == 'success') {
          let fullUrl = el.data.data.fileURL
          window.open(fullUrl,
              "", "width=300, height=300")
        }
      }).catch(err => {
        notifyErr(err.response.message)
      })
    },
    saveFile(id) {
      let item = this.tableData.find(el => id == el.id)
      if (!item) return notifyErr('Không tồn tại tệp này.')
      if (!item.sort_order || isNaN(item.sort_order)) return notifyErr('có lỗi nhập liêu.')
      this.updateMaterial()
    },
    openConfirmExam(id) {
      if (this.tableData.length < 2) return notifyErr('không được xóa hết bài thi.')
      this.setup.confirmExam = true
      this.idExamDelete = id
    },
    async deleteExam() {
      let findIndex = this.examTable.findIndex(el => el.id === this.idExamDelete)
      if (findIndex < 0) return notifyErr('Không tồn tại bài thi này.')
      this.examTable.splice(findIndex, 1)
      await this.updateMaterial()
      this.setup.confirmExam = false
    },
    async addExam() {
      this.setup.confirmExam = false
      this.validExam = this.exam.id == ''
      let valid = await this.$refs.examForm.validate((valid, fields) => {
        if (!valid || !this.exam.id) {
          return false;
        }
      })
      if (!valid) return false
      this.validExam = false
      await this.updateMaterial('examAdd')
      this.setup.examDialog = false
    },
    async saveExams(id) {
      let findIndex = this.examTable.findIndex(el => el.id === id)
      if (findIndex < 0) return false;
      let item = this.examTable[findIndex];
      if (!item.sort_order || isNaN(item.sort_order)) return false
      await this.updateMaterial()
      await this.detailMaterial()
    },
    changeExam(id) {
      let message = ''
      let findIndex = this.examTable.findIndex(el => el.id === id)
      if (findIndex < 0) return false;
      let item = this.examTable[findIndex];
      let elementInput = document.getElementById('exam-' + id)
      let elementErr = document.getElementById('err-' + id)
      if (!item.sort_order) message = messages.NULL
      if (item.sort_order && isNaN(item.sort_order)) message = messages.NUMBER
      if (item.sort_order && !isNaN(item.sort_order)) message = ''
      elementErr.innerText = message
      if (message) {
        elementInput.classList.add('is-invalid')
      } else {
        elementInput.classList.remove('is-invalid')
      }
    },
  }

}
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {Check, View, Delete, Plus} from "@element-plus/icons-vue";

</script>

<style scoped lang="scss">
::v-deep .is-error {
  .vs__dropdown-toggle {
    border-color: red;
  }
}
</style>

export const BASE_URL = process.env.VUE_APP_BASE_URL_API;
export const AUTH = {
  LOGIN: "/login",
  REGISTER: "/register",
  LOGOUT: "/logout",
  SEND_MAIL: "/send-mail-reset-password",
  ME: "/me",
  UPDATE_ME: "/admin-update-profile",
  RESET_PASSWORD: "/reset-password",
  GET_PERMISSIONS: "/get-current-user-permission",
  CHANGE_PASSWORD: "/change-password",
};
export const STUDENTS = {
  GET_LIST_STUDENT: "/list-student",
  GET_STUDENT: "/get-detail-student",
  CREATE_STUDENT: "/create-student",
  INFO_STUDENT: "/list-students-info",
  UPDATE_STUDENT_STATUS: "/update-student-status",
  UPDATE_STUDENT_INFO: "/update-student",
  IMPORT_STUDENT: "/import-students",
  DETAIL_STUDENT: "/detail-student",
  CREATE_STUDENT_INFO: "/student-info",
  UPDATE_STUDENT_INFO_EXTEND: "/student-info",
  RESET_PASSWORD: "/reset-password",
};

export const BATCHES = {
  GET_LIST_BATCHES: "/get-all-batch",
  GET_ROLL_CALL_BATCHES: "/off-learn-reasons",
  GET_ROLL_CALL_STUDENT_BATCHES: "/list-roll-call-reports-by-student-id",
  GET_HISTORY_POINT_STUDENT: "/histories",
  CREATE_BATCH: "/create-batch",
  ASSIGN_TO_BATCH: "/assign-batch",
  ASSIGN_TEACHER: "/assign-teacher",
  BATCH_DETAIL: "/get-detail-batch",
  SCORE_BOARD:"/score-board",
  BATCH_UPDATE: "/update-batch",
  STUDENTS_IN_BATCH: "/list-student-batch",
  STUDENTS_CHECKIN_BATCH: "/roll-call-for-student",
  REMOVE_STUDENT_TO_BATCH: "/remove-batch",
  UPDATE_POINT_STUDENT: "/update-point-student",
  ACCEPT_ROLL_CALL: "/accept-reasons",
  HISTORIES_HOMEWORK: "/record-homework-for-student",
};

export const ROADMAP = {
  GET_ALL_ROAD_MAP: "/get-all-roadmap",
  GET_ROADMAP: "/roadmaps",
  CREATE_ROADMAP: "/roadmaps",
  UPDATE_ROADMAP: "/roadmaps",
  DETAIL_ROADMAP: "/roadmaps",
};

export const EXAM = {
  GET_ALL_SUBJECT: "/subjects",
  CREATE_QUESTION: "/questions",
  CREATE_EXAM: "/exams",
  DETAIL_EXAM: "/get-detail-exam",
  UPDATE_EXAM: "/update-exam",
  QUESTIONS: "/questions",
  LIST_EXAM: "/exams",
  EXAM_REPORT: "/exam-report",
};

export const COURSE = {
  LIST_COURSE: "/courses",
  CREATE_COURSE: "/courses",
  UPDATE_COURSE: "/courses",
  DETAIL_COURSE: "/find-course-detail",
  SUBJECTS: "/subjects",
  PREVIEW_COURSE: 'generate-token-preview-course',
  REPORT_COURSE: '/coursePointStudent/report-point',
  COURSE_POINT_OF_STUDENT: '/coursePointStudent/store',
  COURSE_POINT_STUDENT:'/coursePointStudent/create',
  COURSE_POINT_STUDENT_GET:'/coursePointStudent',
  COURSE_POINT_STUDENT_DELETE:'/coursePointStudent/destroy',
  COURSE_POINT_STUDENT_UPDATE:'/coursePointStudent/update'
};
export const MATERIAL = {
  LIST_MATERIAL: "/materials",
  CREATE_MATERIAL: "/materials",
  UPDATE_MATERIAL: "/materials",
  DETAIL_MATERIAL: "/materials",
};
export const SECTION = {
  LIST_SECTION: "/sections",
  CREATE_SECTION: "/sections",
  GET_MATERIAL_BY_SECTION: "/get-material-by-section",
  DETAIL_SECTION: "/sections",
  UPDATE_SECTION: "/sections",
};
export const COMMON = {
  GET_FILES: "/get-list-file",
  GET_FILE: "/get-file",
  GET_FILE_DETAIL: "/file-detail",
  UPLOAD_FILE: "/upload-file",
  UPDATE_FILE: "/update-file",
  CREATE_FILE: "/store-file",
  ASSIGN_FILE: "/assignment-files",
  ASSIGNMENTS: "/assignments",
  MARKS_SCORE: "/mark-assignment-file",
  ASSIGNMENT_TEACHER: "assignment-trainer/create",
  ASSIGNMENT_TEACHER_DELETE: "assignment-trainer/destroy",
  TOPICS: "comments",
  DELETE_TOPIC: "delete-topic-admin",
  MARKS_EXERCISE: "/marking-exercise",
};
export const MODULE = {
  GET_MODULES: "/modules",
  CREATE_MODULE: "/modules",
  UPDATE_MODULE: "/modules",
  DETAIL_MODULE: "/modules",
};
export const USERS = {
  GET_LIST_USER: "/list-user",
  GET_LIST_TEACHER: "/list-teacher",
  GET_USER: "/get-detail-user",
  RESET_PASSWORD_USER: "/reset-password-user",
  CREATE_USER: "/create-user",
  UPDATE_USER: "/update-user",
  DELETE_USER: "/delete-user",
  REMOVE_TEACHER_FROM_BATCH: "/remove-teacher-from-batch",
  LIST_PERMISSION: "/permissions",
  UPDATE_PERMISSION: "/permissions",
  UPDATE_ROLE_PERMISSION: "/update-permission-roles",
  LIST_ROLE: "/roles",
  CREATE_ROLE: "/roles",
  DELETE_ROLE: "/roles",
  UPDATE_ROLE: "/roles",
};
export const REPORT_MATERIAL = {
  RATTING: "/material-ratings",
  STUDY: "/get-list-report",
  LIST_FILE_BY_STUDENT: "/get-list-student-file",
  STUDENT_MATERIAL_RATTING: "/get-student-material-ratings",
  STUDENT_ROLL_CALL_REPORT: "/list-roll-call-reports-by-date",
  ROLL_CALL_STATUS: "/roll-call-master-status",
  ROLL_CALL_BY_MONTH: "list-statistics-by-month",
  REPORT_QUIZ_DETAIL: "get-report-quiz-detail",
  REPORT_EXERCISE_DETAIL: "get-report-exercise-detail",
  HISTORIES_HOMEWORK: "/histories-homework-for-student",
};

export const EVENT = {
  LIST_EVENTS: "/list-events",
  CREATE_EVENT: "/create-events",
  DELETE_EVENT_IN_DAY: "/destroy-events-in-day",
  DELETE_EVENT: "/delete-events",
  GET_DETAIL_EVENT: "/detail-events",
  UPDATE_EVENT: "/update-events",
};
export const FACILITY = {
  CREATE_FACILITY: "/branches",
  GET_DETAIL_FACILITY: "/branches",
  GET_LIST_FACILITY: "/branches",
  DELETE_FACILITY: "/branches",
  UPDATE_FACILITY: "/branches",
  ADD_USERS_TO_FACILITY: "/add-user-to-branch",
  ADD_CLASS_TO_FACILITY: "/add-batch-to-branch",
  REMOVE_USER_FROM_FACILITY: "/remove-user-from-branch",
  REMOVE_CLASS_FROM_FACILITY: "/remove-batch-from-branch",
};

export const ROOM = {
  CREATE_ROOM: "rooms",
  GET_LIST_ROOM: "rooms",
  DELETE_ROOM: "rooms",
  GET_DETAIL_ROOM: "rooms",
  UPDATE_ROOM: "rooms",
};

export const SCHEDULE = {
  CREATE_SCHEDULE: "/schedules",
  GET_LIST_SCHEDULE: "/schedules",
  DELETE_SCHEDULE: "/schedules",
  DETAIL_SCHEDULE: "/schedules",
  UPDATE_SCHEDULE: "/schedules",
};

export const SURVEY = {
  CREATE_SURVEY: "create-survey",
  GET_LIST_SURVEY: "list-survey",
  GET_DETAIL_SURVEY: "survey-detail",
  UPDATE_SURVEY: "update-survey",
  DELETE_SURVEY: "delete-survey",
  CREATE_SURVEY_QUESTION: "create-survey-question",
  DELETE_SURVEY_QUESTION: "delete-survey-question",
  UPDATE_SURVEY_QUESTION: "update-survey-question",
};

export const SETTING = {
  CREATE_SYSTEM_INFO: "system-infos",
  GET_SYSTEM_INFO: "system-infos",
  DELETE_SYSTEM_INFO: "system-infos",
  UPDATE_SYSTEM_INFO: "system-infos",
};

export const BLOG = {
  CREATE_BLOG: "posts",
  GET_LIST_BLOG: "posts",
  GET_DETAIL_BLOG: "posts",
  UPDATE_BLOG: "posts",
  DELETE_BLOG: "posts",
};

<template>
  <div class="w-100">
    <label v-if="showLabel"> <span class="text-danger">*</span> Mô đun</label>
    <vSelect
        v-model="moduleId"
        placeholder="Lựa chọn module"
        :disabled="disableSelect"
        :options="modules"
        label="title"
        :class="{'is-invalid': validation}"
        :reduce="(module) => module.id"
        class="w-100"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import {GetListModule} from "@/services/course";
import CONFIG_APP from "@/configs";

export default {
  expose: ['resetData', 'searchByRoadmap'],
  name: "Module-select",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn mô đun.'},
    validation: {type: Boolean, default: false},
    modelValue: String || Number,
    idOld: {type: Array, default: null},
    showLabel: {type: Boolean, default: true},
  },
  data() {
    return {
      moduleId: null,
      hasNextPage: false,
      disableSelect: false,
      modules: [],
      page: 1,
      roadmap_id: null
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {
    moduleId() {
      this.$emit('selected', this.moduleId)
      this.$emit('update:modelValue', this.moduleId)
    },
    modules() {
      this.moduleId = this.modelValue
    }
  },
  created() {
    this.getModule()
  },

  methods: {
    getModule(params) {
      this.hasNextPage = true
      this.disableSelect = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page}
      }
      if (this.idOld) {
        params.per_page = this.per_page + this.idOld.length
      }
      GetListModule(params).then(e => {
        if (e.data.status === 'success') {
          let data = e.data.data.modules.data
          if (this.idOld) {
            this.idOld.map(id => {
              let index = data.findIndex(el => el.id === id)
              if (index > -1) {
                data.splice(index, 1)
              }
            })
          }
          this.modules = data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        console.log(err)
        this.disableSelect = false
      })
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      let params = {per_page: this.per_page, page: 1, keyword: query, roadmap_id: this.roadmap_id}
      this.getModule(params)
    },
    resetData() {
      this.moduleId = null
    },
    searchByRoadmap(id) {
      this.hasNextPage = true
      this.roadmap_id = id
      let params = {per_page: this.per_page, page: 1, roadmap_id: id}
      this.getModule(params)
    },
  },

}
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}

::v-deep .vs__actions {
  button {
    font-size: 8px !important;
  }
}
</style>

<template>
  <div id="rollCallBatches" class="bg-white p-3">
    <div class="py-2 text-end">
      <el-row :gutter="20" :align="10">
        <el-col :span="6" class="pb-3">
          <el-input
              placeholder="Tên học sinh"
              v-model="setup.keyword"
          />
        </el-col>
        <el-col :span="6">
          <batchSelect v-model="setup.batch_id"  :show-label="false" :is-multiple="false"></batchSelect>
        </el-col>
        <el-col :span="6">
          <el-select v-model="setup.accepted_status" placeholder="Tìm kiếm theo trạng thái" class="w-100">
            <el-option
                v-for="item in configs.acceptedStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="6" class="pb-3">
          <handle-button
              label="Tìm kiếm"
              icon="fa fa-search"
              eventCode="off-learn-reasons.index"
              typeButton="primary"
              @click="searchStudent"
          />
          <handle-button
              label="Làm mới"
              icon="refresh"
              typeButton="warning"
              eventCode="off-learn-reasons.index"
              @click="refreshSearch"
          />
        </el-col>
      </el-row>

    </div>
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border @select="handleSelected"
              @selection-change="handleSelectAll"
    >
      <el-table-column type="selection" width="40" :selectable="checkedIn"/>
      <el-table-column fixed prop="id_reason" label="ID" min-width="40" sortable/>
      <el-table-column fixed prop="name_student" label="Tên học sinh" max-width="200" sortable/>
      <el-table-column fixed prop="name_class" label="Tên lớp" min-width="70" sortable/>
      <el-table-column fixed prop="type" label="Loại hình" min-width="70" sortable/>
      <el-table-column fixed prop="reason" label="Lý do" min-width="150">
        <template #default="scope">
          <div class="text-truncate" :title="scope.row.reason">{{ scope.row.reason }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed prop="date" label="Ngày tạo y/c" min-width="100" sortable/>
      <el-table-column
          prop="accepted_status"
          label="Trạng thái"
          width="120"
      >
        <template #default="scope">
          <el-tag
              :type="scope.row.accepted_status === configs.acceptedStatus[2].label ? 'danger' : scope.row.accepted_status === configs.acceptedStatus[1].label?'success':'primary'"
              disable-transitions>
            {{ scope.row.accepted_status }}
          </el-tag>
        </template>
      </el-table-column>

    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
    <div class="pb-3 mt-3 text-center">
      <handle-button
          label="Chấp nhận"
          icon="fa fa-thumbs-up"
          typeButton="success"
          eventCode="off-learn-reasons.accept-reason"
          @click="openConfirm(configs.acceptedStatus[1].label)"
          :disabled="selected.length === 0"
      />
      <handle-button
          label="Từ chối"
          icon="fa fa-thumbs-down"
          eventCode="off-learn-reasons.accept-reason"
          typeButton="danger mr-2"
          @click="openConfirm(configs.acceptedStatus[2].label)"
          :disabled="selected.length === 0"
      />
    </div>

  </div>


</template>
<script>
import {ElMessageBox} from 'element-plus'
import {AcceptRollCall, GetRollCallBatches} from "@/services/batches";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import batchSelect from "@/views/Batches/batchSelect";

export default {
  name: "rollCallBatches",
  mixins: [setBreadcrumb],
  components: {pagination, batchSelect},
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      selected: [],
      configs: configs,
      nameRouter: NameRouter,
      setup: {
        accepted_status: 0,
      },
      isOpenConfirm: false,
    };
  },
  watch: {},

  created() {
    let query = this.$router.currentRoute?.value?.query;
    let {
      accepted_status, page
      , per_page
    } = query;
    if (accepted_status || page
        || per_page) {
      this.setup = query
      this.getRollCallBatches({...query})
    } else {
      this.getRollCallBatches({
        ...this.setup, ...this.$route.query,
        per_page: configs.itemOnPage,
        page: this.currentPage
      })
    }

    this.getRollCallBatches({...this.setup, ...this.$route.query, per_page: configs.itemOnPage, page: this.currentPage})
  },
  methods: {
    checkedIn(row, index) {
      if (row.accepted_status === this.configs.acceptedStatus[1].label || row.accepted_status === this.configs.acceptedStatus[2].label) {
        return false;
      } else {
        return true;
      }

    },
    getRollCallBatches(param) {
      this.loading = true;
      if (!param) {
        param = {per_page: configs.itemOnPage, page: this.currentPage};
      }
      GetRollCallBatches(param).then(e => {
        let dataTable = [];
        if (e.data.status == 'success') {
          e.data.data?.offLearnReasons?.data.map((e, index) => {
            const indexStatus = e.accepted_status;
            const findAcceptStatus = configs.acceptedStatus.find(i => i.value === indexStatus)
            dataTable.push({
              id_reason: e.id,
              id_student: e.student_id,
              reason: e.reason,
              accepted_status: findAcceptStatus.label,
              name_student: e.student.name,
              name_class: e.batch_student?.batches.batch_name,
              date: e.date ? formatDate(e.date) : '',
              type: e.type ? configs.typeReason[e.type] : '',
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data?.offLearnReasons.total
          this.isShowPagination = e.data.data?.offLearnReasons.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex((id_reason) => row.id_reason == id_reason);

      if (indexItem > -1) {
        this.selected.splice(indexItem, 1);
      }
      if (indexItem < 0) {
        this.selected.push(row.id_reason);
      }


    },
    handleSelectAll(val) {
      if (val.length > 0) {
        val.map((e) => {
          if (this.selected.findIndex((id_reason) => e.id_reason == id_reason) > -1) return;
          this.selected.push(e.id_reason);
        });
      } else {
        this.selected = []
      }
    },
    acceptReason() {
      if (this.selected.length === 0) {
        notifyErr("Chưa có yêu cầu nào được chọn")
      } else {
        let params = {
          reasonIds: this.selected,
          accepted_status: 1
        }

        AcceptRollCall(params).then(e => {
          notifySuccess("edit")
          this.getRollCallBatches()
        }).catch(err => {
          this.loading = false;
          notifyErr()
        })
      }
    },
    rejectReason() {
      if (this.selected.length === 0) {
        notifyErr("Chưa có yêu cầu nào được chọn")
      } else {
        let params = {
          reasonIds: this.selected,
          accepted_status: 2
        }
        AcceptRollCall(params).then(e => {
          notifySuccess("edit")
          this.getRollCallBatches()
        }).catch(err => {
          this.loading = false;
          notifyErr()
        })
      }
    },
    searchStudent() {
      this.currentPage = 1;
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.$router.push({path: location.pathname, query: params})
      this.getRollCallBatches(params);
    },
    refreshSearch() {
      this.currentPage = 1;
      this.setup = {
        keyword: null,
      };
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.$router.push({path: location.pathname, query: {}})
      this.getRollCallBatches(params);
    },
    nextPage(index) {
      this.currentPage = index
      let params = this.setup;
      params.page = index;
      params.per_page = configs.itemOnPage;
      this.getRollCallBatches(params);
    },
    openConfirm(type) {
      ElMessageBox.confirm(
          'Bạn có chắc chắn với hành động này không ?',
          type,
          {
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
            type: 'primary',
          }
      )
          .then(() => {
            if (type == this.configs.acceptedStatus[2].label) {
              this.rejectReason();
              this.setup.accepted_status = null;
            } else {
              this.acceptReason();
              this.setup.accepted_status = null;
            }
          })
    }

  }
}


</script>
<script setup>
import {ref} from "vue";

const dialogAdd = ref()
</script>
<style scoped lang="scss">

</style>

import {ElNotification} from "element-plus";
import moment from "moment";

// check router redundant
export const checkCurrentRouteAndRedirect = {
    methods: {
        checkCurrentRouteAndRedirect(router, name) {
            const {
                currentRoute: {name: curPath},
            } = router;
            if (curPath !== name) router.push({name: name});
        },
    },
};

export const setBreadcrumb = {
    created() {
        const arrayBreadcrumb = this.$route.matched.map(i => ({
            nameBreadcrumb: i.meta.alias,
            pathBreadcrumb: i.path,
        }))
        this.$store.dispatch("breadcrumbSet", arrayBreadcrumb)
    },
};

// custom formDta
/**
 *
 * @param data
 * @returns {FormData}
 */
export const formData = (data, isObj) => {
    return Object.keys(data).reduce((form, key) => {
        if (data[key]) {
            form.append(key, data[key]);
            if (!isObj) {
                if (typeof data[key] === "object" && data[key] !== null) {
                    Object.keys(data[key]).reduce((form2, key2) => {
                        form.append(`${key}.${key2}`, data[key][key2]);
                    });
                }
            }
        }
        return form;
    }, new FormData());
};

// check file image
/***
 *
 * @param file
 * @returns {boolean}
 */
export const isImage = (file) => {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
    return file && acceptedImageTypes.includes(file["type"]);
};
export const isExcel = (file) => {
    const acceptedImageTypes = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return file && file["type"] == acceptedImageTypes;
};

export const removeFile = (data, refs, id) => {
    if (refs === "avatar") {
        data[refs].clearFiles();
        return "avatar";
    }
    let indexFiles = -1;
    for (let i = 0; i < data[refs].uploadFiles.length; i++) {
        if (data[refs].uploadFiles[i].uid == id) {
            indexFiles = i;
            break;
        }
    }
    if (indexFiles > -1) {
        data[refs].uploadFiles.splice(indexFiles, 1);
        return true;
    }
    return false;
};

/**
 *
 * @param value
 * @returns {string}
 */
export const formatNumber = (n) => {
    let money = parseInt(n);
    if (isNaN(money)) return 0 + " " + "VND";
    money = money.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
    });

    return money;
};

/**
 *
 * @param message
 * @returns {NotificationHandle}
 */
export const notifyErr = (message) => {
    return ElNotification.error({
        title: "Thất bại",
        message: message ?? "Lỗi hệ thống",
    });
};
/**
 *
 * @param type {edit, add, delete, editImage}
 * @param messageCus
 * @returns {NotificationHandle}
 */
export const notifySuccess = (type, messageCus) => {
    let message = "";
    switch (type) {
        case "add":
            message = messageCus ?? "Thêm mới thành công.";
            break;
        case "edit":
            message = "Thay đổi thành công.";
            break;
        case "delete":
            message = "Xóa thành công.";
            break;
        case "editImage":
            message = "Thay đổi ảnh thành công.";
            break;
        case "login":
            message = "Đăng nhập thành công.";
            break;
        default:
            message = "Lưu thành công.";
    }
    return ElNotification.success({
        title: "Thành Công",
        message: message,
    });
};

/**
 *
 * @param message
 * @returns {NotificationHandle}
 */
export const notifyWarning = (message) => {
    return ElNotification.warning({
        title: "Thất bại",
        message: message ?? "Lỗi hệ thống.",
    });
};

export const formatDate = (date) => {
    if (date) return moment(date).format("DD/MM/yyyy");
    else return "Thời gian không hợp lệ";
};
export const formatDateTime = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
};

export const formatDataServe = (date) => {
    let dateNew = Date.parse(date)
    let a = moment(dateNew).format("YYYY-MM-DD")
    return a;
};

export const getDefaultDate = () => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    return [firstDay.toLocaleDateString('en-CA'), lastDay.toLocaleDateString('en-CA')]
}
/**
 *
 * required all filed into form
 * @returns {NotificationHandle}
 */
export const notifyFiled = () => {
    return ElNotification.error({
        title: "Thất bại",
        message: "Lỗi upload file",
    });
};

export const formatMoney = (n) => {
    let money = parseInt(n);
    if (isNaN(money)) return 0 + " " + "VND";
    money = money.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
    });

    return money;
};

const digitsRE = /(\d{3})(?=\d)/g;

export function currency(value, currency, decimals) {
    value = parseFloat(value);
    if (!isFinite(value) || (!value && value !== 0)) return "";
    currency = currency != null ? currency : "$";
    decimals = decimals != null ? decimals : 2;
    let stringified = Math.abs(value).toFixed(decimals);
    let _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
    let i = _int.length % 3;
    let head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
    let _float = decimals ? stringified.slice(-1 - decimals) : "";
    let sign = value < 0 ? "-" : "";
    return (
        sign + currency + head + _int.slice(i).replace(digitsRE, "$1,") + _float
    );
}

export const changeHTMLtoParagraph = (string) => {
    let span = document.createElement("span");
    span.innerHTML = string;
    return span.textContent || span.innerText;
};
export const changeMoneyToText = (value) => {
    if (value > 0) {
        const money = value / 1000000;
        if (money >= 1000) return money + " " + "tỷ";
        else if (money >= 1) return money + " " + "triệu";
        else if (money >= 0.001) return money * 1000 + " " + "nghìn";
        else if (Math.floor(value) === value) return this.formatMoney(value);
    }
};

export const getToday = () => {
    return moment().format('YYYY-MM-DD')
};
export const getNow = () => {
    return moment().format('HH:MM')
};
export const convertToSlug = (str) => {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
    str = str.replace(/\u02C6|\u0306|\u031B/g, "");
    str = str.split(" ").join("-")
    return str;
}
<template>
  <el-popconfirm
      v-if="showButton|| isAdmin"
      confirm-button-text="Xác nhận"
      cancel-button-text="Hủy"
      :icon="InfoFilled"
      icon-color="#626AEF"
      title="Bạn có chắc muốn xóa."
      @confirm="$emit('click')"
  >
    <template #reference>
      <button class="btn btn-danger py-1"  style="font-size: 12px"><i class="fa fa-trash"></i> {{ label }}</button>
    </template>
  </el-popconfirm>
</template>
<script>
export default {
  name: "PopConfirm",
  props: {
    eventCode: {type: String},
    label: {type: String}
  },
  data() {
    return {
      showButton: true,
      role: this.$helpers.getRole()
    }
  },
  computed: {
    isAdmin() {
      return this.$helpers.roleAdmin === this.role.is_admin
    }
  },
  created() {
    let permissions = this.$helpers.getPermission()
    this.showButton = permissions.find(el => el.route_name === this.eventCode) ?? false
  }
}
</script>
<script setup>
import {InfoFilled} from '@element-plus/icons-vue'

</script>
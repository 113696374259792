<template>
  <div class="bg-white p-3 rounded">
    <infoAssignment :form="form" ref="infoAssign"></infoAssignment>
    <div class="py-3 text-center">
      <handle-button
          label="Tạo bài kiểm tra"
          eventCode="assignments.show"
          icon="fa fa-plus"
          typeButton="primary"
          @click="createAssignment"/>
    </div>
  </div>
</template>
<script>

import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {CreateAssignment} from "@/services/common";
import {NameRouter} from "@/constants/nameRouter";
import infoAssignment from "@/views/AssigmentFile/infoAssignment";

export default {
  name: 'CreateAssignment',
  components: {
    infoAssignment
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      form: {
        courseId: null,
        batchId: null,
        description: '',
        toDate: '',
        fromDate: '',
        name: '',
        deadline: '',
        isActive: 1,
        isHackathon: 1,
      },
      descriptionValid: false,
    }
  },
  watch: {},
  methods: {
    async createAssignment() {
      let validCourse = await this.$refs.infoAssign.validForm()
      if (!validCourse) return false
      let assignment = {
        title: this.form.name,
        description: this.form.description,
        batch_id: this.form.batchId,
        course_id: this.form.courseId,
        from_date: this.form.fromDate,
        to_date: this.form.toDate,
        // deadline: this.form.deadline,
        is_active: this.form.isActive,
        is_hackathon: this.form.isHackathon
      }
      await CreateAssignment(assignment).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('add')
          this.$router.push(NameRouter.PAGES.ASSIGNMENT_MANAGER)
        }
      }).catch(err => {
        return notifyErr()
      })
      event.preventDefault()
    },
  }
}
</script>
<script setup>
import {Delete, Plus} from '@element-plus/icons-vue'
import {ref} from "vue";
import messages from "@/constants/messages";
import configs from "@/configs";

const avatar = ref()
</script>
<style scoped lang="scss">
:deep(.is-error) {
  .tox-tinymce {
    border-color: red;

  }
}

</style>

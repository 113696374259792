<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
    <el-tab-pane label="Thêm mới chương học" name="first">
      <infoSection ref="sectionForm" :form="form"></infoSection>
      <div class="text-center py-4">
        <handle-button
            label="Tạo chương"
            eventCode="sections.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createSection"/>

      </div>
    </el-tab-pane>
    <el-tab-pane label="Thêm mới nội dung" name="content">
      <listMaterialCheckBox ref="materialSection" v-model="setup.materials"></listMaterialCheckBox>
      <div class="text-center py-4">
        <handle-button
            label="Tạo chương"
            eventCode="sections.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createSection"/>

      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import infoSection from "@/views/ManagementSection/infoSection";
import listMaterialCheckBox from "@/views/ManagementMaterial/listMaterialCheckBox";
import {CreateSection} from "@/services/course";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'CreatSection',
  components: {
    infoSection,
    listMaterialCheckBox,
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      setup: {
        materials: [],
        activeName: 'first',
      },
      form: {
        course_id: null,
        name: '',
        duration: '',
        is_active: 1,
        sort_order: '',
      }
    }
  },
  methods: {
    async createSection() {
      let valid = await this.$refs.sectionForm.validForm()
      if (!valid) return false
      let validMaterial = await this.$refs.materialSection.validMaterial()
      if (!validMaterial) {
        this.setup.activeName = 'content'
        return false
      }
      let materials = []
      this.setup.materials.map((el, index) => {
        materials.push({id: el, sort_order: index + 1})
      })
      this.form.materials = materials
      let obj = {
        course_id: this.form.course_id,
        sections: [this.form],
      }
      CreateSection(obj).then(async res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm chương học thành công')
          await this.$router.push(NameRouter.PAGES.LIST_SECTION)
        }
      }).catch(err => {
        notifyErr()
      })
    },
  }
}
</script>
<script setup>
import {Check} from "@element-plus/icons-vue";
</script>


<template>
    <el-table :data="tablePointStd" style="width: 100%; margin-bottom: 20px;" fixed v-loading="loading" border>
        <el-table-column prop="index" label="Tên Module" width="200" />
        <el-table-column prop="daySchool" label="Ngày đi học" width="200" />
        <el-table-column prop="dayHouseWork" label="Ngày làm bài kiểm tra" width="200" />
        <el-table-column prop="dayStydy " label="Tổng ngày học" width="150" />
        <el-table-column prop="pointHackathon" label="Điểm hackathon" width="200" />
        <el-table-column prop="pointTeacher" label="Điểm thầy giáo" width="200" />
        <el-table-column prop="pointGuest" label="Điểm chuyên gia" width="200" />
        <el-table-column prop="rankOnClass" label="Xếp hạng" />
    </el-table>
</template>
<script>
import configs from "@/configs";
import { NameRouter } from "@/constants/nameRouter";
import { GetHistoryPointStudent } from "@/services/batches";
import { notifyErr, getDefaultDate, formatDataServe, formatDate } from "@/mixins/utils";
import moment from "moment";

export default {
    name: "point-Student",
    data() {
        return {
            tablePointStd: [],
            loading: false,
            configs: configs,
            nameRouter: NameRouter,
            value: [],
        };
    },

    methods: {

    },
};
</script>
<script setup>


</script>
<style scoped></style>
  
<template>
  <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm">
    <div class="pb-3">
      <courseSelect v-model="form.course_id" :validation="validation" ref="courseSelectBox"></courseSelect>
    </div>
    <el-form-item class="w-100" prop="name" label="Tên chương">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item class="w-100" prop="code" label="Mã code">
      <el-input v-model="form.code"></el-input>
    </el-form-item>
    <el-form-item label="Ví trí" prop="sort_order">
      <el-input v-model.number="form.sort_order"></el-input>
    </el-form-item>

    <el-form-item label="Trạng thái hoạt động">
      <el-radio-group v-model="form.is_active">
        <el-radio :label="configs.status.active" size="lage" border>
          {{ configs.status.activeStr }}
        </el-radio>
        <el-radio :label="configs.status.inActive" size="lage" border>
          {{ configs.status.inActiveStr }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
import {checkIsInteger, textInputValidate} from "@/validator";
import courseSelect from "@/views/Course/courseSelect";

export default {
  expose: ['validForm', 'queryCourseByName'],
  name: "InfoSection",
  components: {
    courseSelect
  },
  props: {
    form: Object
  },
  data() {
    return {
      validation: false,
      addContentRule: {
        name: [textInputValidate],
        code: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger]
      },
    }
  },
  methods: {
    async validForm() {
      this.validation = this.form.course_id === ''
      let valid = await this.$refs.contentForm.validate((valid, fields) => {
        if (!valid || this.form.course_id == '') {
          return false;
        }
      })
      if (!valid) return false;
      return true
    },
    queryCourseByName(name){
      this.$refs.courseSelectBox.queryByName(name)
    }
  }
}
</script>
<script setup>
import configs from "@/configs";
</script>
<style scoped>

</style>

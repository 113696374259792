<template>
  <div id="management-course" class="bg-white p-3">
    <div class="box-search">
      <i>Tìm kiếm theo mục đích</i>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="setup.keyword" placeholder="Tìm kiếm mô đun"/>
        </el-col>
        <el-col :span="6">
         <roadmap-select v-model="setup.roadmap_id"/>
        </el-col>
        <el-col :span="8">
          <div class="d-flex">
            <handle-button
                typeButton="primary"
                eventCode="modules.index"
                @click="searchData"
                label="Tìm kiếm"/>


            <handle-button
                type="link"
                label="Tạo bài mô đun"
                eventCode="modules.store"
                icon="fa fa-plus"
                typeButton="primary"
                :to="{ name: nameRouter.PAGES.CREATE_MODULE}"/>

            <handle-button
                typeButton="warning"
                eventCode="modules.index"
                @click="reload"
                label="Làm mới"/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="py-2" v-if="!isPass"><span class="text-danger">Vui lòng chọn mô đun</span></div>
    <el-table
        :data="tableData"
        @select="handleSelected"
        @selection-change="handleSelectAll"
        style="width: 100%"
        border
        ref="modules"
        fixed v-loading="loading">
      <el-table-column type="selection" width="55" v-if="showCheckbox"/>
      <el-table-column prop="id" label="Id" min-width="50"/>
      <el-table-column
          label="Hình ảnh"
          width="150">
        <template #default="scope">
          <el-image :src="scope.row.avatar" style="width: 100px; height: 100px" :fit="fit">
            <div slot="placeholder" class="image-slot">
              Loading<span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Tên module" min-width="150"/>
      <el-table-column prop="code" label="Mã code" min-width="50"/>
      <el-table-column prop="summary" label="Tóm tắt" min-width="150">
        <template #default="scope">
          <div v-html="scope.row.summary"></div>
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="Thời gian học(phút)" min-width="70"/>
      <el-table-column
          label="Hành động"
          fixed="right"
          min-width="80">
        <template #default="scope">
          <handle-button
              type="link"
              label="Chi tiết"
              eventCode="modules.show"
              icon="fa fa-eye"
              typeButton="primary"
              :to="{name: nameRouter.PAGES.DETAIL_MODULE, params: {id: scope.row.id}}"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListModule} from "@/services/course";
import roadmapSelect from "@/components/roadmapSelect";
export default {
  name: "Module-checkbox",
  expose: ['validModule'],
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: true}
  },
  components: {
    pagination,
    roadmapSelect
  },
  data() {
    return {
      isPass: true,
      tableData: [],
      selected: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      nameRouter: NameRouter,
      setup: {
        keyword: null,
        roadmap_id: null,
        module_id: null,
        course_id: null,
        section_id:null
      }
    };
  },
  watch: {
    selected() {
      this.$emit('update:modelValue', this.selected)
    },
    tableData: {
      handler(newTable) {
        let that = this
        that.$nextTick(function () {
          that.toggleSelection()
        })
      },
      immediate: true
    }
  },

  created() {
    let query = this.$router.currentRoute?.value?.query;
    let {keyword,roadmap_id,module_id,course_id,section_id,per_page , page} = query;
    if (keyword||roadmap_id||module_id||course_id|section_id || per_page || page) {
      this.setup =query
      this.getModules()
    } else {
      this.getModules()
    }

  },
  methods: {
    getModules() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage,...this.setup}
      GetListModule(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.modules.data.map(e => {
            dataTable.push({
              id: e.id,
              code: e.code,
              title: e.title,
              avatar: e.full_image_url,
              duration: e.duration && e.duration > 0 ? e.duration / 60 : 0,
              summary: e.description,
              level: e.level,
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.modules.total
          this.isShowPagination = e.data.data.modules.total > 0
          this.selected = this.modelValue
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getModules(index);
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.selected.push(row.id)
      }
      this.$emit('update:modelValue', this.selected)
    },
    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.selected.findIndex(id => e.id == id) > -1) return
          this.selected.push(e.id)
        })
        this.$emit('update:modelValue', this.selected)
      }
    },
    toggleSelection(table) {
      if (this.selected.length < 1) {
        return false
      }
      this.selected.forEach(e => {
        let item = this.tableData.find(item => e == item.id)
        if (!item) return false;
        this.$refs.modules.toggleRowSelection(item);
      })
    },
    validModule() {
      if (this.selected.length < 1) {
        this.isPass = false
      } else {
        this.isPass = true
      }
      return this.isPass
    },
    searchData() {
      this.currentPage = 1
      this.getModules();
      this.$router.push({path: location.pathname, query: this.setup})

    },
    reload() {
      this.setup.keyword = null
      this.setup.roadmap_id = null
      this.getModules()
      this.$router.push({path: location.pathname, query: {}})

    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {
  Check,
  Plus,
  Edit,
  Close,
  Search,
  Star,
} from '@element-plus/icons-vue'

const disabledDate = (time, data) => {

  return time.getTime() > Date.now()
}
const dialogAdd = ref()
</script>
<style scoped lang="scss">
::v-deep .cell {
  word-break: break-word !important;
}
</style>


<template>
  <div id="addStudentsToBatch">
    <div class="bg-white p-3">
      <div class="search py-2">
        <el-row :gutter="10">
          <el-col :span="6" :offset="8" class=" text-right">
            <el-input v-model="setup.keyword" placeholder="Tìm kiếm người dùng" />
          </el-col>
          <el-col :span="10" class="box-search text-right">
            <div class="d-flex">
              <button class="mx-3 py-1 btn btn-primary" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm</button>
              <handle-button type="link" label="Chi tiết lớp" eventCode="batches.get-detail-batch" icon="fa fa-edit"
                typeButton="primary" :to="{ name: nameRouter.PAGES.BATCH_DETAIL, params: { id: idBatch } }" />
              <handle-button type="link" label="Thêm học viên" eventCode="batches.assign-batch" icon="fa fa-edit"
                typeButton="primary" :to="{ name: nameRouter.PAGES.ADD_STUDENT_TO_BATCH, params: { id: idBatch } }" />
              <button class="btn btn-warning mx-3 py-1" @click="reload"> Làm mới</button>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="d-flex pt-3">
        <h5>Danh sách giáo viên</h5>
      </div>
    </div>
    <el-table class="px-3 pb-3" :data="tableData" style="width: 100%" ref="studentTable" border v-loading="loading">
      <el-table-column prop="username" label="Tên đăng nhập" width="150" />
      <el-table-column prop="name" label="Họ tên" min-width="150" />
      <el-table-column prop="email" label="Email" min-width="150" />
      <el-table-column prop="role" label="Quyền hạn" min-width="100" />
      <el-table-column label="Tình trạng" width="150">
        <template #default="scope">
          <el-button type="warning" size="small" v-if="scope.row.teacherHadClass">
            Đã có trong lớp
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="TT hoạt động" min-width="100">
        <template #default="scope">
          <el-button type="success" size="small" v-if="scope.row.isActive">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button type="warning" size="small" v-if="!scope.row.isActive">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column min-width="100">
        <template #default="scope">
          <el-button type="warning" size="small" @click="openDiagLog(scope.row)" :disabled="scope.row.teacherHadClass">
            Thêm vào lớp
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination :total="total" v-show="isShowPagination" :pageIndex="pageIndex" @currentPageChange="nextPage">
      </pagination>
    </div>
  </div>
  <el-dialog v-model="setup.dialogSetType" title="Thêm giáo viên với vai trò." width="30%">
    <div class="p-3">
      <el-select v-model="form.type" class="w-100">
        <div v-for="(type, index) in CONFIG_APP.typeTeacher" :key="type.key">
          <el-option :value="type.key" :label="type.value"
            v-if="idTeacherMain ? index > 0 : index <= CONFIG_APP.typeTeacher.length">
          </el-option>
        </div>
      </el-select>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="setup.dialogSetType = false">hủy</el-button>
        <el-button type="primary" @click="assignTeacher">Thêm vào lớp</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { AssignTeacherToBatch, GetListBatches } from "@/services/batches";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import { formatDate, notifyErr, notifySuccess, notifyWarning, setBreadcrumb } from "@/mixins/utils";
import { NameRouter } from "@/constants/nameRouter";
import { GetListTeacher } from "@/services/users";

export default {
  name: "AddTeacherIntoBatch",
  components: {
    pagination
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      idBatch: this.$route.params.id,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
      loading: false,
      selected: [],
      batch: {},
      idTeacherMain: '',
      teachersInBatch: [],
      setup: {
        dialogSetType: false,
        keyword: null
      },
      typeTeacherMain: 1,
      typeTeacherSupport: 2,
      typeTeacher: 1,
      form: {
        type: 2,
        batch_id: this.$route.params.id,
        teacher_id: '',
      }
    };
  },
  async created() {
    await this.getTeacher()
    await this.getBatches()
  },

  methods: {
    async getTeacher(params) {
      this.loading = true
      if (!params) {
        params = { per_page: configs.itemOnPage, page: this.currentPage, filter_active: configs.status.active }
      }
      GetListTeacher(params).then(e => {
        let dataTable = []
        let teacherInBatch = []
        let teacherHadClass = false, mainTeacher
        if (e.data.status === 'success') {
          let teachers = e.data.data.list_teacher.data
          teachers.map(e => {
            teacherHadClass = false
            mainTeacher = false
            if (e.batch_teacher && e.batch_teacher.length > 0) {
              e.batch_teacher.map(batch => {
                if (batch.type == configs.typeTeacher[0].key && batch.batch_id == this.idBatch) {
                  mainTeacher = true
                  this.idTeacherMain = e.id
                }
                if (batch.batch_id == this.idBatch) {
                  teacherHadClass = true
                }
              })

            }
            dataTable.push({
              id: e.id,
              username: e.username,
              email: e.email,
              name: e.name,
              type: e.type,
              teacherHadClass: teacherHadClass,
              mainTeacher: mainTeacher,
              role: configs.roles[e.role_level],
              isActive: e.is_active == configs.status.active,
            })
          })
          this.teachersInBatch = teacherInBatch
          this.total = e.data.data.list_teacher.total
          this.isShowPagination = e.data.data.list_teacher.total > 1
        }
        this.loading = false

        this.tableData = dataTable
      }).catch(err => {
        this.loading = false
      })
    },
    async nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      await this.getTeacher();

    },
    closeDialog() {
      this.setup.dialogSetType = false
      this.form = {
        type: this.typeTeacherSupport,
        batch_id: this.$route.params.id,
        teacher_id: '',
      }
    },
    getBatches() {
      let byId = { batch_id: this.$route.params.id }
      GetListBatches(byId).then(e => {
        if (e.data.status == 'success') {
          let batch = e.data.data.allBatch.data[0]
          if (!batch) {
            return this.$router.push({ name: NameRouter.PAGES.LIST_BATCHES })
          }
          this.batch = {
            name: batch.batch_name,
            start_date: formatDate(batch.start_date),
            end_date: formatDate(batch.end_date)
          }
        }
      }).catch(err => {
        notifyErr()
      })
    },
    openDiagLog(row) {
      if (row.teacherHadClass) {
        return notifyWarning('Giáo viên đã tồn tại trong lớp này.')
      }
      this.form.teacher_id = row.id
      if (!this.idTeacherMain) {
        return this.setup.dialogSetType = true
      }
      this.form.type = this.typeTeacherSupport
      this.assignTeacher()
    },
    assignTeacher(id) {
      this.loading = true
      AssignTeacherToBatch(this.form).then(e => {
        if (e.data.status == 'success') {
          notifySuccess('add', 'Thêm giáo viên vào lớp thành công.')
          this.setup.dialogSetType = false
          this.getTeacher();
        }

      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
      this.loading = false
    },
    searchData() {
      let params = {
        per_page: configs.itemOnPage,
        page: this.currentPage,
        search_name: this.setup.keyword ?? null
      }
      this.getTeacher(params);
    },
    reload() {
      let params = {
        keyword: null,
        per_page: configs.itemOnPage,
        page: 1,
      }
      this.currentPage = 1
      this.getTeacher(params)
    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
</script>
<style scoped lang="scss"></style>

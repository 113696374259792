<template>
  <courseCheckbox :show-checkbox="false" ></courseCheckbox>
</template>
<script>
import courseCheckbox from "@/views/Course/courseCheckbox";
import {setBreadcrumb} from "@/mixins/utils";

export default {
  name: 'List-Module',
  components: {
    courseCheckbox
  },
  mixins: [setBreadcrumb],
}
</script>
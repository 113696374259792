<template>
  <div id="managementPoint" class="bg-white p-3">
    <div class="py-2 text-end">
      <el-row :gutter="20" :align="10">
        <el-col :span="6" class="pb-3">
          <el-input
              placeholder="Tìm kiếm tên học sinh"
              v-model="setup.search_name"
          />
        </el-col>
        <el-col :span="6" class="pb-3">
          <el-input placeholder="Tìm kiếm email" v-model="setup.search_email"/>
        </el-col>
        <el-col :span="6" class="pb-3">
          <batch-select
              v-model="setup.search_batch"
              :show-label="false"
              :isMultiple="false"
              :add-option="true"
              ref="batchSelectBox"
          />
        </el-col>
        <el-col :span="6" class="pb-3">
          <el-select
              placeholder="Tìm kiếm trạng thái"
              class="w-100"
              v-model="setup.filter_active"
          >
            <el-option :value="null" label="Tất cả"/>
            <el-option
                :value="configs.status.active"
                :label="configs.status.activeStr"
            />
            <el-option
                :value="configs.status.inActive"
                :label="configs.status.inActiveStr"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="16" :span="8">
          <handle-button
              label="Tìm kiếm"
              eventCode="students.list-student"
              icon="fa fa-search"
              typeButton="primary"
              @click="searchStudent"
          />
          <handle-button
              label="Làm mới"
              eventCode="students.list-student"
              icon="refresh"
              typeButton="warning"
              @click="refreshSearch"
          />
        </el-col>
      </el-row>
    </div>
    <el-table
        :data="tableData"
        style="width: 100%"
        fixed
        v-loading.fullscreen.lock="loading"
        border
    >
      <el-table-column prop="index" label="TT " width="50"/>
      <el-table-column prop="id" label="ID " width="50"/>
      <el-table-column prop="name" label="Họ tên" min-width="100"/>
      <el-table-column prop="email" label="Email" min-width="150"/>
      <el-table-column prop="batchName" label="Tên lớp" min-width="100"/>
      <el-table-column label="TT hoạt động" width="150">
        <template #default="scope">
          <el-button type="success" size="small" v-if="scope.row.isActive">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button type="warning" size="small" v-if="!scope.row.isActive">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="point" label="Điểm" min-width="50"/>
      <el-table-column fixed="right" width="300" label="Hành động">
        <template #default="scope">
          <handle-button
              type-button="primary"
              icon="fa fa-edit"
              label="Sửa điểm"
              event-code="batches.update-point-student"
              @click="openDialogUpdate(scope)"
          />
          <handle-button
              type-button="primary"
              icon="fa fa-history"
              type="link"
              label="Lịch sử điểm"
              event-code="students.get-detail-student"
              :to="{
              name: nameRouter.PAGES.DETAIL_STUDENT,
              params: { id: scope.row.id },
              query: {activeName: 'historyPoint' }
            }"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
  <div class="editPoint">
    <el-dialog
        v-model="setup.dialogEdit"
        width="40%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        @closed="closeDialog()"
    >
      <template #title>
        <div class="text-center text-primary">
          <h4>Cập nhật điểm</h4>
        </div>
      </template>
      <el-form
          ref="formUpdate"
          class="login-form"
          :model="model"
          label-position="top"
          :rules="rulesForm"
      >
        <el-form-item prop="name" label="Họ tên: ">
          <el-input v-model="model.name" placeholder="Họ tên" disabled></el-input>
        </el-form-item>
        <el-form-item label="Loại điểm:" prop="typePoint" class="m-0">
          <el-radio-group v-model="model.typePoint">
            <el-radio :label="1">Điểm cộng</el-radio>
            <el-radio :label="2">Điểm trừ</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="typeReason" label="Mục:">
          <el-select class="w-100" v-model="model.typeReason">
            <el-option
                v-for="item in listReason"
                :key="item.id"
                :label="item.title"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="point" label="Số điểm: ">
          <el-input v-model="model.point" placeholder="Nhập số điểm"></el-input>
        </el-form-item>
        <el-form-item prop="note" label="Ghi chú:">
          <el-input class="w-100" v-model="model.note"/>
        </el-form-item>
      </el-form>
      <div class="mx-4 d-flex justify-content-center">
        <handle-button
            label="Hủy"
            eventCode=""
            icon="fa fa-minus"
            typeButton="danger"
            @click="closeDialog()"
        />

        <handle-button
            label="Cập nhật"
            eventCode="batches.update-point-student"
            icon="fa fa-plus"
            typeButton="primary"
            @click="updatePoint()"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetListStudents,
} from "@/services/students";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {
  notifyErr,
  notifySuccess,
  setBreadcrumb,
} from "@/mixins/utils";
import {
  textInputValidate,
} from "@/validator";
import {NameRouter} from "@/constants/nameRouter";
import batchSelect from "@/views/Batches/batchSelect";
import {GetSystemInfo} from "@/services/setting";
import {UpdatePointStudent} from "@/services/batches"

export default {
  name: "PointList",
  components: {
    batchSelect,
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      listReason: [],
      studentEditId: null,
      model: {
        name: "",
        typePoint: 1,
        point: "",
        typeReason: "",
        note: ""
      },
      setup: {
        search_name: null,
        search_phone: null,
        search_email: null,
        search_batch: null,
        filter_active: null,
        dialogEdit: false,
      },
      nameRouter: NameRouter,
      rulesForm: {
        point: [textInputValidate],
        typeReason: [textInputValidate],
      },
      configs: configs,
    };
  },

  created() {
    let query = this.$router.currentRoute?.value?.query;
    let {
      search_name,
      search_phone,
      search_email,
      search_batch,
      filter_active,
      dialogEdit
    } = query;
    if (search_name ||
        search_phone ||
        search_email ||
        search_batch ||
        filter_active ||
        dialogEdit) {
      this.setup = {...query,dialogEdit: false}
      this.getStudents(query);
    } else {
      this.getStudents();
    }

    this.getReasonPoint(this.model.typePoint)
  },


  watch: {
    'model.typePoint'(newValue) {
      if (!newValue) return false
      this.getReasonPoint(newValue);
    },
    'model.typeReason'(newValue) {
      if (!newValue) return false
      this.getPoint(newValue);
    },
  },

  methods: {
    getStudents(param) {
      this.loading = true;
      if (!param) {
        param = {per_page: configs.itemOnPage, page: this.currentPage};
      }

      GetListStudents(param).then((e) => {
        let dataTable = [];
        if (e.data.status === "success") {
          let students = e.data.data.list_student.data;
          students.map((e, index) => {
            dataTable.push({
              id: e.id,
              index: index + 1,
              username: e.username,
              email: e.email,
              name: e.name,
              isActive: e.is_active == configs.status.active,
              phone: e.phone_number,
              point: e.point,
              batchName:
                  e.batch_students.length > 0
                      ? e.batch_students[0].batches.batch_name
                      : "",
              batchId:
                  e.batch_students.length > 0
                      ? e.batch_students[0].batches.batch_id
                      : "",
            });
          });
          this.total = e.data.data.list_student.total;
          this.isShowPagination = e.data.data.list_student.total > 1;
        }
        this.loading = false;
        this.tableData = dataTable;
      })
          .catch((err) => {
            this.loading = false;
          });
    },
    async getReasonPoint(typePoint) {
      this.model.typeReason = "";
      this.model.point = "";
      try {
        this.loading = true;
        let res = await GetSystemInfo({code_system: typePoint == 1 ? "point_increment" : "point_decrement"});
        this.listReason = res.data.data.listSystemInfo.data;
        this.loading = false;
      } catch (err) {
        notifyErr();
      }
    },
    getPoint(type) {
      let item = this.listReason.find(i => i.id === type);
      this.model.point = item.value;
    },
    closeDialog() {
      this.setup.dialogEdit = false;
    },
    openDialogUpdate(scope) {
      let id = scope.row.id
      let name = scope.row.id
      let batch_id = scope.row.batchId
      this.setup.dialogEdit = true;
      this.studentEditId = id;
      this.model.name = name;
      this.model.batch_id = batch_id;
    },
    async updatePoint() {
      let valid = await this.$refs.formUpdate.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return false;
      let params = {
        student_id: this.studentEditId,
        system_id: this.model.typeReason,
        point: this.model.point,
        note: this.model.note,
        batch_id: this.model.batch_id,
      }

      try {
        this.loading = true;
        await UpdatePointStudent(params);
        notifySuccess("edit");
        this.getStudents();
      } catch (error) {
        notifyErr(error.response.data.message);
      }
      this.closeDialog();
      this.loading = false;
    },
    searchStudent() {
      this.currentPage = 1;
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.$router.push({path: location.pathname, query: params})
      this.getStudents(params);
    },
    refreshSearch() {
      this.currentPage = 1;
      this.setup = {
        search_name: null,
        search_phone: null,
        search_email: null,
        search_batch: null,
        filter_active: null,
      };
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.$router.push({path: location.pathname, query: {}})
      this.getStudents(params);
      this.$refs.batchSelectBox.resetData();
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.getStudents(params);
    },
  },
};
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {Check, Close} from "@element-plus/icons-vue";
</script>


<template>
  <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm" class="bg-white p-3">
    <el-row :gutter="40">
      <el-col :span="12">
        <el-form-item class="w-100" prop="day" label="Thời gian khoá học">
          <el-date-picker
              class="w-100"
              v-model="form.day"
              type="daterange"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              range-separator="To"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
          />
        </el-form-item>
        <el-form-item class="w-100" prop="title" label="Tên tiêu đề">
          <el-input v-model="form.title" placeholder="Tên tiêu đề"/>
        </el-form-item>
        <el-form-item class="w-100" prop="description" label="Mô tả">
          <editorTiny v-model="form.description" class="w-100"></editorTiny>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item class="w-100" prop="room_id" label="Phòng">
          <el-select class="w-100" v-model="form.room_id" placeholder="Chọn phòng">
            <el-option
                v-for="item in listRoom"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="w-100" prop="batch_id" label="Lớp">
          <batch-select v-model="form.batch_id" :show-label="false" :isMultiple="false" :add-option="false"
                        ref="batchSelectBox"/>
        </el-form-item>
        <el-form-item class="w-100" prop="course_id" label="Khóa học">
          <course-select v-model="form.course_id" :show-label="false" :isMultiple="false" :add-option="true"
                         ref="courseSelectBox"/>
        </el-form-item>
        <el-form-item class="w-100" prop="trainer_id" label="Giảng viên">
          <el-select class="w-100" v-model="form.trainer_id" placeholder="Chọn giảng viên">
            <el-option
                v-for="item in listTeacher"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import {textInputValidate} from "@/validator";
import {notifyErr} from "@/mixins/utils";
import editorTiny from "@/components/editor";
import batchSelect from "@/views/Batches/batchSelect";
import courseSelect from "@/views/Course/courseSelect";
import {GetDetailCourse} from '@/services/course'
import {GetListRoom} from '@/services/room'
import {GetListTeacher} from '@/services/users'

export default {
  expose: ['validForm'],
  name: "InfoSchedule",
  components: {
    batchSelect,
    courseSelect,
    editorTiny
  },
  props: {
    isDetail: {type: Boolean, default: false},
    form: {trainer_assistant_id: null},
  },

  created() {
    this.getInitData();
  },

  watch: {
    'form.batch_id'(newValue) {
      if (!newValue) return false
      this.$refs.courseSelectBox.searchByBatch({batch_id: newValue})
    },
    'form.course_id'(newValue) {
      if (!newValue) return false
      this.course_id = newValue;
      // this.getMartialOrSection()
    },
  },
  data() {
    return {
      loading: false,
      validation: false,
      addContentRule: {
        day: [textInputValidate],
        time: [textInputValidate],
        title: [textInputValidate],
        batch_id: [textInputValidate],
        trainer_id: [textInputValidate],
        course_id: [textInputValidate],
        trainer_assistant_id:[textInputValidate],
      },
      course_id: null,
      isSection: true,
      listRoom: [],
      listTeacher: [],
    }
  },

  methods: {
    async validForm() {
      return await this.$refs.contentForm.validate((valid, fields) => {
        return !valid;
      })
    },
    async getInitData() {
      this.loading = true;
      try {
        let resRoom = await GetListRoom();
        let resTeacher = await GetListTeacher();
        this.listRoom = resRoom.data.data.listRoom.data;
        this.listTeacher = resTeacher.data.data.list_teacher.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        return notifyErr(err.response.data.message)
      }
    },
    // async getMartialOrSection() {
    //   try {
    //     let res = await GetDetailCourse(this.course_id);
    //     let type = res.data.data.course.type;
    //     if (type === 2) {
    //       this.isSection = false
    //       this.$refs.materialSelectBox?.searchBy({course_id: this.course_id})
    //     } else {
    //       this.isSection = true
    //       this.$refs.sectionSelectBox.searchByCourse(this.course_id)
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }
}
</script>
<script setup>
import configs from "@/configs";
import {Delete, Plus, Refresh} from '@element-plus/icons-vue'


</script>
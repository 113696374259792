<template>
  <div class="bg-white p-3 mb-4">
    <el-calendar ref="calendar" class="calendar">
      <template #header="{ date }">
        <el-button-group>
          <el-button size="small" @click="selectDate('prev-month',date)">
            Tháng trước
          </el-button>
          <el-button size="small" @click="selectDate('today',date)">Hôm nay</el-button>
          <el-button size="small" @click="selectDate('next-month',date)">
            Tháng sau
          </el-button>
        </el-button-group>
      </template>
      <template #dateCell="{data}">
        <el-popover placement="auto" :width="400" trigger="contextmenu">
          <template #reference>
            <ul class="list-data" @click="viewDetail(data.day)">
              <li style="text-align: center; font-weight: 500">{{ data.day.split('-').pop() }}</li>
              <li class="data-content"
                  v-for="item in tableList[data.day]"
                  :key="item"
              >
                <div style="display:flex; align-items:center; gap:4px">
                  <div style="width: 4px; height: 20px ; background-color: red" v-if="item.date_loop == 1"></div>
                  <div style="width: 4px; height: 20px ; background-color: green" v-if="item.date_loop == 0"></div>
                  <span>{{ item.event.name }}</span>
                </div>
              </li>
            </ul>
          </template>
          <div class="more-info" v-if="tableList[data.day]">
            <div class="header">Thông tin sự kiện</div>
            <div v-for="item in tableList[data.day]"
                 :key="item"
                 style="width: 100%"
            >
              <div class="title" style="display: flex ; justify-content: space-between; width:100%">
                <div style="display:flex; align-items:center; gap:4px">
                  <div style="width: 4px; height: 20px ; background-color: red" v-if="item.date_loop == 1"></div>
                  <div style="width: 4px; height: 20px ; background-color: green" v-if="item.date_loop == 0"></div>
                  {{ item.event.name }}
                </div>
              </div>
            </div>
            <div>
              <el-button type="primary" size="small" @click="viewDetail(data.day)">Chi tiết sự kiện</el-button>
              <el-button type="danger" size="small" @click="openConfirm(data.day)">Xóa sự kiên trong ngày</el-button>
            </div>
          </div>
          <div class="more-info" v-else>
            <div class="title">Không có thông tin sự kiện</div>
            <el-row>
              <el-button type="primary" size="small" @click="createEvent(data.day)">Tạo sự kiện</el-button>
            </el-row>
          </div>
        </el-popover>
      </template>
    </el-calendar>
    <confirm v-model="isOpenCofirm" @close="closeDialog"
             @deleteService="removeEventInDay"></confirm>
  </div>

</template>

<script>
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {DeleteEventInDay, GetListEvent} from "@/services/event";
import configs from "@/configs";
import {NameRouter} from "@/constants/nameRouter";
import confirm from "@/components/confirm";


export default {
  name: "ListEvent",
  mixins: [setBreadcrumb],
  components: {
    confirm
  },
  created() {
    this.getListEvent();
  },
  data() {
    return {
      visible: false,
      tableList: [],
      configs: configs,
      currentPage: 1,
      pageIndex: 1,
      NameRouter: NameRouter,
      isOpenCofirm: false,
      dayDeleted: "",
    }
  },
  methods: {
    getListEvent(params) {
      this.loading = true;
      let month = (new Date()).toLocaleDateString("en-CA").split('-')
      month.pop()
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage, date: month.join("-")}
      }
      GetListEvent(params).then(res => {
        if (res.data.status === 'success') {
          this.tableList = res.data.data.listEvent;
        }
      }).catch(e => {
        return notifyErr()
      })
    },
    openConfirm(day) {
      this.isOpenCofirm = true;
      this.dayDeleted = day;
    },
    closeDialog() {
      this.isOpenCofirm = false;
    },
    removeEventInDay() {
      let event_detail_ids = this.tableList[this.dayDeleted].map(i => i.id)
      let params = {
        day: this.dayDeleted,
        event_detail_ids,
      }
      DeleteEventInDay(params).then(res => {
        this.loading = true;
        if (res.data.status === 'success') {
          notifySuccess("delete")
          let month = this.dayDeleted.split("-");
          month.pop();
          this.getListEvent({per_page: this.configs.itemOnPage, page: this.currentPage, date: month.join("-")});
        }
        this.isOpenCofirm = false;
        this.loading = false;
      }).catch(e => {
        return notifyErr()
      })
    },
    viewDetail(day) {
     if(this.tableList[day]){
       this.$router.push({name: NameRouter.PAGES.DETAIL_DAY_EVENT, params: {id: day}})
     }else {
       this.$router.push({name: NameRouter.PAGES.CREATE_EVENT_IN_DAY, params: {id: day}})
     }

    },
    createEvent(day) {
      this.$router.push({name: NameRouter.PAGES.CREATE_EVENT_IN_DAY, params: {id: day}})
    },
    selectDate(val, date) {
      this.$refs.calendar.selectDate(val);

      let arrDate = date.split(" ");
      let year = arrDate[0];
      let month = arrDate[3];
      let newDate;

      if (val == 'today') {
        let cMonth = (new Date()).toLocaleDateString("en-CA").split('-')
        cMonth.pop()
        newDate = cMonth.join("-")
      } else if (val == 'next-month') {
        year = month == 12 ? Number(year) + 1 : year
        month = month == 12 ? 1 : Number(month) + 1
        newDate = year + '-' + month
      } else {
        year = month == 1 ? Number(year) - 1 : year
        month = month == 1 ? 12 : Number(month) - 1
        newDate = year + '-' + month
      }

      let params = {per_page: configs.itemOnPage, page: this.currentPage, date: newDate}

      GetListEvent(params).then(res => {
        if (res.data.status === 'success') {
          this.tableList = res.data.data.listEvent;
        }
      }).catch(e => {
        return notifyErr()
      })

    }

  }
}
</script>


<style scoped>
>>> .current {
  height: 120px;
}

>>> .el-calendar-table .el-calendar-day {
  height: 120px;
}

.list-data {
  width: 100%;
  height: 100%;
  padding: 2px;
  margin: 0;
  list-style-type: none;
  overflow-y: hidden;
}

.data-content {
  padding: 2px;
  font-size: 14px;
}

.more-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.header {
  color: #616161;
  font-size: 16px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #383966;
}

.time {
  color: #242424;
}

</style>

<script setup>
import {
  Delete,
} from '@element-plus/icons-vue'
</script>
<template>
  <div id="management-course" class="bg-white py-2">
    <div  class="box-search">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="setup.keyword" placeholder="Tìm kiếm người dùng"/>
        </el-col>
        <el-col :span="6">
          <el-select
              class="w-100"
              v-model="setup.role_level"
              placeholder="Tìm kiếm theo quyền"
          >
            <div v-for="(item, key) in listRole" :key="key">
              <el-option
                  v-if="item.id !== roleAdmin"
                  :value="item.id.toString()"
                  :label="item.description + ' (' + item.name + ')'"
              />
            </div>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
              class="w-100"
              v-model="setup.is_active"
              placeholder="Tìm kiếm theo trạng thái"
          >
            <el-option
                :value="configs.status.active.toString()"
                :label="configs.status.activeStr"
            />
            <el-option
                :value="configs.status.inActive.toString()"
                :label="configs.status.inActiveStr"
            />
          </el-select>
        </el-col>
        <el-col :span="6">
          <handle-button
              label="Tìm kiếm"
              icon="fa fa-search"
              event-code="users.index"
              type-button="primary"
              @click="searchData"/>
          <handle-button
              label="Làm mới"
              icon="fa-search"
              event-code="users.index"
              type-button="warning"
              @click="reload"/>
        </el-col>
      </el-row>
    </div>
    <el-table
        ref="modules"
        v-loading="loading"
        :data="tableData"
        fixed
        style="width: 100%"
        border
        @select="handleSelected"
        @selection-change="handleSelectAll"
    >
      <el-table-column v-if="showCheckbox" type="selection" width="55"/>
      <el-table-column prop="id" label="Id" width="50"/>
      <el-table-column prop="name" label="Tên người dùng" width="200"/>
      <el-table-column prop="email" label="Email" min-width="150"/>
      <el-table-column prop="role" label="Quyền hạn" min-width="120">
        <template #default="scope">
          <div>{{ scope.row.description }} ({{ scope.row.role }})</div>
        </template>
      </el-table-column>
      <el-table-column prop="isActive" label="Hoạt động" width="100">
        <template #default="scope">
          <div class="text-center">
            <el-checkbox v-model="scope.row.isActive" disabled/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Loại hình" width="200">
        <template #default="scope">
          <span>{{ configs.typeOfUser[scope.row.isTeacher] }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="300">
        <template #default="scope">
          <handle-button
              label="Chi tiết"
              event-code="users.list-user"
              type-button="primary"
              icon="fa fa-eye"
              type="link"
              :to="{
              name: nameRouter.PAGES.DETAIL_USER,
              params: { id: scope.row.id },
            }"
          />
          <handle-button
              label="Đổi mật khẩu"
              event-code="users.reset-password-user"
              type-button="primary"
              icon="fa fa-lock"
              @click="changePassword(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
  <!-- showdialog -->
  <el-dialog
      v-model="setup.dialogAdd"
      title="Thay đổi mật khẩu"
      width="50%"
      draggable
      @close="closeDialog"
  >
    <el-form ref="form" class="login-form" :model="model" :rules="rulesLogin">
      <el-form-item prop="password">
        <el-label style="">
          Mật khẩu mới
        </el-label>
        <el-input
            v-model="model.password"
            placeholder="Mật khẩu mới"
            type="password"
            :prefix-icon="Lock"
            autocomplete="off"
            @change="onChange"
        />
      </el-form-item>
      <el-form-item prop="rePassword">
        <el-label>Xác nhận khẩu mới</el-label>
        <el-input
            v-model="model.rePassword"
            placeholder="Nhập lại mật khẩu"
            type="password"
            autocomplete="off"
            :prefix-icon="Lock"
            @change="onChange"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <handle-button
            label="Hủy"
            event-code="users.reset-password-user"
            type-button="danger"
            @click="closeDialog"
        />
        <handle-button
            label="Xác nhận"
            event-code="users.reset-password-user"
            type-button="primary"
            icon="fa fa-save"
            @click="submit"
        />
      </span>
    </template>
  </el-dialog>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListUser, ListRole} from "@/services/users";
import {ResetPasswordUser} from "@/services/users";
import {textInputValidate, checkPassword} from "@/validator";

export default {
  name: "ListUser",
  expose: ["validMaterial"],
  components: {
    pagination,
  },
  mixins: [setBreadcrumb],
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: false},
  },
  data() {
    return {
      isPass: true,
      tableData: [],
      listRole: [],
      selected: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      nameRouter: NameRouter,
      setup: {
        keyword: null,
          role_level: "",
        is_active: "",
        dialogAdd: false
      },
      roleAdmin: 1,
      configs: configs,
      userId: null,
      model: {
        password: "",
        rePassword: "",
      },
      rulesLogin: {
        password: [textInputValidate, checkPassword, {
          validator: (rule, val, callback) => {
            if (val != this.model.rePassword) callback(new Error('Mật khẩu chưa giống nhau.'));
            callback();
          },
          required: true,
          trigger: "blur",
        }
        ],
        rePassword: [textInputValidate, checkPassword, {
          validator: (rule, val, callback) => {
            if (val != this.model.password) callback(new Error('Mật khẩu chưa giống nhau.'));
            callback();
          },
          required: true,
          trigger: "blur",
        }],
      },
    };
  },
  watch: {
    selected() {
      this.$emit("update:modelValue", this.selected);
    },
    tableData: {
      handler(newTable) {
        let that = this;
        that.$nextTick(function () {
          that.toggleSelection();
        });
      },
      immediate: true,
    },
  },

  created() {

    let query = this.$router.currentRoute?.value?.query;
    let {search_name,filter_role, filter_active, per_page, page} = query;
    if (search_name||filter_role|| filter_active || per_page || page) {
      this.setup = {is_active:filter_active,keyword:search_name,role_level:filter_role,per_page, page}
      this.getUsers(query);
      this.getListRole();
    } else {
      this.getUsers();
      this.getListRole();
    }
  },
  methods: {
    changePassword(id) {
      this.setup.dialogAdd = true;
      this.userId = id;
    },
    getUsers(params) {
      this.loading = true;
      if (!params) {
        params = {
          per_page: configs.itemOnPage,
          page: this.currentPage,
          keyword: this.setup.keyword,
        };
      }
      GetListUser(params)
          .then((e) => {
            if (e.data.status == "success") {
              let dataTable = [];
              e.data.data.list_user.data.map((e) => {
                dataTable.push({
                  id: e.id,
                  name: e.name,
                  isActive: configs.status.active === e.is_active,
                  userName: e.username,
                  role: e.role.name,
                  description: e.role.description,
                  email: e.email,
                  isTeacher: e.role ? e.role.is_teacher : 0
                });
              });
              this.tableData = dataTable;
              this.total = e.data.data.list_user.total;
              this.isShowPagination = e.data.data.list_user.total > 0;
              this.selected = this.modelValue;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            notifyErr();
          });
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getUsers();
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex((id) => row.id == id);
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1);
      }
      if (indexItem < 0) {
        this.selected.push(row.id);
      }
      this.$emit("update:modelValue", this.selected);
    },
    handleSelectAll(val) {
      if (val) {
        val.map((e) => {
          if (this.selected.findIndex((id) => e.id == id) > -1) return;
          this.selected.push(e.id);
        });
        this.$emit("update:modelValue", this.selected);
      }
    },
    toggleSelection(table) {
      if (!this.selected) {
        return false;
      }
      this.selected.forEach((e) => {
        let item = this.tableData.find((item) => e == item.id);
        if (!item) return false;
        this.$refs.modules.toggleRowSelection(item);
      });
    },
    validMaterial() {
      if (this.selected.length < 1) {
        this.isPass = false;
      } else {
        this.isPass = true;
      }
      return this.isPass;
    },
    searchData() {
      let params = {
        per_page: configs.itemOnPage,
        page: this.currentPage,
        filter_active: this.setup.is_active ?? null,
        filter_role: this.setup.role_level ?? null,
        search_name: this.setup.keyword ?? null,
      };
      this.$router.push({path: location.pathname, query: params})
      this.getUsers(params);
    },
    reload() {
      this.setup = {
        keyword: null,
        role_level: "",
        is_active: "",
      };
      this.$router.push({path: location.pathname, query: {}})
      this.getUsers();
    },
    getListRole() {
      ListRole()
          .then((res) => {
            if (res.data.status == "success") {
              this.listRole = res.data.data.roles;
            }
          })
          .catch((err) => {
            notifyErr(err.response.data.status);
          });
    },
    async submit(e) {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        e.preventDefault()
        return false;
      }

      let param = {
        user_id: this.userId,
        new_password: this.model.password,
      }
      ResetPasswordUser(param).then(e => {
        if (e.data.status == 'success') {
          notifySuccess("Thay đổi mật khẩu thành công !")
          this.setup.dialogAdd = false;
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
      e.preventDefault()
    },
    async onChange() {
      let valid = await this.$refs.form.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      })
      if (!valid) {
        return false;
      }
    },
    closeDialog() {
      this.setup.dialogAdd = false
      this.$refs.form.resetFields()
    }
  },
};
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {Search} from "@element-plus/icons-vue";
import {Lock} from "@element-plus/icons-vue";
import HandleButton from "@/components/button";

const dialogAdd = ref(false);
</script>
<style scoped lang="scss">
::v-deep .cell {
  word-break: break-word !important;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.input_password {
  width: 100%;
  display: flex;
}
</style>

import {HTTP_REQUEST_AUTH} from "./requestAxios";
import { FACILITY} from "@/constants/endPoints";

export const CreateFacility = (params) => HTTP_REQUEST_AUTH.post(FACILITY.CREATE_FACILITY, params);
export const GetDetailFacility = (param) => HTTP_REQUEST_AUTH.get(FACILITY.GET_DETAIL_FACILITY+'/'+param);
export const GetListFacility = (params) => HTTP_REQUEST_AUTH.get(FACILITY.GET_LIST_FACILITY, {params: params});
export const DeleteFacility = (params, id) => HTTP_REQUEST_AUTH.delete(FACILITY.DELETE_FACILITY + '/' + id, params);
export const UpdateFacility = (params, id) => HTTP_REQUEST_AUTH.put(FACILITY.UPDATE_FACILITY + '/' + id, params);
export const AddUserToFacility = (params) => HTTP_REQUEST_AUTH.post(FACILITY.ADD_USERS_TO_FACILITY , params);
export const AddClassToFacility = (id,params) => HTTP_REQUEST_AUTH.post(FACILITY.ADD_CLASS_TO_FACILITY+'/'+id , params);
export const RemoveUserFromFacility = (id, params) => HTTP_REQUEST_AUTH.post(FACILITY.REMOVE_USER_FROM_FACILITY+'/'+id , params);
export const RemoveClassFromFacility = (id, params) => HTTP_REQUEST_AUTH.post(FACILITY.REMOVE_CLASS_FROM_FACILITY+'/'+id , params);
<template>
  <fileCheckbox :show-checkbox="false"></fileCheckbox>
</template>
<script>
import {setBreadcrumb} from "@/mixins/utils";
import fileCheckbox from "@/views/ManagerFile/fileCheckbox";
export default {
  name: "ListFile",
  components: {
    fileCheckbox
  },
  mixins: [setBreadcrumb],
}

</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

<template>
  <div class="container" v-loading="loading">
    <div class="bg-white p-3 border border-bottom-0 ">
      <el-row :gutter="20">
        <el-col :md="4" :lg="2" :xl="3">
          <el-avatar shape="square" :size="80" fit="fill" :src="topic.student.full_avatar_url"/>
        </el-col>
        <el-col :md="16" :lg="18">
          <div>
            <span class="text-lightblue mr-2">Tên học sinh:</span>
            {{ topic.student.name }}
          </div>
          <div>
            <span class="text-lightblue mr-2">Đang học khóa:</span>
            {{ topic.course.title }}
          </div>
        </el-col>

        <el-col :md="4" :lg="3">
          <handle-button
              label="Xóa"
              eventCode="comments.store"
              icon="fa fa-minus"
              typeButton="danger"
              @click="openConfirm"
          />
        </el-col>
      </el-row>
      <div class="pt-3 text-bold">{{ topic.title }}</div>
      <div v-html="topic.content"></div>
      <div style="color: #B0B3BC">
        <small class="mr-4"><i class="fa fa-comment"></i> {{ topic.commentCount }}</small>
        <small class="mr-2"><i class="fa fa-clock"></i> Ngày tạo: {{ topic.createdAt }}</small>
      </div>
      <div class="py-3 mt-3 border-top">
        <el-row :gutter="20" :key="key" v-for="(item, key) in comments">
          <el-col :md="4" :lg="2" :xl="2">
            <el-avatar
                shape="square" :size="80" fit="fill"
                :src="item.user.full_avatar_url||item.student.full_avatar_url "/>
          </el-col>
          <el-col :md="20" :lg="21" :xl="22">
            <div class="pr-3"><i class="fa fa-user" style="color: #B0B3BC"></i> {{ item.user.name || item.student.name}}</div>
            <div><small class="mr-2">
              <i class="fa fa-clock" style="color: #B0B3BC"></i> Ngày tạo:
              {{ item.updated_at ? formatDate(item.updated_at) : formatDate(item.created_at) }}
            </small></div>
          </el-col>
          <div class="col-12">
            <div class="w-100 pt-2 pl-2" v-if="!item.isEdit" v-html="item.content"></div>
            <div v-if="item.isEdit">
              <editorTiny v-if="item.isEdit" v-model="item.contentEdit"></editorTiny>
              <handle-button
                  :disabled="!item.contentEdit"
                  label="Chỉnh sửa"
                  eventCode="subjects.store"
                  icon="fa fa-plus"
                  typeButton="primary"
                  @click="editMessages(item.id)"/>
              <el-button class="my-3" @click="item.isEdit = false">Hủy</el-button>
            </div>
            <div style="color: #B0B3BC" class="pl-2" v-if="userInfo.id === item.user_id && !item.isEdit">
              <small class="mr-4" style="cursor: pointer" @click="openBoxEdit(item.id)">
                <i class="fa fa-edit"></i> Chỉnh sửa</small>
              <el-popconfirm
                  @confirm="confirmEvent(item.id)"
                  title="Bạn có chắc muốn xóa bình luận này?">
                <template #reference>
                  <small class="mr-4" style="cursor: pointer"><i class="fa fa-trash"></i> Xóa</small>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </el-row>
      </div>
    </div>
    <div class="px-2 py-3 bg-white border">
      <editorTiny v-model="form.content"></editorTiny>
      <div class="mt-3">
        <handle-button
            label="Trả lời"
            eventCode="comments.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createMessage"/>
      </div>
    </div>
  </div>
  <confirm
      v-model="setup"
      @close="setup = false"
      @deleteService="deleteTopic"
  ></confirm>
</template>
<script>
import {
  DetailTopic,
  CreateMessage,
  DeleteMessage,
  UpdateMessage,
  DeleteTopic,
} from "@/services/common";
import {NameRouter} from "@/constants/nameRouter";
import editorTiny from "@/components/editor";
import {textInputValidate} from "@/validator";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import confirm from "@/components/confirm";

export default {
  name: 'TopicDetail',
  components: {
    editorTiny
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      topic: {},
      comments: [],
      form: {
        content: '',
      },
      userInfo: {},
      formRule: {
        content: [textInputValidate]
      },
      loading: false,
      setup: false,
    };
  },
  created() {
    this.getListComment()
  },
  methods: {
    getListComment() {
      this.loading = true;
      this.topic = this.$helpers.getContentTopic();
      this.userInfo = this.$helpers.getInfoUser();
      DetailTopic(this.$route.params.id)
          .then((res) => {
            if (res.data.status === "success") {
              this.comments = res.data.data.comment;
              this.comments.map((el) => {
                el.isEdit = false;
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            notifyErr();
          });
    },
    openConfirm() {
      this.setup = true;
    },

    async deleteTopic() {
      await DeleteTopic(this.$route.params.id)
          .then((e) => {
            if (e.data.status == "success") {
              this.$router.push({
                name: NameRouter.PAGES.TOPIC_LIST,
              });
              notifySuccess("delete");
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            notifyErr();
          });
    },
    createMessage() {
      if (!this.form.content) {
        return notifyErr('Vui lòng điền nội dung.')
      }
      let params = {content: this.form.content, topic_id: this.$route.params.id}
      CreateMessage(params).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('add', 'Đã bình luận thành công.')
          this.form.content = ''
          this.getListComment()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    confirmEvent(id) {
      let index = this.comments.findIndex(el => el.id == id)
      this.loading = true
      DeleteMessage(id).then(res => {
        if (res.data.status == 'success') {
          this.comments.splice(index, 1)
          notifySuccess('delete')
          this.getListComment()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    openBoxEdit(id) {
      let index = this.comments.findIndex(el => el.id == id)
      this.comments[index].isEdit = true
      this.comments[index].contentEdit = this.comments[index].content
    },
    editMessages(id) {
      let index = this.comments.findIndex(el => el.id == id)
      if (index < 0) return notifyErr('Không tồn tại bình luận này.')
      if (!this.comments[index].content) return notifyErr('Vui lòng điền nội dung.')
      let params = {content: this.comments[index].contentEdit}
      UpdateMessage(params, id).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
          this.getListComment()
        }
      }).catch(err => {

        notifyErr(err.response.data.message)
      })
    }
  }
}
</script>
<script setup>
import {formatDate} from "@/mixins/utils";
</script>
<style scoped>
::v-deep #editor-tiny-custom {
  width: 100% !important;
}
</style>

import axios from "axios";
import {BASE_URL} from "@/constants/endPoints";
import {logout} from "./auth";
import * as HELPERS from "@/plugin/helper"

let token = HELPERS.getTokenCookies()

let header = {
    "Access-Control-Allow-Origin": "*",
}
if (token) {
    header.Authorization = `Bearer ${token}`
}

export const HTTP_REQUEST = axios.create({
    baseURL: `${BASE_URL}/api`,
    timeout: 7000,
    headers: header,
});
let baseUrlAPI = '';
if (window.location.href.search(process.env.VUE_APP_URL_PRODUCT) !== -1) {
    baseUrlAPI = process.env.VUE_APP_BASE_URL_API_PRODUCT;
} else if (window.location.href.search(process.env.VUE_APP_URL_DEV) !== -1) {
    baseUrlAPI = process.env.VUE_APP_BASE_URL_API;
} else if (window.location.href.search(process.env.VUE_APP_URL_WEB_ONLINE) !== -1) {
    baseUrlAPI = process.env.VUE_APP_BASE_URL_API_WEBONLINE;
} else {
    baseUrlAPI = BASE_URL;
}

export const HTTP_REQUEST_AUTH = axios.create({
    baseURL: `${baseUrlAPI}/api`,
    timeout: 30000,
    headers: header,
});

const formatError = (error) => {
    if (error.response.status === 401) {
        HELPERS.logout()
        if (token) {
            logout().then(e => {
                if (e.data.status == 'success') {
                    return window.location.href = '/'
                }
                return false
            }).catch(err => {
                return window.location.href = '/'
            })
        }
    }
    if (error.response.status === 403) {
        // HELPERS.logout()
        return window.location.href = '/permission-denied'
    }
};

HTTP_REQUEST_AUTH.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        formatError(error);
        return Promise.reject(error);
    }
);

HTTP_REQUEST_AUTH.interceptors.request.use(
    (config) => {
        token = HELPERS.getTokenCookies()
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

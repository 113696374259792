<template>
  <div id="management-materials" class="bg-white py-2">
    <div class="box-search">
      <i>Tìm kiếm theo mục đích</i>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="setup.keyword" placeholder="Tìm kiếm bài học"/>
        </el-col>
        <el-col :span="6">
          <el-input v-model="setup.code" placeholder="Tìm kiếm theo mã"/>
        </el-col>
        <el-col :span="6">
          <roadmap-select v-model="setup.roadmap_id" ref="roadmapSelectBox"/>
        </el-col>
        <el-col :span="6" v-show="isShowModule">
          <select-module :show-label="false" ref="moduleSelectBox" v-model="setup.module_id"/>
        </el-col>
        <el-col :span="6">
          <course-select :show-label="false" ref="courseSelectBox" v-model="setup.course_id"/>
        </el-col>
        <el-col :span="6" v-show="showSection">
          <section-select :show-label="false" ref="sectionSelectBox" v-model="setup.section_id"/>
        </el-col>
        <el-col :span="6" class="">
          <div class="d-flex">
            <handle-button
                label="Tìm kiếm"
                eventCode="materials.index"
                icon="fa fa-search"
                typeButton="primary"
                @click="searchData"/>
            <handle-button
                type="link"
                target="_blank"
                label="Tạo bài học"
                eventCode="materials.store"
                icon="fa fa-plus"
                typeButton="primary"
                :to="{ name: nameRouter.PAGES.CREATE_MATERIAL}"
            />
            <handle-button
                label="Làm mới"
                eventCode="materials.index"
                typeButton="warning"
                @click="reload"/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="py-2" v-if="!isPass"><span class="text-danger">Vui lòng chọn Bài học.</span></div>
    <div class="px-2">
      <el-table
          :data="tableData"
          @select="handleSelected"
          @selection-change="handleSelectAll"
          style="width: 100%"
          border
          ref="modules"

          fixed v-loading="loading">
        <el-table-column type="selection" width="55" v-if="showCheckbox"/>
        <el-table-column prop="title" label="Tên bài học" min-width="150"/>
        <el-table-column prop="code" label="Mã code" min-width="100"/>
        <el-table-column prop="purpose_of_uses" label="Mục đích" min-width="100"/>
        <el-table-column prop="duration" label="Thời gian học(phút)" min-width="120"/>
        <el-table-column
            fixed="right"
            min-width="150">
          <template #default="scope">
            <handle-button
                type="link"
                label="Chi tiết"
                eventCode="materials.show"
                icon="fa fa-eye"
                typeButton="primary"
                :to="{name: nameRouter.PAGES.DETAIL_MATERIAL, params: {id: scope.row.id}}"></handle-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pb-3 text-center">
        <pagination
            v-show="isShowPagination"
            :total="total"
            :page-index="pageIndex"
            @currentPageChange="nextPage"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetMaterial } from "@/services/course";
import roadmapSelect from "@/components/roadmapSelect";
import selectModule from "@/views/ManagementModule/selectModule";
import courseSelect from "@/views/Course/courseSelect";
import sectionSelect from "@/views/ManagementSection/sectionSelect";

export default {
  name: "material-checkbox",
  expose: ['validMaterial'],
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: true}
  },
  components: {
    pagination,
    roadmapSelect,
    selectModule,
    courseSelect,
    sectionSelect
  },
  data() {
    return {
      isPass: true,
      tableData: [],
      selected: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      nameRouter: NameRouter,
      setup: {
        keyword: null,
        code: null,
        module_id: null,
        roadmap_id: null,
        course_id: null,
        section_id: null
      },
      isShowModule: false,
      showSection: false
    };
  },
  watch: {
    selected() {
      this.$emit('update:modelValue', this.selected)
    },
    tableData: {
      handler(newTable) {
        let that = this
        that.$nextTick(function () {
          that.toggleSelection()
        })
      },
      immediate: true
    },
    'setup.roadmap_id'() {
      this.setup.module_id = null
      this.setup.course_id = null
      let roadmap = this.$refs.roadmapSelectBox?.getRoadmapById(this.setup.roadmap_id)
      let hasModule = 1
      this.isShowModule = false
      if (roadmap?.type === hasModule) {
        this.isShowModule = true
        this.$refs.moduleSelectBox?.searchByRoadmap(this.setup.roadmap_id)
      }
      this.$refs.courseSelectBox?.search({roadmap_id: this.setup.roadmap_id})
    },
    'setup.module_id'() {
      this.setup.course_id = null
      this.$refs.courseSelectBox.search({module_id: this.setup.module_id})
    },
    'setup.course_id'() {
      let course = this.$refs.courseSelectBox?.getCourseCurrent()
      let hasSection = 1
      this.showSection = false;
      if (course?.type === hasSection) {
        this.showSection = true
        this.$refs.sectionSelectBox?.searchByCourse(this.setup.course_id)
      }
    },
  },

  created() {
   
    let query = this.$router.currentRoute?.value?.query;
    let {keyword,code,module_id,roadmap_id,course_id,section_id} = query;
    if (keyword||code||module_id||roadmap_id||course_id||section_id) {
      this.setup = {...query,per_page: configs.itemOnPage, page: this.currentPage}
      this.getMaterials()
    } else {
      this.getMaterials()
    }


  },
  methods: {
    getMaterials() {
      this.loading = true
      let perPage = { per_page: configs.itemOnPage, page: this.currentPage, ...this.setup }
      GetMaterial(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.materials.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.name,
              code: e.code,
              purpose_of_uses: CONFIG_APP.type_of_purpose[e.purpose_of_uses],
              isActive: e.is_active,
              duration: e.duration && e.duration > 0 ? e.duration / 60 : 0,
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.materials.total
          this.isShowPagination = e.data.data.materials.total > 0
          this.selected = this.modelValue
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getMaterials(index);
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.selected.push(row.id)
      }
      this.$emit('update:modelValue', this.selected)
    },
    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.selected.findIndex(id => e.id == id) > -1) return
          this.selected.push(e.id)
        })
        this.$emit('update:modelValue', this.selected)
      }
    },
    toggleSelection(table) {
      if (this.selected?.length < 1) {
        return false
      }
      this.selected?.forEach(e => {
        let item = this.tableData.find(item => e == item.id)
        if (!item) return false;
        this.$refs.modules.toggleRowSelection(item);
      })
    },
    validMaterial() {
      if (this.selected.length < 1) {
        this.isPass = false
      } else {
        this.isPass = true
      }
      return this.isPass
    },
    searchData() {
      this.currentPage = 1
      this.getMaterials();
      this.$router.push({path: location.pathname, query: this.setup})
    },
    reload() {
      this.setup.keyword = ''
      this.getMaterials()
      this.$router.push({path: location.pathname, query: {}})
    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {Search} from '@element-plus/icons-vue'

const dialogAdd = ref()
</script>
<style scoped lang="scss">
::v-deep .cell {
  word-break: break-word !important;
}
</style>


<template>
  <div id="infoBatch">


    <div class="p-3 my-3 bg-white">
      <div class="d-flex  justify-content-between">
        <div class="justify-content-start">
          <div class="text-center pt-3">
            <h5>Danh sách học viên</h5>
          </div>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" ref="studentTable" fixed border v-loading.fullscreen="loading">
        <el-table-column label="ID" prop="id" width="55" />
        <el-table-column prop="username" label="Tên đăng nhập" />
        <el-table-column prop="name" label="Họ tên" />
        <el-table-column prop="email" label="email" />
        <el-table-column prop="phone" label="SDT" />
        <el-table-column fixed="right" label="TT hoạt động" width="140">
          <template #default="scope">
            <el-button type="success" size="small" v-if="scope.row.isActive">
              {{ CONFIG_APP.status.activeStr }}
            </el-button>
            <el-button type="warning" size="small" v-if="!scope.row.isActive">
              {{ CONFIG_APP.status.inActiveStr }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="400">
          <template #default="scope">
            <handle-button :disable="scope.row.isActive && scope.row.batchName ? disabled : ''" label="Chấm điểm"
                           eventCode="coursePointStudent.create" type-button="primary" icon="fa fa-pen" text @click="point(scope.row)" />
          </template>
        </el-table-column>


      </el-table>
      <el-form ref="ruleFormRef" class="login-form" :model="ruleFormPoint" label-position="left" :rules="rulesFormPoint">
        <el-dialog v-model="setup.centerDialogVisible" title="Chấm điểm" width="30%" center>
          <div class="py-2">
            <h6 class="tabpointstd">
              Tên người chấm : {{ ruleFormPoint.userName }}
            </h6>
          </div>
          <div class="py-2">
            <h6 class="tabpointstd">Tên học viên : {{ selectedStudent.name }}</h6>
          </div>
          <div class="py-2">
            <h6 class="tabpointstd"> Tên lớp : {{ batch.name }}</h6>
          </div>
          <h6 class="tabpointstd">Lộ trình học : </h6>
          <roadmapSelect v-model="ruleFormPoint.roadmap_id" :isDisabled="true" />
          <el-form-item prop="course_id">
            <courseSelect v-model="ruleFormPoint.course_id" :rules="rulesFormPoint" ref="courseSelectRef"
              :disableSelect="true" />
          </el-form-item>
          <el-form-iteam>
            <h6 class="tabpointstd"> Bài thi :</h6>
            <el-input placeholder="Tên bài thi" required v-model="ruleFormPoint.title" disabled="true"></el-input>
          </el-form-iteam>
          <el-form-item v-if="isGuest" prop="nameGuest" class="py-3">
            <h6 class="tabpointstd">Tên chuyên gia :</h6>
            <el-input placeholder="Tên chuyên gia" required v-model="ruleFormPoint.nameGuest" />
          </el-form-item>
          <el-form-item prop="is_test_hackathon" class="mb-2 flex items-center text-sm">
            <el-radio-group v-model="ruleFormPoint.is_test_hackathon">
              <el-radio label="0" size="large" class="tabpointstd">Bài kiểm tra Hackathon</el-radio>
              <el-radio label="1" size="large" class="tabpointstd">Bài cuối module</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="point" style="margin-top: 20px;" v-if="ruleFormPoint.is_test_hackathon === '1'">
            <h6 style="margin: 0;" class="tabpointstd">
              Điểm Học viên:
            </h6>
            <el-input-number size="small" v-model="ruleFormPoint.point" :min="0" :max="100" maxlength="100" required />
          </el-form-item>
          <div v-if="ruleFormPoint.is_test_hackathon === '0'">
            <el-form-item prop="point1" style="margin-top: 20px;">
              <h6 style="margin: 0;" class="tabpointstd">
                Điểm thực hành :
              </h6>
              <el-input-number size="small" v-model="ruleFormPoint.point1" :min="0" :max="100" maxlength="100" required />
            </el-form-item>
            <el-form-item prop="point2" style="margin-top: 20px;">
              <h6 style="margin: 0;" class="tabpointstd">
                Điểm cơ bản:
              </h6>
              <el-input-number size="small" prop="point2" v-model="ruleFormPoint.point2" :min="0" :max="100"
                maxlength="100" required />
            </el-form-item>
            <el-form-item prop="point3" style="margin-top: 20px;">
              <h6 style="margin: 0;" class="tabpointstd">
                Điểm nâng cao:
              </h6>
              <el-input-number size="small" prop="point3" v-model="ruleFormPoint.point3" :min="0" :max="100"
                maxlength="100" required />
            </el-form-item>
          </div>
          <el-form-item prop="content_review" style="margin-top: 20px;">
            <h6 class="tabpointstd">Nhận xét về học viên :</h6>
            <el-input v-model="ruleFormPoint.content_review" :rows="2" autocomplete="off" type="textarea"
              placeholder="Nhận xét" required />
          </el-form-item>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancelScoring()">Hủy</el-button>
              <el-button type="primary" @click="savePointStd">
                Lưu
              </el-button>
            </span>
          </template>
        </el-dialog>
      </el-form>
      <confirm v-model="setup.confirmDialog" @close="closeDialog" @deleteService="removeContent"></confirm>
      <confirm v-model="setup.teacherRemoveConfirm" @close="closeDialog" @deleteService="removeTeacherBatches"></confirm>
    </div>

  </div>
</template>
<script>
import { BatchDetail, BatchUpdate, RemoveStudentBatch } from "@/services/batches";
import configs from "@/configs";
import { formatDate, notifyErr, notifySuccess, setBreadcrumb } from "@/mixins/utils";
import { NameRouter } from "@/constants/nameRouter";
import confirm from "@/components/confirm";
import { checkSpecialCharacter, textInputValidate, ValidService, } from "@/validator";
import { RemoveTeacherFromBatch } from "@/services/users";
import moment from "moment";
import courseSelect from "@/views/Course/courseSelect.vue";
import roadmapSelect from "@/components/roadmapSelect.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { CoursePointStudent } from "@/services/course"

export default {
  name: "BatchDetail",
  components: {
    courseSelect,
    roadmapSelect,
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      username: '',
      idBatch: this.$route.params.id,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmap_id: "",
      loading: false,
      selected: [],
      selectedStudent: {
        name: '',
        class: '',
      },
      model: {
        email: "",
        password: "",
        username: "",
        name: "",
        phone_number: "",
        is_active: configs.status.active,
      },
      batch: {},
      tableTeachers: [],
      removeStudentId: '',
      setup: {
        dialogAdd: false,
        confirmDialog: false,
        teacherRemoveConfirm: false,
        centerDialogVisible: false,
      },
      ruleFormPoint: {
        userName: this.$helpers.getRole().name,
        name: "",
        class: "",
        roadmap_id: "",
        course_id: "",
        content_review: "",
        title: "",
        nameGuest: "",
        point: "",
        point_hackathon: "",
        point1: '',
        point2: '',
        point3: '',
        is_test_hackathon: "",
        assignmentId: "",
      },
      rulesFormPoint: {
        content_review: [textInputValidate],
        course_id: [textInputValidate],
        timeExample: [textInputValidate],
        title: [textInputValidate],
        nameGuest: [textInputValidate],
        point: [textInputValidate],
        point1: [textInputValidate],
        point2: [textInputValidate],
        point3: [textInputValidate],
      },
      rulesForm: {
        email: [textInputValidate, ValidService.checkEmail],
        password: [textInputValidate],
        username: [textInputValidate, checkSpecialCharacter],
        name: [textInputValidate, checkSpecialCharacter],
        phone_number: [textInputValidate, ValidService.checkPhone],
        title: [textInputValidate]
      },
      studentInactive: 0,
      assignmentCurrent: '',
    };
  },

  created() {
    this.getBatch()
    let infoUser = this.$helpers.getRole()
    this.userName = infoUser.name
    this.resetForm();
  },

  methods: {
    cancelScoring() {
      this.$refs.courseSelectRef.resetData();
      this.setup.centerDialogVisible = false;
      this.ruleFormPoint = '';
      this.resetForm();
    },
    resetForm() {
      this.ruleFormPoint = {
        course_id: '',
        is_test_hackathon: '',
        point: '',
        timeExample: '',
        content_review: ''
      };

    },

    async savePointStd() {
      let valid = await this.$refs.ruleFormRef.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) {
        return
      };
      this.loading = true
      if (this.ruleFormPoint.is_test_hackathon === '0') {
        this.ruleFormPoint.point_hackathon =
          [
            { point: this.ruleFormPoint.point1, type: 1 },
            { point: this.ruleFormPoint.point2, type: 2 },
            { point: this.ruleFormPoint.point3, type: 3 }
          ];
      } else {
        this.ruleFormPoint.point
      }
      CoursePointStudent(this.ruleFormPoint).then((e) => {
        this.loading = false
        this.cancelScoring();
        notifySuccess('add', "Chấm điểm thành công")
      }).catch((err) => {
        notifyErr()
        console.log(err);
      })
    },
    async point(user) {
      this.setup.centerDialogVisible = true;
      this.ruleFormPoint.reviewer_name = this.$helpers.getRole().name
      this.selectedStudent.name = user.name
      this.selectedStudent.class = this.batch.name
      this.ruleFormPoint.batch_id = this.$route.params.id
      this.ruleFormPoint.name = user.name
      this.ruleFormPoint.class = user.batchName
      this.ruleFormPoint.roadmap_id = this.form.roadmap_id
      this.ruleFormPoint.course_id = this.assignmentCurrent.course_id
      this.ruleFormPoint.student_id = user.student_id
      this.ruleFormPoint.assignmentId = this.$route.query.assignmentId
      this.ruleFormPoint.title = this.assignmentCurrent.title
      this.ruleFormPoint.is_test_hackathon = this.assignmentCurrent.is_hackathon.toString()
    },
    getBatch() {
      this.loading = true
      let byId = { batch_id: this.$route.params.id }
      BatchDetail(byId).then(e => {
        if (e.data.status == 'success') {
          let batch = e.data.data.data
          this.batch = {
            name: batch.batch_name,
            roadmap: batch.roadmap.title,
            code: batch.code,
          }
          let assignments = batch.assignments
          this.assignmentCurrent = assignments.find((e) => e.id == this.$route.query.assignmentId)
          this.form = {
            batch_id: batch.id,
            batch_name: batch.batch_name,
            code: batch.code,
            is_active: batch.is_active,
            roadmap_id: batch.roadmap.id,
            end_date: batch.end_date,
            start_date: batch.start_date,
            time: [batch.start_date + batch.start_time, batch.start_date + batch.end_time],
            description: batch.description,
          }
          this.roadmap = batch.roadmap
          let dataTable = []
          let tableTeachers = []
          let studentList = batch.student
          let teachers = batch.teacher
          studentList.map(e => {
            let item = e.students
            if (item.is_active == configs.status.inActive) {
              this.studentInactive++
            }
            dataTable.push({
              student_id: item.id,
              userName: this.$helpers.getRole().name,
              username: item.username,
              email: item.email,
              name: item.name,
              isActive: item.is_active == configs.status.active,
              phone: item.phone_number,
            })
          })
          this.tableData = dataTable
          this.loading = false
        }
      }).catch(err => {
        notifyErr()
        this.loading = false
      })
    },
  }
}
</script>
<script setup>
import CONFIG_APP from "@/configs";
import HandleButton from "@/components/button";
</script>
<style scoped lang="scss">
.infoBatch {
  position: relative;

  .buttonEdit {
    position: absolute;
    top: 4%;
    right: 1%;
  }
}

.point {
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-bottom: 20px;
}

#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}

.tabpointstd {
  font-weight: 400 !important;
}

.examdate {
  display: flex;
  justify-content: space-between;
}

.infoStudent {

  th,
  td {
    text-align: left;
    padding: 0.8rem;
    border-bottom: 1px solid #bbbbbb;
  }
}

.el-textarea__inner {
  line-height: 2;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
}
</style>
<template>
  <div id="managementBatches" class="bg-white p-3">
    <div class="py-2 text-end">
      <el-button
          type="primary"
          :icon="Plus"
          @click="dialogAdd= true"
      >
        Thêm mới cơ sở
      </el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed border>
      <el-table-column fixed prop="id" label="ID" width="50"/>
      <!--      <el-table-column-->
      <!--          fixed-->
      <!--          label="Hình ảnh"-->
      <!--          width="160">-->
      <!--        <template #default="scope">-->
      <!--          <el-image :src="scope.row.avatar"/>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column fixed prop="name" label="Tên cơ sở" width="100"/>
      <el-table-column fixed prop="code" label="Mã code" width="100"/>
      <el-table-column prop="area" label="Khu vực" min-width="100"/>
      <el-table-column prop="address" label="Địa chỉ" width="150"/>
      <el-table-column prop="description" label="Mô tả" width="350">
        <template #default="scope">
          <div v-html="scope.row.description"></div>
        </template>
      </el-table-column>
      <el-table-column
          label="Trạng thái"
          width="150"
      >
        <template #default="scope">
          <el-button
              v-if="scope.row.is_active == '1'"
              type="success" size="small"
          >
            {{ configs.statusFacility[1].label }}
          </el-button>
          <el-button
              v-if="scope.row.is_active == '0'"
              type="warning" size="small"
          >
            {{ configs.statusFacility[0].label }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="600" label="Hành động">
        <template #default="scope">
          <handle-button
              type="link"
              label="Chi tiết"
              eventCode=""
              icon="fa fa-plus"
              typeButton="primary"
              :to="{ name: NameRouter.PAGES.FACILITY_DETAIl, params:{id: scope.row.id}}"/>
          <handle-button
              type="link"
              label="Thêm nhân viên"
              eventCode=""
              icon="fa fa-plus"
              typeButton="primary"
              :to="{ name: NameRouter.PAGES.ADD_USERS_TO_FACILITY, params:{id: scope.row.id}}"/>
          <handle-button
              type="link"
              label="Thêm lớp học"
              eventCode=""
              icon="fa fa-plus"
              typeButton="primary"
              :to="{ name: NameRouter.PAGES.ADD_CLASS_TO_FACILITY, params:{id: scope.row.id}}"/>
          <handle-button
              label="Xóa chi nhánh"
              eventCode=""
              icon="fa fa-minus"
              typeButton="danger"
              @click="openConfirmDelete(scope.row.id)"
          />
        </template>

      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
  <div class="addBatch">
    <el-dialog
        v-model="dialogAdd"
        width="40%"
        @close="closeDialog"
    >
      <template #title>
        <div class="text-center text-primary">
          <h4>Thêm mới cơ sở</h4>
        </div>
      </template>
      <el-form
          ref="formAdd"
          class="login-form"
          :model="model"
          label-position="top"
          :rules="rulesLogin"
      >
        <!--        <div class="text-bold">Ảnh đại diện-->
        <!--          <span class="text-danger">*</span>-->
        <!--        </div>-->
        <!--        <el-form-item prop="avatar">-->
        <!--          <el-upload-->
        <!--              ref="avatar"-->
        <!--              :limit="1"-->
        <!--              class="avatar-uploader"-->
        <!--              :class="imageUrl? 'disable-event': '' "-->
        <!--              :before-upload="beforeAvatarUpload"-->
        <!--          >-->
        <!--            <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar"-->
        <!--                 style="max-width: 500px; object-fit: cover">-->
        <!--            <el-icon v-if="!imageUrl" class="avatar-uploader-icon">-->
        <!--              <Plus/>-->
        <!--            </el-icon>-->
        <!--          </el-upload>-->
        <!--        </el-form-item>-->
        <!--        <div v-if="imageUrl" class="ml-lg-5 pl-lg-5 pl-md-5">-->
        <!--          <el-button type="danger" size="small" class="p-1">-->
        <!--            <el-icon>-->
        <!--              <Delete/>-->
        <!--            </el-icon>-->
        <!--            <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>-->
        <!--          </el-button>-->
        <!--        </div>-->
        <el-form-item prop="name" label="Tên cơ sở">
          <el-input
              v-model="model.name"
              placeholder="Tên cơ sở"
          />
        </el-form-item>
        <el-form-item prop="code" label="Mã code">
          <el-input
              v-model="model.code"
              placeholder="Mã code"
          />
        </el-form-item>
        <el-form-item prop="area" label="Trạng thái">
          <el-select
              v-model="model.is_active"
              class="w-100"
              filterable
              default-first-option
              :reserve-keyword="false"
              placeholder="Chọn trạng thái"
          >
            <el-option
                v-for="item in configs.statusFacility"
                :key="item.id"
                :label="item.label"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="area" label="Khu vực">
          <el-select
              v-model="model.area"
              class="w-100"
              filterable
              default-first-option
              :reserve-keyword="false"
              placeholder="Chọn khu vực"
          >
            <el-option
                v-for="item in configs.area"
                :key="item.id"
                :label="item.name"
                :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="address" label="Địa chỉ">
          <el-input
              v-model="model.address"
              placeholder="Địa chỉ"
          />
        </el-form-item>
        <el-form-item prop="description" label="Mô tả">
          <div style="width:100%">
            <editorTiny v-model="model.description"/>
          </div>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" :icon="Close" @click="closeDialog">Hủy</el-button>
          <el-button type="primary" :icon="Plus" @click="createBatch">Thêm mới</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <confirm v-model="confirmDelete" @close="closeConfirmDelete" @deleteService="removeBranch"></confirm>
</template>
<script>
import editorTiny from "@/components/editor";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, isImage, notifyErr, notifyFiled, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {checkSpecialCharacter, textInputValidate} from "@/validator";
import {NameRouter} from "@/constants/nameRouter";
import messages from "@/constants/messages";
import {CreateFacility, DeleteFacility, GetListFacility} from "@/services/facility";
import confirm from "@/components/confirm";


export default {
  name: "listFacility",
  components: {
    pagination,
    editorTiny,
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogAdd: false,
      confirmDelete: false,
      idBranchDelete: "",
      configs: configs,
      NameRouter: NameRouter,
      messages: messages,
      model: {
        name: "",
        code: "",
        area: "",
        address: "",
        description: "",
        // avatar: "",
        is_active: "",
        user_ids: [],
      },
      imageUrl: '',
      rulesLogin: {
        name: [textInputValidate, checkSpecialCharacter],
        code: [textInputValidate],
        area: [textInputValidate],
        address: [textInputValidate],
        description: [textInputValidate],
        is_active: [textInputValidate],
        // avatar: [textInputValidate]
      },
    };
  },
  watch: {},

  created() {
    this.getBatches()
  },
  methods: {
    getBatches(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      GetListFacility(params).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.listBranch.data.map(e => {
            dataTable.push({
              id: e.id,
              name: e.name,
              code: e.code,
              area: e.area,
              address: e.address,
              description: e.description,
              is_active: e.is_active
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.listBranch.total
          this.isShowPagination = e.data.data.listBranch.total > 0
        }
        this.loading = false;
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
        this.loading = false
      })

    },
    openConfirmDelete(id) {
      this.confirmDelete = true
      this.idBranchDelete = id
    },
    closeConfirmDelete() {
      this.confirmDelete = false
      this.idBranchDelete = ""
    },
    closeDialog() {
      this.dialogAdd = false
      this.$refs.formAdd.resetFields()
      this.imageUrl = ""
    },
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled()
        return false
      }
      this.imageUrl = URL.createObjectURL(uploadFile)
      this.model.avatar = this.imageUrl
      return true
    },

    async removeBranch() {
      DeleteFacility("", this.idBranchDelete).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('delete')
          this.confirmDelete = false
          this.idBranchDelete =""
          this.getBatches()
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
    },


    handleRemove() {
      this.$refs.avatar.clearFiles()
      this.imageUrl = ''
      this.model.avatar = ""
    },

    async createBatch() {
      let valid = await this.$refs.formAdd.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return false
      CreateFacility(this.model).then(e => {
        if (e.data.status === 'success') {
          notifySuccess('add', 'Tạo mới cơ sở thành công.')
          this.dialogAdd = false;
          this.$refs.formAdd.resetFields()
          this.getBatches()
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
        this.loading = false
      })
    },

    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getBatches(index);
    },
  }

}

</script>

<script setup>
import {
  Delete,
  Close,
  Plus,
} from '@element-plus/icons-vue'
</script>


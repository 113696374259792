import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {BATCHES} from "@/constants/endPoints";

export const GetListBatches = (params) => HTTP_REQUEST_AUTH.get(BATCHES.GET_LIST_BATCHES, {params: params});

export const GetRollCallBatches = (params) => HTTP_REQUEST_AUTH.get(BATCHES.GET_ROLL_CALL_BATCHES, {params: params});

export const GetRollStudentBatches = (params) => HTTP_REQUEST_AUTH.get(BATCHES.GET_ROLL_CALL_STUDENT_BATCHES, {params: params});

export const GetHistoryPointStudent = (params) => HTTP_REQUEST_AUTH.get(BATCHES.GET_HISTORY_POINT_STUDENT, {params: params});

export const AcceptRollCall = (params) => HTTP_REQUEST_AUTH.post(BATCHES.ACCEPT_ROLL_CALL, params);

export const AssignStudentToBatch = (params) => HTTP_REQUEST_AUTH.post(BATCHES.ASSIGN_TO_BATCH, params);

export const AssignTeacherToBatch = (params) => HTTP_REQUEST_AUTH.post(BATCHES.ASSIGN_TEACHER, params);

export const CreatedBatch = (params) => HTTP_REQUEST_AUTH.post(BATCHES.CREATE_BATCH, params);

export const BatchDetail = (params) => HTTP_REQUEST_AUTH.post(BATCHES.BATCH_DETAIL, params);

export const BatchUpdate = (params) => HTTP_REQUEST_AUTH.post(BATCHES.BATCH_UPDATE, params);

export const StudentInBatch = (params) => HTTP_REQUEST_AUTH.post(BATCHES.STUDENTS_IN_BATCH, params);

export const StudentCheckInBatch = (params) => HTTP_REQUEST_AUTH.post(BATCHES.STUDENTS_CHECKIN_BATCH, params);

export const RemoveStudentBatch = (params) => HTTP_REQUEST_AUTH.post(BATCHES.REMOVE_STUDENT_TO_BATCH, params);

export const UpdatePointStudent = (params) => HTTP_REQUEST_AUTH.post(BATCHES.UPDATE_POINT_STUDENT, params);

export const CreateHistoriesHomework = (params) => HTTP_REQUEST_AUTH.post(BATCHES.HISTORIES_HOMEWORK, params);

<template>
  <div class="bg-white py-3">
    <div class="border mx-2 py-2">
      <div class="text-center border-bottom py-2 font-weight-bold">Thông tin chi tiết</div>
      <div class="m-3 d-flex justify-content-center font-weight-bold text-sm">
        <el-row class="w-75 ">
          <el-col v-for="item in configs.typeRoadmap" :key='item.id' :span="4" class="d-flex justify-content-center ">
            <span :class="[item.textColor,'mr-2']" v-html="item.icon"></span>
            <span :class="item.textColor" class="mr-2">  {{ item.label }}</span>
            <el-button size="small" type="default" :icon="Plus" @click="createTreeFolder(item.router.create)"
                       style="margin-left: 8px">
            </el-button>
          </el-col>
        </el-row>
      </div>
      <el-tree
          :data="dataSource"
          node-key="id"
          default-expand-all
          @node-click="handleNodeClick"
      >
        <template #default="{ node, data }">
          <div class="custom-tree-node">
            <div :class="[findTypeRoadmap(data.typeRoadmap).textSize]">
              <span :class="findTypeRoadmap(data.typeRoadmap).textColor"
                    v-html="findTypeRoadmap(data.typeRoadmap).icon"></span>
              <span :class="findTypeRoadmap(data.typeRoadmap).textColor" class="mr-2">{{ data.title }}</span>
            </div>
            <div>
              <el-button size="small" type="primary" :icon="Edit"
                         @click.stop="detailTreeFolder(data.typeRoadmap, data.id)" style="margin-left: 8px">
                Chi tiết
              </el-button>
              <el-button size="small" type="default" :icon="Loading"
                         @click="handleNodeClick(node,data)" style="margin-left: 8px">
                Reload
              </el-button>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
  </div>
</template>
<script>
import configs from "@/configs";
import {notifyErr, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetRoadmap} from "@/services/roadmap";
import {GetListCourse, GetListModule, GetListSection, GetMaterial} from "@/services/course";
import {GetFiles} from "@/services/common";

export default {
  name: "TreeFoder",
  mixins: [setBreadcrumb],
  data() {
    return {
      id: 1000,
      nameRouter: NameRouter,
      loading: false,
      configs: configs,
      dataSource: [],
      maxItemOnPages: configs.itemOnPage * 5,
    }
  },
  created() {
    this.getRoadMap();
  },

  methods: {
    getRoadMap() {
      this.loading = true
      GetRoadmap().then(e => {
        if (e.data.status == 'success') {
          this.dataSource = e.data.data.roadmaps.data?.map(i => {
            return {...i, typeRoadmap: configs.typeRoadmap[0].id}
          })
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },
    handleNodeClick(node, data) {
      console.log(node);
      if (node.typeRoadmap === configs.typeRoadmap[0].id && node.type === 1) {
        this.loading = true
        GetListModule({roadmap_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.courses?.data?.map(i => {
              let data = {...i, typeRoadmap: configs.typeRoadmap[2].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          notifyErr();
          console.log(err);
        })
      }
      if (node.typeRoadmap === configs.typeRoadmap[0].id && node.type === 2) {
        this.loading = true
        GetListCourse({roadmap_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.courses?.data?.map(i => {
              let data = {...i, typeRoadmap: configs.typeRoadmap[1].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          notifyErr();
          console.log(err);
        })
      }
      if (node.typeRoadmap === configs.typeRoadmap[1].id && node.type === 1) {
        this.loading = true
        GetListSection({course_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.sections.data?.map(i => {
              let data = {id: i.id, title: i.name, typeRoadmap: configs.typeRoadmap[3].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          notifyErr();
          console.log(err);
        })
      }
      if (node.typeRoadmap === configs.typeRoadmap[1].id && node.type === 2) {
        this.loading = true
        GetMaterial({course_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.materials.data?.map(i => {
              let data = {id: i.id, title: i.name, typeRoadmap: configs.typeRoadmap[4].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
      if (node.typeRoadmap === configs.typeRoadmap[2].id) {
        this.loading = true
        GetListCourse({module_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.courses.data?.map(i => {
              let data = {...i, typeRoadmap: configs.typeRoadmap[1].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
      if (node.typeRoadmap === configs.typeRoadmap[3].id) {
        this.loading = true
        GetMaterial({section_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.materials.data?.map(i => {
              let data = {id: i.id, title: i.name, typeRoadmap: configs.typeRoadmap[4].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
      if (node.typeRoadmap === configs.typeRoadmap[4].id) {
        this.loading = true
        GetFiles({material_id: node.id, per_page: this.maxItemOnPages}).then(e => {
          if (e.data.status == 'success') {
            e.data.data.listAllFile.data?.map(i => {
              let data = {id: i.id, title: i.name, typeRoadmap: configs.typeRoadmap[5].id}
              this.append(node, data)
            })
          }
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
    },
    append(node, data) {
      if (!node.children) {
        node.children = [data];
      }
      if (!node.children.map(i => i.id).includes(data.id)) {
        node.children.push(data);
      }
    },
    detailTreeFolder(type, id) {
      let routeData = this.$router.resolve({name: configs.typeRoadmap[type].router.detail, params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    createTreeFolder(name) {
      let routeData = this.$router.resolve({name: name});
      window.open(routeData.href, '_blank');
    },
    findTypeRoadmap(type) {
      return this.configs.typeRoadmap.find(i => i.id === type)
    }
  }
}

</script>
<script setup>
import {
  Delete,
  Edit,
  Plus,
  Loading
} from '@element-plus/icons-vue'
</script>
<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.el-tree-node {
  margin-top: 8px;
}
</style>




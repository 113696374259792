<template>
  <div id="infoBatch">
    <div class="p-3 bg-white infoBatch">
      <div class="text-center mb-3">
        <h5>Bảng điểm của học sinh lớp {{ batch.name }}</h5>
      </div>

      <el-row :gutter="20">
        <el-col :span="12">
          <div class="pb-2">
            <span>Tên lớp học:</span> {{ batch.name }}
          </div>

          <div class="pb-2">
            <span>Tổng số học sinh:</span> {{ tableData.length }}
          </div>
          <div class="pb-2">
            <span>Học viên ngừng hoạt động:</span> {{ studentInactive }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="pb-2">
            <span>Mã lớp:</span> {{ batch.code }}
          </div>
          <div class="pb-2">
            <span>Thời gian học:</span> {{ batch.start_date }} - {{ batch.end_date }}
          </div>
          <div class="pb-2">
            <span>Thời gian buổi học:</span> {{ batch.start_time }} - {{ batch.end_time }}
          </div>
          <div class="pb-2">
            <span>Lộ trình học:</span> {{ roadmap.title }}
          </div>

        </el-col>
      </el-row>
    </div>
    <div class="p-3 my-3 bg-white">
      <div class="d-flex justify-content-between">
        <div class="justify-content-start">
          <div class="text-center">
            <h5>Bảng điểm</h5>
            <div class="pb-2">
              <span>Module:  </span>
              <el-select v-model="selectedCourse" placeholder="Chọn Module">
                <el-option
                    v-for="course in courses"
                    :key="course.id"
                    :value="course.name"
                    :label="course.name"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <table class="table table-bordered">
      <tr>
        <th v-for="(item, index) in tableHead" :key="index" :rowspan="item.id === '#81234'? 3: 1"
            :colspan="item.id != '#81234'? ((totalHackathon+ teacherPoint + expertPoint) >0 ? (totalHackathon+ teacherPoint + expertPoint+1) : 1 )   : 1  ">
          {{ item.name }}
        </th>
      </tr>
      <tr v-if="tableHead.length >0">
        <template v-for="(item, index ) in tableHead.length -1" :key="index">
          <th v-if="totalHackathon" :colspan="totalHackathon">Điểm hackathon</th>
          <th v-if="teacherPoint" :colspan="teacherPoint">Điểm project</th>
          <th v-if="expertPoint" :colspan="expertPoint">Điểm CG</th>
          <th rowspan="2">TB Project</th>
        </template>

      </tr>
      <tr v-if="tableHead.length >0">
        <template v-for="(item, index ) in tableHead.length -1" :key="index">
          <th v-for="i in totalHackathon " :key="i">
            Hackathon {{ i }}
          </th>
          <th v-for="i in teacherPoint " :key="i">
            Project {{ i }}
          </th>
          <th v-for="i in expertPoint " :key="i">
            Điểm CG {{ i }}
          </th>
        </template>

      </tr>
      <tbody>

      <tr v-for="student in tableData" :key="student.studentId">
        <td>{{ student.studentName }}</td>
        <template v-for="(item, index) in tableCol[student.studentId]" :key="index">
          <td v-for="i in totalHackathon" :key="i">{{ item.totalHackathon[i - 1]?.avgPoint ?? 0 }}</td>
          <td v-for="i in teacherPoint" :key="i">{{ item.teacherPoint[i - 1]?.point ?? 0 }}</td>
          <td v-for="i in expertPoint" :key="i">{{ item.expertPoint[i - 1]?.point ?? 0 }}</td>
          <td>{{ item.sumPoint}}</td>
        </template>

      </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
import {setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {checkSpecialCharacter, textInputValidate} from "@/validator";
import {ReportPointStudent} from "@/services/course";

export default {
  name: "BatchDetail",
  mixins: [setBreadcrumb],
  data() {
    return {
      idBatch: this.$route.params.id,
      tableData: [],
      tableCol: [],
      tableHead: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmap: {},
      loading: false,
      selected: [],
      batch: {},
      selectedCourse: null, // Selected course ID
      courses: [], // Array of selectable courses
      tableTeachers: [],
      removeStudentId: "",
      setup: {
        dialogAdd: false,
        confirmDialog: false,
        teacherRemoveConfirm: false
      },
      rulesForm: {
        end_date: [textInputValidate],
        start_date: [textInputValidate],
        batch_name: [textInputValidate, checkSpecialCharacter],
        roadmap_id: [textInputValidate],
        code: [textInputValidate],
        time: [textInputValidate]
      },
      form: {
        title: "",
        code: "",
        end_date: "",
        start_date: "",
        description: "",
        time: null
      },
      removeTeacherId: "",
      studentInactive: 0,
      expertPoint: 0,
      teacherPoint: 0,
      totalHackathon: 0
    };
  },
  async created() {
    await this.getBatch();
  },

  computed: {},
  methods: {
    async getBatch() {
      this.loading = true;
      let batch_id = this.$route.params.id;
      await ReportPointStudent({batch_id: batch_id}).then(e => {
        let students = e.data
        let studentsPoint = [];
        let countPointTeacher = 0;
        let countPointHackathon = 0;
        let expertPoint = 0;
        let tableHead = [{id: '#81234', 'name': 'Tên học sinh'}];
        let tableColum = [];
        for (const student in students) {
          let studentInfo = {
            studentId: students[student]?.student_info?.student_id,
            studentName: students[student]?.student_info?.students.name,
            courses: []
          }

          let idStudent = students[student]?.student_info?.student_id;
          let studentCourse = students[student].coursesStudent
          tableColum[idStudent] = []
          for (const course in studentCourse) {
            let courseInfo = {
              id: studentCourse[course].course_id,
              name: studentCourse[course].title,
              expertPoint: studentCourse[course].expertPoint,
              teacherPoint: studentCourse[course].teacherPoint,
              totalHackathon: studentCourse[course].totalHackathon,
              avgHackathon: studentCourse[course].avgHackathon,
              sumPoint: studentCourse[course].sumPoint,

            }
            if (studentCourse[course].expertPoint.length > expertPoint) expertPoint = studentCourse[course].expertPoint.length
            if (studentCourse[course].teacherPoint.length > countPointTeacher) countPointTeacher = studentCourse[course].teacherPoint.length
            if (studentCourse[course].totalHackathon.length > countPointHackathon) countPointHackathon = studentCourse[course].totalHackathon.length
            if (tableHead.length === 0) tableHead.push({
              id: studentCourse[course].course_id,
              name: studentCourse[course].title,
            })
            if (tableHead.length > 0) {
              if (!tableHead.find(e => e.id === studentCourse[course].course_id)) tableHead.push({
                id: studentCourse[course].course_id,
                name: studentCourse[course].title,

              })
            }

            tableColum[idStudent].push({
              totalHackathon: studentCourse[course].totalHackathon,
              teacherPoint: studentCourse[course].teacherPoint,
              expertPoint: studentCourse[course].expertPoint,
              avgHackathon: studentCourse[course].avgHackathon,
              sumPoint: studentCourse[course].sumPoint,
            })
            studentInfo['courses'] = courseInfo
          }
          studentsPoint.push(studentInfo)
        }
        this.expertPoint = expertPoint;
        this.teacherPoint = countPointTeacher;
        this.totalHackathon = countPointHackathon;
        this.tableData = studentsPoint;
        this.tableCol = tableColum;
        this.tableHead = [...tableHead];
        console.log(this.tableCol)
      })

    },
  }
}
</script>
<template>
  <div class="pb-3" :class="{'hasErr': validation}">
    <label> <span class="text-danger">*</span> Môn học</label>
    <vSelect
        v-model="subjectId"
        placeholder="Lựa chọn môn học"
        :disabled="disableSelect"
        :options="subjects"
        label="name"
        :class="{'is-invalid': validation}"
        :reduce="(subject) => subject.id"
        class="w-100"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import {AllSubject} from "@/services/exam";
import CONFIG_APP from "@/configs";

export default {
  expose: ['searchSubject', 'reloadSubject'],
  name: "Subject-select",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn môn học.'},
    validation: Boolean,
    modelValue: String || Number
  },
  data() {
    return {
      subjectId: '',
      hasNextPage: false,
      disableSelect: false,
      subjects: [],
      page: 1
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {
    subjectId() {
      this.$emit('selected', this.subjectId)
      this.$emit('update:modelValue', this.subjectId)
    },
    subjects() {
      this.subjectId = this.modelValue
    }
  },
  created() {
    this.getSubject()
  },

  methods: {
    getSubject(params) {
      this.hasNextPage = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page}
      }
      AllSubject(params).then(e => {
        if (e.data.status == 'success') {
          this.subjectId = this.modelValue
          this.subjects = e.data.data.subjects.data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        if (err.response.data == 'failed') {
          location.reload();
        }
      })
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      let params = {per_page: this.per_page, page: 1, keyword: query}
      this.getSubject(params)
    },
    searchSubject(keyword) {
      let params = {per_page: this.per_page, page: 1, keyword: keyword}
      this.getSubject(params)
    },
    reloadSubject() {
      this.getSubject()
    }
  },

}
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}
</style>

<template>
  <div class="search bg-white p-3 mb-4">
    <div class="row">
      <div class="col-4">
        <label for="search-student-name">Trạng thái</label>
        <el-select v-model="searchParams.status" class="w-100">
          <el-option
              v-for="(item,key) in Configs.statusAssigmentFile"
              :key="key"
              :label="item"
              :value="key"
          />
        </el-select>
      </div>
      <div class="col-4">
        <label for="search-student-name">Số điểm</label>
        <div class="w-100 d-flex justify-content-between">
          <el-input type="text" class="mr-1" id="search-student-name" v-model="searchParams.score_from"
                    placeholder="Từ"/>
          <el-input type="text" class="mr-2" placeholder="Đến" v-model="searchParams.score_to"/>
        </div>
      </div>
      <div class="col-4">
        <label for="search-student-name">Ngày nộp</label>
        <div class="w-100 d-flex justify-content-between">
          <el-date-picker
              v-model="searchParams.submission_time_from"
              :disabled-date="(time) => disabledEndDate(time, searchParams.submission_time_to) "
              class="mr-1 w-50"
              type="datetime"
              placeholder="Ngày hết hạn "
              format="DD/MM/YYYY HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
          <el-date-picker
              v-model=" searchParams.submission_time_to"
              :disabled-date="(time) => disabledStartDate(time, searchParams.submission_time_from) "
              class=" w-50"
              type="datetime"
              placeholder="Ngày kết thúc"
              format="DD/MM/YYYY HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="py-2">
          <label for="search-student-name">Tên học sinh</label>
          <el-input type="text" id="search-student-name" v-model="searchParams.keyword"/>
        </div>
      </div>
      <div class="col-3 pt-4" style="align-self: center">
        <div class="text-center">
          <el-button type="primary mr-2" @click="searchData"><i class="fa fa-search"></i> Tìm kiếm</el-button>
          <el-button type="primary" @click="reload()" :icon="Refresh">Làm mới</el-button>
        </div>
      </div>
    </div>
  </div>
  <div id="managementBatches" class="bg-white p-2">
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading">
      <el-table-column fixed prop="idStudent" label="Id" min-width="50"/>
      <el-table-column prop="nameStudent" label="Họ tên" min-width="150"/>
      <el-table-column prop="username" label="Tên đăng nhập" min-width="150"/>
      <el-table-column prop="submitDate" label="Ngày nộp" min-width="150"/>
      <el-table-column prop="fileUrl" label="Download file" min-width="120">
        <template #default="scope">
          <a v-if="scope.row.fileUrl" target="_blank" href="#" @click="downloadFile(scope.row.linkCode)"> <i
              class="fa fa-download"></i>
            Tải file</a>
          <span v-if="!scope.row.fileUrl">Không file</span>
        </template>
      </el-table-column>
      <el-table-column prop="urlSource" label="Url Source" min-width="120">
        <template #default="scope">
          <a v-if="scope.row.urlSource" target="_blank" :href="scope.row.urlSource"><i class="fa fa-file-archive"></i>
            Xem code</a>
          <span v-if="!scope.row.urlSource">Không file</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Trạng thái" min-width="120">
        <template #default="scope">
          <el-button type="success" size="small" v-if="scope.row.status == 1">
            {{ Configs.statusAssigmentFile[scope.row.status] }}
          </el-button>
          <el-button type="warning" size="small" v-if="scope.row.status == 2">
            {{ Configs.statusAssigmentFile[scope.row.status] }}
          </el-button>
          <el-button type="danger" size="small" v-if="scope.row.status == 3">
            {{ Configs.statusAssigmentFile[scope.row.status] }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="Điểm" min-width="120">
        <template #default="scope">
          <input v-model="scope.row.score" type="text" class="form-control" :id="'score-'+ scope.row.id"
                 @change="changeScore(scope.row.id)">
          <div class="invalid-feedback" :id="'err-'+ scope.row.id"></div>
        </template>
      </el-table-column>
      <el-table-column label="Hành động" min-width="120">
        <template #default="scope">
          <handle-button
              label="Lưu"
              eventCode="assignments_files.mark-assignment-file"
              icon=""
              typeButton="primary"
              @click="saveScore(scope.row.id)"/>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, formatDateTime, notifyErr, notifySuccess, notifyWarning, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {DetailAssignment, GetFile, MarkScore} from "@/services/common";
import messages from "@/constants/messages";

export default {
  name: "ListAssignmentFile",
  mixins: [setBreadcrumb],
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogVisible: false,
      dialogAdd: false,
      nameRouter: NameRouter,
      roadmaps: [],
      assignmentId: this.$route.params.id,
      searchParams: {
        score_to: null,
        score_from: null,
        submission_time_from: null,
        submission_time_to: null,
        keyword: null
      }
    };
  },

  created() {
    this.getListFile()
  },
  methods: {
    getListFile(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage}
      }
      DetailAssignment(this.assignmentId, params).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          if (e.data.data.assignmentFiles.length < 1) {
            notifyWarning('Hiện tại học sinh chưa nộp bài kiểm tra.')
            return this.$router.back()
          }
          e.data.data.assignmentFiles.data.map(e => {
            dataTable.push({
              id: e.id,
              nameStudent: e.student ? e.student.name : '',
              username: e.student ? e.student.username : '',
              idStudent: e.student_id,
              fileUrl: e.file_url ? e.file_full_url : '',
              urlSource: e.link_source_code,
              submitDate: formatDateTime(e.created_at),
              score: e.score,
              status: e.status,
              linkCode: e.file_url
            })
          })
          this.tableData = dataTable;
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    reload() {
      location.reload();
    },
    changeScore(id) {
      let message = ''
      let findIndex = this.tableData.findIndex(el => el.id === id)
      if (findIndex < 0) return false;
      let item = this.tableData[findIndex];
      let elementInput = document.getElementById('score-' + id)
      let elementErr = document.getElementById('err-' + id)
      if (!item.score) message = messages.NULL
      if (item.score && isNaN(item.score)) message = messages.NUMBER
      if (item.score && !isNaN(item.score)) {
        if ((item.score < 0 || item.score > 10)) {
          message = 'Điểm > 0 và < 10'
        } else {
          message = ''
        }
      }
      elementErr.innerText = message
      if (message) {
        elementInput.classList.add('is-invalid')
      } else {
        elementInput.classList.remove('is-invalid')
      }
    },
    saveScore(id) {
      let findIndex = this.tableData.findIndex(el => el.id === id)
      if (findIndex < 0) return false;
      let item = this.tableData[findIndex];
      if (!item.score) return notifyErr('Vui lòng điền đầy đủ thông tin.')
      if (item.score && isNaN(item.score)) return notifyErr('Vui lòng nhập điểm là kiểu số.')
      if ((item.score && !isNaN(item.score)) && (item.score < 0 || item.score > 10)) {
        return notifyErr('Vui lòng nhập điểm > 0 và < 10.')
      }
      let obj = {score: item.score}
      MarkScore(obj, id).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('add', 'Chấm điểm bài kiểm tra thành công.')
          this.getListFile()
        }
      }).catch(err => {
        notifyErr()
      })
    },
    searchData() {
      let params = this.searchParams
      params.per_page = configs.itemOnPage;
      params.page = 1;
      this.getListFile(params)
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = this.searchParams
      params.per_page = configs.itemOnPage;
      params.page = this.currentPage;
      this.getListFile(params);
    },
    disabledStartDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() < dateNew.getTime()
      }
    },
    disabledEndDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() > dateNew.getTime()
      }
    },
    downloadFile(path) {
      this.loading = true
      GetFile({file_path: path}).then(res => {
        if (res.data.status == 'success') {
          location.href = res.data.data.fileURL
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        notifyErr('Không tồn tịa file này')
      })

      event.preventDefault()
    }
  }
}
</script>
<script setup>
import {Refresh} from '@element-plus/icons-vue'
import Configs from "@/configs";
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

<template>
  <div id="managementFile" class="bg-white p-2">
    <div class="search py-2">
      <el-row :gutter="10">
        <el-col :span="4" :offset="12">
          <el-input v-model="setup.keyword" placeholder="Tìm môn học"/>
        </el-col>
        <el-col :span="8">
          <div class="d-flex">
            <handle-button
                label="Tìm kiếm"
                eventCode="subjects.index"
                icon="fa fa-search"
                typeButton="primary"
                @click="searchSubject"
                />
            <handle-button
                label="Thêm môn học"
                eventCode="subjects.store"
                icon="fa fa-plus"
                typeButton="primary"
                @click="setup.createDialog = true"/>
            <handle-button
                label="Làm mới"
                eventCode="subjects.index"
                icon=""
                typeButton="warning"
                @click="reloadSearch"
            />
          </div>
        </el-col>

      </el-row>
    </div>
    <el-table
        :data="tableData"
        style="width: 100%"
        fixed
        v-loading="loading"
    >
      <el-table-column fixed prop="name" label="Môn học" min-width="150"/>
      <el-table-column prop="code" label="Mã code" min-width="70"/>
      <el-table-column prop="version" label="Phiên bản" min-width="100"/>
      <el-table-column label="Hoạt động" min-width="120">
        <template #default="scope">
          <div class="pl-3">
            <el-checkbox :disabled="true" :checked="scope.row.is_active === Configs.status.active"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Hành động" min-width="120">
        <template #default="scope">
          <el-button
              :icon="View"
              type="success"
              @click="openEditDialog(scope.row)"
              size="small">
            Chỉnh sửa
          </el-button>
          <el-button
              :disabled="!scope.row.isRemove"
              type="danger"
              @click="openConfirm(scope.row.id)"
              size="small">
            Xóa
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
    <confirm v-model="setup.confirmDialog" @deleteService="confirmEvent"></confirm>
    <el-dialog v-model="setup.editDialog" title="Cập nhật môn học" width="50%" draggable>
      <infoSubject :form="contentEdit" ref="infoSubjectBox"></infoSubject>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="setup.editDialog = false">Hủy</el-button>
        <el-button type="primary" @click="updateSubject">
          Cập nhật
        </el-button>
      </span>
      </template>
    </el-dialog>
    <createSubject v-model="setup.createDialog" @reloadSubject="getSubject"></createSubject>
  </div>
</template>
<script>
import CONFIG_APP from "@/configs";
import confirm from "@/components/confirm";
import Pagination from "@/components/pagination";
import {DeleteSubject, GetListSubject, UpdateSubject} from "@/services/course";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import infoSubject from "@/views/MangementSubject/infoSubject";
import configs from "@/configs";
import createSubject from "@/views/MangementSubject/createSubject";

export default {
  expose: ['searchSubject'],
  name: "ListSubject",
  components: {confirm, Pagination, infoSubject, createSubject},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn môn học.'},
    validation: Boolean,
    modelValue: String || Number
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      subjectId: '',
      subjects: [],
      page: 1,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      setup: {
        confirmDialog: false,
        editDialog: false,
        keyword: '',
        createDialog: false
      },
      contentEdit: {
        name: '',
        code:'',
        version: '',
        is_active: ''
      },
      deleteSubject: '',
      updateSubjectId: ''
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {},
  created() {
    this.getSubject()
  },
  methods: {
    getSubject(params) {
      this.loading = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page}
      }
      GetListSubject(params).then(res => {
        if (res.data.status === 'success') {
          let tableData = []
          res.data.data.subjects.data.map(item => {
            tableData.push({
              name: item.name,
              code:item.code,
              version: item.version,
              id: item.id,
              isRemove: item.course_count < 1 && item.subject_question_count < 1,
              is_active: item.is_active ? item.is_active : 0
            })
          })
          this.tableData = tableData;
          this.total = res.data.data.subjects.total
          this.isShowPagination = res.data.data.subjects.total > configs.itemOnPage
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        notifyErr()
      })
    },
    searchSubject() {
      let params = {per_page: this.per_page, page: 1, keyword: this.setup.keyword}
      this.getSubject(params)
    },

    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.page = index;
      this.getSubject();
    },
    openConfirm(id) {
      this.deleteSubject = id;
      this.setup.confirmDialog = true
    },

    confirmEvent() {
      let index = this.tableData.findIndex(el => el.id == this.deleteSubject)
      if (index < 0) return notifyErr('Không tồn tại môn học này.')
      DeleteSubject(this.deleteSubject).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('delete')
          this.tableData.splice(index, 1)
          this.setup.confirmDialog = false
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    reloadSearch() {
      this.setup.keyword = ''
      this.getSubject()
    },
    openEditDialog(data) {
      this.setup.editDialog = true
      this.contentEdit = {
        name: data.name,
        code:data.code,
        version: data.version,
        is_active: data.is_active
      }
      this.updateSubjectId = data.id
    },
    async updateSubject() {
      let valid = await this.$refs.infoSubjectBox.validForm()
      if (!valid) return notifyErr('Có lỗi nhập liệu.')
      UpdateSubject(this.updateSubjectId, this.contentEdit).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('edit')
          this.setup.editDialog = false
          this.getSubject()
        }
      }).catch(err => {
        notifyErr()
      })
    },

  },

}
</script>
<script setup>
import {Refresh, Search, Plus, View, Remove} from "@element-plus/icons-vue";
import Configs from "@/configs";
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}
</style>

<template>
  <div class="p-2">
    <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
      <el-tab-pane label="Thông tin trang" name="page">
        <div id="managementBatches" class="bg-white p-3">
          <el-table v-loading="loading" :data="tableData.pageTableData" style="width: 100%" fixed border>
            <el-table-column fixed prop="id" label="ID" width="50"/>
            <el-table-column fixed prop="key" label="Từ khóa"/>
            <el-table-column fixed prop="value" label="Giá trị"/>
            <el-table-column fixed prop="is_active" label="Trạng thái">
              <template #default="scope">
                <el-button type="danger" v-if="!scope.row.is_active">Không hoạt động</el-button>
                <el-button type="success" v-if="scope.row.is_active">Hoạt động</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Hành động">
              <template #default="scope">
                <handle-button
                    label="Chi tiết"
                    eventCode=""
                    icon="fa fa-plus"
                    typeButton="primary"
                    @click="keywordDetail(scope.row.id)"
                />
                <handle-button
                    label="Xóa"
                    eventCode=""
                    icon="fa fa-trash"
                    typeButton="danger"
                    @click="openConfirmDelete(scope.row.id)"
                />

              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Hình ảnh" name="image">
        <div id="managementBatches" class="bg-white p-3">
          <el-table v-loading="loading" :data="tableData.imageTableData" style="width: 100%" fixed border>
            <el-table-column fixed prop="id" label="ID" width="50"/>
            <el-table-column fixed prop="key" label="Từ khóa"/>
            <el-table-column
                label="Hình ảnh"
                fixed
                width="150"
            >
              <template #default="scope">
                <el-image :src="scope.row.value" style="width: 100px; height: 100px" :fit="fit">
                  <div slot="placeholder" class="image-slot">
                    Loading<span class="dot">...</span>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column fixed prop="value" label="Giá trị"/>
            <el-table-column fixed prop="is_active" label="Trạng thái">
              <template #default="scope">
                <el-button type="danger" v-if="!scope.row.is_active">Không hoạt động</el-button>
                <el-button type="success" v-if="scope.row.is_active">Hoạt động</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Hành động">
              <template #default="scope">
                <handle-button
                    label="Chi tiết"
                    eventCode=""
                    icon="fa fa-plus"
                    typeButton="primary"
                    @click="keywordDetail(scope.row.id,configs.typeSetting[1])"
                />
                <handle-button
                    label="Xóa"
                    eventCode=""
                    icon="fa fa-trash"
                    typeButton="danger"
                    @click="openConfirmDelete(scope.row.id)"
                />

              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Thông tin hệ thống" name="system">
        <div id="managementBatches" class="bg-white p-3">
          <el-table v-loading="loading" :data="tableData.systemTableData" style="width: 100%" fixed border>
            <el-table-column fixed prop="id" label="ID" width="50"/>
            <el-table-column fixed prop="key" label="Từ khóa"/>
            <el-table-column fixed prop="value" label="Giá trị"/>
            <el-table-column fixed prop="is_active" label="Trạng thái">
              <template #default="scope">
                <el-button type="danger" v-if="!scope.row.is_active">Không hoạt động</el-button>
                <el-button type="success" v-if="scope.row.is_active">Hoạt động</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Hành động">
              <template #default="scope">
                <handle-button
                    label="Chi tiết"
                    eventCode=""
                    icon="fa fa-plus"
                    typeButton="primary"
                    @click="keywordDetail(scope.row.id)"
                />
                <handle-button
                    label="Xóa"
                    eventCode=""
                    icon="fa fa-trash"
                    typeButton="danger"
                    @click="openConfirmDelete(scope.row.id)"
                />

              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Nội quy" name="rule">
        <div id="managementBatches" class="bg-white p-3">
          <el-table v-loading="loading" :data="tableData.ruleTableData" style="width: 100%" fixed border>
            <el-table-column fixed prop="id" label="ID" width="50"/>
            <el-table-column fixed prop="title" label="Tiêu đề"/>
            <el-table-column fixed prop="value" label="Giá trị"/>
            <el-table-column fixed prop="code_system" label="Loại">
              <template #default="scope">
                <el-button type="success" v-if="scope.row.code_system === configs.typeRuleSetting[1]">Điểm cộng
                </el-button>
                <el-button type="danger" v-if="scope.row.code_system === configs.typeRuleSetting[0]">Điểm trừ
                </el-button>
              </template>
            </el-table-column>
            <el-table-column fixed prop="is_active" label="Trạng thái">
              <template #default="scope">
                <el-button type="danger" v-if="!scope.row.is_active">Không hoạt động</el-button>
                <el-button type="success" v-if="scope.row.is_active">Hoạt động</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Hành động">
              <template #default="scope">
                <handle-button
                    label="Chi tiết"
                    eventCode="system-infos.update"
                    icon="fa fa-plus"
                    typeButton="primary"
                    @click="keywordDetail(scope.row.id)"
                />
                <handle-button
                    label="Xóa"
                    eventCode="system-infos.destroy"
                    icon="fa fa-trash"
                    typeButton="danger"
                    @click="openConfirmDelete(scope.row.id)"
                />

              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Quy định" name="ruleClass">
        <div id="managementBatches" class="bg-white p-3">
          <el-table v-loading="loading" :data="tableData.ruleClassTableData" style="width: 100%" fixed border>
            <el-table-column fixed prop="id" label="ID" width="50"/>
            <el-table-column fixed prop="title" label="Tiêu đề"/>
            <el-table-column fixed prop="value" label="Lớp"/>
            <el-table-column fixed prop="is_active" label="Trạng thái">
              <template #default="scope">
                <el-button type="danger" v-if="!scope.row.is_active">Không hoạt động</el-button>
                <el-button type="success" v-if="scope.row.is_active">Hoạt động</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Hành động">
              <template #default="scope">
                <handle-button
                    label="Chi tiết"
                    eventCode="system-infos.update"
                    icon="fa fa-plus"
                    typeButton="primary"
                    @click="keywordDetail(scope.row.id)"
                />
                <handle-button
                    label="Xóa"
                    eventCode="system-infos.destroy"
                    icon="fa fa-trash"
                    typeButton="danger"
                    @click="openConfirmDelete(scope.row.id)"
                />

              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Video nổi bật" name="featureVideo">
        <div id="managementBatches" class="bg-white p-3">
          <el-table v-loading="loading" :data="tableData.featuredVideo" style="width: 100%" fixed border>
            <el-table-column fixed prop="id" label="ID" width="50"/>
            <el-table-column fixed prop="key" label="Tiêu đề"/>
            <el-table-column fixed prop="value" label="Link youtube"/>
            <el-table-column
                label="Ảnh bìa"
                fixed
                width="150"
            >
              <template #default="scope">
                <el-image :src="scope.row.description" style="width: 100px; height: 100px" :fit="fit">
                  <div slot="placeholder" class="image-slot">
                    Loading<span class="dot">...</span>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column fixed prop="is_active" label="Trạng thái">
              <template #default="scope">
                <el-button type="danger" v-if="!scope.row.is_active">Không hoạt động</el-button>
                <el-button type="success" v-if="scope.row.is_active">Hoạt động</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Hành động">
              <template #default="scope">
                <handle-button
                    label="Chi tiết"
                    eventCode=""
                    icon="fa fa-plus"
                    typeButton="primary"
                    @click="keywordDetail(scope.row.id,configs.typeSetting[1])"
                />
                <handle-button
                    label="Xóa"
                    eventCode=""
                    icon="fa fa-trash"
                    typeButton="danger"
                    @click="openConfirmDelete(scope.row.id)"
                />

              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="updateKeyWord">
      <el-dialog
          v-model="setup.dialogUpdate"
          width="40%"
          @close="closeDialog"
      >
        <template #title>
          <div class="text-center text-primary">
            <h4>Cập nhật từ khóa</h4>
          </div>
        </template>
        <el-form
            ref="formUpdate"
            class="login-form"
            :model="formUpdate"
            label-position="top"
            :rules="rulesUpdate"
        >
          <el-form-item v-if="formUpdate.code_system !== 'rules_Class'" prop="key" label="Từ khóa:">
            <el-input
                v-model="formUpdate.key"
                placeholder="Từ khóa"
                disabled
            />
          </el-form-item>
          <el-form-item prop="title" label="Tiêu đề:">
            <el-input
                v-model="formUpdate.title"
                placeholder="Tiêu đề"
            />
          </el-form-item>
          <div style="margin-bottom: 20px">
            <span style="font-weight: 500; font-size: 16px;margin-bottom: 8px;line-height: 22px;">Mô tả:</span>
            <editorTiny v-model="formUpdate.description"
                        style="height:300px; overflow-y: hidden; border-bottom: 1px solid #ccc;"/>
          </div>


          <el-form-item v-if="formUpdate.code_system !== 'rules_Class'" prop="value" label="Giá trị:"  >
            <el-input

                v-model="formUpdate.value"
                placeholder="Giá trị"
            />

          </el-form-item>
          <el-form-item v-else prop="key" label="Dành cho lớp:">
            <el-radio-group v-model="formUpdate.value">
              <el-radio :label="'Fulltime'">Fulltime</el-radio>
              <el-radio :label="'Parttime'">Parttime</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item  prop="is_active" label="Trạng thái:">
            <el-radio-group v-model="formUpdate.is_active">
              <el-radio :label="1">Hoạt động</el-radio>
              <el-radio :label="0">Dừng hoạt động</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" :icon="Close" @click="closeDialog">Hủy</el-button>
           <handle-button
               label="Cập nhật"
               eventCode="system-infos.update"
               icon="fa fa-plus"
               typeButton="primary"
               @click="updateKeyWord"
           />
        </span>
        </template>
      </el-dialog>
    </div>
    <div class="updateKeyWordImage">
      <el-dialog
          v-model="setup.dialogUpdateImage"
          width="40%"
          @close="closeDialogImage"
      >
        <template #title>
          <div class="text-center text-primary">
            <h4>Cập nhật từ khóa</h4>
          </div>
        </template>
        <el-form
            ref="formUpdate"
            class="login-form"
            :model="formUpdate"
            label-position="top"
            :rules="rulesUpdate"
        >
          <el-form-item prop="key" label="Từ khóa">
            <el-input
                v-model="formUpdate.key"
                placeholder="Từ khóa"
                disabled
            />
          </el-form-item>
          <el-form-item prop="value" label="Giá trị">
            <el-input v-if="file" v-model="formUpdate.value" autocomplete="off" disabled/>
            <el-upload
                v-if="!file"
                limit="1"
                action="/"
                ref="uploadFile"
                :before-upload="beforeAvatarUpload"
            >
              <el-button type="primary" :icon="UploadFilled">Chon tệp</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item prop="is_active" label="Trạng thái">
            <el-radio-group v-model="formUpdate.is_active">
              <el-radio :label="1">Hoạt động</el-radio>
              <el-radio :label="0">Dừng hoạt động</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" :icon="Close" @click="closeDialog">Hủy</el-button>
          <el-button type="primary" :icon="Plus" @click="updateKeyWord(configs.typeSetting[1])">Cập nhật</el-button>
        </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <confirm v-model="confirmDelete" @close="closeConfirmDelete" @deleteService="removeKeyWord()"></confirm>
</template>
<script>
import {notifyWarning, notifySuccess, notifyErr, setBreadcrumb, isImage} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import configs from "@/configs";
import {DeleteSystemInfo, GetSystemInfo, UpdateSystemInfo} from "@/services/setting";
import confirm from "@/components/confirm";
import {textInputValidate} from "@/validator";
import {UploadFile} from "@/services/common";
import editorTiny from "@/components/editor";


export default {
  name: 'SettingList',
  mixins: [setBreadcrumb],
  components: {
    confirm,
    editorTiny
  },
  data() {
    return {
      setup: {
        activeName: 'page',
        dialogUpdate: false,
        dialogUpdateImage: false,
      },
      tableData: {
        pageTableData: [],
        imageTableData: [],
        systemTableData: [],
        ruleTableData: [],
        ruleClassTableData: [],
        allTableData: []
        ,
        featuredVideo: []
      },
      NameRouter: NameRouter,
      configs: configs,
      loading: false,
      confirmDelete: false,
      idKeyWordDelete: "",
      formUpdate: {
        id: "",
        code_system: "",
        key: "",
        value: "",
        is_active: "",
        description: '',
        title: "",
      },
      rulesUpdate: {
        value: [textInputValidate],
        description: [textInputValidate],
      },
      file: null,
    }
  },
  created() {
    this.getListSystemInfo();
  },
  watch: {
    file() {
      if (!this.file) return false;
      this.createFile()
    }
  },

  methods: {
    async getListSystemInfo() {
      try {
        let res = await GetSystemInfo();
        this.tableData.allTableData = res.data.data.listSystemInfo.data;
        let dataPage = [];
        let dataSystem = [];
        let dataImage = [];
        let dataRule = [];
        let dataRuleClass = [];
        let dataFeaturedVideo = []
        this.tableData.allTableData.map(i => {

          if (i.code_system === this.configs.typeSetting[0]) {
            dataSystem.push(i);
          }
          if (i.code_system === this.configs.typeSetting[1]) {
            let item = {...i, value: this.configs.baseUrlImage + i.value}
            dataImage.push(item);
          }
          if (i.code_system === this.configs.typeSetting[2]) {
            dataPage.push(i);
          } if (i.code_system === "rules_Class") {
            dataRuleClass.push(i);
          }
          if (this.configs.typeRuleSetting.includes(i.code_system)) {
            dataRule.push(i);
          }  if (i.code_system === "featured_video") {
            dataFeaturedVideo.push(i);
          }
        })
        this.tableData.pageTableData = dataPage;
        this.tableData.imageTableData = dataImage;
        this.tableData.systemTableData = dataSystem;
        this.tableData.ruleTableData = dataRule;
        this.tableData.ruleClassTableData = dataRuleClass;
        this.tableData.featuredVideo = dataFeaturedVideo;
      } catch (e) {
        notifyErr()
      }
    },
    keywordDetail(id, type) {
      if (!type) {
        this.setup.dialogUpdate = true;
      } else {
        this.setup.dialogUpdateImage = true;
      }

      let keyWordUpdate = this.tableData.allTableData.find(i => i.id === id);
      this.formUpdate = {
        id: keyWordUpdate.id,
        code_system: keyWordUpdate.code_system,
        key: keyWordUpdate.key,
        value: keyWordUpdate.value,
        is_active: keyWordUpdate.is_active,
        description: keyWordUpdate.description,
        title: keyWordUpdate.title,
      }
    },
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) return notifyWarning('Vui lòng chọn tệp ảnh.')
      this.file = uploadFile
    },
    async createFile() {
      let formData = new FormData
      formData.append('file', this.file)
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          this.formUpdate.value = e.data.data.filePath;
        }
      })
    },
    async removeKeyWord() {
      try {
        await DeleteSystemInfo(this.idKeyWordDelete);
        notifySuccess('delete')
        this.confirmDelete = false;
        this.idKeyWordDelete = "";
        this.getListSystemInfo();
      } catch (e) {
        notifyErr()
      }
    },
    openConfirmDelete(id) {
      this.confirmDelete = true
      this.idKeyWordDelete = id
    },
    closeConfirmDelete() {
      this.confirmDelete = false
      this.idKeyWordDelete = ""
    },
    closeDialog() {
      this.setup.dialogUpdate = false
      this.$refs.formUpdate.resetFields()
    },
    closeDialogImage() {
      this.setup.dialogUpdateImage = false
      this.$refs.formUpdate.resetFields()
    },
    async updateKeyWord(type) {
      if (type === this.configs.typeSetting[1]) {
        if (!this.file) {
          return notifyWarning("Bạn cần upload file mới để cập nhật");
        }
      }
      let valid = await this.$refs.formUpdate.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return false;

      try {
        await UpdateSystemInfo(this.formUpdate.id, this.formUpdate);
        notifySuccess("edit")
        this.closeDialog();
        this.closeDialogImage();
        await this.getListSystemInfo();
      } catch (e) {
        notifyErr()
      }

    }
  }

}
</script>
<script setup>
import {
  UploadFilled,
  Plus,
  Close
} from '@element-plus/icons-vue'
</script>


<template>
  <div id="managementBatches" class="px-3 py-3">
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border>
      <div class="search my-2">
        <el-row :gutter="10">
          <el-col :offset="16" :span="4">
            <el-input v-model="setup.keyword"/>
          </el-col>
          <el-col :span="4">
            <el-button :icon="Search" type="primary" @click="searchData">Tìm kiếm</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table-column prop="id" label="Id" min-width="50"/>
      <el-table-column
          label="Hình ảnh"
          width="160"
      >
        <template #default="scope">
          <el-image :src="scope.row.avatar" style="width: 100px; height: 100px" :fit="fit">
            <div slot="placeholder" class="image-slot">
              Loading<span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Tên lộ trình" min-width="150"/>
      <el-table-column prop="code" label="Mã code" min-width="100"/>
      <el-table-column prop="duration" label="Thời gian học(phút)" min-width="120"/>
      <el-table-column prop="type" label="Có module" min-width="110">
        <template #default="scope">
          <div class="pl-4">
            <el-checkbox :checked="scope.row.type === hasModule" disabled/>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="version" label="Phiên bản" min-width="120"/>
      <el-table-column prop="position" label="Vị trí" width="100"/>
      <el-table-column
          label="TT hoạt động"
          width="160">
        <template #default="scope">
          <el-button
              v-if="scope.row.isActive"
              type="success"
              size="small">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button
              type="warning"
              v-if="!scope.row.isActive" size="small">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          min-width="150">
        <template #default="scope">
          <handle-button
              type="link"
              label="Chi tiết"
              eventCode="roadmaps.show"
              icon="fa fa-eye"
              typeButton="primary"
              :to="{name: nameRouter.PAGES.DETAIL_ROADMAP, params: {id: scope.row.id}}"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetRoadmap} from "@/services/roadmap";

export default {
  name: "ListRoadmap",
  expose: ['getRoadmapById'],
  components: {
    pagination
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      hasModule: 1,
      notModule: 2,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
      setup: {
        keyword: null
      }
    };
  },
  watch: {},

  created() {
    this.getRoadmap()
  },
  methods: {
    getRoadmap() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage, keyword: this.setup.keyword}
      GetRoadmap(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.roadmaps.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.title,
              code: e.code,
              avatar: e.full_image_url,
              duration: e.duration && e.duration > 0 ? e.duration / 60 : 0,
              position: e.sort_order,
              isActive: e.is_active,
              version: e.version,
              type: e.type,
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.roadmaps.total
          this.isShowPagination = e.data.data.roadmaps.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getRoadmap(index);
    },
    searchData(index) {
      this.currentPage = 1
      this.getRoadmap(index);
    },
  }
}

</script>
<script setup>``
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {
  Check,
  Plus,
  Edit,
  Close,
  Search,
  Star,
} from '@element-plus/icons-vue'

const disabledDate = (time, data) => {

  return time.getTime() > Date.now()
}
const dialogAdd = ref()
</script>
<style scoped lang="scss">
::v-deep .cell {
  word-break: break-word !important;
}
</style>


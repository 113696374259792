<template>
  <div id="addStudentsToBatch">
    <div class="bg-white p-3 ">
      <div class="mb-4">
        <el-form label-position="top" :model="checkinForm" ref="checkinFormRef">
          <el-row :gutter="20">
            <el-col :span="8">
              <batch-select :isMultiple="false" v-model="checkinForm.batchId"></batch-select>
            </el-col>
            <el-col :span="8" style="align-self: center" class="pt-4">
              <el-button type="primary" class="mt-2" @click="searchBatch"
              ><i class="fa fa-search"></i> Tìm lớp điểm danh
              </el-button>

            </el-col>

          </el-row>
        </el-form>
      </div>
      <div class="p-3" v-if="checkinForm.batchId">
        Sĩ số: {{ `${countCheckIn}/${tableData.length}` }}
      </div>
      <div>
        <div class="text-center pt-3"><h5>Danh sách học sinh</h5></div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :disabled="disableTable"
          ref="studentTable"
          border
          v-loading="loading"
        >
          <el-table-column prop="id" label="ID học sinh" width="150"/>
          <el-table-column prop="username" label="Tên đăng nhập"/>
          <el-table-column prop="name" label="Họ tên"/>
          <el-table-column label="TT làm bài" >
            <template #default="scope">
              <el-select v-model="scope.row.status" class="m-2" placeholder="Lựa chọn" size="small" @change="handleChangeStatus(scope.row.id)">
                <el-option
                  v-for="item in optionsLeave"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table>
        <div class="py-3 text-center ">
          <handle-button
            label="Điểm danh"
            eventCode="batch_reports.record-homework-for-student"
            icon="fa fa-plus"
            typeButton="primary"
            v-if="tableData.length > 0 "
            @click="checkinAtBatch"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {CreateHistoriesHomework, StudentCheckInBatch, StudentInBatch,} from "@/services/batches";
import {formatDataServe, notifyErr, notifySuccess, notifyWarning,} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import "vue-select/src/scss/vue-select.scss";
import configs from "@/configs";
import batchSelect from "@/views/Batches/batchSelect";
import moment from "moment";
import selectStatus from "@/views/Batches/selectStatus.vue";

export default {
  name: "CheckHomeworkOnBatch",
  components: {
    batchSelect
  },
  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
      loading: false,
      selected: [],
      selectedCheckout: [],
      batches: [],
      checkinForm: {
        dateSelect: "",
        batchId: "",
      },
      dateDefault: new Date(),
      keyword: "",
      hasNextPage: false,
      messageErr: "",
      checked: [],
      dateNow: true,
      setup: {
        dialogVisible: false,
        tabRollCall: 1,
      },
      disableTable: true,
      countCheckIn: 0,
      config: configs,
      typeRollCall: '',
      listStudentLate: [],
      listStudentChecked: [],
      checkedOutStudent: [],
      optionsLeave: [
        {id: 1, name: "Làm đầy đủ"},
        {id: 2, name: "Không làm bài"},
      ],
    };
  },
  watch: {
    tableData: {
      handler(newTable) {
        let that = this;
        that.$nextTick(function () {
          that.toggleSelection();
        });
        this.checkedIn();
      },
      immediate: true,
    },
    "checkinForm.dateSelect": {
      handler(newTable) {
        this.tableData = [];
      },
      immediate: true,
    },
  },

  methods: {
    async checkinAtBatch(params = "") {
      if (!params) {
        if (this.selected.length < 1) return notifyWarning("Vui lòng chọn học viên.");
        let data = [];
        let error = false
        this.selected.map((e) => {
          let itemRollCall = this.tableData.find(item => item.id === e)
          if (!itemRollCall.status) return error = itemRollCall.username
          data.push({id: e, status: itemRollCall.status})
        });
        if (error) return notifyErr('Chưa chọn trạng thái làm bài của học viên: ' + error)
        params = {
          students: data,
          batch_id: this.checkinForm.batchId,
        };

      }
      CreateHistoriesHomework(params).then((e) => {
        if (e.data.status === "success") {
          notifySuccess("add", "Cập nhật thành công.");
          this.searchBatch();
        }
      }).catch((err) => {
        if (!err.response) return false
        notifyErr(err.response.data.message);
      });
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex((id) => row.id === id);
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1);
      }
      if (indexItem < 0) {
        this.selected.push(row.id);
      }
    },
    handleSelectAll(val) {
      if (val) {
        val.map((e) => {
          if (this.selected.findIndex((id) => e.id == id) > -1) return;
          this.selected.push(e.id);
        });
      }
    },
    toggleSelection(table) {
      if (this.checked.length < 1) return false;
      this.checked.forEach((e) => {
        let item = this.tableData.find((item) => e == item.id);
        if (!item) return false;
        this.$refs.studentTable.toggleRowSelection(item);
      });
    },
    searchBatch() {
      let today = new Date();
      if (!this.checkinForm.batchId) return false;
      this.tableData = [];
      this.selected = [];
      this.loading = true;
      this.checked = [];
      StudentInBatch({
        batch_id: this.checkinForm.batchId,
        date_select: moment(today).format("YYYY-MM-DD"),
      })
        .then((e) => {
          let dataTable = [];
          if (e.data.status === "success") {
            let students = e.data.data.listStudentBatch;
            this.countCheckIn = 0;
            students.map((e) => {
              let item = e.students;
              let student = {
                id: item?.id,
                username: item?.username,
                email: item?.email,
                name: item?.name,
                phone: item?.phone_number,
                status: item?.histories_homework ? item?.histories_homework?.status_homework : '',
                date: '',
              }
              this.countCheckIn = item?.roll_call_report.length > 0 ? this.countCheckIn + 1 : this.countCheckIn;
              if (item?.histories_homework) {
                this.checked.push(item?.id)
                this.selected.push(item?.id)
              }
              dataTable.push(student);
            });
            if (this.checkinForm.dateSelect !== formatDataServe(new Date())) {
              this.dateNow = false;
              this.disableTable = true;
            } else {
              this.dateNow = true;
            }
            if (this.checked.length > 0) {
              this.toggleSelection();
            }
            this.tableData = dataTable;

          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err)
          this.loading = false;
        });
    },
    disabledDate(date) {
      let dateNew = new Date();
      return date.getTime() > dateNew.getTime();
    },
    checkedIn(row, index) {
      if (!row) return false;
      return true;
    },
    handleChangeStatus(id){
      if(this.selected.find(el => el ===id)) return false
      this.selected.push(id)
    }
  },
};

</script>
<script setup>
import {ref} from "vue";

const checkinFormRef = ref();
</script>
<style scoped lang="scss"></style>

<template>
  <div id="managementStudent" class="bg-white p-3">
    <div class="py-2 text-end">
      <el-row :gutter="20" :align="10">
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm tên học sinh" v-model="setup.search_name" />
        </el-col>
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm email" v-model="setup.search_email" />
        </el-col>
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm SĐT" v-model="setup.search_phone" />
        </el-col>
        <el-col :span="8" class="pb-3">
          <batch-select v-model="setup.search_batch" :show-label="false" :isMultiple="false" :add-option="true"
            ref="batchSelectBox" />
        </el-col>
        <el-col :span="8" class="pb-3">
          <el-select placeholder="Tìm kiếm trạng thái" class="w-100" v-model="setup.filter_active">
            <el-option :value="null" label="Tất cả" />
            <el-option :value="configs.status.active" :label="configs.status.activeStr" />
            <el-option :value="configs.status.inActive" :label="configs.status.inActiveStr" />
          </el-select>
        </el-col>
        <el-col :span="8">
          <handle-button label="Tìm kiếm" eventCode="students.list-student" icon="fa fa-search" typeButton="primary"
            @click="searchStudent" />
          <handle-button label="Làm mới" eventCode="students.list-student" icon="refresh" typeButton="warning"
            @click="refreshSearch" />
          <handle-button label="Tạo học sinh" eventCode="students.create-student" icon="fa fa-plus" typeButton="primary"
            @click="openDialogAdd" />
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" style="width: 100%" fixed v-loading="loading" border>
      <el-table-column prop="index" label="TT " width="100" />
      <el-table-column prop="id" label="ID " width="100" />
      <el-table-column prop="name" label="Họ tên" min-width="120" />
      <el-table-column prop="dob" label="Ngày sinh" min-width="100" />
      <el-table-column prop="email" label="Email" min-width="120" />
      <el-table-column prop="phone" label="Điện thoại" min-width="100" />
      <el-table-column prop="batchName" label="Tên lớp" min-width="170" />
      <el-table-column prop="createdAt" label="Ngày tạo" min-width="100" />
      <el-table-column fixed="right" label="TT hoạt động" width="150">
        <template #default="scope">
          <el-button type="success" size="small" v-if="scope.row.isActive">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button type="warning" size="small" v-if="!scope.row.isActive">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="400">
        <template #default="scope">
          <handle-button type-button="primary" icon="fa fa-eye" type="link" label="Chi tiết"
            event-code="students.get-detail-student" :to="{
              name: nameRouter.PAGES.DETAIL_STUDENT,
              params: { id: scope.row.id },
            }" />
          <handle-button label="Đặt lại mật khẩu" type-button="primary" icon="fa fa-lock"
            @click="confirmReset(scope.row.id)" />
          <handle-button :disabled="scope.row.isActive && scope.row.batchName ? disabled : ''" label="Chấm điểm"
            type-button="primary" icon="fa fa-pen" text @click="pointStudent(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <el-form ref="ruleFormRef" class="login-form" :model="ruleFormPoint" label-position="left" :rules="rulesFormPoint">

      <el-dialog v-model="centerDialogVisible" title="Chấm điểm" width="30%" center>
        <div class="py-2">
          <h6 class="tabpointstd">
            Tên người chấm : {{ userName }}
          </h6>
        </div>
        <div class="py-2">
          <h6 class="tabpointstd">Tên học viên : {{ selectedStudent.name }}</h6>
        </div>
        <div class="py-2">
          <h6 class="tabpointstd"> Tên lớp : {{ selectedStudent.class }}</h6>
        </div>
        <h6 class="tabpointstd">Lộ trình học :</h6>
        <roadmapSelect v-model="roadmap_id" :isDisabled="true" />
        <el-form-item prop="modulePoint">
          <courseSelect v-model="ruleFormPoint.modulePoint" />
        </el-form-item>

        <el-form-item v-if="isGuest" prop="nameGuest" class="py-3">
          <h6 class="tabpointstd">Tên chuyên gia :</h6>
          <el-input placeholder="Tên chuyên gia" required v-model="ruleFormPoint.nameGuest" />
        </el-form-item>
        <el-form-item prop="exampleStudent" class="mb-2 flex items-center text-sm">
          <el-radio-group v-model="ruleFormPoint.exampleStudent">
            <el-radio label="1" size="large" class="tabpointstd">Bài kiểm tra Hackathon</el-radio>
            <el-radio label="2" size="large" class="tabpointstd">Bài cuối module</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="pointstudent">
          <h6 style="margin: 0;" class="tabpointstd">
            Điểm học viên :
          </h6>
          <el-input-number size="small" v-model="ruleFormPoint.pointStudent" :min="0" :max="100" maxlength="100"
            @change="handleChange" />
        </div>
        <el-form-item prop="timeExample" class="examdate">
          <h6 style="margin: 0; margin-right: 10px;" class="tabpointstd">
            Thời gian :
          </h6>
          <el-date-picker v-model="ruleFormPoint.timeExample" type="daterange" range-separator="Đến"
            start-placeholder="Bắt đầu" end-placeholder="Kết thúc" :size="size" />
        </el-form-item>
        <el-form-item prop="commentsOnStd" style="margin-top: 20px;">
          <h6 class="tabpointstd">Nhận xét về học viên :</h6>
          <el-input v-model="ruleFormPoint.commentsOnStd" :rows="2" autocomplete="off" type="textarea"
            placeholder="Nhận xét" />
        </el-form-item>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">Hủy</el-button>
            <el-button type="primary" @click="savePointStd">
              Lưu
            </el-button>
          </span>
        </template>
      </el-dialog>
    </el-form>


    <div class="pb-3 text-center">
      <pagination v-show="isShowPagination" :total="total" :page-index="pageIndex" @currentPageChange="nextPage" />
    </div>


    <div class="addStudent">
      <el-dialog v-model="setup.dialogAdd" width="50%" :close-on-press-escape="false" :close-on-click-modal="false"
        @closed="closeDialog('dialogAdd')">
        <template #title>
          <div class="text-center text-primary">
            <h4>Thêm mới học sinh</h4>
          </div>
        </template>
        <el-form ref="formAdd" class="login-form" :model="model" label-position="top" :rules="rulesForm">
          <el-form-item prop="email" label="Email">
            <el-input v-model="model.email" placeholder="email"></el-input>
          </el-form-item>
          <el-form-item prop="name" label="Họ tên">
            <el-input v-model="model.name" placeholder="Họ tên" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="username" label="Tên đăng nhập">
            <el-input v-model="model.username" placeholder="Tên đăng nhập" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="phone_number" label="SĐT">
            <el-input v-model="model.phone_number" placeholder="Số điện thoại" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="Mật khẩu">
            <el-input v-model="model.password" placeholder="Password" type="password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Trạng thái" prop="isActive">
            <el-radio-group v-model="model.is_active">
              <el-radio :label="CONFIG_APP.status.active">{{
                CONFIG_APP.status.activeStr
              }}
              </el-radio>
              <el-radio :label="CONFIG_APP.status.inActive">{{
                CONFIG_APP.status.inActiveStr
              }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <template #footer>
          <div class="dialog-footer d-flex">
            <el-button type="danger" :icon="Close" @click="closeDialog('dialogAdd')">Hủy
            </el-button>
            <handle-button label="Thêm mới" eventCode="students.create-student" icon="fa fa-plus" typeButton="primary"
              @click="createStudent" />
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  CreatedStudent,
  GetListStudents,
  GetStudent,
  UpdateStudents,

} from "@/services/students";
import pagination from "@/components/pagination.vue";
import roadmapSelect from "@/components/roadmapSelect.vue";
import courseSelect from "@/views/Course/courseSelect.vue";
import configs from "@/configs";
import {
  formatDate,
  notifyErr,
  notifySuccess,
  setBreadcrumb,
} from "@/mixins/utils";
import {
  checkSpecialCharacter,
  textInputValidate,
  ValidService,
} from "@/validator";
import { NameRouter } from "@/constants/nameRouter";
import batchSelect from "@/views/Batches/batchSelect";
import { ElMessage, ElMessageBox } from 'element-plus'
import { resetPasswordStudent } from "../../services/students"
import isReserve from "./detailStudent.vue"

export default {
  expose: ['validForm'],
  name: "StudentList",
  components: {
    batchSelect,
    roadmapSelect,
    courseSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      validation: false,
      username: '',
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogDetail: false,
      roadmap_id: "",
      selectedStudent: {
        name: '',
        class: '',
      },
      model: {
        email: "",
        password: "",
        username: "",
        name: "",
        phone_number: "",
        is_active: configs.status.active,
      },
      rulesForm: {
        email: [textInputValidate, ValidService.checkEmail],
        password: [textInputValidate],
        username: [textInputValidate, checkSpecialCharacter],
        name: [textInputValidate, checkSpecialCharacter],
        phone_number: [textInputValidate, ValidService.checkPhone],
      },
      ruleFormPoint: {
        userName: this.$helpers.getRole().name,
        name: "",
        class: "",
        roadmap_id: "",
        modulePoint: "",
        commentsOnStd: "",
        timeExample: "",
        nameGuest: "",
        pointStudent: "",
        exampleStudent: "",
      },
      rulesFormPoint: {
        commentsOnStd: [textInputValidate],
        modulePoint: [textInputValidate],
        timeExample: [textInputValidate],
        nameGuest: [textInputValidate],
        exampleStudent: [textInputValidate],
      },

      setup: {
        search_name: null,
        search_phone: null,
        search_email: null,
        search_batch: null,
        filter_active: null,
        dialogAdd: false,
      },
      nameRouter: NameRouter,
      currentId: "",
      configs: configs,
      nameMaster: "",
      isGuest: this.$helpers.getRole().name == "Guest",
    };

  },

  created() {
    let infoUser = this.$helpers.getRole()
    this.userName = infoUser.name

    let query = this.$router.currentRoute?.value?.query;
    let { search_name, search_phone, search_email, search_batch, filter_active, per_page, page } = query;
    if (search_name || search_phone || search_email || search_batch || filter_active || per_page || page) {
      this.setup = { ...query, dialogAdd: false }
      this.getStudents(query);
    } else {
      this.getStudents();
    }
  },

  methods: {
    getStudents(param) {
      this.loading = true;
      if (!param) {
        param = { per_page: configs.itemOnPage, page: this.currentPage };
      }
      GetListStudents(param)
        .then((e) => {
          let dataTable = [];
          if (e.data.status === "success") {
            let students = e.data.data.list_student.data;
            students.map((e, index) => {
              dataTable.push({
                id: e.id,
                index: index + 1,
                username: e.username,
                email: e.email,
                name: e.name,
                isActive: e.is_active == configs.status.active,
                phone: e.phone_number,
                batchName: e.batch_students.length > 0 && isReserve == true ? e.batch_students[0].batches.batch_name = "Bảo lưu" : e.batch_students.length > 0 ?
                  e.batch_students[0].batches.batch_name : "",
                roadmapId: e.batch_students.length > 0 ? e.batch_students[0]?.batches.roadmap_id : null,
                createdAt: formatDate(e.created_at),
                dob: e.dob ? formatDate(e.dob) : "Chưa cập nhật",
              });
            });
            this.total = e.data.data.list_student.total;
            this.isShowPagination = e.data.data.list_student.total > 1;
          }
          this.loading = false;
          this.tableData = dataTable;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.getStudents(params);
    },

    closeDialog(type) {
      if (type == "dialogAdd") {
        this.setup.dialogAdd = false;
        this.$refs.formAdd.resetFields();
      }
      return (this.dialogDetail = false);
    },

    async openDialog(id) {
      this.loading = true;
      this.currentId = id;
      await GetStudent({ student_id: id })
        .then((e) => {
          this.infoStudent = e.data.data;
          this.dialogDetail = true;
        })
        .catch((err) => {
          notifyErr();
        });
      this.loading = false;
    },
    openDialogAdd() {
      return (this.setup.dialogAdd = true);
    },
    async createStudent() {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      let valid = await this.$refs.formAdd.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return false;
      let text = (
        this.model.username.charAt(0) +
        "" +
        this.model.username.charAt(1)
      ).toUpperCase();
      this.model.avatar =
        "https://via.placeholder.com/640x480.png/" +
        randomColor +
        "?text=" +
        text;
      CreatedStudent(this.model)
        .then((e) => {
          if (e.data.status == "success") {
            notifySuccess("add");
            this.getStudents();
            this.closeDialog("dialogAdd");
            this.setup.dialogAdd = false;
            this.$refs.formAdd.resetFields();
          }
          if (e.data.status == "failed") {
            notifyErr(e.data.message);
            this.getStudents();
          }
        })
        .catch((err) => {
          notifyErr(err.response.data.message);
        });
    },

    searchStudent() {
      this.currentPage = 1;
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.$router.push({ path: location.pathname, query: params })

      this.getStudents(params);
    },
    refreshSearch() {
      this.currentPage = 1;
      this.setup = {
        search_name: null,
        search_phone: null,
        search_email: null,
        search_batch: null,
        filter_active: null,
      };
      let params = this.setup;
      params.page = this.currentPage;
      params.per_page = configs.itemOnPage;
      this.$router.push({ path: location.pathname, query: {} })
      this.getStudents(params);
      this.$refs.batchSelectBox.resetData();
    },
    confirmReset(id) {

      ElMessageBox.confirm(
        'Bạn có muốn đặt lại mật khẩu?',
        'Xác nhận',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Hủy',
          type: 'warning',
        }
      )
        .then(() => {
          resetPasswordStudent({ student_id: id })
          ElMessage({
            type: 'success',
            message: 'Đặt lại lại thành công',
          })
        })
    },
    async pointStudent(user) {

      this.centerDialogVisible = true;
      this.selectedStudent.name = user.name
      this.selectedStudent.class = user.batchName
      this.roadmap_id = user.roadmapId
      this.ruleFormPoint.name = user.name
      this.ruleFormPoint.class = user.batchName
      this.ruleFormPoint.roadmap_id = user.roadmapId
      this.ruleFormPoint.id = user.id
    },

    async savePointStd() {
      let valid = await this.$refs.ruleFormRef.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) false;
      ElMessageBox.confirm(
        'Lưu điểm học viên',
        'Xác nhận',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Hủy',
          type: 'warning',
        }
      )
        .then(() => {
          this.centerDialogVisible = false;
          ElMessage({
            type: 'success',
            message: 'Lưu điểm thành công',
          })
        })
    },
  },
};


</script>
<script setup  >
import CONFIG_APP from "@/configs";
import { Check, Close } from "@element-plus/icons-vue";

import { ref } from 'vue'

const centerDialogVisible = ref(false)

const radio1 = ref('1')

const value1 = ref('')

const textarea = ref('')

const input = ref('')

const num = ref(1)
const handleChange = (value) => {
  console.log(value)
}

</script>
<style scoped lang="scss">
* {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #333333
}

.pointstudent {
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-bottom: 20px;
}

#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}

.tabpointstd {
  font-weight: 400 !important;
}


.examdate {
  display: flex;
  justify-content: space-between;
}

.infoStudent {

  th,
  td {
    text-align: left;
    padding: 0.8rem;
    border-bottom: 1px solid #bbbbbb;
  }
}

.el-textarea__inner {
  line-height: 2;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
}
</style>

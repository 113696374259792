<template>
  <div class="w-100">
    <label v-if="showLabel"> <span class="text-danger">*</span> Bài học</label>
    <vSelect
        v-model="sectionId"
        placeholder="Lựa chọn bài học"
        :disabled="disableSelect"
        :options="sections"
        label="name"
        :class="{'is-invalid': validation}"
        :reduce="(section) => section.id"
        class="w-100"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import {GetListSection} from "@/services/course";
import CONFIG_APP from "@/configs";

export default {
  expose: ['resetData', 'getSectionCurrent', 'searchSectionOfReport', 'searchByCourse'],
  name: "Section-select",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn bài học.'},
    validation: {type: Boolean, default: false},
    modelValue: String || Number,
    idOld: {type: Array, default: null},
    showLabel: {type: Boolean, default: true},
  },
  data() {
    return {
      sectionId: null,
      hasNextPage: false,
      disableSelect: false,
      sections: [],
      page: 1,
      courseId: null,
      hasGetMaterial: 0  // 1 == true; 0 == false;
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {
    sectionId() {
      this.$emit('selected', this.sectionId)
      this.$emit('update:modelValue', this.sectionId)
    },
    sections() {
      this.sectionId = this.modelValue
    }
  },
  created() {
    this.getSection()
  },

  methods: {
    getSection(params) {
      this.hasNextPage = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page, get_material: this.hasGetMaterial}
      }
      if (this.idOld) {
        params.per_page = this.per_page + this.idOld.length
      }
      GetListSection(params).then(e => {
        if (e.data.status === 'success') {
          let data = e.data.data.sections.data
          if (this.idOld) {
            this.idOld.map(id => {
              let index = data.findIndex(el => el.id === id)
              if (index > -1) {
                data.splice(index, 1)
              }
            })
          }
          this.sections = data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      let params = {
        per_page: this.per_page,
        page: 1,
        keyword: query,
        course_id: this.courseId,
        get_material: this.hasGetMaterial
      }
      this.getSection(params)
    },
    resetData() {
      this.moduleId = null
    },
    searchByCourse(id) {
      this.hasNextPage = true
      this.courseId = id
      let params = {per_page: this.per_page, page: 1, course_id: id}
      this.getSection(params)
    },
    searchSectionOfReport(id, hasGetMaterial) {
      this.hasNextPage = true
      this.courseId = id;
      this.hasGetMaterial = hasGetMaterial
      let params = {per_page: 999, page: 1, course_id: id, get_material: hasGetMaterial}
      this.getSection(params)
    },
    getSectionCurrent() {
      return this.sections.find(item => item.id === this.sectionId)
    }
  },

}
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}
::v-deep .vs__actions {
  button {
    font-size: 8px !important;
  }
}
</style>

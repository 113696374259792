<template>
  <div id="managementBatches" class="bg-white p-3">
    <div style="margin-bottom: 20px">
      Chi tiết sự kiện ngày {{ detailDate }}
    </div>
    <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
      <el-tab-pane label="Thông tin" name="info">
        <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed border
                  order @select="handleSelected" @selection-change="handleSelectAll"
        >
          <el-table-column type="selection" width="40"/>
          <el-table-column fixed prop="id" label="ID" width="50"/>
          <el-table-column fixed label="Tên sự kiện" width="450">
            <template #default="scope">
              <span>{{ scope.row.event.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="Trạng thái"
              width="250"
          >
            <template #default="scope">
              <el-button
                  v-if="scope.row.date_loop==configs.dateLoop[1].value"
                  type="danger" size="small"
              >
                {{ configs.statusEvent[1] }}
              </el-button>
              <el-button
                  v-if="scope.row.date_loop==0"
                  type="success" size="small"
              >
                {{ configs.statusEvent[0] }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              width="400"
              label="Hành động"
          >
            <template #default="scope">
              <div class="d-flex">
                <el-button
                    size="small"
                    :icon="Edit"
                    type="primary"
                    @click="viewDetail(scope.row.id,scope.row.event.id)"
                >
                  Chi tiết
                </el-button>
                <el-button
                    size="small"
                    type="danger"
                    :icon="Delete"
                    @click="openConfirmDelete(scope.row.event.id,0)"
                >
                  Xóa 1 lần
                </el-button>
                <el-button
                    size="small"
                    v-if="scope.row.date_loop==1"
                    :icon="Delete"
                    type="danger"
                    @click="openConfirmDelete(scope.row.event.id,1,scope.row.day_or_date,scope.row.type_loop_date)"
                >
                  Xóa nhiều lần
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb-3 text-center">
          <pagination
              v-show="isShowPagination"
              :total="total"
              :page-index="pageIndex"
              @currentPageChange="nextPage"
          />
        </div>
        <div class="pb-3 mt-3 text-center">
          <handle-button
              icon="fa fa-trash"
              type="danger"
              :disabled="selected.length === 0"
              label="Xóa"
              event-code="events.delete-events"
              @click="isOpenCofirmDeleteMulti=true"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Thông tin chi tiết" name="info-detail">
        <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm" class="bg-white p-3">
          <el-row>
            <el-col :span="24">
              <el-form-item class="w-100" prop="day" label="Chọn ngày">
                <el-date-picker
                    class="w-100"
                    v-model="form.day"
                    type="daterange"
                    start-placeholder="Ngày bắt đầu"
                    end-placeholder="Ngày kết thúc"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item class="w-100" prop="type_update" label="Kiểu cập nhật">
                <el-select class="w-100" v-model="form.type_update" placeholder="Chọn kiểu cập nhật">
                  <el-option
                      v-for="item in configs.typeUpdate"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="w-100" prop="event_is_day_off" label="Kiểu ngày">
                <el-select class="w-100" v-model="form.event_is_day_off" placeholder="Chọn kiểu ngày">
                  <el-option
                      v-for="item in configs.isDayOff"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="w-100" prop="day_or_date" label="Lặp theo (thứ / ngày)"
                            v-if="form.date_loop == '1' ">
                <el-select class="w-100" v-model="form.day_or_date" placeholder="Chọn lặp theo (thứ / ngày )">
                  <el-option
                      v-for="item in configs.dayOrDate"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="w-100" prop="event_name" label="Tên sự kiện">
                <el-input v-model="form.event_name" placeholder="Tên sự kiện"/>
              </el-form-item>
              <el-form-item class="w-100" prop="date_loop" label="Số lần lặp">
                <el-select class="w-100" v-model="form.date_loop" placeholder="Chọn số lần lặp">
                  <el-option
                      v-for="item in configs.dateLoop"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="w-100" prop="type_loop_date" label="Lặp theo (tháng / năm)"
                            v-if="form.date_loop == configs.dateLoop[1].value && form.day_or_date == configs.dateLoop[0].value ">
                <el-select class="w-100" v-model="form.type_loop_date" placeholder="Chọn lặp theo (tháng / năm )">
                  <el-option
                      v-for="item in configs.typeLoopDate"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="text-center py-3 px-3">
          <handle-button
              label="Cập nhật sự kiện"
              eventCode=""
              icon="fa fa-edit"
              typeButton="primary"
              @click="updateEvent"/>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>

  <confirm
      v-model="isOpenCofirmDeleteMulti"
      @close="isOpenCofirmDeleteMulti=false"
      @deleteService="removeMultiEvent"/>
  <confirm
      v-model="isOpenConfirm"
      @close="closeConfirmDeleteEvent"
      @deleteService="removeEvent"/>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import confirm from "@/components/confirm";
import {DeleteEventInDay, GetListEvent, DeleteEvent, UpdateEvent, GetDetailEvent} from "@/services/event";
import {textInputValidate} from "@/validator";


export default {
  name: "DetailDayEvent",
  components: {
    pagination,
    confirm
  },
  mixins: [setBreadcrumb],

  data() {
    return {
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      configs: configs,
      nameRouter: NameRouter,
      selected: [],
      isOpenCofirmDeleteMulti: false,
      isOpenConfirm: false,
      idDelete: null,
      type_delete: "",
      day_or_date: "",
      type_loop_date: "",
      setup: {
        activeName: "info"
      },
      form: {},
      addContentRule: {
        type_update: [textInputValidate],
        day: [textInputValidate],
        event_name: [textInputValidate],
        date_loop: [textInputValidate],
        day_or_date: [textInputValidate],
        type_loop_date: [textInputValidate],
        event_is_day_off: [textInputValidate]
      },
      idEditEvent: "",
      idEditChildEvent: "",
    };
  },
  computed: {
    detailDate() {
      let arrayId = this.$route.params.id.split("-")
      return arrayId[2] + '-' + arrayId[1] + '-' + arrayId[0];
    }
  },

  created() {
    this.getDetailDayEvent();
  },
  methods: {
    async getDetailDayEvent() {
      this.loading = true
      let dataTable;
      GetListEvent({date: this.$route.params.id}).then(res => {
        if (res.data.status === 'success') {
          dataTable = res.data.data.listEvent[this.$route.params.id]
          this.tableData = dataTable
          this.loading = false;
        }
      }).catch(e => {
        return notifyErr(e.response?.data.message)
      })
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex((id) => row.id == id);

      if (indexItem > -1) {
        this.selected.splice(indexItem, 1);
      }
      if (indexItem < 0) {
        this.selected.push(row.id);
      }
    },
    handleSelectAll(val) {
      if (val.length > 0) {
        val.map((e) => {
          if (this.selected.findIndex((id) => e.id == id) > -1) return;
          this.selected.push(e.id);
        });
      } else {
        this.selected = []
      }
    },
    async removeMultiEvent() {
      let params = {
        day: this.$route.params.id,
        event_detail_ids: this.selected,
      }
      DeleteEventInDay(params).then(res => {
        this.loading = true;
        if (res.data.status === 'success') {
          this.isOpenCofirmDeleteMulti = false
          notifySuccess("delete")
          this.getDetailDayEvent();
        }
        this.loading = false;
      }).catch(e => {
        return notifyErr(e.response.data.message)
      })
    },
    openConfirmDelete(id, type, day_or_date, type_loop_date) {
      this.selected = [];
      this.isOpenConfirm = true
      this.idDelete = id
      this.type_delete = type
      this.day_or_date = day_or_date
      this.type_loop_date = type_loop_date
    },
    closeConfirmDeleteEvent() {
      this.isOpenConfirm = false
      this.idDelete = null
      this.type_delete = ""
      this.day_or_date = ""
      this.type_loop_date = ""
    },
    async removeEvent() {
      let params = {
        event_day: this.$route.params.id,
        type_delete: this.type_delete,
        day_or_date: this.day_or_date,
        type_loop_date: this.type_loop_date,
        event_id: this.idDelete,
      }
      DeleteEvent(params).then(res => {
        this.loading = true;
        if (res.data.status === 'success') {
          this.isOpenConfirm = false
          notifySuccess("delete")
          this.getDetailDayEvent();
        }
        this.loading = false;
      }).catch(e => {
        return notifyErr()
      })

    },
    async updateEvent() {
      let validEvent = await this.$refs.contentForm.validate((valid, fields) => {
        return !valid;
      })
      if (!validEvent) return false
      let params = {
        "type_update": this.form.type_update,
        "day_or_date": this.form.day_or_date,
        "type_loop_date": (this.form.date_loop == configs.dateLoop[1].value && this.form.day_or_date == configs.dayOrDate[1].value) ? configs.typeLoopDate[1].value : this.form.type_loop_date,
        "start_day": this.form.day[0].toLocaleDateString("sv-SE"),
        "end_day": this.form.day[1].toLocaleDateString("sv-SE"),
        "event_name": this.form.event_name,
        "event_is_day_off": this.form.event_is_day_off,
        "event_id": this.idEditChildEvent,
        "start_time": "00:00:00",
        "end_time": "23:59:59",
      }
      this.loading = true;
      UpdateEvent(params).then(res => {
        if (res.data.status === 'success') {
          notifySuccess("edit")
          this.$router.go();
        }
        this.loading = false;
      }).catch(error => {
        return notifyErr(error.response?.data.message)
      })


    },
    async getDetailEvent() {
      this.loading = true;
      let params = {
        event_detail_id: this.idEditEvent,
      };
      GetDetailEvent(params).then(res => {
        if (res.data.status === 'success') {
          let infoEvent = res.data.data.detailEvent[0];
          this.event_id = infoEvent.event_id
          this.form = {
            day: [new Date(infoEvent.day), new Date(infoEvent.day)],
            event_name: infoEvent.event.name,
            event_is_day_off: configs.isDayOff.find(i => i.value === infoEvent.is_day_off)?.value,
            date_loop: configs.dateLoop.find(i => i.value === infoEvent.date_loop)?.value,
            day_or_date: configs.dayOrDate.find(i => i.value === infoEvent.day_or_date)?.value,
            type_loop_date: configs.typeLoopDate.find(i => i.value === infoEvent.type_loop_date)?.value,
            type_update: configs.typeUpdate[0].value,
          }
        }
        this.loading = false;
      }).catch(e => {
        return notifyErr()
      })

    },
    viewDetail(id, childId) {
      this.idEditEvent = id;
      this.idEditChildEvent = childId;
      this.setup.activeName = "info-detail"
      this.getDetailEvent();
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getDetailDayEvent(index);
    },
  }
}

</script>
<script setup>
import {
  Delete,
  Edit
} from '@element-plus/icons-vue'
import HandleButton from "@/components/button";

</script>

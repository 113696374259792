<template>
  <div class="bg-white p-3">
    <div class="row">
      <div class="col-2" v-if="dataImport.length < 1">
        <el-upload
            v-model:file-list="setup.fileList"
            class="upload-demo"
            limit="1"
            action="/"
            ref="uploadFile"
            :before-upload="beforeAvatarUpload"
        >
          <el-button v-if="setup.fileList.length  < 1" type="primary" :icon="UploadFilled">Chon tệp</el-button>
        </el-upload>
      </div>
      <div class="col-2">
        <el-button type="primary" v-on:click="onExport" v-if="dataImport.length < 1">Excel download</el-button>
      </div>
    </div>
    <div class="text-right">
      <el-button type="danger" v-on:click="reload()" v-if="dataImport.length > 0">Xóa data</el-button>
    </div>
    <div class="py-3">
      <table v-if="dataImport.length > 0" class=" table-bordered">
        <tr>
          <th v-for="(th, index) in tHead" :key="index">{{ th.name }}</th>
        </tr>
        <tr v-for="(td, index) in dataImport" :key="index">
          <td v-for="(item, iField) in tHead" :key="iField+index">
            <el-tooltip :content="showErr(td, item.field)? showErr(td,  item.field) : ''" placement="bottom"
                        effect="customized" v-if="showErr(td, item.field)">
              <div class="border border-danger has-text" v-if=" td[item.field]">{{ td[item.field] }}</div>
              <div class="border border-danger no-text" v-if="!td[item.field]">{{ td[item.field] }}</div>
            </el-tooltip>
            <div v-else class="has-text">{{ td[item.field] }}</div>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="dataImport.length > 0" class="text-center">
      <handle-button
          label="Tạo học sinh"
          eventCode="students.import-students"
          icon="fa fa-plus"
          typeButton="primary"
          @click="createStudents"/>
    </div>
  </div>
</template>
<script>
import * as XLSX from "xlsx";
import {GetInfoAllStudents, ImportStudent} from "@/services/students";
import {formatDataServe, isExcel, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import configs from "@/configs";
import messages from "@/constants/messages";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'ImportStudent',
  data() {
    return {
      dataImport: [],
      dataNew: [],
      studentsInfo: [],
      errFile: false,
      tHead: configs.fieldAccessExcel,
      setup: {
        fileList: [],
      },
    }
  },
  mixins: [setBreadcrumb],
  created() {
    this.getAllStudent()
  },

  methods: {
    async readerFile(file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileData = reader.result;
        const wb = XLSX.read(fileData, {type: 'binary'});
        wb.SheetNames.forEach((sheetName) => {
          const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.createArray(rowObj);
        })
      };
      reader.readAsBinaryString(file);
    },

    getAllStudent() {
      GetInfoAllStudents().then(res => {
        if (res.data.status === 'success') {
          this.studentsInfo = res.data.data.listStudent
        }
      }).catch(err => {
        notifyErr()
      })
    },

    async createArray(rowObj) {
      let dataStudent = [];
      rowObj.map(item => {
        let objNew = {}
        let i = 0;
        for (const key in item) {
          let fieldItem = configs.fieldAccessExcel.find(e => e.name.toUpperCase() == key.trim().toUpperCase())
          if (!fieldItem) {
            this.errFile = true;
            return false;
          }
          objNew[fieldItem.field] = item[key];
          i++;
        }
        if (i > configs.fieldAccessExcel.length) {
          this.errFile = true;
          return false;
        }
        dataStudent.push(objNew)
      })
      let data = this.validateRecord(dataStudent)
      this.dataImport = data
      return data
    },

    validateRecord(array) {
      let arrayNew = []
      let arrayOld = array
      let infoStudent = this.studentsInfo, fields = configs.fieldAccessExcel, genders = configs.gender
      let regex = {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        phone_number: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        birthday: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
        username: /^[a-zA-Z0-9]*$/,
      }
      let error = {};
      let fieldCheckExist = ['email', 'phone_number', 'username']
      let fieldCheckRequired = ['email', 'phone_number', 'username', 'name']
      let fieldCheckRegex = ['email', 'phone_number', 'username','birthday']
      let fieldValidate = ['email', 'phone_number', 'username', 'birthday', 'name', 'gender']
      let checkGender = 'gender'
      array.map((item, index) => {
        error = {}
        item.hasErr = false
        fields.map(fieldItem => {
          let field = fieldItem.field
          if (item[field] === undefined || item[field] == null) {
            item[field] = ''
          }
          if (fieldValidate.find(itemField => itemField == field)) {
            error[field] = []
          }
          if (fieldCheckRequired.find(itemField => itemField == field)) {
            if (!item[field]) error[field].push(messages.NULL)
          }
          let keyMessage = field.toUpperCase()
          if (fieldCheckRegex.find(itemField => itemField == field)) {
            if (item[field] && !regex[field].test(item[field])) error[field].push(messages[keyMessage + '_REGEX'])
          }
          if (fieldCheckExist.find(item => item == field)) {
            let arrDup = arrayOld.filter(el => el[field] == item[field])
            if (arrDup.length > 1) error[field].push(messages.DUPLICATE)
            let arrExist = infoStudent.filter(el => el[field] == item[field])
            if (arrExist.length > 0) error[field].push(messages[keyMessage + '_EXIST'])
          }
          if (field == checkGender) {
            if (item[field] && !genders.find(el => item[field].toString().toUpperCase() == el.toUpperCase())) error[field].push(messages[keyMessage])
          }
          if (fieldValidate.find(itemField => itemField == field)) {
            for (const key in error) {
              if (error[key] == undefined) continue
              if (error[key].length > 0) return item.hasErr = true;
            }
          }

        })
        item.error = error
        arrayNew.push(item)
      })
      return arrayNew
    },
    showErr(item, key) {
      let textErr = ''
      let fieldValidate = ['email', 'phone_number', 'username', 'birthday', 'name', 'gender']
      let field = fieldValidate.find(el => el == key)
      if (!field) return textErr
      if (!item.hasErr) textErr = ''
      if (item.error[key].length <= 0) textErr = ''
      if (item.error[key].length > 0) textErr = item.error[key][0]
      return textErr
    },
    onExport() {
      let dataExport = {}
      configs.fieldAccessExcel.map(item => {
        dataExport[item.name] = ''
      })
      let date = formatDataServe(new Date)
      let exportDefault = XLSX.utils.json_to_sheet([dataExport])
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, exportDefault, 'Học sinh') // sheetAName is name of Worksheet
      XLSX.writeFile(wb, 'template-student-' + date + '.xlsx')
    },
    beforeAvatarUpload(uploadFile) {
      if (!isExcel(uploadFile)) {
        return notifyErr('Vui lòng chọn file excel.')
      }
      this.setup.fileList.push(uploadFile)
      this.readerFile(uploadFile)
      return true
    },
    reload() {
      location.reload()
    },
    async createStudents() {
      let dataSend = this.dataImport, err = false
      let dataNew = []
      for (let i = 0; i < dataSend.length; i++) {
        let item = dataSend[i]
        if (item.hasErr) {
          err = true
          break
        }
        item.hasErr = null
        item.error = null
        item.is_active = 1
        item.birthdayNew = item.birthday
        item.birthday = item.birthday ? formatDataServe(item.birthday) : ''
        let index = configs.gender.findIndex((el, index) => el.toUpperCase() == item.gender.toUpperCase())
        if (index > -1) item.gender = index + 1
        if (index <= -1) item.gender = 3
      }
      let obj = {students: dataSend}
      if (err) {
        return notifyErr('Vui lòng điền thông tin theo yêu cầu.')
      }
      ImportStudent(obj).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm học sinh thành công')
          this.$router.push(NameRouter.PAGES.LIST_STUDENTS)
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    }
  }
}
</script>
<script setup>
import {View, UploadFilled, Search} from '@element-plus/icons-vue'
import {ref} from "vue";

const uploadFile = ref('')
</script>
<style lang="scss">
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  color: white;
  background: red;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: red;
  right: 0;
}

table {
  width: 100%;

  th {
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  td {
    .has-text {
      height: auto;
      padding: .45rem;
      overflow: auto;
    }

    .no-text {
      height: auto;
      padding: 1.2rem;
      overflow: auto;
    }
  }
}
</style>

import ERROR_MESSAGE from "@/constants/messages";
import moment from "moment";

export const ValidService = {};
ValidService.required = {
  required: true,
  message: ERROR_MESSAGE.REQUIRE,
  trigger: "blur",
};
ValidService.requiredChange = {
  required: true,
  message: ERROR_MESSAGE.REQUIRE,
  trigger: "change",
};
ValidService.checkPhone = {
  pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
  message: ERROR_MESSAGE.PHONE,
  trigger: "blur",
};
ValidService.checkEmail = {
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: ERROR_MESSAGE.EMAIL,
  trigger: "blur",
};
ValidService.checkDateTime = {
  pattern:
    /^([1-9]|([012][0-9])|(3[01]))[/]([0]{0,1}[1-9]|1[012])[/]\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$/g,
  message: ERROR_MESSAGE.DATE,
  trigger: "blur",
};
ValidService.checkDate = {
  pattern:
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g,
  message: ERROR_MESSAGE.DATE,
  trigger: "blur",
};
ValidService.checkYear = {
  pattern: /^\d{4}$/g,
  message: ERROR_MESSAGE.YEAR,
  trigger: "blur",
};
ValidService.checkMonth = {
  pattern: /^\d{2}[/]\d{4}$/g,
  message: ERROR_MESSAGE.MONTH,
  trigger: "blur",
};
ValidService.checkStringSpace = {
  validator: (rule, value, callback) => {
    if (value.trim() === "") {
      callback(new Error(LanguageService.lang.valid_required));
    } else {
      callback();
    }
  },
  trigger: "blur",
};
ValidService.checkNumberOtherZero = {
  validator: (rule, value, callback) => {
    if (value === "") callback(new Error(ERROR_MESSAGE.NULL));
    else if (!Number(value) && value !== 0)
      callback(new Error(ERROR_MESSAGE.NUMBER));
    callback();
  },
  required: true,
  trigger: "blur",
};
ValidService.checkNumber = {
  validator: (rule, value, callback) => {
    if (value === "") callback(new Error(ERROR_MESSAGE.NULL));
    else if (!Number(value)) callback(new Error(ERROR_MESSAGE.NUMBER));
    callback();
  },
  required: true,
  trigger: "blur",
};
ValidService.checkPositiveNumber = {
  validator: (rule, value, callback) => {
    if (value === "") callback(new Error(ERROR_MESSAGE.NULL));
    else if (!Number(value)) callback(new Error(ERROR_MESSAGE.NUMBER));
    else if (value < 0) callback(new Error(ERROR_MESSAGE.NEGATIVE));
    callback();
  },
  required: true,
  trigger: "blur",
};
ValidService.checkMoney = {
  validator: (rule, value, callback) => {
    if (value === "") callback(new Error(ERROR_MESSAGE.NULL));
    else if (/\s/g.test(value)) callback(new Error(ERROR_MESSAGE.SPACE));
    else if (isNaN(value)) callback(new Error(ERROR_MESSAGE.NUMBER));
    else if (value < 0) callback(new Error(ERROR_MESSAGE.MONEY));
    else if (value % 1 !== 0) callback(new Error(ERROR_MESSAGE.FLOAT));
    callback();
  },
  required: true,
  trigger: "blur",
};
export const textInputValidate = {
  validator: (rule, value, callback) => {
    if (value && typeof value === "string") value = value.trim();
    if (!value) callback(new Error(ERROR_MESSAGE.NULL));

    callback();
  },
  required: true,
  trigger: "blur",
};
export const dateMax = (dateMax) => {
  return {
    validator: (rule, value, callback) => {
      if (value) callback(new Error(ERROR_MESSAGE.DATE_MAX))

      callback();
    },
    required: true,
    trigger: "blur",
  }
};
export const dateMin = (dateMin) => {
  return {
    validator: (rule, value, callback) => {
      if (value && typeof value === "string") value = value.trim();
      if (value) callback(new Error(ERROR_MESSAGE.DATE_MIN))

      callback();
    },
    required: true,
    trigger: "blur",
  }
};
export const checkInputNotSpaceValidate = {
  validator: (rule, value, callback) => {
    if (value.trim().indexOf(" ") > -1 || value.trim() === "")
      callback(new Error(ERROR_MESSAGE.CODE_NOT_NULL));
    else if (!value) callback(new Error(ERROR_MESSAGE.NULL));
    callback();
  },
  required: true,
  trigger: "blur",
};
export const checkSpecialCharacter = {
  validator: (rule, value, callback) => {
    let regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (value === "") callback(new Error(ERROR_MESSAGE.NULL));
    if (regex.test(value)) callback(new Error(ERROR_MESSAGE.CHARACTER));
    callback();
  },
  required: true,
  trigger: "blur",
};
export const checkIsInteger = {
  validator: (rule, value, callback) => {
    if (isNaN(value)) callback(new Error(ERROR_MESSAGE.NUMBER_NOT_INTEGER));
    callback();
  },
  required: true,
  trigger: "blur",
};

export const checkSpecialPhoneNumber = {
  validator: (rule, value, callback) => {
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (!regex.test(value)) callback(new Error(ERROR_MESSAGE.PHONE));
    callback();
  },
  required: true,
  trigger: "blur",
};
export const checkStatDateTime = {
  type: "date",
  validator: (rule, val, callback) => {
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    if (!moment(val).isSameOrAfter(dateNow))
      callback(new Error(ERROR_MESSAGE.DATE_BEFORE_NOW));
    callback();
  },
  required: true,
  trigger: "change",
};

export const checkNameLength = {
  type: "string",
  validator: (rule, val, callback) => {
    if (val.length > 100) callback(new Error(ERROR_MESSAGE.NAME_LENGTH));
    callback();
  },
  required: true,
  trigger: "change",
};
export const checkDescriptionLength = {
  type: "string",
  validator: (rule, val, callback) => {
    if (val.length > 500) callback(new Error(ERROR_MESSAGE.DESCRIPTION_LENGTH));
    callback();
  },
  required: true,
  trigger: "change",
};
export const checkSpecialChar = {
  validator: (rule, value, callback) => {
    let regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (regex.test(value)) callback(new Error(ERROR_MESSAGE.CHARACTER));
    callback();
  },
  required: true,
  trigger: "blur",
};

export const requiredLateTrim = {
  validator: (rule, value, callback) => {
    value = value.toString().trim();
    if (value === "") callback(new Error(ERROR_MESSAGE.NULL));
    callback();
  },
  required: true,
  trigger: "blur",
};

export const checkCodeLength = {
  type: "string",
  validator: (rule, val, callback) => {
    if (val.length > 8 || val.length < 8)
      callback(new Error(ERROR_MESSAGE.CODE));
    callback();
  },
  required: true,
  trigger: "change",
};

export const checkUsernameLength = {
  type: "string",
  validator: (rule, val, callback) => {
    if (val.length < 4 || val.length > 20)
      callback(new Error(ERROR_MESSAGE.USERNAME));
    callback();
  },
  required: true,
  trigger: "change",
};

export const checkEmptyEditor = {
  validator: (rule, val, callback) => {
    let strippedString = val.replace(/<[^>]*>/gi, "").replaceAll("&nbsp;", "");
    if (strippedString.trim().length <= 0)
      callback(new Error(ERROR_MESSAGE.NULL));
    callback();
  },
  required: true,
  trigger: "change",
};
export const checkPassword = {
  validator: (rule, val, callback) => {
    if (val.trim().length < 8) callback(new Error(ERROR_MESSAGE.PASSWORD_LENGTH));
    callback();
  },
  required: true,
  trigger: "blur",
};

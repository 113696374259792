<template>
  <div class="bg-white" v-loading="loading">
    <info-user :form="form" ref="formUser" :isDetail="true" />
    <div v-if="batchManage.length > 0">
      <div class="p-3 text-center"><b>Lớp học đang quản lý</b></div>
      <el-table :data="batchManage" style="width: 100%" class="px-3" border ref="modules" fixed>
        <el-table-column prop="code" label="Mã lớp" min-width="150" />
        <el-table-column prop="name" label="Tên lớp" min-width="150" />
        <el-table-column prop="time" label="Thời gian" min-width="150" />
        <el-table-column prop="isActive" label="Hoạt động" width="100">
          <template #default="scope">
            <el-checkbox v-model="scope.row.isActive" disabled />
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #default="scope">
            <handle-button label="Loại bỏ" eventCode="batches.remove-teacher-from-batch" icon="fa fa-plus"
              typeButton="primary" @click="openConfirm(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="text-center py-3">
      <handle-button label="Cập nhật" eventCode="users.create-user" icon="fa fa-plus" typeButton="primary"
        @click="updateUser" />
    </div>
  </div>
  <confirm v-model="setup.isConfirmed" @close="setup.isConfirmed = false" @deleteService="removeBatches"></confirm>
</template>
<script>
import infoUser from "@/views/ManagementUser/infoUser";
import { formatDate, notifyErr, notifySuccess, setBreadcrumb } from "@/mixins/utils";
import { GetUser, UpdateUser, RemoveTeacherFromBatch } from "@/services/users";
import confirm from "@/components/confirm";
import { NameRouter } from "@/constants/nameRouter";
import configs from "@/configs";

export default {
  name: 'Detail-user',
  components: {
    infoUser,
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      form: {
        name: '',
        username: '',
        email: '',
        description: '',
        role_level: '',
        is_active: 1,
        avatar: ''
      },
      loading: false,
      batchManage: [],
      setup: {
        isConfirmed: false,
        deleteId: ''
      }
    }
  },
  created() {
    this.getUser()
  },
  computed: {
    isAdmin() {
      let role = this.$helpers.getRole()
      return role.is_admin === this.$helpers.roleAdmin
    }
  },
  methods: {
    async getUser() {
      this.loading = true
      GetUser({ user_id: this.$route.params.id }).then(res => {
        let user = res.data.data
        if (user) {
          this.form = {
            id: user.id,
            name: user.name,
            username: user.username,
            email: user.email,
            description: user.description,
            role_level: user.role_level.toString(),
            is_active: user.is_active,
            avatar: user.avatar
          }
          let batches = [];
          if (user.batch_teacher.length > 0) {
            user.batch_teacher.map(el => {
              batches.push({
                id: el.id,
                code: el.batches.code,
                name: el.batches.batch_name,
                isActive: configs.status.active == el.batches.is_active,
                time: formatDate(el.batches.start_date) + '-' + formatDate(el.batches.end_date),
              })
              this.batchManage = batches
            })
          }
          this.$refs.formUser.setImage(user.full_avatar_url)
          this.loading = false
        }
      }).catch(err => {
        notifyErr(err.response.message)
        this.loading = false
      })
    },

    async updateUser() {
      let valid = await this.$refs.formUser.validForm()
      if (!valid) return notifyErr('Vui lòng điền đầy đủ thông tin.')
      let params = {
        user_id: this.form.id,
        name: this.form.name,
        description: this.form.description,
        is_active: this.form.is_active,
        avatar: this.form.avatar,
      }
      if (this.isAdmin) {
        params.role_level = this.form.role_level
      }
      UpdateUser(params).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    openConfirm(id) {
      this.setup.deleteId = id;
      this.setup.isConfirmed = true
    },
    removeBatches() {
      console.log(this.$route.params.id);
      console.log(this.setup.deleteId);

      RemoveTeacherFromBatch({ teacher_id: this.$route.params.id, batch_id: this.setup.deleteId }).then(res => {
        notifySuccess('delete')
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    }
  }
}
</script>
<style lang="scss">
::v-deep .is-error {}
</style>
<template>
  <el-form
      ref="contentForm"
      label-position="top"
      :model="form"
      :rules="addContentRule"
      class="bg-white p-3 mh-100"
  >
    <el-row>
      <el-col :span="6">
        <div class="text-bold">
          Ảnh đại diện<span class="text-danger">*</span>
        </div>
        <div class="py-3">
          <el-form-item prop="avatar">
            <el-upload
                :limit="1"
                class="avatar-uploader"
                :class="imageUrl ? 'disable-event' : ''"
                :before-upload="beforeAvatarUpload"
                ref="avatar"
            >
              <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="avatar"
                  alt="avatar"
              />
              <el-icon v-if="!imageUrl" class="avatar-uploader-icon">
                <Plus/>
              </el-icon>
            </el-upload>
          </el-form-item>
          <div class="ml-lg-5 pl-lg-5 pl-md-5" v-if="imageUrl">
            <el-button type="danger" size="small" class="p-1">
              <el-icon>
                <Delete/>
              </el-icon>
              <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>
            </el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <el-form-item class="w-100" prop="name" label="Họ tên">
          <el-input v-model="form.name" placeholder="Họ tên"/>
        </el-form-item>
        <el-form-item class="w-100" prop="email" label="Email">
          <el-input
              v-model="form.email"
              :disabled="isDetail"
              placeholder="Email"
          />
        </el-form-item>
        <el-form-item class="w-100" prop="username" label="Tên đăng nhập">
          <el-input
              v-model="form.username"
              :disabled="isDetail"
              placeholder="Tên đăng nhập"
          />
        </el-form-item>
        <el-form-item class="w-100" prop="description" label="Mô tả">
          <el-input v-model="form.description" type="textarea" placeholder="Mô tả"/>
        </el-form-item>
        <el-form-item label="Quyền hạn" prop="role_level">
          <el-select v-model="form.role_level" placeholder="Vui lòng chon quyền" class="w-100" :disabled="true">
            <el-option :value="1" label="admin"/>
            <div v-for="(item, key) in listRole" :key="key">
              <el-option
                  :value="item.id.toString()"
                  :label="item.description + ' ('+item.name +')'"/>
            </div>
          </el-select>
        </el-form-item>

        <div class="dialog-footer d-flex justify-content-center">
          <handle-button
              class="py-1 btn btn-primary"
              icon="fa fa-save"
              event-code="auth.get-current-user-permission"
              type-button="primary"
              label="Cập nhật"
              @click="updateMe"
          />
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import {textInputValidate, ValidService} from "@/validator";
import {
  notifyErr,
  isImage,
  notifyFiled,
  notifySuccess,
  setBreadcrumb,
} from "@/mixins/utils";
import {UploadFile} from "@/services/common";
import {GetCurrentUserInfo, UpdateUserCurrent} from "@/services/auth";
import configs from "@/configs";
import {ListRole} from "@/services/users";

export default {
  expose: ["validForm", "setImage"],
  name: "DetailAdmin",
  components: {},
  mixins: [setBreadcrumb],
  data() {
    return {
      validation: false,
      imageUrl: "",
      form: {},
      addContentRule: {
        name: [textInputValidate],
        email: [textInputValidate],
        username: [textInputValidate],
        avatar: [textInputValidate],
        phone_number: [textInputValidate, ValidService.checkPhoneNumber],
        address: [textInputValidate],
      },
      configs: configs,
      isDetail: true,
      listRole: [],
      isAdmin: this.$helpers.roleAdmin
    };
  },
  async created() {
    await this.getListRole()
    await this.GetMe();
  },
  methods: {
    async GetMe() {
      this.loading = true;
      GetCurrentUserInfo()
          .then((res) => {
            this.form = res.data.data.data;
            this.setImage(this.form.full_avatar_url);
            this.loading = false;
          })
          .catch((err) => {
            notifyErr(err.response.message);
            this.loading = false;
          });
    },
    async validForm() {
      return await this.$refs.contentForm.validate((valid, fields) => {
        return !valid;
      });
    },
    handleRemove() {
      this.$refs.avatar.clearFiles();
      this.imageUrl = "";
    },
    async beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled();
        return false;
      }
      this.imageUrl = URL.createObjectURL(uploadFile);
      this.file = await uploadFile;
      await this.uploadFile();
      return true;
    },
    async uploadFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      let path = "";
      UploadFile(formData).then((e) => {
        if (e.data.status == "success") {
          this.form.avatar = e.data.data.filePath;
          path = e.data.data.filePath;
        }
        return path;
      });
    },
    setImage(url) {
      this.imageUrl = url;
    },
    getListRole() {
      ListRole().then(res => {
        if (res.data.status == "success") {
          this.listRole = res.data.data.roles
        }
      }).catch(err => {
        notifyErr(err.response.data.status)
      })
    },
    updateMe() {
      event.preventDefault()
      let {description, name, avatar} = this.form
      let param = {description, name, avatar}
      UpdateUserCurrent(param).then(res => {
        notifySuccess('edit', 'Cập nhật thành công')
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    }
  },
};
</script>
<script setup>
import {Delete, Plus, Refresh} from "@element-plus/icons-vue";
import HandleButton from "@/components/button";
</script>
<style scoped lang="scss">
.avatar-uploader .avatar {
  max-width: 280px;
  max-height: 300px;
  min-height: 250px;
  min-width: 250px;
  display: block;
  position: relative;
}

.action-image {
  background: #000000b5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  pointer-events: none;
  display: none;

  svg {
    color: white;
  }

  .action-delete {
    font-size: 18px;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
}

::v-deep .el-upload:hover {
  .action-image {
    display: block;
  }
}

.hasErr {
  ::v-deep .tox {
    border: 1px solid red;
  }
}
</style>
  
<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card">
    <el-tab-pane label="Thêm mới lộ trình" name="first">
      <infoRoadmap ref="roadmap" :infoRoadmap="formRoadmap" @resetContent="resetContent"></infoRoadmap>
    </el-tab-pane>
    <el-tab-pane label="Thêm mới nội dung" name="content">
      <div v-if="formRoadmap.type === notModule">
        <courseCheckbox ref="courseList" v-model="setup.courseId"></courseCheckbox>
      </div>
      <div v-if="formRoadmap.type === hasModule">
        <moduleCheckbox ref="modules" v-model="setup.moduleId"></moduleCheckbox>
      </div>
    </el-tab-pane>
    <div class="text-center py-4">
      <handle-button
          label="Tạo lộ trình"
          eventCode="roadmaps.store"
          icon="fa fa-plus"
          typeButton="primary"
          @click="createRoadmap"
      />
    </div>
  </el-tabs>
</template>
<script>
import infoRoadmap from "@/views/ManagementRoadmap/infoRoadmap";
import courseCheckbox from "@/views/Course/courseCheckbox";
import moduleCheckbox from "@/views/ManagementModule/moduleCheckBox";
import {CreateRoadmap} from "@/services/roadmap"
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'Create-Roadmap',
  components: {
    infoRoadmap,
    courseCheckbox,
    moduleCheckbox
  },
  mixins: [setBreadcrumb],
  data() {
    return {

      hasModule: 1,
      notModule: 2,
      formRoadmap: {
        title: '',
        code:"",
        duration: '',
        sort_order: '',
        version: '',
        description: '',
        image: '',
        is_active: 1,
        type: 1
      },
      nameRouter: NameRouter,
      setup: {
        activeName: 'first',
        courseId: [],
        moduleId: [],
      }
    }
  },
  methods: {
    async createRoadmap() {
      let vailForm = await this.$refs.roadmap.validRoadmap()
      if (!vailForm) return false
      let valid = false
      if (this.formRoadmap.type === this.hasModule) {
        valid = await this.$refs.modules.validModule()
      }
      if (this.formRoadmap.type === this.notModule) {
        valid = await this.$refs.courseList.validCourse()
      }
      if (!valid) {
        this.setup.activeName = 'content'
        return false
      }
      if (this.formRoadmap.type === this.hasModule) {
        let modules = [];
        this.setup.moduleId.map((item, index) => {
          modules.push({id: item, sort_order: index + 1})
        })
        this.formRoadmap.modules = modules
      }
      if (this.formRoadmap.type === this.notModule) {
        let courses = [];
        this.setup.courseId.map((item, index) => {
          courses.push({id: item, sort_order: index + 1})
        })
        this.formRoadmap.courses = courses
      }
      event.preventDefault()
      CreateRoadmap(this.formRoadmap).then(res => {
        if (res.data.status == 'success') {
          notifySuccess()
          this.$router.push(NameRouter.PAGES.LIST_ROADMAP)
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },

    resetContent() {
      if (this.formRoadmap.type == this.hasModule) {
        this.setup.moduleId = []
      }
      if (this.formRoadmap.type == this.notModule) {
        this.setup.courseId = []
      }
    }
  }
}
</script>
<script setup>
import {Check} from '@element-plus/icons-vue'

</script>
<template>
  <div class="w-100" :class="{'hasErr': validation}">
    <label style="color: #606266" v-if="showLabel"> <span class="text-danger">*</span> Khóa học</label>
    <vSelect
        v-model="courseId"
        placeholder="Lựa chọn khóa học"
        :disabled="disableSelect"
        :options="courseList"
        label="title"
        :class="{'is-invalid': validation}"
        :reduce="(course) => course.id"
        class="w-100"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import {GetListCourse} from "@/services/course";
import CONFIG_APP from "@/configs";

export default {
  expose: ['resetData', 'queryByName', 'search','searchByBatch', 'getCourseCurrent'],
  name: "course-select",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn khóa học.'},
    validation: {type: Boolean, default: false},
    showLabel: {type: Boolean, default: true},
    modelValue: String || Number,
    idOld: { type: Array, default: null },
    disable: { type: Boolean, default: false },
  },
  data() {
    return {
      courseId: null,
      hasNextPage: false,
      disableSelect: false,
      courseList: [],
      page: 1,
      roadmap_id: null,
      module_id: null,
      batch_id: null,
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {
    courseId() {
      this.$emit('selected', this.courseId)
      this.$emit('update:modelValue', this.courseId)
    },
    courseList() {
      this.courseId = this.modelValue
    }
  },
  created() {
    this.getCourse()
  },

  methods: {
    getCourse(params) {
      this.hasNextPage = true
      if (!params) {
        params = { per_page: this.per_page, page: this.page }
      }
      GetListCourse(params).then(e => {
        if (e.data.status === 'success') {
          let data = e.data.data.courses.data
          if (this.idOld) {
            this.idOld.map(id => {
              let index = data.findIndex(el => el.id === id)
              if (index > -1) {
                data.splice(index, 1)
              }
            })
          }
          this.courseList = data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        if (err.response?.data == 'failed') {
          location.reload();
        }
      })
    },
    queryKeyword(query) {
      this.disableSelect = true
      this.subjects = []
      this.hasNextPage = true
      let params = { per_page: this.per_page, page: 1, keyword: query }
      params.roadmap_id = this.roadmap_id
      params.module_id = this.module_id
      params.batch_id = this.batch_id
      this.getCourse(params)
    },
    resetData() {
      this.roadmaps = []
      this.courseId = null
    },
    queryByName(name) {
      this.queryKeyword(name)
    },
    search(search) {
      this.disableSelect = true
      this.hasNextPage = true
      this.roadmap_id = search.roadmap_id
      this.module_id = search.module_id
      let params = { per_page: this.per_page, page: 1, ...search }
      this.getCourse(params)
    },
    searchByBatch(search) {
      this.disableSelect = true
      this.hasNextPage = true
      this.batch_id = search.batch_id
      let params = { per_page: this.per_page, page: 1, ...search }
      this.getCourse(params)
    },
    getCourseCurrent() {
      return this.courseList.find(item => item.id === this.courseId)
    }
  },

}
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}

::v-deep .vs__actions {
  button {
    font-size: 8px !important;
  }
}
</style>

<template>
  <div id="managementQuestion">
    <el-form :model="form" ref="questionForm" label-width="120px" label-position="top">
      <div class="text-end">
        <el-button type="primary" :icon="Plus" @click="addItem('')">Thêm câu hỏi</el-button>
      </div>
      <el-row>
        <el-col :span="12" v-for="(item, index) in form.questionForm" :key="index" class="question">
          <div class=" bg-white p-3 m-3 ">
            <div class="text-end removeQuestion">
              <el-button class="border-0" :icon="Close" @click="removeQuestion(item.id,'question')"/>
            </div>
            <div class="pb-3" :class="{'hasErr': item.validation}">
              <label :for="'select-'+item.id"> <span class="text-danger">*</span><b> Môn học</b></label>
              <vSelect
                  :id="'select-'+item.id"
                  multiple
                  :value="item.subjectId"
                  :disabled="disableSelect"
                  :options="subjects"
                  label="name"
                  :class="{'is-invalid': item.validation}"
                  :reduce="(subject) => subject.id"
                  class="w-100"
                  @search="(query)=> queryKeyword(query)"
                  @close="onClose"
                  v-model="item.subjectId"
                  placeholder="Lựa chọn môn học">
                <template #list-footer>
                  <li v-show="hasNextPage" ref="load" class="loader text-center">
                    loading...
                  </li>
                </template>
              </vSelect>
              <span class="invalid-feedback">{{ messageErr }}</span>
            </div>
            <el-form-item
                :rules="rulesForm.content"
                :label="'Nội dung câu hỏi '+(index+1)"
                :prop="'questionForm['+index+'].content'">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  placeholder="Nội dung câu hỏi"
                  v-model="item.content">
              </el-input>
            </el-form-item>
            <el-form-item
                :label="'Loại câu hỏi'"
                :prop="'questionForm['+index+'].type'">
              <el-radio-group v-model="item.type">
                <el-radio :label="1">Một câu trả lời</el-radio>
                <el-radio :label="2">Nhiều câu trả lời</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="text-end p-2">
              <el-button size="small" type="primary" :icon="Plus" @click="addItem(item.id)">Thêm câu trả lời</el-button>
            </div>
            <div class="" v-if="item.validAnswer">
              <i class="fa fa-warning text-danger"></i> <small class="text-danger ">{{ messageErrAnswer }}</small>
            </div>
            <table class="table">
              <thead>
              <tr>
                <th scope="col" style="width: 5%">#</th>
                <th scope="col" style="width: 50%">Câu trả lời</th>
                <th scope="col" style="width: 15%">Câu đúng</th>
                <th scope="col" style="width: 15%"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(answer, i) in item.answers" :key="answer.id">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <el-form-item
                      :prop="'questionForm.'+index+'.answers.' + i + '.content'"
                      :id="answer.id"
                      :rules="rulesForm.content">
                    <el-input
                        v-model="answer.content"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="Nội dung câu trả lời."
                    >
                    </el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item class="text-center" prop="isCorrectAnswer">
                    <el-radio v-model="item.isCorrectAnswer" v-if="item.type == 1" :label="answer.id">Đúng</el-radio>
                    <el-checkbox
                        v-model="answer.isCorrectAnswer"
                        v-if="item.type == 2"
                        :label="answer.id">
                      Đúng
                    </el-checkbox>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item>
                    <el-button size="small" type="danger"
                               @click="removeQuestion(answer.id,'answer', item.id)"
                               :icon="Delete"></el-button>
                  </el-form-item>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
      <div class="text-center">
        <handle-button
            label="Thêm câu hỏi"
            eventCode="questions.store"
            icon="fa fa-plus"
            typeButton="primary"
            @click="createdQuestion"/>
      </div>
    </el-form>
  </div>
</template>
<script>
import {textInputValidate, ValidService} from "@/validator";
import {AllSubject, CreateStudent} from "@/services/exam";
import vSelect from 'vue-select';
import "vue-select/src/scss/vue-select.scss";
import {notifyWarning, notifySuccess, notifyErr, setBreadcrumb} from "@/mixins/utils";
import Messages from "@/constants/messages";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'QuestionCreate',
  components: {vSelect},
  mixins: [setBreadcrumb],
  data() {
    return {
      rulesForm: {
        content: [textInputValidate],
      },
      ruleAnswer: {
        content: [textInputValidate]
      },
      disableSelect: false,
      form: {
        questionForm: [
          {
            id: 'q99990',
            content: '',
            subjectId: [],
            type: 1,
            validation: false,
            validAnswer: false,
            answers: [
              {
                id: 'answer-q99990-0',
                content: '',
                isCorrectAnswer: ''
              },
              {
                id: 'answer-q99990-1',
                content: '',
                isCorrectAnswer: ''
              },
            ]
          },
          {
            id: 'q99991',
            content: '',
            subjectId: [],
            type: 1,
            isCorrectAnswer: '',
            answers: [
              {
                id: 'answer-q99991-0',
                content: '',
                isCorrectAnswer: ''
              },
              {
                id: 'answer-q99991-1',
                content: '',
                isCorrectAnswer: ''
              },
            ]
          }
        ],
      },
      search: '',
      per_page: 30,
      page: 1,
      subjects: [],
      hasNextPage: false,
      limitedItemAnswer: 3,
      limitedItemQuestion: 2,
      messageErr: '',
      messageErrAnswer: '',
      manyCorrect: 2
    }
  },
  created() {
    this.getSubject()
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    getSubject(params) {
      this.hasNextPage = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page}
      }
      AllSubject(params).then(e => {
        if (e.data.status == 'success') {
          this.subjects = e.data.data.subjects.data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        if (err.response.data == 'failed') {
          location.reload();
        }
      })
    },
    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.page += 1
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      let params = {per_page: 30, page: 1, keyword: query}
      this.getSubject(params)
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.form.questionForm.map((e, index) => {
        if (e.subjectId.length > 0) {
          e.validation = false
        }
      })
    },
    addItem(id = '') {
      let idNew = new Date().getTime()
      if (id) {
        let indexQuestion = this.form.questionForm.findIndex(e => e.id === id)
        return this.form.questionForm[indexQuestion].answers.push({
              id: 'answer-' + idNew,
              content: '',
              isCorrectAnswer: ''
            }
        )
      }
      this.form.questionForm.push({
        id: idNew,
        content: '',
        subjectId: [],
        isCorrectAnswer: '',
        type: 1,
        answers: [
          {
            id: 'answer-0-' + idNew,
            content: '',
            isCorrectAnswer: ''
          },
          {
            id: 'answer-1-' + idNew,
            content: '',
            isCorrectAnswer: ''
          },
        ]
      })
    },
    removeQuestion(id, type, idQuestion) {
      let index = ''
      if (type === 'answer') {
        let indexQuestion = this.form.questionForm.findIndex(e => e.id === idQuestion)
        if (indexQuestion <= -1) return false
        if (this.form.questionForm[indexQuestion].answers.length < this.limitedItemAnswer) {
          return notifyWarning('Phải có ít nhất hai đáp án.')
        }
        index = this.form.questionForm[indexQuestion].answers.findIndex(e => e.id === id)
        this.form.questionForm[indexQuestion].answers.splice(index, 1)
      }
      if (type === 'question') {
        index = this.form.questionForm.findIndex(e => e.id === id)
        if (this.form.questionForm.length < this.limitedItemQuestion) {
          return notifyWarning('Phải có ít nhất một câu hỏi.')
        }
        if (index > -1) {
          this.form.questionForm.splice(index, 1)
        }
      }
    },
    async createdQuestion() {
      event.preventDefault()
      let params = [];
      let valid = await this.$refs.questionForm.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      this.form.questionForm.map((e, index) => {
        if (e.subjectId.length <= 0) {
          e.validation = true
          this.messageErr = Messages.NULL
          valid = false
        }
        if (!e.isCorrectAnswer && e.type !== this.manyCorrect) {
          e.validAnswer = true
          this.messageErrAnswer = Messages.ANSWER_REQUIRE
          valid = false
        }
        if (e.isCorrectAnswer && e.type !== this.manyCorrect) {
          e.validAnswer = false
        }
        if (e.type == this.manyCorrect) {
          let corrects = e.answers.filter(el => el.isCorrectAnswer != '')
          if (corrects.length < 1) {
            e.validAnswer = true
            this.messageErrAnswer = Messages.ANSWER_REQUIRE
            valid = false
          }
        }
      })

      if (!valid) return false

      this.form.questionForm.map((question, index) => {
        let data = {
          question: {
            type: question.type,
            content: question.content,
            subject_ids: question.subjectId,
          }
        };
        let answers = [];
        question.answers.forEach(answer => {
          if (question.type !== this.manyCorrect) {
            answers.push({
              content: answer.content,
              is_correct_answer: question.isCorrectAnswer == answer.id ? 1 : 0
            })
          }
          if (question.type == this.manyCorrect) {
            answers.push({
              content: answer.content,
              is_correct_answer: answer.isCorrectAnswer ? 1 : 0
            })
          }
        });
        data.answers = answers
        params.push(data)

      })
      CreateStudent({data: params}).then(e => {
        if (e.data.status == 'success') {
          notifySuccess('add', 'Thêm câu hỏi thành công.')
          this.$router.push(NameRouter.PAGES.EXAM_CREATE)
        }
        if (e.data.status == 'failed') {
          notifyErr(e.data.message)
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.message)
        }
      })
    },
  }

}
</script>
<script setup>
import {
  Delete,
  Plus,
  Document,
  Close,
} from '@element-plus/icons-vue'
</script>
<style lang="scss" scoped>
#managementQuestion {
  tbody {
    ::v-deep .el-form-item {
      margin-bottom: 0 !important;
    }
  }

  .question {
    position: relative;

    .removeQuestion {
      top: 4%;
      right: 2%;
      position: absolute;
    }
  }

  .hasErr {
    :deep(.vs__dropdown-toggle) {
      border-color: red;
    }

    .textErr {
      display: block;
      color: red
    }
  }
}

</style>

<template>
  <!-- <div class="bg-white p-3 mb-4">
    <div class="row">
      <div class="col-8"></div>
      <div class="col-4">
        <el-date-picker
            v-model="value"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date">
        </el-date-picker>
      </div>
    </div>
  </div> -->
  <el-table :data="tableCountDay" style="width: 100%; margin-bottom: 20px;" fixed v-loading="loading" border>
    <el-table-column prop="number_of_early_leave" label="Ngày về sớm" min-width="100" />
    <el-table-column prop="number_of_late_arrival" label="Ngày đến muộn" min-width="100" />
    <el-table-column prop="number_of_missing_hours" label="Ngày học thiếu giờ" min-width="100" />
    <el-table-column prop="number_of_off_afternoon" label="Ngày nghỉ buổi chiều" min-width="100" />
    <el-table-column prop="number_of_off_morning" label="Ngày nghỉ buổi sáng" min-width="100" />
    <el-table-column prop="number_of_off_day" label="Ngày nghỉ cả ngày" min-width="100" />
    <el-table-column prop="number_of_ok" label="Ngày học đầy đủ" min-width="100" />
    <el-table-column prop="number_of_rest_day" label="Ngày nghỉ" min-width="100" />
  </el-table>


  <div class="bg-white p-3 mb-4">
    <el-calendar ref="calendar">
      <template #header="{ date }">

        <span>{{ date }}</span>
        <el-button-group>
          <el-button size="normal" @click="selectDate('prev-month', date)">
            Tháng trước
          </el-button>
          <el-button size="normal" @click="selectDate('today', date)">Hôm nay</el-button>
          <el-button size="normal" @click="selectDate('next-month', date)">
            Tháng sau
          </el-button>
        </el-button-group>
      </template>
      <template #dateCell="{ data }">

        <el-popover placement="auto" :width="400" trigger="contextmenu">
          <template #reference>
            <ul style="list-style: none;padding: 0;">
              <li style="text-align: center; font-weight: 500;width:100%">{{ data.day.split('-').pop() }}</li>
              <li class="data-content" v-for="item of tableData" :key="item">
                <div style="display:flex; align-items:center; gap:4px">
                  <div style="width: 4px; height: 20px ; background-color: green"
                    v-if="item?.roll_call_report_status[0]?.status_id == 1 && item?.day === data.day"></div>
                  <div style="width: 4px; height: 20px ; background-color: red"
                    v-if="item?.roll_call_report_status[0]?.status_id == 5 && item?.day === data.day"></div>
                  <div style="width: 4px; height: 20px ; background-color: orangered"
                    v-if="item?.roll_call_report_status[0]?.status_id == 9 && item?.day === data.day"></div>
                  <span style="text-align: center;">{{ item?.day === data.day ? item?.status : "" }}</span>
                </div>
              </li>
            </ul>
          </template>

        </el-popover>
      </template>
    </el-calendar>

  </div>
</template>
<script>
import configs from "@/configs";
import { NameRouter } from "@/constants/nameRouter";
import { formatDataServe, getDefaultDate, notifyErr } from "@/mixins/utils";
import { GetRollStudentBatches } from "@/services/batches";
import moment from "moment";
export default {
  name: "RollCall-Student",

  data() {
    return {
      tableData: [],
      tableCountDay: [],
      loading: false,
      configs: configs,
      nameRouter: NameRouter,
      value: [],
      start_date: "",
      end_date: "",
      visible: false,
      tableList: [],
      currentPage: 1,
      pageIndex: 1,
      NameRouter: NameRouter,
      isOpenCofirm: false,
      dayDeleted: "",
    };
  },
  created() {
    this.setDefaultValue();
    this.getRollCallStudentBatches();
  },
  watch: {
    // value() {
    //   this.getRollCallStudentBatches({
    //     start_date: moment(this.value[0]).format('YYYY-MM-DD'),
    //     end_date: moment(this.value[1]).format('YYYY-MM-DD'),
    //     student_id: this.$route.params.id
    //   })
    // }
  },
  methods: {
    getRollCallStudentBatches(param) {
      this.loading = true;
      if (!param) {
        param = {
          start_date: formatDataServe(this.start_date),
          end_date: formatDataServe(this.end_date),
          student_id: this.$route.params.id
        };
      }
      GetRollStudentBatches(param).then(e => {
        let dataTable = [];
        if (e.data.status == 'success') {
          e.data.data.rollCallReports?.map((i, index) => {
            if (i.roll_call_report.length > 0) {
              let status = i.roll_call_report[0].roll_call_report_status?.map(s => s.roll_call_master_status.name).toString().split(",").join(", ");
              dataTable.push({ ...i.roll_call_report[0], status })
            }
          })
          this.tableData = dataTable;
          this.tableCountDay = [e.data.data.countStatus];
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()

      })
    },
    setDefaultValue() {

      let date = getDefaultDate()
      const firstDayOfMonth = new Date(date[0]);
      const lastDayOfMonth = new Date(date[1])
      this.getRangeMonth(firstDayOfMonth, lastDayOfMonth)

    }
    ,

    selectDate(val, date) {
      this.$refs.calendar.selectDate(val);

      let arrDate = date.split(" ");
      let year = parseInt(arrDate[0]);
      let month = parseInt(arrDate[3]);
      let firstDay = ""
      let lastDay = ""
      if (val == 'today') {

        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        firstDay = new Date(y, m, 1);
        lastDay = new Date(y, m + 1, 0);

      } else if (val == 'next-month') {

        firstDay = new Date(year, month, 1);
        lastDay = new Date(year, month + 1, 0);

      } else {
        firstDay = new Date(year, month - 2, 1);
        lastDay = new Date(year, month - 1, 0);

      }
      this.getRangeMonth(firstDay, lastDay)
      this.getRollCallStudentBatches()

    }
    ,
    getRangeMonth(firstDayOfMonth, lastDayOfMonth) {
      const firstDate = new Date(firstDayOfMonth.getTime());
      const lastDay = new Date(lastDayOfMonth.getTime());

      if (firstDayOfMonth.getDate() != 0) {
        let spaceFirstDay = firstDayOfMonth.getDay() - 1;

        firstDate.setDate(firstDate.getDate() - (spaceFirstDay + 1))
      }


      if (lastDayOfMonth.getDate() != 6) {
        let spaceLastDay = 6 - lastDayOfMonth.getDay();
        lastDay.setDate(lastDay.getDate() + spaceLastDay)
      }



      this.start_date = firstDate
      this.end_date = lastDay
    }


  },
};
</script>
<script setup>


</script>
<style scoped></style>

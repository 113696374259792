<template>
  <el-dialog
      v-model="modelValue"
      width="35%"
      @close="close"
  >
    <template #title><h5>Cảnh báo</h5></template>
    <div>
      <div class="pb-3">
        <h6 style="font-size: 1rem">{{message}}</h6>
      </div>
      <slot name="message"></slot>
    </div>
    <div class="base__btn-row">
      <el-button class="btn-decline" @click="close">Huỷ</el-button>
      <el-button class="btn-edit" @click="deleteService"> Đồng ý</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "dialogConfirm",
  props: {
    dialogVisible: {type: Boolean, default: false, require: true},
    message: {
      type: String,
      default: "Bạn có chắc chắn muốn xóa không?",
      require: true,
    },
    modelValue: {type: Boolean, default: false}
  },
  data() {
    return {
      confirm: false,
    };
  },
  methods: {
    close() {
      this.confirm = false;
      this.$emit("closeConfirm");
      this.$emit("close");
    },
    deleteService() {
      this.$emit("deleteService");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .el-dialog__footer {
  padding: 0.75rem 1rem;
}

::v-deep .el-dialog__body {
  padding: 1rem;
}

@include center-xxl-xl() {
  ::v-deep .el-dialog {
    width: 50% !important;
  }
}

h6 {
  color: $violet;
}
</style>

<template>
  <div id="material">
    <el-form :model="materials" ref="materialForm">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th style="width: 25%">Tên bài học</th>
          <th style="width: 25%">Thời gian học (phút)</th>
          <th style="width: 10%">Vị trí</th>
          <th style="width:15%"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in materials" :key="index">
          <td>{{ item.name }}</td>
          <td>
            {{ item.duration }}
          </td>
          <td>
            <el-form-item :class="item.valid && item.position === '' || item.validNum && item.position ? 'error' : ''">
              <el-input v-model.number="item.position" placeholder="Vị trí" class="w-100" :name="`position-${item.id}`"
                        @change="checkValid(index)"/>
              <small class="text-danger" v-if="item.valid && item.position === '' && !item.validNum">
                {{ item.msg }}</small>
              <small class="text-danger" v-if="item.validNum && item.position && item.msgNum">{{ item.msgNum }}</small>
            </el-form-item>
          </td>

          <td>
            <div class="d-flex">
              <handle-button
                  label=""
                  eventCode="courses.update"
                  icon="fa fa-check"
                  typeButton="primary"
                  @click="$emit('editMaterial',item.id)"/>
              <handle-button
                  label=""
                  eventCode="courses.update"
                  icon="fa fa-trash"
                  typeButton="danger"
                  @click="$emit('deleteMaterial',item.id)"/>
              <handle-button
                  type="link"
                  label=""
                  eventCode="materials.show"
                  icon="fa fa-eye"
                  typeButton="primary"
                  :to="{name: NameRouter.PAGES.DETAIL_MATERIAL, params:{id: item.id}}"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </el-form>
  </div>
</template>
<script>
import {GetFiles} from "@/services/common";
import configs from "@/configs";
import {notifyErr} from "@/mixins/utils";
import {textInputValidate} from "@/validator";
import {ref} from "vue";

export default {
  expose: ['materialValid'],
  name: 'Materials-component',
  props: {
    materials: Array,
    isEdit: Boolean,
  },
  data() {
    return {
      fileList: [],
      perPage: configs.itemOnPage,
      page: 1,
      materialRequire: {
        name: [textInputValidate],
        file: [textInputValidate],
        position: [textInputValidate],
      }
    }
  },
  created() {
    this.getListFile()
  },
  methods: {
    getListFile() {
      let params = {per_page: this.perPage, page: this.page}
      GetFiles(params).then(res => {
        if (res.data.status === 'success') {
          this.fileList = res.data.data.listAllFile.data
        }
      }).catch(err => {
        notifyErr()
      })
    },
    materialValid() {
      let isFail = []
      this.materials.map(item => {
        if (item.name === '' || item.position === '' || item.file === '') {
          item.valid = true;
          item.msg = 'Không được trống.';
          isFail.push(false)
        }
        if (item.position && item.position % 1 !== 0) {
          item.validNum = true;
          item.msgNum = 'Phải là số.';
          isFail.push(false)
        } else {
          item.validNum = false;
        }
      })
      return isFail.length <= 0
    },
    checkValid(index) {
      if (this.materials[index].position % 1 !== 0) {
        this.materials[index].validNum = true
        this.materials[index].msgNum = 'Phải là số.';
      } else {
        this.materials[index].validNum = false
        this.materials[index].msgNum = '';
      }
    }
  },
}
</script>
<script setup>
import {
  Delete,
  Checked,
  Search,
} from '@element-plus/icons-vue'
import {NameRouter} from "@/constants/nameRouter";
</script>
<style lang="scss" scoped>
::v-deep .vs__dropdown-menu {
  z-index: 1009 !important;
  display: block;
  box-sizing: content-box;
  padding: 5px 0;
  width: 100%;
  overflow-y: auto;
  border-top-style: none;
}

.error {
  ::v-deep .el-input {
    border: 1px solid red;
    box-shadow: none;
    border-radius: 5px;
  }
}

.table td {
  padding: 0.25rem 0.5rem
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>

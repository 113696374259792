<template>
  <div class="bg-white">
    <info-room :form="form" ref="formRoom"/>
    <div class="text-start py-3 px-3">
      <handle-button
          label="Cập nhật phòng học"
          eventCode="rooms.update"
          icon="fa fa-edit"
          typeButton="primary"
          @click="updateRoom"/>
    </div>
  </div>
</template>
<script>
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import infoRoom from "@/views/Room/infoRoom";
import {GetDetailRoom,UpdateRoom} from "@/services/room";

export default {
  name: "CreateEvent",
  mixins: [setBreadcrumb],
  components: {
    infoRoom
  },
  data() {
    return {
      configs: configs,
      NameRouter: NameRouter,
      form: {},
      loading: false,
    };
  },

  created() {
    this.getDetailRoom(this.$route.params.id)
  },


  methods: {
    async updateRoom() {
      let validEvent = await this.$refs.formRoom.validForm()
      if (!validEvent) return false
      let param = {
        name: this.form.name,
        branch_id: this.form.branch,
        code:this.form.code
      }
      let id = this.$route.params.id;
      UpdateRoom(id,param).then(e => {
        notifySuccess("edit")
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
    async getDetailRoom(id) {
      this.loading = true;
      GetDetailRoom(id).then(e => {
        let infoRoom = e.data.data.roomDetail[0];
        this.form= {
          name: infoRoom.name,
          branch: infoRoom.branch_id,
          code:infoRoom.code
        }
        this.loading = false;
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })
    },
  }
  ,
}

</script>
<script setup>

</script>
<style scoped lang="scss">

</style>
<template>
  <el-dialog v-model="modelValue" title="Thêm mới môn học" width="50%" draggable @close="closeDialog">
    <infoSubject ref="infoSubjectBox" :form="form"></infoSubject>
    <template #footer>
      <div class="dialog-footer d-flex justify-content-end">
        <handle-button
            typeButton="danger"
            @click="$emit('update:modelValue', false)"
            icon=""
            eventCode="subjects.store"
            label="Hủy"/>
          <handle-button
              label="Thêm môn học"
              eventCode="subjects.store"
              icon="fa fa-plus"
              typeButton="primary"
              @click="createSubject"/>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import infoSubject from "@/views/MangementSubject/infoSubject";
import {notifyErr, notifySuccess} from "@/mixins/utils";
import {CreateSubject} from "@/services/course";

export default {
  name: 'CreateSubject',
  components: {infoSubject},
  props: {
    modelValue: {type: Boolean, default: false}
  },
  data() {
    return {
      form: {
        name: '',
        version: '',
        is_active: 1
      }
    }
  },
  methods: {
    async createSubject() {
      let valid = await this.$refs.infoSubjectBox.validForm();
      if (!valid) return notifyErr('Có lỗi nhập liệu.')
      CreateSubject(this.form).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm mới môn học thành công.')
          this.$emit('reloadSubject')
          this.$emit('update:modelValue', false)
          this.form = {
            name: '',
            version: '',
            is_active: 1
          }
        }
      }).catch(err => {
        notifyErr()
      })
    },
    closeDialog() {
      this.$emit('update:modelValue', false)
      this.$refs.infoSubjectBox.clearForm()
      this.form = {
        name: '',
        version: '',
        is_active: 1
      }
    }
  }
}
</script>

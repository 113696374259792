<template>
  <div id="addStudentsToBatch">
    <div class="bg-white p-3">
      <el-row :gutter="20">
        <el-col :span="16">
          <div>
            <b>Tên lớp học:</b> {{ batch.name }}
          </div>
          <div>
            <b>Thời gian học:</b> {{ batch.start_date }} : {{ batch.end_date }}
          </div>
        </el-col>
        <el-col :span="8">
          <div class="text-end">
            <handle-button
                label="Thêm vào lớp"
                eventCode="batches.assign-batch"
                icon="fa fa-plus"
                typeButton="primary"
                @click="assignStudent"/>
            <handle-button
                type="link"
                label="Chi tiết lớp"
                eventCode="batches.get-detail-batch"
                icon="fa fa-edit"
                typeButton="primary"
                :to="{ name: nameRouter.PAGES.BATCH_DETAIL, params:{id: idBatch}}"/>
          </div>
        </el-col>
      </el-row>
      <div class="text-center pt-3"><h5>Danh sách học sinh</h5></div>
    </div>
    <div class="p-3 bg-white">
      <el-row :gutter="20" :align="10">
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm tên học sinh" v-model="setup.search_name"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm email" v-model="setup.search_email"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <el-input placeholder="Tìm kiếm SĐT" v-model="setup.search_phone"/>
        </el-col>
        <el-col :span="8" class="pb-3">
          <batch-select v-model="setup.search_batch" :show-label="false" :isMultiple="false" :add-option="true"
                        ref="batchSelectBox"/>
        </el-col>
        <el-col :span="8">
          <handle-button
              label="Tìm kiếm"
              eventCode="students.list-student"
              icon="fa fa-search"
              typeButton="primary"
              @click="searchStudent"/>
          <handle-button
              label="Làm mới"
              eventCode="students.list-student"
              icon="refresh"
              typeButton="warning"
              @click="refreshSearch"/>
        </el-col>
      </el-row>
    </div>
    <el-table
        class="px-3 pb-3"
        :data="tableData"
        style="width: 100%"
        ref="studentTable"
        fixed
        border
        @select="handleSelected"
        @selection-change="handleSelectAll"
        v-loading.fullscreen.lock="loading">
      <el-table-column type="selection" width="55" :selectable="checkedIn"/>
      <el-table-column prop="id" width="55" label="ID"/>
      <el-table-column prop="name" label="Họ tên"/>
      <el-table-column prop="email" label="Email"/>
      <el-table-column prop="phone" label="Điện thoại"/>
      <el-table-column label="TT hoạt động">
        <template #default="scope">
          <el-button
              type="success"
              size="small" v-if="scope.row.isActive">
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button
              type="warning"
              size="small" v-if="!scope.row.isActive">
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Lớp hiên tại">
        <template #default="scope">
          <el-button
              type="warning"
              size="small"
              v-if="scope.row.isClass">
            {{ scope.row.batchName }}
          </el-button>
          <el-button
              type="success"
              size="small"
              v-if="!scope.row.isClass">
            Chưa có lớp
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          :total="total"
          v-show="isShowPagination"
          :pageIndex="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>

</template>
<script>
import {AssignStudentToBatch, BatchDetail, GetListBatches} from "@/services/batches";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, notifyWarning, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListStudents} from "@/services/students";
import batchSelect from "@/views/Batches/batchSelect";

export default {
  name: "AddStudentsIntoBatch",
  components: {
    pagination,
    batchSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      idBatch: this.$route.params.id,
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
      loading: false,
      selected: [],
      batch: {},
      studentInBatch: [],
      setup: {
        search_name: null,
        search_phone: null,
        search_email: null,
        search_batch: null,
        filter_active: null,
      },
      configs: configs
    };
  },
  async created() {
    await this.getBatches()
    await this.getStudentInBatch()
    await this.getStudents()
  },
  watch: {
    tableData: {
      handler(newTable) {
        this.checkedIn()
      },
      immediate: true
    }
  },
  methods: {
    async getStudents(params) {
      this.loading = true
      if (!params) {
        params = {per_page: configs.itemOnPage, page: this.currentPage, filter_active: configs.status.active}
      }
      GetListStudents(params).then(e => {
        let dataTable = []
        let studentInBatch = []
        let hadClass = false
        let batchName = ''
        if (e.data.status === 'success') {
          let students = e.data.data.list_student.data
          students.map((e, index) => {
            hadClass = false
            if (e.batch_students && e.batch_students.length > 0) {
              studentInBatch.push(e.id)
              hadClass = true
              batchName = e.batch_students[0].batches.batch_name
            }
            dataTable.push({
              index: index + 1,
              id: e.id,
              username: e.username,
              email: e.email,
              name: e.name,
              isClass: hadClass,
              batchName: batchName,
              isActive: e.is_active == configs.status.active,
              phone: e.phone_number,
            })
          })
          this.studentInBatch = studentInBatch
          this.total = e.data.data.list_student.total
          this.isShowPagination = e.data.data.list_student.total > 1
        }
        this.loading = false

        this.tableData = dataTable
      }).catch(err => {
        this.loading = false
      })
    },
    async nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      let params = this.setup
      params.page = this.currentPage
      params.per_page = configs.itemOnPage
      await this.getStudents(params);
    },

    closeDialog() {
      this.dialogAdd = false
      this.$refs.formAdd.resetFields()
    },

    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.selected.push(row.id)
      }
    },

    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.selected.findIndex(id => e.id == id) > -1) return
          this.selected.push(e.id)
        })
      }
    },

    getBatches() {
      let byId = {batch_id: this.$route.params.id}
      GetListBatches(byId).then(e => {
        if (e.data.status == 'success') {
          let batch = e.data.data.allBatch.data[0]
          if (!batch) {
            return this.$router.push({name: NameRouter.PAGES.LIST_BATCHES})
          }
          this.batch = {
            name: batch.batch_name,
            start_date: formatDate(batch.start_date),
            end_date: formatDate(batch.end_date)
          }
        }
      }).catch(err => {
        notifyErr()
      })
    },

    toggleSelection(table) {
      if (this.studentInBatch.length < 1) {
        return false
      }
      return true
    },

    assignStudent() {
      if (this.selected.length < 1) {
        return notifyWarning('Vui lòng chọn học viên.')
      }
      this.loading = true
      let obj = {
        batch_id: this.$route.params.id,
        student_ids: this.selected
      }
      AssignStudentToBatch(obj).then(e => {
        if (e.data.status == 'success') {
          this.$refs.studentTable.clearSelection()
          notifySuccess('add')
        }
        if (e.data.status == 'failed') {
          notifyErr()
        }
      }).catch(err => {
        if (err.response) {
          notifyErr(err.response.data.message)
        }
      })
      this.loading = false
    },

    async getStudentInBatch() {
      this.loading = true
      BatchDetail({batch_id: this.idBatch}).then(res => {
        if (res.data.status == 'success') {
          let studentInBatch = [];
          let data = res.data.data.data.student
          data.map(el => {
            studentInBatch.push(el.student_id)
          })
          this.studentInBatch = studentInBatch
        }
        this.loading = false
      }).catch()
    },
    checkedIn(row, index) {
      if (!row) return false
      return !row.isClass
    },
    searchStudent() {
      this.currentPage = 1
      let params = this.setup
      params.page = this.currentPage
      params.per_page = configs.itemOnPage
      this.getStudents(params)
    },
    refreshSearch() {
      this.currentPage = 1
      this.setup = {
        search_name: null,
        search_phone: null,
        search_email: null,
        search_batch: null,
        filter_active: null,
      }
      let params = this.setup
      params.page = this.currentPage
      params.per_page = configs.itemOnPage
      this.getStudents(params)
      this.$refs.batchSelectBox.resetData()
    }
  }
}

</script>
<script setup>
import CONFIG_APP from "@/configs";
</script>
<style scoped lang="scss">

</style>

<template>
  <div class="bg-white p-3 rounded" v-loading="loading">
    <infoAssignment :form="form" ref="infoAssign"></infoAssignment>
    <div class="py-3 text-center">
      <handle-button
          label="Cập nhật"
          eventCode="assignments.update"
          icon="fa fa-check"
          typeButton="primary"
          @click="updateAssignment"/>

      <handle-button
          v-if="hasFileOfStudent"
          label="Xóa"
          eventCode="assignments.destroy"
          icon="fa fa-trash"
          typeButton="danger"
          @click="openDialogConfirm()"/>
    </div>
    <confirm v-model="setup.confirmDialog" @deleteService="deleteAssignment"
             @close="setup.confirmDialog = false"></confirm>
  </div>
</template>
<script>

import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {DeleteAssignment, DetailAssignment, UpdateAssignment} from "@/services/common";
import infoAssignment from "@/views/AssigmentFile/infoAssignment";
import confirm from "@/components/confirm";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'DetailAssignment',
  components: {
    infoAssignment,
    confirm
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      form: {
        courseId: null,
        batchId: null,
        description: '',
        toDate: '',
        fromDate: '',
        name: '',
        deadline: '',
        isActive: 1,
        isHackathon: 0
      },
      loading: false,
      descriptionValid: false,
      setup: {confirmDialog: false},
      hasFileOfStudent: false
    }
  },
  created() {
    this.assignmentGet()
  },
  methods: {
    async updateAssignment() {
      this.loading = true
      let validCourse = await this.$refs.infoAssign.validForm()
      if (!validCourse) return false
      let assignment = {
        title: this.form.name,
        description: this.form.description,
        batch_id: this.form.batchId,
        course_id: this.form.courseId,
        from_date: this.form.fromDate,
        to_date: this.form.toDate,
        deadline: this.form.deadline,
        is_active: this.form.isActive,
        is_hackathon: this.form.isHackathon
      }
      await UpdateAssignment(assignment, this.form.id).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('edit')
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        return notifyErr()
      })
      event.preventDefault()
    },
    async assignmentGet() {
      await DetailAssignment(this.$route.params.id).then(res => {
        if (res.data.status === 'success') {
          let assignment = res.data.data.assignment
          this.form = {
            id: assignment.id,
            courseId: assignment.course_id,
            batchId: assignment.batch_id,
            name: assignment.title,
            description: assignment.description,
            fromDate: assignment.from_date,
            toDate: assignment.to_date,
            deadline: assignment.deadline,
            isActive: assignment.is_active,
            isHackathon: assignment?.is_hackathon
          }
          this.$refs.infoAssign.queryByName()
          let file = res.data.data.assignmentFiles.data
          if (file) {
            this.hasFileOfStudent = true
          }
          this.loading = false
        }
      }).catch(err => {
        return notifyErr()
      })
    },
    openDialogConfirm() {
      this.setup.confirmDialog = true
    },
    deleteAssignment() {
      DeleteAssignment(this.form.id).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('delete')
          this.$router.push({name: NameRouter.PAGES.ASSIGNMENT_LIST})
        }
      }).catch(err => {
        notifyErr(err.response.messages)
      })
    }
  }
}
</script>
<script setup>
import {Delete, Plus} from '@element-plus/icons-vue'
import {ref} from "vue";
import messages from "@/constants/messages";
import configs from "@/configs";

const avatar = ref()
</script>
<style scoped lang="scss">
:deep(.is-error) {
  .tox-tinymce {
    border-color: red;

  }
}

</style>

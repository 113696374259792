export default {
  REQUIRE: "Trường hợp này không được để trống",
  PHONE: "Số điện thoại không đúng định dạng",
  EMAIL: "Email không đúng định dạng",
  DATE: "Ngày không đúng",
  MONTH: "Tháng không đúng",
  YEAR: "Năm không đúng",
  NULL: "Không được để trống.",
  SPACE: "Không được nhập khoảng trắng",
  DATE_BEFORE_NOW: "Thời gian phải lớn hơn hoặc bằng hiện tại",
  DATE_COMPARE: "Thời gian kết thúc phải lớn hơn thời gian bắt đầu",
  START_DATE: "Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc",
  TITLE: "Thất bại",
  MESSAGE: "Lỗi hệ thống",
  REQUIRE_ALL_FILED: "Vui lòng điền đầy đủ thông tin.",
  REQUIRE_FILE: "Vui lòng chỉ nhập file (jpg, jpeg, png)",
  CHARACTER: "Không chứa các ký tự đặc biệt.",
  NUMBER: "Vui lòng chọn kiểu số.",
  NOT_ENNOUGH: "Vui lòng điền đầy đủ thông tin",
  CLEAR_IMAGE: "Không được xóa hết ảnh",
  NAME_LENGTH: "Không được nhập tên vượt quá 100 ký tự ",
  DESCRIPTION_LENGTH: "Không được nhập quá 500 ký tự",
  MONEY: "Tiền phải là số dương",
  NEGATIVE: "Không được nhập số âm",
  FLOAT: "Không được nhập kiểu số thực",
  LIMIT_SLIDE: "Số ảnh đã đủ số lượng cho phép. Vui lòng xóa bớt ảnh.",
  CODE: "Mã phải là 8 ký tự",
  USERNAME: "Tên tài khoản từ 4 - 20 ký tự",
  IMAGE: "Ảnh không đúng các định dạng jpeg, png, pjpeg, x-png",
  AVATAR_IMAGE: "Không được bỏ trống ảnh đại diện",
  SLIDE_IMAGE: "Không được bỏ trống ảnh slide",
  CODE_NOT_NULL: "Không được chứa khoảng trắng",
  NUMBER_NOT_INTEGER: "Không phải số nguyên",
  DATE_MAX: "Không được lớn hơn ngày kết thúc.",
  DATE_MIN: "Không được nhỏ hơn ngày bắt đầu.",
  ANSWER_REQUIRE: 'Hãy chọn một đáp án đúng.',
  QUESTION_REQUIRE: 'Câu hỏi chưa được chọn.',
  DUPLICATE: 'Trường này không được trùng.',
  EMAIL_EXIST: 'Email đã tồn tại.',
  PHONE_NUMBER_EXIST: 'Số điện thoại đã tồn tại.',
  USERNAME_EXIST: 'Tên đăng nhập tồn tại.',
  EMAIL_REGEX: "Email không đúng định dạng",
  PHONE_NUMBER_REGEX: "Số điện thoại không đúng định dạng.",
  USERNAME_REGEX: "Tên đăng nhâp không được có ký tự đặc biệt.",
  BIRTHDAY_REGEX: "Định dạng ngày tháng phải (dd/mm/yyyy).",
  GENDER: "Vui lòng chọn giới tính (Nam, Nữ hoặc Khác).",
  COURSE_NULL: "Vui lòng chọn khóa học.",
  PASSWORD_LENGTH: "Mật khẩu phải dài hơn ít nhất 8 ký tự.",
  RE_PASSWORD: "Mật khẩu chưa giống nhau.",
};

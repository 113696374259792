import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {REPORT_MATERIAL} from "@/constants/endPoints";

export const GetRatting = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.RATTING, {params: params});

export const ReportMaterial = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.STUDY, {params: params});

export const ListFileByStudent = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.LIST_FILE_BY_STUDENT, {params: params});

export const StudentMaterialRatting = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.STUDENT_MATERIAL_RATTING, {params: params});

export const StudentRollCallReport = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.STUDENT_ROLL_CALL_REPORT, {params: params});

export const RollCallStatus = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.ROLL_CALL_STATUS, {params: params});

export const RollCallMonth = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.ROLL_CALL_BY_MONTH, {params: params});

export const ReportQuizDetail = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.REPORT_QUIZ_DETAIL, {params: params});

export const ReportExerciseDetail = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.REPORT_EXERCISE_DETAIL, {params: params});

export const HistoriesHomeworks = (params) => HTTP_REQUEST_AUTH.get(REPORT_MATERIAL.HISTORIES_HOMEWORK, {params: params});


import { param } from "jquery";
import {HTTP_REQUEST_AUTH} from "./requestAxios";
import {COMMON} from "@/constants/endPoints";

export const GetFiles = (params) => HTTP_REQUEST_AUTH.get(COMMON.GET_FILES, {params: params});
export const UploadFile = (params) => HTTP_REQUEST_AUTH.post(COMMON.UPLOAD_FILE, params);
export const UpDateFile = (params) => HTTP_REQUEST_AUTH.post(COMMON.UPDATE_FILE, params);
export const GetFile = (params) => HTTP_REQUEST_AUTH.get(COMMON.GET_FILE, {params: params});
export const CreateFile = (params) => HTTP_REQUEST_AUTH.post(COMMON.CREATE_FILE, params);
export const DetailFile = (id) => HTTP_REQUEST_AUTH.get(COMMON.GET_FILE_DETAIL + '/' + id);

export const GetAssignFile = (params) => HTTP_REQUEST_AUTH.get(COMMON.ASSIGN_FILE, {params: params});
export const GetAssignment = (params) => HTTP_REQUEST_AUTH.get(COMMON.ASSIGNMENTS, {params: params});
export const CreateAssignment = (params) => HTTP_REQUEST_AUTH.post(COMMON.ASSIGNMENTS, params);
export const DetailAssignment = (id, params) => HTTP_REQUEST_AUTH.get(COMMON.ASSIGNMENTS + '/' + id, {params: params});
export const MarkScore = (params, id) => HTTP_REQUEST_AUTH.put(COMMON.MARKS_SCORE + '/' + id, params);
export const UpdateAssignment = (params, id) => HTTP_REQUEST_AUTH.put(COMMON.ASSIGNMENTS + '/' + id, params);
export const DeleteAssignment = (id) => HTTP_REQUEST_AUTH.delete(COMMON.ASSIGNMENTS + '/' + id);
export const AssignmentTeacher = (params)=> HTTP_REQUEST_AUTH.post(COMMON.ASSIGNMENT_TEACHER, params)
export const AssignmentTeacherDelete = (params)=> HTTP_REQUEST_AUTH.delete(COMMON.ASSIGNMENT_TEACHER_DELETE, {params: params})


export const ListTopic = (params, id) => HTTP_REQUEST_AUTH.get(COMMON.TOPICS, {params: params});
export const DetailTopic = (id) => HTTP_REQUEST_AUTH.get(COMMON.TOPICS + '/' + id);
export const CreateMessage = (params) => HTTP_REQUEST_AUTH.post(COMMON.TOPICS, params);
export const UpdateMessage = (params, id) => HTTP_REQUEST_AUTH.put(COMMON.TOPICS + '/' + id, params);
export const DeleteMessage = (id) => HTTP_REQUEST_AUTH.delete(COMMON.TOPICS + '/' + id);
export const MarkExercise = (params) => HTTP_REQUEST_AUTH.post(COMMON.MARKS_EXERCISE, params)
export const DeleteTopic = (id) => HTTP_REQUEST_AUTH.post(COMMON.DELETE_TOPIC + "/" + id);

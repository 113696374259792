<template>
  <div class="w-100" :class="{'hasErr': validation}">
    <label style="color: #606266" v-if="showLabel"> <span class="text-danger">*</span> Bài học</label>
    <vSelect
        v-model="fileId"
        placeholder="Lựa chọn bài giảng"
        :disabled="disableSelect"
        :options="fileList"
        label="name"
        :class="{'is-invalid': validation}"
        :reduce="(file) => file.id"
        class="w-100"
        :multiple="isMultiple"
        @search="(query)=> queryKeyword(query)"
        @close="$emit('onClose')"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader text-center">
          loading...
        </li>
      </template>
    </vSelect>
    <span class="invalid-feedback">{{ messageErr }}</span>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import {GetFiles} from "@/services/common";
import CONFIG_APP from "@/configs";

export default {
  expose: ['resetData'],
  name: "FilesSelect",
  components: {vSelect},
  props: {
    messageErr: {type: String, default: 'Vui lòng chọn bài học.'},
    validation: Boolean,
    modelValue: Array,
    idOld: {type: Array, default: null},
    isMultiple: {type: Boolean, default: true},
    showLabel: {type: Boolean, default: true},
  },
  data() {
    return {
      fileId: [],
      hasNextPage: false,
      disableSelect: false,
      fileList: [],
      page: 1
    }
  },
  computed: {
    per_page: () => {
      return CONFIG_APP.itemOnPage
    }
  },
  watch: {
    fileId() {
      this.$emit('selected', this.fileId)
      this.$emit('update:modelValue', this.fileId)
    },
    fileList() {
      this.fileId = this.modelValue
    }
  },
  created() {
    this.getFiles()
  },

  methods: {
    getFiles(params) {
      this.hasNextPage = true
      if (!params) {
        params = {per_page: this.per_page, page: this.page}
      }
      if (this.idOld) {
        params.per_page = this.per_page + this.idOld.length
      }
      GetFiles(params).then(e => {
        if (e.data.status === 'success') {
          let data = e.data.data.listAllFile.data
          if (this.idOld) {
            this.idOld.map(item => {
              let index = data.findIndex(el => el.id === item.id)
              if (index > -1) {
                data.splice(index, 1)
              }
            })
          }
          this.fileList = data
          this.disableSelect = false
          this.hasNextPage = false
        }
      }).catch(err => {
        if (err.response.data == 'failed') {
          location.reload();
        }
      })
    },
    queryKeyword(query) {
      this.subjects = []
      this.hasNextPage = true
      let params = {per_page: this.per_page, page: 1, keyword: query}
      this.getFiles(params)
    },
    resetData() {
      this.fileId = null
    }
  },

}
</script>
<style scoped lang="scss">
.hasErr {
  .is-invalid {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
}
</style>

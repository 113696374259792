<template>
  <div id="managementExamReports">
    <div class="search py-2">
      <el-row :gutter="10">
      <el-col :span="2" style="text-align:right; padding-top:8px; color:#606266; font-weight:700">
      Tên học viên:
      </el-col>
      <el-col :span="7"  style="margin-top: 8px" >
          <el-input v-model="setup.keyword" />
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="Từ ngày:" class="pt-1">
            <el-date-picker
              v-model="checkinForm.dateSelectFrom"
              type="date"
              :disabled-date="(date) => disabledDate(date)"
              :default-value="dateDefault"
              label="Pick a date"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-form-item label="Đến ngày:" >
            <el-date-picker
              v-model="checkinForm.dateSelectTo"
              type="date"
              :disabled-date="(date) => disabledDate(date)"
              :default-value="dateDefault"
              label="Pick a date"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2" style="margin-top: 4px">
          <el-button :icon="Search" type="primary" @click="searchData">
            Tìm kiếm
          </el-button>
        </el-col>
         <el-col :span="2" style="margin-top: 4px">
          <el-button  type="primary" @click="resetData">
            Reset
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed>
      <el-table-column
        fixed
        prop="student_name"
        label="Tên học viên"
        min-width="150"
      />
      <el-table-column prop="email" label="Email" min-width="100" />
      <el-table-column
        prop="last_date"
        label="Thời gian lần cuối làm bài"
        min-width="120"
      />
      <el-table-column prop="max_score" label="Điểm cao nhất" min-width="120" />
      <el-table-column
        prop="is_passed"
        label="Trạng thái bài thi"
        min-width="120"
      >
        <template #default="scope">
          <div v-if="scope.row.is_passed == '1'" class="text-success">
Đạt
</div>
          <div v-if="scope.row.is_passed == '0'" class="text-danger">
            Không đạt
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="isShowPagination"
      :total="total"
      :page-index="pageIndex"
      @currentPageChange="nextPage"
    />
  </div>
</template>
<script>
import { ExamReport } from "@/services/exam";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import { formatDate, notifyErr } from "@/mixins/utils";

export default {
  name: "ListExamReport",
  components: {
    pagination,
  },
  data() {
    return {
      setup: {
        keyword: "",
      },
      checkinForm: {
        dateSelectFrom: "",
        dateSelectTo: "",
      },
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      idExamCourse: this.$route.params.id,
      loading: false,
    };
  },

  watch: {},

  created() {
    this.getListExamReport();
  },
  methods: {
    getListExamReport() {
      this.loading = true;
      let perPage = {
        per_page: configs.itemOnPage,
        page: this.currentPage,
        student_name: this.setup.keyword,
        time_take_exam_from: this.checkinForm.dateSelectFrom,
        time_take_exam_to: this.checkinForm.dateSelectTo,
      };
      ExamReport(perPage, this.idExamCourse)
        .then((e) => {
          if (e.data.status == "success") {
            let dataTable = [];
            e.data.data.examReport.data.map((e) => {
              dataTable.push({
                id: e.id,
                student_name: e.student ? e.student.name : "",
                email: e.student ? e.student.email : "",
                last_date: e.last_time_take_exam
                  ? formatDate(e.last_time_take_exam)
                  : "",
                max_score: e.max_score,
                is_passed: e.is_passed,
              });
            });
            this.tableData = dataTable;
            this.total = e.data.data.examReport.total;
            this.isShowPagination = e.data.data.examReport.total > 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          notifyErr();
        });
    },
    resetListExamReport() {
      this.loading = true;
      this.checkinForm.dateSelectFrom = "";
      this.setup.keyword= "";
      this.checkinForm.dateSelectTo="";
      this.getListExamReport();
    },

    disabledStartDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() < dateNew.getTime();
      }
    },
    disabledEndDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() > dateNew.getTime();
      }
    },
    searchData(index) {
      this.currentPage = 1;
      this.getListExamReport(index);
    },
      resetData() {
      this.currentPage = 1;
      this.resetListExamReport();
    },
    disabledDate(date) {
      let dateNew = new Date();
      return date.getTime() > dateNew.getTime();
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getListExamReport(index);
    },
  },
};
</script>

import {useCookies} from "vue3-cookies";
import {BASE_URL} from "@/constants/endPoints";

const {cookies} = useCookies();
const key = "key";
const keyToken = "token";
const infoUser = "infoUser";
const permissions = "permissions";
const contentFile = "contentFile";
const contentAssignment = "contentAssignment";
const contentToken = "contentToken";
const role = "role"

const setKeyStore = (data) => {
  localStorage.setItem(key, data);
};

const setTokenStore = (data) => {
  return localStorage.setItem(keyToken, data);
};

const getTokenStore = (data) => {
  return localStorage.getItem(data);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const setTokenCookies = (data) => {
  return cookies.set(keyToken, data)
}

/**
 * Set info user
 *
 * @param data
 * @returns {VueCookiesManager}
 */
const setInfoUser = (data) => {
  return cookies.set(infoUser, data)
}

/**
 * Get Token
 *
 * @returns {string}
 */
const getTokenCookies = () => {
  return cookies.get(keyToken)
}

/**
 * Get info user login
 *
 * @returns {string}
 */
const getInfoUser = () => {
  return cookies.get(infoUser)
}
const setContentFile = (data) => {
  return cookies.set(contentFile, data)
}
const getContentFile = () => {
  return cookies.get(contentFile)
}
const getContentAssignment = () => {
  return cookies.get(contentAssignment)
}
const getContentTopic = () => {
  return cookies.get(contentToken)
}
const setContentTopic = (data) => {
  return cookies.set(contentToken, data)
}
const setContentAssignment = (data) => {
  return cookies.set(contentAssignment, data)
}
const setPermission = (data) => {
  return localStorage.setItem(permissions, data);
}
const getPermission = () => {
  if (!localStorage.getItem(permissions)) return null
  try {
    return JSON.parse(localStorage.getItem(permissions))
  } catch (err) {
    logout()
  }
}

const setRole = (data) => {
  return cookies.set(role, data)
}
const getRole = () => {
  return cookies.get(role)
}


const logout = () => {
  cookies.remove(infoUser)
  cookies.remove(contentAssignment)
  cookies.remove(contentAssignment)
  cookies.remove(keyToken)
  cookies.remove(role)
  localStorage.removeItem(permissions)
}
const openPreviewCourse = (token, course_id) => {
  let baseUrlAPI = '';
  if (window.location.href.search(process.env.VUE_APP_URL_PRODUCT) !== -1) {
    baseUrlAPI = process.env.VUE_APP_URL_WEB_STUDENT_PRODUCT;
  } else if (window.location.href.search(process.env.VUE_APP_URL_DEV) !== -1) {
    baseUrlAPI = process.env.VUE_APP_URL_WEB_STUDENT_DEV;
  } else if (window.location.href.search(process.env.VUE_APP_URL_WEB_ONLINE) !== -1) {
    baseUrlAPI = process.env.VUE_APP_URL_WEB_STUDENT_ONLINE;
  } else {
    baseUrlAPI = process.env.VUE_APP_URL_WEB_STUDENT_LOCAL;
  }
  return baseUrlAPI + '/preview-course/' + '?token=' + token + '&course_id=' + course_id
}

const roleAdmin = 1
export {
  setKeyStore,
  setTokenStore,
  getTokenStore,
  clearLocalStorage,
  getTokenCookies,
  setTokenCookies,
  getInfoUser,
  setInfoUser, logout,
  setContentFile,
  getContentFile,
  setContentAssignment,
  getContentAssignment,
  setContentTopic,
  getContentTopic,
  setPermission,
  getPermission,
  setRole,
  getRole,
  roleAdmin,
  openPreviewCourse
};

<template>
  <div id="managementBatches" class="bg-white p-3">
    <div class="search py-2">
      <el-row :gutter="4">
        <el-col :span="6" :offset="18" class="text-right">
          <handle-button
              type="link"
              label="Chi tiết cơ sở"
              eventCode=""
              icon="fa fa-edit"
              typeButton="primary"
              :to="{ name: nameRouter.PAGES.FACILITY_DETAIl, params:{id: idBatch}}"/>
          <handle-button
              type="link"
              label="Thêm nhân viên"
              eventCode=""
              icon="fa fa-edit"
              typeButton="primary"
              :to="{ name: nameRouter.PAGES.ADD_USERS_TO_FACILITY, params:{id: idBatch}}"/>
        </el-col>
      </el-row>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" fixed border>
      <el-table-column fixed prop="id" label="ID" width="50"/>
      <el-table-column fixed prop="batch_name" label="Tên lớp" width="150"/>
      <el-table-column fixed prop="code" label="Mã lớp" width="100"/>
      <el-table-column prop="roadmap_name" label="Tên khóa học" min-width="100"/>
      <el-table-column prop="countStudentInBatch" label="Sĩ số" width="100"/>
      <el-table-column prop="start_date" label="Ngày bắt đầu" width="150"/>
      <el-table-column prop="end_date" label="Ngày kết thúc" width="150"/>
      <el-table-column
          label="Trạng thái"
          width="150"
      >
        <template #default="scope">
          <el-button
              v-if="scope.row.isActive"
              type="success" size="small"
          >
            {{ CONFIG_APP.status.activeStr }}
          </el-button>
          <el-button
              v-if="!scope.row.isActive"
              type="warning" size="small"
          >
            {{ CONFIG_APP.status.inActiveStr }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          width="300"
      >
        <template #default="scope">
          <div class="d-flex justify-content-center">
            <el-button
                type="primary"
                size="small"
                @click="addToFacility(scope.row.id)"
                v-if="!scope.row.classHadFacility"
            >
              Thêm vào cơ sở
            </el-button>
            <el-button
                type="danger"
                size="small"
                v-if="scope.row.classHadFacility"
                :disabled="true"
            >
              Đã có trong cơ sở
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
</template>
<script>
import {GetListBatches} from "@/services/batches";
import {GetAllRoadmap} from "@/services/roadmap";
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {formatDate, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {AddClassToFacility, GetDetailFacility} from "@/services/facility";

export default {
  name: "AddClassToFacility",
  components: {
    pagination
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      idBatch: this.$route.params.id,
      arrayFacilityHasClass: [],
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      infoStudent: {},
      nameRouter: NameRouter,
      roadmaps: [],
    };
  },
  watch: {},

  created() {
    this.getRoadmap()
    this.getBatches()
  },
  methods: {
    async getBatches() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage}
      await GetDetailFacility(this.idBatch).then(res => {
        if (res.data.status == 'success') {
          let arrayClassExist = res.data.data.branchDetail[0].batch?.map(e => e.id)
          this.arrayFacilityHasClass = arrayClassExist
        }
      })
      GetListBatches(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          e.data.data.allBatch.data.map((e, index) => {
            dataTable.push({
              index: e.index,
              id: e.id,
              batch_name: e.batch_name,
              code: e.code,
              roadmap_name: e.roadmaps.title,
              countStudentInBatch: e.batch_students_count,
              start_date: e.start_date ? formatDate(e.start_date) : '',
              end_date: e.end_date ? formatDate(e.end_date) : '',
              isActive: e.is_active == configs.status.active,
              classHadFacility: this.arrayFacilityHasClass.includes(e.id)
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.allBatch.total
          this.isShowPagination = e.data.data.allBatch.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    getRoadmap() {
      GetAllRoadmap().then(e => {
        if (e.data.status == 'success') {
          this.roadmaps = e.data.data.allRoadmap
        }

      }).catch(err => {
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getBatches(index);
    },
    async addToFacility(id) {
      this.loading = false;
      let param = {
        batch_ids: [id]
      }
      AddClassToFacility(this.idBatch, param).then(res => {
        if (res.data.status == 'success') {
          this.getBatches()
          notifySuccess("add")
        }
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })

      this.loading = false;
    }
  }

}

</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {
  Check,
  Plus,
  Edit,
  Close,
  Search,
  Star,
} from '@element-plus/icons-vue'

const disabledDate = (time, data) => {

  return time.getTime() > Date.now()
}
const dialogAdd = ref()
</script>
<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

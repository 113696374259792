<template>
  <div class="bg-white">
    <info-schedule :form="form" ref="formSchedule"/>
    <div class="text-center py-3 px-3">
      <handle-button
          label="Tạo lịch trình"
          eventCode="schedules.destroy"
          icon="fa fa-plus"
          typeButton="primary"
          @click="createSchedule"/>
    </div>
  </div>
</template>
<script>
import configs from "@/configs";
import {formatDataServe, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import infoSchedule from "@/views/Schedule/infoSchedule";
import {CreateSchedule} from '@/services/schedule'
import moment from "moment";

export default {
  name: "CreateSchedule",
  mixins: [setBreadcrumb],
  components: {
    infoSchedule
  },
  data() {
    return {
      configs: configs,
      NameRouter: NameRouter,
      form: {},
      loading: false,
    };
  },
  created() {
      this.getDayParams();
  },
  methods: {
    async createSchedule() {
      let validEvent = await this.$refs.formSchedule.validForm()
      if (!validEvent) return false
      if (!validEvent) return false
      if(this.form.day && this.form.day.length > 0){
        this.form.start_time= this.form.day[0];
        this.form.end_time =  this.form.day[1]
      }
      this.loading = true;
      try {
        this.loading = false;
        await CreateSchedule(this.form);
        notifySuccess('add')
        this.$router.push({name: NameRouter.PAGES.LIST_SCHEDULE})
      } catch (error) {
        this.loading = false
        return notifyErr(error.response?.data.message)
      }
    },
    getDayParams(){
      this.form.day = new Date(this.$route.params.id)
    }

  }
  ,
}

</script>
<script setup>

</script>
<style scoped lang="scss">

</style>
<template>
  <el-tabs v-model="setup.activeName" vel-tabs type="border-card" v-loading="loading">
    <el-tab-pane label="Khóa học" name="first">
      <infoCourse :form="form" ref="courseInfoForm" :isUpdate="true"></infoCourse>
      <div class="py-3 text-center">
        <el-button type="primary" @click="editCourse">Cập nhật</el-button>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Nội dung khóa học" name="secord">
      <div v-if="form.type === hasSection" class="add-section">
        <div class="text-end pb-3">
          <handle-button
              label="Thêm chương học"
              eventCode="sections.store"
              icon="fa fa-plus"
              typeButton="primary"
              @click="setup.contentDialog= true"/>
        </div>
        <sectionCourse
            v-if="form.type === hasSection"
            ref="section"
            :sections="sectionForm" @updateSection="updateSection"
            @DeleteSection="openConfirm"/>

      </div>
      <div v-if="form.type === notSection" class="add-material">
        <div class="text-right my-2" ref="materialComponent">
          <el-button :icon="Plus" type="primary" @click="setup.contentDialog= true">Thêm bài học</el-button>
        </div>
        <Materials
            ref="materialCourse"
            :materials="materials"
            :is-edit="true"
            @deleteMaterial="openConfirm"
            @editMaterial="editMaterial"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane label="Kiểm tra" name="exams">
      <div class="text-end pb-3">
        <handle-button
            label="Thêm bài thi"
            eventCode="exams.index"
            icon="fa fa-plus"
            typeButton="primary"
            @click="examDialog= true"/>
      </div>
      <el-table
          ref="exams"
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%"
          fixed
      >
        <el-table-column fixed prop="title" label="Tiêu đề" min-width="150"/>
        <el-table-column prop="duration" label="Thời gian thi" width="200"/>
        <el-table-column prop="createdAt" label="Ngày tạo" width="200"/>
        <el-table-column prop="createdAt" label="Vị trí" width="120">
          <template #default="scope">
            <input v-model="scope.row.sort_order" class="form-control" :id="'exam-'+ scope.row.id"
                   @change="changeExam(scope.row.id)"/>
            <div class="invalid-feedback" :id="'err-'+ scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column label="Hoạt động" width="250">
          <template #default="scope">
            <div class="d-flex">
              <handle-button
                  label="Loại bỏ"
                  eventCode="courses.update"
                  icon="fa fa-trash"
                  typeButton="danger"
                  @click="openConfirmExam(scope.row.id)"/>
              <handle-button
                  label="Lưu bài thi"
                  eventCode="courses.update"
                  icon="fa fa-check"
                  typeButton="primary"
                  @click="saveExams(scope.row.id)"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>

  <!--  dialog-->
  <el-dialog v-model="setup.contentDialog" @close="closeDialog">
    <template #title>
      <div class="border-bottom py-2">
        <b v-if="form.type == hasSection">Thêm chương</b>
        <b v-if="form.type == notSection">Thêm bài học</b>
      </div>
    </template>
    <el-form label-position="top" :model="addContent" :rules="addContentRule" ref="contentForm">
      <el-form-item class="w-100" prop="name" label="Tiêu đề">
        <el-input v-model="addContent.name"></el-input>
      </el-form-item>
      <el-form-item label="Ví trí" prop="sort_order">
        <el-input v-model="addContent.sort_order"></el-input>
      </el-form-item>
      <el-form-item label="Mã code" prop="code">
        <el-input v-model="addContent.code"></el-input>
      </el-form-item>
      <el-form-item label="Thời gian học(phút)" prop="duration" v-if="form.type == notSection">
        <el-input v-model="addContent.duration"></el-input>
      </el-form-item>
      <div class="mb-3">
        <materialSelect v-if="form.type === hasSection" v-model="setup.materialSelect" ref="materialList"
                        :validation="validMaterialSelect"></materialSelect>
        <files-select v-if="form.type === notSection" v-model="setup.files" ref="materialList"
                      :validation="fileValid"></files-select>
      </div>
      <el-form-item  v-if="form.type == notSection" label="Mục đích" prop="purpose_of_uses">
        <el-select v-model="addContent.purpose_of_uses" placeholder="Vui lòng chọn mục đích" class="w-100">
          <el-option  v-for="(item, index) in CONFIG_APP.type_of_purpose" :key="index"
                      :label="item" :value="index"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Trạng thái hoạt động">
        <el-radio-group v-model="addContent.is_active">
          <el-radio :label="configs.status.active" size="lage" border>
            {{ configs.status.activeStr }}
          </el-radio>
          <el-radio :label="configs.status.inActive" size="lage" border>
            {{ configs.status.inActiveStr }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">Đóng</el-button>
        <el-button type="primary" @click="contentNew">Lưu</el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="examDialog" @close="closeDialog">
    <el-form label-position="top" :model="exam" :rules="examContentRule" ref="examForm">
      <el-form-item class="w-100" prop="id">
        <examSelect v-model="exam.id" :id-old="examOld" :validation="validExam" ref="examSelected"></examSelect>
      </el-form-item>
      <el-form-item class="w-100" label="Vị trí" prop="sort_order">
        <el-input v-model="exam.sort_order"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog('examDialog')">Đóng</el-button>
        <el-button type="primary" @click="addExam">Lưu</el-button>
      </div>
    </template>
  </el-dialog>
  <confirm v-model="confirmDialog" @deleteService="deleteContent"></confirm>
  <confirm v-model="confirmExam" @deleteService="deleteExam"></confirm>
</template>

<script>
import sectionCourse from "@/components/section";
import Materials from "@/components/material";
import confirm from "@/components/confirm";
import filesSelect from "@/views/ManagerFile/filesSelect";

import {formatDate, notifyErr, notifySuccess, notifyWarning, setBreadcrumb} from "@/mixins/utils";
import infoCourse from "@/views/Course/infoCourse";
import materialSelect from "@/views/ManagementMaterial/materialSelect";
import {checkIsInteger, textInputValidate} from "@/validator";
import examSelect from "@/views/Exam/examSelect";
import messages from "@/constants/messages";
import CONFIG_APP from "@/configs";
import {
  CreateMaterial,
  CreateSection,
  DeleteSection,
  GetDetailCourse,
  UpdateCourse,
  UpdateMaterial,
  UpdateSection,
  DeleteMaterial,
} from "@/services/course";


export default {
  name: "CourseDetail",
  components: {
    sectionCourse,
    Materials,
    infoCourse,
    materialSelect,
    examSelect,
    confirm,
    filesSelect
  },
  mixins: [setBreadcrumb],
  data() {
    return {
      CONFIG_APP:CONFIG_APP,
      examId: null,
      form: {
        subject: '',
        summary: '',
        description: '',
        whatLearn: [],
        requirement: [],
        examIds: [],
        type: 1,
        avatar: '',
        name: '',
        roadmapId: '',
        position: '',
        isActive: 1,
      },
      idCourse: '',
      sectionMaterial: false,
      sectionForm: [],
      file: {},
      materials: [{
        id: 1,
        name: '',
        position: '',
        isActive: true,
        file: '',
      }],
      currentSection: false,
      validMaterialSelect: false,
      confirmExam: false,
      hasSection: 1,
      notSection: 2,
      setup: {
        activeName: 'first',
        contentDialog: false,
        materialSelect: [],
        files: [],
      },
      addContent: {
        name: '',
        sort_order: '',
        is_active: 1,
        materials: [],
        code: '',
        purpose_of_uses:'2',
      },
      addContentRule: {
        name: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger],
        code: [textInputValidate]
      },
      examContentRule: {
        id: [textInputValidate],
        sort_order: [textInputValidate, checkIsInteger]
      },

      loading: false,
      fileValid: false,
      confirmDialog: false,
      validExam: false,
      examDialog: false,
      idDeleteCurrent: '',
      sectionMaterials: [],
      tableData: [],
      examOld: [],
      exam: {
        id: null,
        sort_order: ''
      },
      idExamDelete: ''
    }
  },
  created() {
    this.getCourse()
  },
  methods: {
    deleteMaterial(obj) {
      if (this.form.type != this.notSection) return false
      let materialIndex = this.materials.findIndex(material => material.id === this.idDeleteCurrent)
      if (materialIndex < 0) return notifyWarning('Không tồn tại bài học này.')
      if (this.materials.length <= 1) return notifyWarning('Không được xóa hết.')
      this.confirmDialog = true
      this.materials.splice(materialIndex, 1)
      DeleteMaterial(this.idDeleteCurrent).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('delete')
          this.getCourse()
          this.confirmDialog = false
        }
      }).catch(err => {
        notifyErr()
      })
    },
    async getCourse() {
      this.idCourse = this.$route.params.id
      this.loading = true
      GetDetailCourse(this.idCourse).then(res => {
        if (res.data.status === 'success') {
          let course = res.data.data.course
          this.form = {
            subject: course.subject_id,
            name: course.title,
            code: course.code,
            summary: course.summary,
            duration: course.duration ? course.duration / 60 : '',
            whatLearn: course.what_learns,
            requirement: course.requirements,
            type: course.type,
            version: course.version,
            level: course.level,
            description: course.description,
            position: course.sort_order,
            avatar: course.image,
            isActive: course.is_active,
            isFree: course.is_free == 1,
            subjectName: course.subject.name,
            roadmapId: course.roadmap_id ?? ''
          };
          this.imageUrl = course.full_course_image_url
          if (course.section && course.type == this.hasSection) {
            let section = []
            let materials = []
            course.section.map(item => {
              item.section_material.map(material => {
                materials.push({
                  id: material.material_id,
                  sort_order: material.sort_order,
                })
              })
              section.push({
                course_id: this.idCourse,
                id: item.id,
                name: item.name,
                code:item.code,
                sort_order: item.sort_order,
                is_active: item.is_active,
                isOld: true,
                materials: materials,
              })
            })
            this.sectionForm = section;
          }
          if (course.material && course.type == this.notSection) {
            this.sectionMaterial = false
            let materials = []
            course.material.map(item => {
              let files = [];
              if (item.material_file) {
                item.material_file.map(file => {
                  files.push(file.file_id)
                })
              }

              materials.push({
                id: item.id,
                name: item.name,
                code:item.code,
                duration: item.duration ? item.duration / 60 : '',
                position: item.sort_order,
                isActive: item.is_active,
                isOld: true,
                file: files,
              })
            })
            this.materials = materials;
          }
          if (course.course_exam) {
            let dataTable = []
            let examOld = []
            course.course_exam.map(item => {
              let e = item.exam
              examOld.push({id: e.id, sort_order: item.sort_order})
              dataTable.push({
                id: e.id,
                title: e.title,
                duration: e.duration,
                sort_order: item.sort_order,
                createdAt: e.created_at ? formatDate(e.created_at) : '',
              })
            })
            this.tableData = dataTable
            this.examOld = examOld
            this.form.examIds = examOld
          }
          this.$refs.courseInfoForm.setImage(course.full_course_image_url)
          this.$refs.courseInfoForm.searchSubject(course.subject.name)
          this.loading = false
        }
      }).catch(err => {
        console.log(err)
        notifyErr()
        this.loading = false
      })
    },
    async createSection(courseId) {
      let materials = []
      this.setup.materialSelect.map((el, index) => {
        materials.push({id: el, sort_order: index})
      })
      this.addContent.materials = materials
      let obj = {
        course_id: courseId,
        sections: [this.addContent],
        code:this.form.code,
      }
      CreateSection(obj).then(async res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm chương học thành công')
          await this.getCourse()
        }
      }).catch(err => {
        notifyErr()
      })
    },
    resetContent() {
      if (this.form.type == 1) {
        this.sectionForm = [
          {
            name: '',
            duration: '',
            position: '',
            isActive: 1,
            id: '1',
            materials: [{
              id: 1,
              name: '',
              position: '',
              file: '',
              isActive: true,
              msg: '',
              msgNum: '',
              valid: false,
              validNum: false,
            }]
          }
        ]
      }
      if (this.sectionForm == 2) {
        this.materials = [{
          id: 1,
          name: '',
          position: '',
          file: 0,
          isActive: true,
          msg: '',
          msgNum: '',
          valid: false,
          validNum: false,
        }]
      }
    },
    async editCourse(type) {
      let validCourse = await this.$refs.courseInfoForm.validForm()
      if (!validCourse) return false
      let course = {
        subject_id: this.form.subject,
        title: this.form.name,
        code: this.form.code,
        summary: this.form.summary,
        duration: this.form.duration * 60,
        version: this.form.version,
        description: this.form.description,
        level: this.form.level,
        requirements: this.form.requirement,
        what_learns: this.form.whatLearn,
        roadmap_id: this.form.roadmapId,
        course_image: this.form.avatar,
        is_active: this.form.isActive,
        is_free: this.form.isFree ? 1 : 0,
        type: this.form.type,
        sort_order: this.form.position,
      }
      if (type == 'examAdd') {
        this.examOld.push(this.exam)
        course.exams = this.examOld
      }
      if (!type) {
        let exams = []
        this.tableData.map(el => {
          if (isNaN(el.sort_order)) return false
          exams.push({id: el.id, sort_order: el.sort_order})
        })
        course.exams = exams
      }

      UpdateCourse(this.idCourse, course).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
        }
      }).catch(err => {
        notifyErr()
      })
      event.preventDefault()
    },
    editMaterial(id) {
      let currentMaterial = this.materials.find(item => item.id == id)
      let files = [];
      if (currentMaterial.file) {
        currentMaterial.file.map((item, index) => {
          files.push({
            id: item,
            sort_order: index
          })
        })
      }
      let material = {
        course_id: this.idCourse,
        name: currentMaterial.name,
        code: currentMaterial.code,
        duration: parseInt(currentMaterial.duration)  ,
        sort_order: currentMaterial.position,
        is_active: currentMaterial.isActive,
        files: files,
        old_file_ids: currentMaterial.file,
      }
      if (this.currentSection) {
        material.old_section_id = this.currentSection
      }
      if (currentMaterial.isOld) {
        UpdateMaterial(id, material).then(res => {
          if (res.data.status == 'success') {
            notifySuccess('edit')
          }
        }).catch(err => {
          notifyErr()
        })
        return true
      }
      if (!currentMaterial.isOld && this.currentSection) {
        material.section_id = this.currentSection
      }

    },
    async createMaterial() {
      if (this.form.type !== this.notSection) return false
      let files = [];
      this.setup.files.map((item, index) => {
        files.push({
          id: item,
          sort_order: index
        })
      })
      let obj = {
        course_id: this.idCourse,
        name: this.addContent.name,
        duration: this.addContent.duration,
        sort_order: this.addContent.sort_order,
        is_active: this.addContent.is_active,
        files: files,
        code:this.addContent.code,
        purpose_of_uses:this.addContent.purpose_of_uses,
      }
      CreateMaterial({materials: [obj]}).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('edit')
        }
      }).catch(err => {
        notifyErr()
      })
    },
    closeDialog(type) {
      if (type) {
        this.confirmDialog = false
        this.examDialog = false
        this.examDialog = false
        this.exam = {
          id: null,
          sort_order: ''
        }
        if (type == 'examDialog') {
          this.$refs.examSelected.resetData()
        }
      }
      this.setup.contentDialog = false
      this.addContent = {
        title: '',
        sort_order: '',
        is_active: 1,
        name: '',
        duration: ''
      }
    },
    async contentNew() {
      if (this.form.type == this.hasSection) {
        this.validMaterialSelect = this.setup.materialSelect < 1
        let valid = await this.$refs.contentForm.validate((valid, fields) => {
          if (!valid || !this.addContent.id || !this.setup.materialSelect) {
            return false;
          }
        })
        if (!valid) return false;
        await this.createSection(this.idCourse)
      }
      if (this.form.type == this.notSection) {
        this.addContentRule.duration = [textInputValidate, checkIsInteger]
        this.fileValid = this.setup.files.length < 1
        let valid = await this.$refs.contentForm.validate((valid, fields) => {
          if (!valid || !this.addContent.id || !this.setup.files) {
            return false;
          }
        })
        if (!valid) return false;
        await this.createMaterial()
      }
      await this.getCourse()
      await this.closeDialog()
    },
    openConfirm(id) {
      if (this.sectionForm.length < 2 && this.form.type == this.hasSection) return notifyErr('không được xóa hết chương học.')
      if (this.materials.length < 2 && this.form.type == this.notSection) return notifyErr('không được xóa hết bài học.')
      this.confirmDialog = true
      this.idDeleteCurrent = id
    },
    deleteSection() {
      DeleteSection(this.idDeleteCurrent).then(res => {
        if (res.data.status == 'success') {
          notifySuccess('delete')
          this.getCourse()
          this.confirmDialog = false
        }
      }).catch(err => {
        notifyErr()
      })
    },
    updateSection(obj) {
      UpdateSection(obj.id, obj.section).then(e => {
        if (e.data.status !== 'success') return notifyErr();
        notifySuccess('add', 'Thay đổi thành công chương học');
        this.getCourse()
      }).catch(err => {
        notifyErr()
      })
    },
    async addExam() {
      this.validExam = this.exam.id == ''
      let valid = await this.$refs.examForm.validate((valid, fields) => {
        if (!valid || !this.exam.id) {
          return false;
        }
      })
      if (!valid) return false
      this.validExam = false
      await this.editCourse('examAdd')
      await this.getCourse()
      this.closeDialog('confirmDialog')
    },
    deleteContent() {
      event.preventDefault()
      if (this.form.type === this.hasSection) {
        this.deleteSection()
      }
      if (this.form.type === this.notSection) {
        this.deleteMaterial()
      }
    },
    changeExam(id) {
      let message = ''
      let findIndex = this.tableData.findIndex(el => el.id === id)
      if (findIndex < 0) return false;
      let item = this.tableData[findIndex];
      let elementInput = document.getElementById('exam-' + id)
      let elementErr = document.getElementById('err-' + id)
      if (!item.sort_order) message = messages.NULL
      if (item.sort_order && isNaN(item.sort_order)) message = messages.NUMBER
      if (item.sort_order && !isNaN(item.sort_order)) message = ''
      elementErr.innerText = message
      if (message) {
        elementInput.classList.add('is-invalid')
      } else {
        elementInput.classList.remove('is-invalid')
      }
    },
    async deleteExam() {
      let findIndex = this.tableData.findIndex(el => el.id === this.idExamDelete)
      if (findIndex < 0) return notifyErr('Không tồn tại bài thi này.')
      this.tableData.splice(findIndex, 1)
      await this.editCourse()
      this.examDialog = false
    },
    async saveExams(id) {
      let findIndex = this.tableData.findIndex(el => el.id === id)
      if (findIndex < 0) return false;
      let item = this.tableData[findIndex];
      if (!item.sort_order || isNaN(item.sort_order)) return false
      await this.editCourse()
      await this.getCourse()
    },
    openConfirmExam(id) {
      if (this.tableData.length < 2) return notifyErr('không được xóa hết bài thi.')
      this.confirmExam = true
      this.idExamDelete = id
    },
  }
}
</script>
<script setup>
import {Delete, Plus, Check} from '@element-plus/icons-vue'
import {ref} from "vue";
import configs from "@/configs";

const avatar = ref()
</script>
<style lang="scss" scoped>
::v-deep .is-error {
  .vs__dropdown-toggle {
    border-color: red;
  }
}
</style>

<template>
  <el-form label-position="top" :model="form" :rules="addContentRule" ref="contentForm">
    <div class="row">
      <div class="col-12">
        <el-form-item class="w-100" prop="name" label="Tên bài kiểm tra">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </div>
      <div class="col-6">
        <div class="pb-3">
          <batchSelect
              v-model="form.batchId"
              :validation="batchValid"
              :is-multiple="false"
              ref="batchSelectBox"></batchSelect>
        </div>
      </div>
      <div class="col-6">
        <div class="pb-3">
          <course-select
              :validation="courseValid"
              v-model="form.courseId"
              :is-multiple="false"
              is-assignment="1"
              ref="courseSelectBox">
          </course-select>
        </div>
      </div>
      <div class="col-6">
        <el-form-item prop="fromDate" label="Ngày bắt đầu">
          <el-date-picker
              v-model="form.fromDate"
              class="w-100"
              type="datetime"
              :disabled-date="(time) => disabledEndDate(time, form.toDate)"
              placeholder="Ngày bắt đầu"
              format="DD/MM/YYYY HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
      </div>
      <div class="col-6">
        <el-form-item prop="toDate" label="Ngày kết thúc">
          <el-date-picker
              v-model="form.toDate"
              :disabled-date="(time) => disabledStartDate(time, form.fromDate) "
              class="w-100"
              type="datetime"
              placeholder="Ngày kết thúc"
              format="DD/MM/YYYY HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
      </div>
      <!-- <div class="col-6">
        <el-form-item prop="deadline" label="Ngày hết hạn">
          <el-date-picker
              v-model="form.deadline"
              :disabled-date="(time) => disabledStartDate(time, form.toDate) "
              class="w-100"
              type="datetime"
              placeholder="Ngày hết hạn "
              format="DD/MM/YYYY HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
      </div> -->
      <div class="col-3">
        <el-form-item label="Loại bài thi">
          <el-radio-group v-model="form.isHackathon">
            <el-radio :label="configs.status.inActive" size="lage" border>
              Bài thi dự án
            </el-radio>
            <el-radio :label="configs.status.active" size="lage" border>
              Bài thi hackathon
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="col-3">
        <el-form-item label="Trạng thái hoạt động">
          <el-radio-group v-model="form.isActive">
            <el-radio :label="configs.status.active" size="lage" border>
              {{ configs.status.activeStr }}
            </el-radio>
            <el-radio :label="configs.status.inActive" size="lage" border>
              {{ configs.status.inActiveStr }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
    <el-form-item prop="description">
      <div slot="label"><span class="text-danger">*</span> <b style="color: #606266">Nội dung câu hỏi</b></div>
      <el-col :span="24">
        <div :class="{'hasErr': descriptionValid}">
          <editorTiny v-model="form.description"></editorTiny>
          <small style="color: #f56c6c" v-if="descriptionValid">{{ Messages.NULL }}</small>
        </div>
      </el-col>
    </el-form-item>
  </el-form>
</template>

<script>
import {textInputValidate} from "@/validator";
import editorTiny from "@/components/editor"
import batchSelect from "@/views/Batches/batchSelect";
import courseSelect from "@/views/Course/courseSelect";


export default {
  expose: ['validForm', 'queryByName'],
  name: "InfoAssignment",
  components: {
    batchSelect,
    courseSelect,
    editorTiny
  },
  props: {
    form: Object
  },
  watch: {
    async 'searchParams.batch_id'() {
      this.form.material_id = null
      await this.$refs.materialSelectBox.resetData()
      await this.$refs.materialSelectBox.searchData(this.searchParams.batch_id)
    }
  },
  data() {
    return {
      validation: false,
      courseValid: false,
      batchValid: false,
      descriptionValid: false,
      addContentRule: {
        name: [textInputValidate],
        // deadline: [textInputValidate],
        toDate: [textInputValidate],
        fromDate: [textInputValidate],
        description: [textInputValidate],
      },
    }
  },
  methods: {
    async validForm() {
      this.batchValid = this.form.batchId == '' || this.form.batchId == null
      this.materialValid = this.form.materialId == '' || this.form.materialId == null
      let valid = await this.$refs.contentForm.validate((valid, fields) => {
        if (!valid || this.materialValid || this.batchValid) {
          return false;
        }
      })
      return valid;
    },
    disabledStartDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() < dateNew.getTime()
      }
    },
    disabledEndDate(date, departureDate) {
      if (departureDate) {
        let dateNew = new Date(departureDate);
        return date.getTime() > dateNew.getTime()
      }
    },
    queryByName() {
      let data = this.$helpers.getContentAssignment()
      this.$refs.batchSelectBox.queryByName(data.nameBatch)
      this.$refs.courseSelectBox.queryByName(data.nameCourse)
    }
  }
}
</script>
<script setup>
import configs from "@/configs";
import Messages from "@/constants/messages";
</script>
<style scoped>

</style>

<template>
  <div class="bg-white p-3" v-loading="setup.isLoading">
    <div class="row">
      <div class="col-3">
        <batch-select :validation="setup.batchValid" v-model="setup.batch_id" ref="batchSelectBox"
                      :is-multiple="false"></batch-select>
      </div>
      <div class="col-3">
        <course-select :validation="setup.courseValid" v-model="setup.course_id" ref="courseSelectBox"
                       :is-multiple="false"></course-select>
      </div>
      <div class="col-2 " v-if="dataImport.length < 1">
        <label>Chọn bảng điểm</label>
        <el-upload
            v-model:file-list="setup.fileList"
            class="upload-demo"
            limit="1"
            action="/"
            ref="uploadFile"
            :before-upload="beforeAvatarUpload"
        >
          <el-button v-if="setup.fileList.length  < 1" type="primary" :icon="UploadFilled">Chon tệp</el-button>
        </el-upload>
      </div>

    </div>
    <div class="text-right">
      <el-button type="danger" v-on:click="reload()" v-if="dataImport.length > 0">Xóa data</el-button>
    </div>
    <div class="py-3">
      <table class=" table-bordered">
        <tr>
          <th v-for="(th, index) in tableHeader" :style="th.toUpperCase() === 'SĐT'? 'width: 10%': ''" :key="index">
            {{ th }}
          </th>
        </tr>
        <tr v-for="(td, index) in dataImport" :key="index">
          <td v-for="(item, iField) in tableHeader" :key="iField+index">
            <el-tooltip :content="td.msg" placement="bottom"
                        effect="customized" v-if="td.hasErr && item.toUpperCase() === 'SĐT'">
              <div class="border border-danger has-text" v-if=" td[item].value">{{ td[item].value }}</div>
              <div class="border border-danger has-text" v-if="!td[item].value">{{ td[item].value }}</div>
            </el-tooltip>
            <div v-else class="has-text text-center">{{ td[item].value }}</div>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="dataImport.length > 0" class="text-center">
      <handle-button
          label="Tạo bảng điểm"
          eventCode="students.import-students"
          icon="fa fa-plus"
          typeButton="primary"
          @click="createStudents"/>
    </div>
  </div>
</template>
<script>
import * as XLSX from "xlsx";
import {isExcel, notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import configs from "@/configs";
import batchSelect from "@/views/Batches/batchSelect.vue";
import courseSelect from "@/views/Course/courseSelect.vue";
import {CoursePointOfStudent} from "@/services/course";
import {BatchDetail} from "@/services/batches";

export default {
  name: 'ImportStudentPoint',
  components: {
    batchSelect,
    courseSelect
  },
  data() {
    return {
      dataImport: [],
      dataNew: [],
      students: [],
      errFile: false,
      tHead: configs.fieldAccessExcel,
      setup: {
        fileList: [],
        isLoading: false,
        course_id: null,
        batch_id: null,
        roadmap_id: '',
        courseValid: false,
        batchValid: false
      },
      tableHeader: [],
    }
  },
  mixins: [setBreadcrumb],
  created() {
  },
  watch: {
    async 'setup.batch_id'(old) {
      if (!old) return false
      this.setup.isLoading = true
      this.setup.fileList = []
      this.setup.course_id = null
      let batchCurrent = this.$refs.batchSelectBox?.batchCurrent()
      this.setup.roadmap_id = batchCurrent?.roadmap_id
      this.dataImport = []
      this.$refs.courseSelectBox?.searchByBatch({batch_id: old})
      await this.getBatchDetail(old)
    },
  },

  methods: {
    async readerFile(file) {
      this.setup.batchValid = false;
      this.setup.courseValid = false
      if (!this.setup.batch_id) return this.setup.batchValid = true;
      if (!this.setup.course_id) return this.setup.courseValid = true;

      const reader = new FileReader();
      reader.onload = async () => {
        const fileData = reader.result;
        const wb = XLSX.read(fileData, {type: 'binary'});
        wb.SheetNames.forEach((sheetName) => {
          const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.createArray(rowObj);
        })
      };
      reader.readAsBinaryString(file);
    },

    getBatchDetail(batchId) {
      BatchDetail({batch_id: batchId}).then(res => {
        if (res.data.status === 'success') {
          this.students = res.data.data.data.student
        }
        this.setup.isLoading = false
      }).catch(err => {
        this.setup.isLoading = false
        notifyErr()
      })
    },

    async createArray(rowObj) {
      const keyRequired = ['SĐT'];
      let tableHeader = []
      let dataStudent = [];
      let id = new Date().getTime()
      rowObj.map((item, index) => {
        for (const key in item) {
          let keyCurrent = key.toUpperCase();
          if (tableHeader.length === 0) tableHeader.push(key);
          if (tableHeader.length > 0) {
            if (!tableHeader.find(e => e.toUpperCase() === keyCurrent)) tableHeader.push(key)
          }
        }
      })
      rowObj.map((item, index) => {
        dataStudent[id + index] = {};
        tableHeader.map(th => {
          dataStudent[id + index][th] = {
            value: item[th] ?? 0,
            isHackathon: th.toUpperCase().search('HACKATHON') > -1,
            isProduct: th.search('PROJECT') > -1,
            hasErr: false
          }
          if (keyRequired.find(e => e === th.toUpperCase())) {
            dataStudent[id + index][th].hasErr = item[th] === undefined || item[th] == null || item[th] === ''
          }
        })
      })
      this.tableHeader = tableHeader;
      let data = this.validateRecord(dataStudent)
      this.dataImport = data
      return data
    },

    validateRecord(object) {
      let arrayNew = [], array = [], error = {};
      for (const key in object) {
        array.push(object[key])
      }
      array.map((item, index) => {
        error = {}
        item.hasErr = false
        item.msg = '';
        item.msg = ''
        let phoneNumber = ''
        for (let itemKey in item) {
          if (itemKey.toUpperCase() === 'SĐT') {
            phoneNumber = item[itemKey].value
            if (!this.students.find(e => e?.students?.phone_number === phoneNumber)) {
              item.hasErr = true;
              item.msg = 'Không ồn tại SĐT trong lớp';
            }
          }
        }
        item.error = error
      })
      return array
    },

    beforeAvatarUpload(uploadFile) {
      if (!isExcel(uploadFile)) {
        return notifyErr('Vui lòng chọn file excel.')
      }
      this.setup.fileList.push(uploadFile)
      this.readerFile(uploadFile)
      return true
    },
    reload() {
      location.reload()
    },
    async createStudents() {
      this.setup.batchValid = false;
      this.setup.courseValid = false
      if (!this.setup.batch_id) return this.setup.batchValid = true;
      if (!this.setup.course_id) return this.setup.courseValid = true;

      let dataOrigin = this.dataImport, err = false
      let dataSend = [];
      for (let i = 0; i < dataOrigin.length; i++) {
        let item = dataOrigin[i];
        let phoneNumber = '';
        let reviewContent = '';
        if (item.hasErr) return notifyErr('Đang có lỗi xảy ra');
        for (let itemKey in item) {
          if (itemKey.toUpperCase() === 'SĐT') phoneNumber = item[itemKey].value;
          if (itemKey.toUpperCase() === 'NHẬN XÉT') reviewContent = item[itemKey].value;
        }
        for (let itemKey in item) {
          let data = {
            phone_number: phoneNumber,
            content_review: reviewContent,
            roadmap_id: this.setup.roadmap_id,
            course_id: this.setup.course_id,
            batch_id: this.setup.batch_id
          }
          if (itemKey.toUpperCase().search('HACKATHON') > -1) {
            data.is_test_hackathon = 1;
            data.point = item[itemKey].value;
            data.title = itemKey
            dataSend.push(data)
          }
          if (itemKey.toUpperCase().search('PROJECT') > -1) {
            data.is_test_hackathon = 0;
            data.point = item[itemKey].value;
            data.title = itemKey
            dataSend.push(data)
          }
        }
      }
      CoursePointOfStudent({students: dataSend}).then(res => {
        if (res.data.status === 'success') {
          notifySuccess('add', 'Thêm bảng điểm thành công')
          this.reload()
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    }
  }
}
</script>
<script setup>
import {View, UploadFilled, Search} from '@element-plus/icons-vue'
import {ref} from "vue";

const uploadFile = ref('')
</script>
<style lang="scss">
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  color: white;
  background: red;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: red;
  right: 0;
}

table {
  width: 100%;

  th {
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  td {
    .has-text {
      height: auto;
      padding: .45rem;
      overflow: auto;
    }

    .no-text {
      height: auto;
      padding: 1.2rem;
      overflow: auto;
    }
  }
}
</style>
<template>
  <div id="managementFile" class="bg-white p-2">
    <div class="box-search py-2">
      <i>Tìm kiếm theo mục đích</i>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="setup.keyword" placeholder="Tìm kiếm bài học"/>
        </el-col>
        <el-col :span="6">
          <el-input v-model="setup.code" placeholder="Tìm kiếm theo mã"/>
        </el-col>
        <el-col :span="6">
          <roadmap-select v-model="setup.roadmap_id" ref="roadmapSelectBox"/>
        </el-col>
        <el-col :span="6" v-show="isShowModule">
          <select-module :show-label="false" ref="moduleSelectBox" v-model="setup.module_id"/>
        </el-col>
        <el-col :span="6">
          <course-select :show-label="false" ref="courseSelectBox" v-model="setup.course_id"/>
        </el-col>
        <el-col :span="6" v-show="showSection">
          <section-select :show-label="false" ref="sectionSelectBox" v-model="setup.section_id"/>
        </el-col>
        <el-col :span="6">
          <material-select :show-label="false" ref="materialSelectBox" v-model="setup.material_id"
                           :is-multiple="false"/>
        </el-col>
        <el-col :span="6" class="">
          <div class="d-flex">
            <handle-button
                label="Tìm kiếm"
                eventCode="files.get-list-file"
                icon="fa fa-search"
                typeButton="primary"
                @click="searchData"/>

            <handle-button
                type="link"
                label="Tạo tệp"
                eventCode="files.store-file"
                icon="fa fa-plus"
                typeButton="primary"
                :to="{ name: nameRouter.PAGES.CREATE_FILE}"/>

            <handle-button
                label="Làm mới"
                eventCode="files.get-list-file"
                typeButton="warning"
                @click="reload"/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="py-2" v-if="!isPass"><span class="text-danger">Vui lòng chọn tệp đính kèm.</span></div>
    <el-table
        :data="tableData"
        style="width: 100%"
        fixed
        @select="handleSelected"
        @selection-change="handleSelectAll"
        v-loading="loading"
        border
    >
      <el-table-column type="selection" width="55" v-if="showCheckbox"/>
      <el-table-column fixed prop="id" label="Id" min-width="50"/>
      <el-table-column fixed prop="title" label="Tên tệp" min-width="150"/>
      <el-table-column prop="code" label="Mã code" min-width="100"/>
      <el-table-column prop="purpose_of_uses_str" label="Mục đích" min-width="100"/>
      <el-table-column prop="type" label="Loại tệp " min-width="120">
        <template #default="scope">
          <el-button
              type="warning"
              size="small">
            {{ CONFIG_APP.type[scope.row.type] + ' ' + scope.row.extension.toUpperCase() }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="bài kiểm tra" min-width="120">
        <template #default="scope">
          <el-button
              type="warning"
              v-if="scope.row.is_submission_available"
              size="small">
            cần nộp
          </el-button>
          <el-button
              type="info"
              v-if="!scope.row.is_submission_available"
              size="small">
            Không cần nộp
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="Thời gian tạo" min-width="100"/>
      <el-table-column label="Hành động" min-width="120">
        <template #default="scope">
          <div class="d-flex">
            <handle-button
                label="Xem tệp"
                :disabled="scope.row.type === 3"
                eventCode="files.update-file"
                icon="fa fa-eye"
                typeButton="success"
                @click="openBrowser(scope.row)"/>
            <handle-button
                label="Chỉnh sửa"
                eventCode="files.update-file"
                icon="fa fa-edit"
                typeButton="primary"
                @click="setFileContent(scope.row)"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import CONFIG_APP from "@/configs";
import {formatDate, notifyErr, notifySuccess} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {CreateFile, GetFile, GetFiles, UploadFile} from "@/services/common";
import roadmapSelect from "@/components/roadmapSelect";
import selectModule from "@/views/ManagementModule/selectModule";
import courseSelect from "@/views/Course/courseSelect";
import sectionSelect from "@/views/ManagementSection/sectionSelect";
import materialSelect from "@/views/ManagementMaterial/materialSelect";
import logger from "@fortawesome/vue-fontawesome/src/logger";

export default {
  name: "FileCheckBox",
  expose: ['validFile'],
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: true}
  },

  components: {
    pagination,
    roadmapSelect,
    courseSelect,
    sectionSelect,
    selectModule,
    materialSelect
  },
  data() {
    return {
      setup: {
        keyword: null,
        roadmap_id: null,
        module_id: null,
        course_id: null,
        material_id: null,
        section_id: null
      },
      tableData: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      dialogFormVisible: false,
      nameRouter: NameRouter,
      fileList: [],
      selected: [],
      isPass: true,
      typeOfFile: 1,
      isShowModule: false,
      showSection: false
    };
  },

  watch: {
    selected() {
      this.$emit('update:modelValue', this.selected)
    },
    tableData: {
      handler(newTable) {
        let that = this
        that.$nextTick(function () {
          that.toggleSelection()
        })
      },
      immediate: true
    },
    'setup.roadmap_id'() {
      this.setup.module_id = null
      this.setup.course_id = null
      this.setup.material_id = null
      let roadmap = this.$refs.roadmapSelectBox?.getRoadmapById(this.setup.roadmap_id)
      let hasModule = 1
      this.isShowModule = false
      if (roadmap?.type === hasModule) {
        this.isShowModule = true
        this.$refs.moduleSelectBox?.searchByRoadmap(this.setup.roadmap_id)
      }
      this.$refs.courseSelectBox?.search({roadmap_id: this.setup.roadmap_id})
    },
    'setup.module_id'() {
      this.setup.course_id = null
      this.$refs.courseSelectBox?.search({module_id: this.setup.module_id})
    },
    'setup.course_id'() {
      this.setup.section_id = null
      let course = this.$refs.courseSelectBox?.getCourseCurrent()
      let hasSection = 1
      this.showSection = false;
      if (course.type === hasSection) {
        this.showSection = true
        this.$refs.sectionSelectBox?.searchByCourse(this.setup.course_id)
      }
    },
    'setup.section_id'() {
      this.setup.material_id = null
      this.$refs.materialSelectBox?.searchBy({course_id: this.setup.course_id, section_id: this.section_id})
    },
  },

  created() {
    let query = this.$router.currentRoute?.value?.query;

    let {
      keyword,
      roadmap_id,
      module_id,
      course_id,
      material_id,
      section_id, isShowModule, showSection
    } = query;

    if (keyword ||
        roadmap_id ||
        module_id ||
        course_id ||
        material_id ||
        section_id || isShowModule || showSection) {
      this.setup = query
      this.getListFile()
    } else {
      this.getListFile()
    }
    if (isShowModule === "true") this.isShowModule = true


    if (showSection === "true") this.showSection = true


  },
  methods: {
    getListFile() {
      this.loading = true
      let perPage = {per_page: configs.itemOnPage, page: this.currentPage, ...this.setup}
      GetFiles(perPage).then(e => {
        if (e.data.status == 'success') {
          let dataTable = [];
          let extension = (url) => {
            let extension = ''
            if (!url) return extension
            let arr = url.split('.')
            if (url.length < 1) return extension
            extension = arr[arr.length - 1]
            if (arr.length > 1) return extension
          }

          e.data.data.listAllFile.data.map(e => {
            dataTable.push({
              id: e.id,
              title: e.name,
              code: e.code,
              purpose_of_uses_str: CONFIG_APP.type_of_purpose[e.purpose_of_uses],
              purpose_of_uses: e.purpose_of_uses,
              url: e.full_url,
              type: e.type,
              path: e.url,
              pathUrl: e.path,
              content: e.content,
              is_submission_available: e.is_submission_available === configs.submissionFile,
              createdAt: e.created_at ? formatDate(e.created_at) : '',
              extension: e.type === this.typeOfFile ? extension(e.url) : ''
            })
          })
          this.tableData = dataTable;
          this.total = e.data.data.listAllFile.total
          this.isShowPagination = e.data.data.listAllFile.total > 0
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        notifyErr()
      })
    },
    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getListFile(index);
    },

    openBrowser(row) {
      if (row.type == 2) {
        return window.open(row.path, "", "width=300, height=300")
      }
      GetFile({file_path: row.path}).then(el => {
        if (el.data.status == 'success') {
          let fullUrl = el.data.data.fileURL
          window.open(fullUrl,
              "", "width=300, height=300")
        }
      }).catch(err => {
        notifyErr(err.response.message)
      })
    },
    async uploadFile() {
      let formData = new FormData
      formData.append('file', this.file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          this.form.url = e.data.data.filePath
          path = e.data.data.filePath
        }
        return path
      })
    },
    beforeAvatarUpload(uploadFile) {
      this.fileList.push(uploadFile)
      this.file = uploadFile
      this.uploadFile()
      return true
    },
    async saveInfoFile() {
      let valid = await this.$refs.formFile.validate((valid, fields) => {
        if (!valid) {
          return false;
        }
      });
      if (!valid) return false;

      CreateFile(this.form).then(el => {
        if (el.data.status == 'success') {
          notifySuccess()
          this.getListFile()
          this.dialogFormVisible = false
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex(id => row.id == id)
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1)
      }
      if (indexItem < 0) {
        this.selected.push(row.id)
      }
      this.$emit('update:modelValue', this.selected)
    },
    handleSelectAll(val) {
      if (val) {
        val.map(e => {
          if (this.selected.findIndex(id => e.id == id) > -1) return
          this.selected.push(e.id)
        })
        this.$emit('update:modelValue', this.selected)
      }
    },
    toggleSelection(table) {
      if (this.selected.length < 1) {
        return false
      }
      this.selected.forEach(e => {
        let item = this.tableData.find(item => e == item.id)
        if (!item) return false;
        this.$refs.modules.toggleRowSelection(item);
      })
    },
    validFile() {
      if (this.selected.length < 1) {
        this.isPass = false
      } else {
        this.isPass = true
      }
      return this.isPass
    },
    searchData() {
      this.currentPage = 1
      this.getListFile();
      if (this.isShowModule) this.setup = {...this.setup, isShowModule: true}
      if (this.showSection) this.setup = {...this.setup, showSection: true}
      this.$router.replace({path: location.pathname, query: this.setup})
      let query = this.$router.currentRoute?.value?.query;
      console.log(query)
    },

    async setFileContent(row) {
      await this.$helpers.setContentFile(row)
      await this.$router.push({name: NameRouter.PAGES.DETAIL_FILE, params: {id: row.id}})
    },
    reload() {
      this.setup.keyword = '';
      this.getListFile()
      this.$router.push({path: location.pathname, query: {}})
    }
  }
}

</script>
<script setup>
import {View, UploadFilled, Search} from '@element-plus/icons-vue'
</script>

<style scoped lang="scss">
#managementStudent {
  .addStudent {
    :deep(.el-dialog__body) {
      padding-top: 0;
    }
  }
}
</style>

<template>
 <div class="bg-white p-3">
   <info-file ref="infoFileNew" :form="form"></info-file>
   <div class="text-center">
     <handle-button
         label="Thêm tệp"
         eventCode="files.store-file"
         icon="fa fa-plus"
         typeButton="primary"
         @click="saveInfoFile"/>
   </div>
 </div>
</template>
<script>
import infoFile from "@/views/ManagerFile/infoFile";
import {CreateFile} from "@/services/common";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import configs from "@/configs";
import {NameRouter} from "@/constants/nameRouter";

export default {
  name: 'CreateFile',
  components: {
    infoFile
  },
  data() {
    return {
      form: {
        url: '',
        name: '',
        type: '1',
        content: '',
        is_submission_available: 0,
        is_skip:0,
      },
    }
  },
  mixins: [setBreadcrumb],
  methods: {
    async saveInfoFile() {
      let valid = await this.$refs.infoFileNew.validForm()
      if (!valid) return false;
      let param = this.form
      param.is_submission_available = this.form.is_submission_available ? 1 : '0'
      param.is_skip = this.form.is_skip ? 1 : '0'
      if (this.form.type == 3) {
        param.url.replace(configs.baseUrlImage, "");
      }
      CreateFile(this.form).then(el => {
        if (el.data.status == 'success') {
          notifySuccess('add')
          this.$router.push({name: NameRouter.PAGES.LIST_FILE})
        }
      }).catch(err => {
        notifyErr(err.response.data.message)
      })
    },
  }

}
</script>
<script setup>
import {ref} from 'vue'

const infoFileNew = ref('')
</script>

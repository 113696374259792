<template>
 <router-view></router-view>
</template>
<script>


export default {
  name: "CourseIndex",
  watch: {},
}

</script>

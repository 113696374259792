import {NameRouter} from "@/constants/nameRouter";
import ListStudents from "@/views/Students/listStudents.vue";
import ListBatches from "@/views/Batches/listBatches.vue";
import ListFacility from "@/views/Facility/listFacility";
import ListRoom from "@/views/Room/listRoom";
import ListSchedule from "@/views/Schedule/listSchedule";
import CreateRoom from "@/views/Room/createRoom";
import CreateSchedule from "@/views/Schedule/createSchedule";
import CreateScheduleInDay from "@/views/Schedule/createScheduleInDay";
import HomeBatch from "@/views/Batches/index.vue";
import FacilityBatch from "@/views/Facility/index";
import RoomBatch from "@/views/Room/index";
import AddStudentsToBatch from "@/views/Batches/addStudentsToBatch.vue";
import DetailBatch from "@/views/Batches/detailBatch.vue";
import scoreBoard from "@/views/Batches/scoreBoard.vue";
import QuestionCreate from "@/views/Exam/questionCreate.vue";
import ExamCreate from "@/views/Exam/createExam.vue";
import checkInAtBatch from "@/views/Batches/checkinAtBatch.vue";
import RollCall from "@/views/RollCall/rollCall.vue";
import ListPoint from "@/views/Batches/listPoint";
import ListExam from "@/views/Exam/listExam.vue";
import ExamDetail from "@/views/Exam/examDetail";
import ExamReport from "@/views/Exam/examReport";
import ManagementExam from "@/views/Exam";
import Roadmap from "@/router/routers/roadmap";
import AssignmentManager from "@/views/AssigmentFile";
import ListAssignment from "@/views/AssigmentFile/assiginments";
import CreateAssignment from "@/views/AssigmentFile/createAssignment";
import createScoreboardStd from "@/views/AssigmentFile/createScoreboardStd";
import ListAssignmentFile from "@/views/AssigmentFile/listFileOfStudent";
import gradingTeacher from "@/views/AssigmentFile/gradingTeacher";
import studentList from "@/views/AssigmentFile/studentList"
import ImportStudent from "@/views/Students/importStudent";
import DetailStudent from "@/views/Students/detailStudent";
import DetailAssigment from "@/views/AssigmentFile/DetailAssigment";
import ManagementTopic from "@/views/MangementTopic";
import ListTopic from "@/views/MangementTopic/listTopic";
import DetailTopic from "@/views/MangementTopic/detailTopic";
import ManagementStudent from "@/views/Students";
import ManagementUser from "@/views/ManagementUser";
import ListUser from "@/views/ManagementUser/listUser";
import CreateUser from "@/views/ManagementUser/createUser";
import DetailUser from "@/views/ManagementUser/detailUser";
import AddTeacherToBatch from "@/views/Batches/addTeacherToBatch";
import AddUsersToFacility from "@/views/Facility/addUsersToFacility";
import AddClassToFacility from "@/views/Facility/addClassToFacility";
import ErrorPage from "@/router/routers/errorPage";
import ReportMaterial from "@/views/ReportMaterial";
import ListRatting from "@/views/ReportMaterial/listRatting";
import ManagerReport from "@/views/ManagerReport";
import MarkingMaterial from "@/views/ReportMaterial/markingMaterial";
import RolePermission from "@/views/ManagementUser/rolePermission";
import RollCallReport from "@/views/ManagerReport/rollCallReport";
import RollCallToday from "@/views/Batches/rollCallToday";
import reportAssignment from "@/views/Batches/reportAssignment.vue";
import EventManagement from "@/views/Event/index"
import ScheduleManagement from '@/views/Schedule/index'
import ListEvent from "@/views/Event/listEvent";
import CreateEvent from "@/views/Event/createEvent"
import DetailDayEvent from "@/views/Event/detailDayEvent"
import DetailDaySchedule from "@/views/Schedule/detailDaySchedule";
import InfoScheduleUpdate from "@/views/Schedule/updateSchedule";
import DetailAuth from "@/views/Auth/detailAuth";
import ChangePassword from "@/views/Auth/changePassword";
import DetailFacility from "@/views/Facility/detailFacility";
import DetailRoom from "@/views/Room/detailRoom"
import BlogManagement from "@/views/BlogManagement";
import ListBlog from "@/views/BlogManagement/listBlog";
import CreateBlog from "@/views/BlogManagement/createBlog";
import DetailBlog from "@/views/BlogManagement/detailBlog";
import ManagementSetting from "@/views/ManagementSetting";
import CreateSetting from "@/views/ManagementSetting/createSetting";
import ListSetting from "@/views/ManagementSetting/listSetting";
import HistoriesHomeworks from "@/views/ManagerReport/historiesHomeworks";
import importPointTable from "@/views/Batches/importPointTable.vue";

export default [
  {
    path: "/",
    name: NameRouter.PAGES.HOME_PAGE,
    NameRouter,
    meta: {
      alias: NameRouter.ALIAS.HOME_PAGE,
    },
    component: () => import("@/views/index"),
    children: [
      ...ErrorPage,
      {
        path: "/" + NameRouter.PAGES.DASH_BOARD,
        name: NameRouter.PAGES.DASH_BOARD,
        alias: NameRouter.ALIAS.DASH_BOARD,
        component: FacilityBatch,
        meta: {
          icon: "fa fa-list-ol",
          alias: NameRouter.ALIAS.DASH_BOARD,
          parentName: NameRouter.PAGES.DASH_BOARD,
          code: "",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.CHECKIN_AT_BATCH};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.CHECKIN_AT_BATCH,
            name: NameRouter.PAGES.CHECKIN_AT_BATCH,
            alias: NameRouter.ALIAS.CHECKIN_AT_BATCH,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.CHECKIN_AT_BATCH,
              code: "roll-call-reports.roll-call-for-student",
            },
            component: checkInAtBatch,
          },
          {
            path: "/" + NameRouter.PAGES.REPORT_ASSIGNMENT,
            name: NameRouter.PAGES.REPORT_ASSIGNMENT,
            alias: NameRouter.ALIAS.REPORT_ASSIGNMENT,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.REPORT_ASSIGNMENT,
              code: "batch_reports.record-homework-for-student",
            },
            component: reportAssignment,
          },

        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_STUDENTS,
        name: NameRouter.PAGES.MANAGEMENT_STUDENTS,
        alias: NameRouter.ALIAS.MANAGEMENT_STUDENTS,
        meta: {
          icon: "far fa-id-card",
          alias: NameRouter.ALIAS.MANAGEMENT_STUDENTS,
          code: "students.list-student",
        },
        component: ManagementStudent,
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_STUDENTS};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_STUDENTS,
            name: NameRouter.PAGES.LIST_STUDENTS,
            alias: NameRouter.ALIAS.LIST_STUDENTS,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.LIST_STUDENTS,
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              code: "students.list-student",
            },
            component: ListStudents,
          },
          {
            path: "/" + NameRouter.PAGES.IMPORT_STUDENT,
            name: NameRouter.PAGES.IMPORT_STUDENT,
            alias: NameRouter.ALIAS.IMPORT_STUDENT,
            meta: {
              icon: "fa fa-plus",
              alias: NameRouter.ALIAS.IMPORT_STUDENT,
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              code: "students.import-students",
            },
            component: ImportStudent,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_STUDENT + "/:id",
            name: NameRouter.PAGES.DETAIL_STUDENT,
            alias: NameRouter.ALIAS.DETAIL_STUDENT,
            meta: {
              icon: "fa fa-plus",
              isDetail: true,
              alias: NameRouter.ALIAS.DETAIL_STUDENT,
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              code: "students.get-detail-student",
              documentManager: true,
            },
            component: DetailStudent,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_BATCHES,
        name: NameRouter.PAGES.MANAGEMENT_BATCHES,
        alias: NameRouter.ALIAS.MANAGEMENT_BATCHES,
        component: HomeBatch,
        meta: {
          icon: "fa fa-list",
          alias: NameRouter.ALIAS.MANAGEMENT_BATCHES,
          parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
          code: "batches.get-all-batch",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_BATCHES};
                },
                children: [
                    {
                        path: "/" + NameRouter.PAGES.LIST_BATCHES,
                        name: NameRouter.PAGES.LIST_BATCHES,
                        alias: NameRouter.ALIAS.LIST_BATCHES,
                        meta: {
                            icon: "fa fa-list",
                            alias: NameRouter.ALIAS.LIST_BATCHES,
                            parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
                            code: "batches.get-all-batch",
                        },
                        component: ListBatches,
                    },
                    {
                        path: "/" + NameRouter.PAGES.ADD_STUDENT_TO_BATCH + "/:id",
                        alias: NameRouter.ALIAS.ADD_STUDENT_TO_BATCH,
                        name: NameRouter.PAGES.ADD_STUDENT_TO_BATCH,
                        meta: {
                            isDetail: true,
                            alias: NameRouter.ALIAS.ADD_STUDENT_TO_BATCH,
                            parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
                            code: "batches.assign-batch",
                        },
                        component: AddStudentsToBatch,
                    },
                    {
                        path: "/" + NameRouter.PAGES.ADD_TEACHER_TO_BATCH + "/:id",
                        alias: NameRouter.ALIAS.ADD_TEACHER_TO_BATCH,
                        name: NameRouter.PAGES.ADD_TEACHER_TO_BATCH,
                        meta: {
                            isDetail: true,
                            alias: NameRouter.ALIAS.ADD_TEACHER_TO_BATCH,
                            parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
                            code: "batches.assign-teacher",
                        },
                        component: AddTeacherToBatch,
                    },
                    {
                        path: "/" + NameRouter.PAGES.BATCH_DETAIL + "/:id",
                        name: NameRouter.PAGES.BATCH_DETAIL,
                        alias: NameRouter.ALIAS.BATCH_DETAIL,
                        meta: {
                            parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
                            isDetail: true,
                            alias: NameRouter.ALIAS.BATCH_DETAIL,
                            code: "batches.get-detail-batch",
                        },
                        component: DetailBatch,
                    },
                    {
                        path: "/" + NameRouter.PAGES.SCORE_BOARD + "/:id",
                        name: NameRouter.PAGES.SCORE_BOARD,
                        alias: NameRouter.ALIAS.SCORE_BOARD,
                        meta: {
                            parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
                            isDetail: true,
                            alias: NameRouter.ALIAS.SCORE_BOARD,
                            code: "batches.score-board",
                        },
                        component: scoreBoard,
                    },
                    {
                        path: "/" + NameRouter.PAGES.REPORT_MATERIAL,
                        name: NameRouter.PAGES.LIST_RATTING,
                        alias: NameRouter.ALIAS.REPORT_MATERIAL,
                        meta: {
                            icon: "fas fa-chalkboard-teacher",
                            alias: NameRouter.ALIAS.REPORT_MATERIAL,
                            code: "comments.index",
                        },
                        component: ReportMaterial,
                        redirect: (to) => {
                            return {name: NameRouter.PAGES.LIST_RATTING};
            },
            children: [
              {
                path: "/" + NameRouter.PAGES.LIST_RATTING,
                name: NameRouter.PAGES.LIST_RATTING,
                alias: NameRouter.ALIAS.LIST_RATTING,
                meta: {
                  icon: "fas fa-book-reader",
                  parentName: NameRouter.PAGES.REPORT_MATERIAL,
                  alias: NameRouter.ALIAS.LIST_RATTING,
                  code: "material-ratings.index",
                },
                component: ListRatting,
              },
              {
                path: "/" + NameRouter.PAGES.MARKING_MATERIAL,
                name: NameRouter.PAGES.MARKING_MATERIAL,
                meta: {
                  icon: "fa fa-user",
                  parentName: NameRouter.PAGES.REPORT_MATERIAL,
                  alias: NameRouter.ALIAS.MARKING_MATERIAL,
                  code: "student-files.get-list-student-file",
                },
                component: MarkingMaterial,
              },
            ],
          },
          {
            path: "/" + NameRouter.PAGES.LIST_POINT,
            name: NameRouter.PAGES.LIST_POINT,
            alias: NameRouter.ALIAS.LIST_POINT,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "",
              alias: NameRouter.ALIAS.LIST_POINT,
              code: "students.list-student",
            },
            component: ListPoint,
          },
          {
            path: "/" + NameRouter.PAGES.CHECKIN_AT_BATCH,
            name: NameRouter.PAGES.CHECKIN_AT_BATCH,
            alias: NameRouter.ALIAS.CHECKIN_AT_BATCH,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.CHECKIN_AT_BATCH,
              code: "roll-call-reports.roll-call-for-student",
            },
            component: checkInAtBatch,
          },

          {
            path: "/" + NameRouter.PAGES.ROLL_CALL,
            name: NameRouter.PAGES.ROLL_CALL,
            alias: NameRouter.ALIAS.ROLL_CALL,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.ROLL_CALL,
              code: "off-learn-reasons.index",
            },
            component: RollCall,
          },
          {
            path: "/" + NameRouter.PAGES.ROLL_CALL_TODAY,
            name: NameRouter.PAGES.ROLL_CALL_TODAY,
            alias: NameRouter.ALIAS.ROLL_CALL_TODAY,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.ROLL_CALL_TODAY,
              code: "off-learn-reasons.index",
            },
            component: RollCallToday,
          },
          {
            path: "/" + NameRouter.PAGES.REPORT_ASSIGNMENT,
            name: NameRouter.PAGES.REPORT_ASSIGNMENT,
            alias: NameRouter.ALIAS.REPORT_ASSIGNMENT,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_BATCHES,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.REPORT_ASSIGNMENT,
              code: "off-learn-reasons.index",
            },
            component: reportAssignment,
          },
          {
            path: "/" + NameRouter.PAGES.IMPORT_POINT,
            name: NameRouter.PAGES.IMPORT_POINT,
            alias: NameRouter.ALIAS.IMPORT_POINT,
            meta: {
              parentName: NameRouter.PAGES.IMPORT_POINT,
              icon: "far fa-edit",
              alias: NameRouter.ALIAS.IMPORT_POINT,
              code: "off-learn-reasons.index",
            },
            component: importPointTable,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_EXAM,
        name: NameRouter.PAGES.MANAGEMENT_EXAM,
        alias: NameRouter.ALIAS.MANAGEMENT_EXAM,
        meta: {
          icon: "fas fa-tasks",
          alias: NameRouter.ALIAS.MANAGEMENT_EXAM,
          code: "exams.index",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_EXAM};
        },
        component: ManagementExam,
        children: [
          {
            path: "/" + NameRouter.PAGES.DETAIL_EXAM + "/:id",
            name: NameRouter.PAGES.DETAIL_EXAM,
            alias: NameRouter.ALIAS.DETAIL_EXAM,
            meta: {
              icon: "fas fa-tasks",
              alias: NameRouter.ALIAS.DETAIL_EXAM,
              isDetail: true,
              parentName: NameRouter.PAGES.MANAGEMENT_EXAM,
              code: "exams.update-exam",
            },
            component: ExamDetail,
          },
          {
            path: "/" + NameRouter.PAGES.LIST_EXAM,
            name: NameRouter.PAGES.LIST_EXAM,
            alias: NameRouter.ALIAS.LIST_EXAM,
            meta: {
              icon: "far fa-address-book",
              alias: NameRouter.ALIAS.LIST_EXAM,
              parentName: NameRouter.PAGES.MANAGEMENT_EXAM,
              code: "exams.index",
            },
            component: ListExam,
          },
          {
            path: "/" + NameRouter.PAGES.QUESTION_CREATE,
            alias: NameRouter.ALIAS.QUESTION_CREATE,
            meta: {
              icon: "far fa-check-circle",
              alias: NameRouter.ALIAS.QUESTION_CREATE,
              parentName: NameRouter.PAGES.MANAGEMENT_EXAM,
              code: "questions.store",
            },

            name: NameRouter.PAGES.QUESTION_CREATE,
            component: QuestionCreate,
          },
          {
            path: "/" + NameRouter.PAGES.EXAM_CREATE,
            name: NameRouter.PAGES.EXAM_CREATE,
            meta: {
              icon: "fas fa-book",
              alias: NameRouter.ALIAS.EXAM_CREATE,
              parentName: NameRouter.PAGES.MANAGEMENT_EXAM,
              code: "exams.store",
            },
            alias: NameRouter.ALIAS.EXAM_CREATE,
            component: ExamCreate,
          },
          {
            path: "/" + NameRouter.PAGES.EXAM_REPORT + "/:id",
            name: NameRouter.PAGES.EXAM_REPORT,
            alias: NameRouter.ALIAS.EXAM_REPORT,
            meta: {
              icon: "fas fa-tasks",
              alias: NameRouter.ALIAS.EXAM_REPORT,
              parentName: NameRouter.PAGES.MANAGEMENT_EXAM,
              isDetail: true,
              code: "exams.exam-report",
            },
            component: ExamReport,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.ASSIGNMENT_MANAGER,
        name: NameRouter.PAGES.ASSIGNMENT_MANAGER,
        alias: NameRouter.ALIAS.ASSIGNMENT_MANAGER,
        meta: {
          icon: "fas fa-laptop-code",
          alias: NameRouter.ALIAS.ASSIGNMENT_MANAGER,
          code: "assignments.index",
        },
        component: AssignmentManager,
        redirect: (to) => {
                    return {name: NameRouter.PAGES.ASSIGNMENT_LIST};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.ASSIGNMENT_MANAGER,
            name: NameRouter.PAGES.ASSIGNMENT_LIST,
            alias: NameRouter.ALIAS.ASSIGNMENT_LIST,
            meta: {
              icon: "fa fa-user",
              alias: NameRouter.ALIAS.ASSIGNMENT_LIST,
              parentName: NameRouter.PAGES.ASSIGNMENT_MANAGER,
              code: "assignments.index",
            },
            component: ListAssignment,
          },
          {
            path: "/" + NameRouter.PAGES.ASSIGNMENT_CREATE,
            name: NameRouter.PAGES.ASSIGNMENT_CREATE,
            alias: NameRouter.ALIAS.ASSIGNMENT_CREATE,
            meta: {
              icon: "fas fa-book-open",
              alias: NameRouter.ALIAS.ASSIGNMENT_CREATE,
              parentName: NameRouter.PAGES.ASSIGNMENT_MANAGER,
              code: "assignments.store",
            },
            component: CreateAssignment,
          },
          {
            path: "/" + NameRouter.PAGES.SCORE_BOARD_STD,
            name: NameRouter.PAGES.SCORE_BOARD_STD,
            alias: NameRouter.ALIAS.SCORE_BOARD_STD,
            meta: {
              icon: "fas fa-book-open",
              alias: NameRouter.ALIAS.SCORE_BOARD_STD,
              parentName: NameRouter.PAGES.ASSIGNMENT_MANAGER,
              code: "assignments.store",
            },
            component: createScoreboardStd,
          },
          {
            path: "/" + NameRouter.PAGES.ASSIGNMENT_FILE_STUDENT + "/:id",
            name: NameRouter.PAGES.ASSIGNMENT_FILE_STUDENT,
            alias: NameRouter.ALIAS.ASSIGNMENT_FILE_STUDENT,
            meta: {
              icon: "fa fa-user",
              parentName: NameRouter.PAGES.MANAGEMENT_STUDENTS,
              isDetail: true,
              alias: NameRouter.ALIAS.ASSIGNMENT_FILE_STUDENT,
              code: "assignments.show",
            },
            component: ListAssignmentFile,
          },
          {
                        path:"/" + NameRouter.PAGES.GRADING_TEACHER +"/:id",
            name: NameRouter.PAGES.GRADING_TEACHER,
            alias: NameRouter.PAGES.GRADING_TEACHER,
                        meta:{
                            icon :"fa fa-user",
                            parentName:NameRouter.PAGES.ASSIGNMENT_MANAGER,
              isDetail: true,
              alias: NameRouter.ALIAS.GRADING_TEACHER,
              code: "assignments.show",
            },
            component: gradingTeacher,
          },
          {
                        path:"/" + NameRouter.PAGES.STUDENT_LIST +"/:id",
            name: NameRouter.PAGES.STUDENT_LIST,
            alias: NameRouter.PAGES.STUDENT_LIST,
                        meta:{
                            icon :"fa fa-user",
                            parentName:NameRouter.PAGES.ASSIGNMENT_MANAGER,
              isDetail: true,
              alias: NameRouter.ALIAS.STUDENT_LIST,
              code: "assignments.show",
            },
            component: studentList,
          },
          {
            path: "/" + NameRouter.PAGES.ASSIGNMENT_DETAIL + "/:id",
            name: NameRouter.PAGES.ASSIGNMENT_DETAIL,
            alias: NameRouter.ALIAS.ASSIGNMENT_DETAIL,
            meta: {
              icon: "fa fa-user",
              isDetail: true,
              parentName: NameRouter.PAGES.ASSIGNMENT_MANAGER,
              alias: NameRouter.ALIAS.ASSIGNMENT_DETAIL,
              code: "assignments.show",
            },
            component: DetailAssigment,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.TOPIC_MANAGER,
        name: NameRouter.PAGES.TOPIC_MANAGER,
        alias: NameRouter.ALIAS.TOPIC_MANAGER,
        meta: {
          icon: "fas fa-chalkboard-teacher",
          alias: NameRouter.ALIAS.TOPIC_MANAGER,
          code: "comments.index",
        },
        component: ManagementTopic,
        redirect: (to) => {
                    return {name: NameRouter.PAGES.TOPIC_LIST};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.TOPIC_LIST,
            name: NameRouter.PAGES.TOPIC_LIST,
            alias: NameRouter.ALIAS.TOPIC_LIST,
            meta: {
              icon: "fas fa-book-reader",
              parentName: NameRouter.PAGES.TOPIC_MANAGER,
              alias: NameRouter.ALIAS.TOPIC_LIST,
              code: "comments.index",
            },
            component: ListTopic,
          },
          {
            path: "/" + NameRouter.PAGES.TOPIC_DETAIL + "/:id",
            name: NameRouter.PAGES.TOPIC_DETAIL,
            meta: {
              icon: "fa fa-user",
              isDetail: true,
              parentName: NameRouter.PAGES.TOPIC_MANAGER,
              alias: NameRouter.ALIAS.TOPIC_DETAIL,
              code: "comments.show",
            },
            component: DetailTopic,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_USER,
        name: NameRouter.PAGES.MANAGEMENT_USER,
        alias: NameRouter.ALIAS.MANAGEMENT_USER,
        meta: {
          icon: "fas fa-user",
          alias: NameRouter.ALIAS.MANAGEMENT_USER,
          code: "users.list-user",
        },
        component: ManagementUser,
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_USER};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_USER,
            name: NameRouter.PAGES.LIST_USER,
            alias: NameRouter.ALIAS.LIST_USER,
            meta: {
              icon: "fas fa-book-reader",
              parentName: NameRouter.PAGES.MANAGEMENT_USER,
              alias: NameRouter.ALIAS.LIST_USER,
              code: "users.list-user",
            },
            component: ListUser,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_USER,
            name: NameRouter.PAGES.CREATE_USER,
            meta: {
              icon: "fa fa-user",
              parentName: NameRouter.PAGES.MANAGEMENT_USER,
              alias: NameRouter.ALIAS.CREATE_USER,
              code: "users.create-user",
            },
            component: CreateUser,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_USER + "/:id",
            name: NameRouter.PAGES.DETAIL_USER,
            meta: {
              icon: "fa fa-user",
              parentName: NameRouter.PAGES.MANAGEMENT_USER,
              alias: NameRouter.ALIAS.DETAIL_USER,
              isDetail: true,
              code: "users.get-detail-teacher",
            },
            component: DetailUser,
          },
          {
            path: "/" + NameRouter.PAGES.ROLE_PERMISSION,
            name: NameRouter.PAGES.ROLE_PERMISSION,
            meta: {
              icon: "fa fa-user",
              parentName: NameRouter.PAGES.MANAGEMENT_USER,
              alias: NameRouter.ALIAS.ROLE_PERMISSION,
              code: "users.get-detail-teacher",
            },
            component: RolePermission,
          },
          {
            path: "/" + NameRouter.AUTH.DETAIL_ADMIN,
            name: NameRouter.AUTH.DETAIL_ADMIN,
            meta: {
              parentName: NameRouter.AUTH.ME,
              alias: NameRouter.ALIAS.AUTH.ME,
              code: "auth.get-current-user-permission",
              isDetail: true,
            },
            component: DetailAuth,
          },
          {
            path: "/" + NameRouter.AUTH.CHANGE_PASSWORD,
            name: NameRouter.AUTH.CHANGE_PASSWORD,
            meta: {
              code: "auth.change-password",
              alias: NameRouter.ALIAS.AUTH.CHANGE_PASSWORD,
              parentName: NameRouter.AUTH.ME,
                            isDetail: true
            },
            component: ChangePassword,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.REPORT_MATERIAL,
        name: NameRouter.PAGES.REPORT_MATERIAL,
        alias: NameRouter.ALIAS.REPORT_MATERIAL,
        meta: {
          icon: "fas fa-chalkboard-teacher",
          alias: NameRouter.ALIAS.REPORT_MATERIAL,
          code: "comments.index",
        },
        component: ReportMaterial,
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_RATTING};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_RATTING,
            name: NameRouter.PAGES.LIST_RATTING,
            alias: NameRouter.ALIAS.LIST_RATTING,
            meta: {
              icon: "fas fa-book-reader",
              parentName: NameRouter.PAGES.REPORT_MATERIAL,
              alias: NameRouter.ALIAS.LIST_RATTING,
              code: "material-ratings.index",
            },
            component: ListRatting,
          },
          {
            path: "/" + NameRouter.PAGES.MARKING_MATERIAL,
            name: NameRouter.PAGES.MARKING_MATERIAL,
            meta: {
              icon: "fa fa-user",
              parentName: NameRouter.PAGES.REPORT_MATERIAL,
              alias: NameRouter.ALIAS.MARKING_MATERIAL,
              code: "student-files.get-list-student-file",
            },
            component: MarkingMaterial,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.REPORT_MANAGEMENT.INDEX,
        name: NameRouter.PAGES.REPORT_MANAGEMENT.INDEX,
        alias: NameRouter.ALIAS.REPORT_MANAGEMENT.INDEX,
        meta: {
          icon: "fas fa-chalkboard-teacher",
          alias: NameRouter.ALIAS.REPORT_MANAGEMENT.INDEX,
          code: "comments.index",
        },
        component: ManagerReport,
        redirect: (to) => {
                    return {name: NameRouter.PAGES.REPORT_MANAGEMENT.STUDY};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.REPORT_MANAGEMENT.STUDY,
            name: NameRouter.PAGES.REPORT_MANAGEMENT.STUDY,
            alias: NameRouter.ALIAS.REPORT_MANAGEMENT.STUDY,
            meta: {
              icon: "fas fa-book-reader",
              parentName: NameRouter.PAGES.REPORT_MANAGEMENT.INDEX,
              alias: NameRouter.ALIAS.REPORT_MANAGEMENT.STUDY,
              code: "material-ratings.index",
            },
            component: HistoriesHomeworks,
          },
          {
            path: "/" + NameRouter.PAGES.REPORT_MANAGEMENT.ROLL_CALL,
            name: NameRouter.PAGES.REPORT_MANAGEMENT.ROLL_CALL,
            alias: NameRouter.ALIAS.REPORT_MANAGEMENT.ROLL_CALL,
            meta: {
              icon: "fas fa-book-reader",
              parentName: NameRouter.PAGES.REPORT_MANAGEMENT.INDEX,
              alias: NameRouter.ALIAS.REPORT_MANAGEMENT.ROLL_CALL,
              code: "material-ratings.index",
            },
            component: RollCallReport,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_FACILITY,
        name: NameRouter.PAGES.MANAGEMENT_FACILITY,
        alias: NameRouter.ALIAS.MANAGEMENT_FACILITY,
        component: FacilityBatch,
        meta: {
          icon: "fa fa-list-ol",
          alias: NameRouter.ALIAS.MANAGEMENT_FACILITY,
          parentName: NameRouter.PAGES.MANAGEMENT_FACILITY,
          code: "",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_FACILITY};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_FACILITY,
            name: NameRouter.PAGES.LIST_FACILITY,
            alias: NameRouter.ALIAS.LIST_FACILITY,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.LIST_FACILITY,
              parentName: NameRouter.PAGES.MANAGEMENT_FACILITY,
              code: "",
            },
            component: ListFacility,
          },
          {
            path: "/" + NameRouter.PAGES.FACILITY_DETAIl + "/:id",
            name: NameRouter.PAGES.FACILITY_DETAIl,
            alias: NameRouter.ALIAS.FACILITY_DETAIL,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_FACILITY,
              isDetail: true,
              alias: NameRouter.ALIAS.FACILITY_DETAIL,
              code: "",
            },
            component: DetailFacility,
          },
          {
            path: "/" + NameRouter.PAGES.ADD_USERS_TO_FACILITY + "/:id",
            alias: NameRouter.ALIAS.ADD_USERS_TO_FACILITY,
            name: NameRouter.PAGES.ADD_USERS_TO_FACILITY,
            meta: {
              isDetail: true,
              alias: NameRouter.ALIAS.ADD_USERS_TO_FACILITY,
              parentName: NameRouter.PAGES.MANAGEMENT_FACILITY,
              code: "",
            },
            component: AddUsersToFacility,
          },
          {
            path: "/" + NameRouter.PAGES.ADD_CLASS_TO_FACILITY + "/:id",
            alias: NameRouter.ALIAS.ADD_CLASS_TO_FACILITY,
            name: NameRouter.PAGES.ADD_CLASS_TO_FACILITY,
            meta: {
              isDetail: true,
              alias: NameRouter.ALIAS.ADD_CLASS_TO_FACILITY,
              parentName: NameRouter.PAGES.MANAGEMENT_FACILITY,
              code: "",
            },
            component: AddClassToFacility,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_EVENT,
        name: NameRouter.PAGES.MANAGEMENT_EVENT,
        alias: NameRouter.ALIAS.MANAGEMENT_EVENT,
        component: EventManagement,
        meta: {
          icon: "fa fa-calendar",
          alias: NameRouter.ALIAS.MANAGEMENT_EVENT,
          parentName: NameRouter.PAGES.MANAGEMENT_EVENT,
          code: "events.index",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_EVENT};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_EVENT,
            name: NameRouter.PAGES.LIST_EVENT,
            alias: NameRouter.ALIAS.LIST_EVENT,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.LIST_EVENT,
              parentName: NameRouter.PAGES.MANAGEMENT_EVENT,
              code: "events.list-events",
            },
            component: ListEvent,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_EVENT,
            name: NameRouter.PAGES.CREATE_EVENT,
            alias: NameRouter.ALIAS.CREATE_EVENT,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.CREATE_EVENT,
              parentName: NameRouter.PAGES.MANAGEMENT_EVENT,
              code: "events.create-events",
            },
            component: CreateEvent,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_EVENT_IN_DAY + "/:id",
            name: NameRouter.PAGES.CREATE_EVENT_IN_DAY,
            alias: NameRouter.ALIAS.CREATE_EVENT_IN_DAY,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.CREATE_EVENT_IN_DAY,
              parentName: NameRouter.PAGES.MANAGEMENT_EVENT,
              code: "events.create-events",
              isDetail: true,
            },
            component: CreateEvent,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_DAY_EVENT + "/:id",
            name: NameRouter.PAGES.DETAIL_DAY_EVENT,
            alias: NameRouter.ALIAS.DETAIL_DAY_EVENT,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.DETAIL_DAY_EVENT,
              parentName: NameRouter.PAGES.MANAGEMENT_EVENT,
              code: "events.detail-events",
              isDetail: true,
            },
            component: DetailDayEvent,
          },

        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_ROOM,
        name: NameRouter.PAGES.MANAGEMENT_ROOM,
        alias: NameRouter.ALIAS.MANAGEMENT_ROOM,
        component: RoomBatch,
        meta: {
          icon: "fa fa-hotel",
          alias: NameRouter.ALIAS.MANAGEMENT_ROOM,
          parentName: NameRouter.PAGES.MANAGEMENT_ROOM,
          code: "",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_ROOM};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_ROOM,
            name: NameRouter.PAGES.LIST_ROOM,
            alias: NameRouter.ALIAS.LIST_ROOM,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.LIST_ROOM,
              parentName: NameRouter.PAGES.MANAGEMENT_ROOM,
              code: "rooms.index",
            },
            component: ListRoom,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_ROOM,
            name: NameRouter.PAGES.CREATE_ROOM,
            alias: NameRouter.ALIAS.CREATE_ROOM,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.CREATE_ROOM,
              parentName: NameRouter.PAGES.MANAGEMENT_ROOM,
              code: "rooms.store",
            },
            component: CreateRoom,
          },
          {
            path: "/" + NameRouter.PAGES.ROOM_DETAIL + "/:id",
            name: NameRouter.PAGES.ROOM_DETAIL,
            alias: NameRouter.ALIAS.ROOM_DETAIL,
            meta: {
              parentName: NameRouter.PAGES.MANAGEMENT_ROOM,
              isDetail: true,
              alias: NameRouter.ALIAS.ROOM_DETAIL,
              code: "rooms.show",
            },
            component: DetailRoom,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGAMENT_SCHEDULE,
        name: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
        alias: NameRouter.ALIAS.MANAGAMENT_SCHEDULE,
        component: ScheduleManagement,
        meta: {
          icon: "fa fa-hotel",
          alias: NameRouter.ALIAS.MANAGAMENT_SCHEDULE,
          parentName: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
          code: "schedules.index",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_SCHEDULE};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_SCHEDULE,
            name: NameRouter.PAGES.LIST_SCHEDULE,
            alias: NameRouter.ALIAS.LIST_SCHEDULE,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.LIST_SCHEDULE,
              parentName: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
              code: "schedules.index",
            },
            component: ListSchedule,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_SCHEDULE,
            name: NameRouter.PAGES.CREATE_SCHEDULE,
            alias: NameRouter.ALIAS.CREATE_SCHEDULE,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.CREATE_SCHEDULE,
              parentName: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
              code: "schedules.store",
            },
            component: CreateSchedule,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_SCHEDULE_IN_DAY + "/:id",
            name: NameRouter.PAGES.CREATE_SCHEDULE_IN_DAY,
            alias: NameRouter.ALIAS.CREATE_SCHEDULE_IN_DAY,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.CREATE_SCHEDULE_IN_DAY,
              parentName: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
              code: "schedules.store",
              isDetail: true,
            },
            component: CreateScheduleInDay,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_DAY_SCHEDULE + "/:id",
            name: NameRouter.PAGES.DETAIL_DAY_SCHEDULE,
            alias: NameRouter.ALIAS.DETAIL_DAY_SCHEDULE,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.DETAIL_DAY_SCHEDULE,
              parentName: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
              code: "schedules.show",
              isDetail: true,
            },
            component: DetailDaySchedule,
          },
          {
            path: "/" + NameRouter.PAGES.DETAIL_SCHEDULE + "/:id",
            name: NameRouter.PAGES.DETAIL_SCHEDULE,
            alias: NameRouter.ALIAS.DETAIL_SCHEDULE,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.DETAIL_SCHEDULE,
              parentName: NameRouter.PAGES.MANAGAMENT_SCHEDULE,
              code: "schedules.show",
              isDetail: true,
            },
            component: InfoScheduleUpdate,
          },
        ],
      },
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_BLOG,
        name: NameRouter.PAGES.MANAGEMENT_BLOG,
        alias: NameRouter.ALIAS.MANAGEMENT_BLOG,
        component: BlogManagement,
        meta: {
          icon: "fa-light fa-book",
          alias: NameRouter.ALIAS.MANAGEMENT_BLOG,
          parentName: NameRouter.PAGES.MANAGEMENT_BLOG,
          code: "",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.LIST_BLOG};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.LIST_BLOG,
            name: NameRouter.PAGES.LIST_BLOG,
            alias: NameRouter.ALIAS.LIST_BLOG,
            meta: {
              icon: "fa fa-list",
              alias: NameRouter.ALIAS.LIST_BLOG,
              parentName: NameRouter.PAGES.MANAGEMENT_BLOG,
              code: "",
            },
            component: ListBlog,
          },
          {
            path: "/" + NameRouter.PAGES.CREATE_BLOG,
            name: NameRouter.PAGES.CREATE_BLOG,
            alias: NameRouter.ALIAS.CREATE_BLOG,
            meta: {
              icon: "",
              alias: NameRouter.ALIAS.CREATE_BLOG,
              parentName: NameRouter.PAGES.MANAGEMENT_BLOG,
              code: "",
            },
            component: CreateBlog,
          },
          {
                        path: "/" + NameRouter.PAGES.DETAIL_BLOG + '/:id',
            name: NameRouter.PAGES.DETAIL_BLOG,
            alias: NameRouter.ALIAS.DETAIL_BLOG,
            meta: {
              isDetail: true,
              icon: "",
              alias: NameRouter.ALIAS.DETAIL_BLOG,
              parentName: NameRouter.PAGES.MANAGEMENT_BLOG,
              code: "",
            },
            component: DetailBlog,
                    }
        ],
      },
      ...Roadmap,
      {
        path: "/" + NameRouter.PAGES.MANAGEMENT_SETTING,
        name: NameRouter.PAGES.MANAGEMENT_SETTING,
        alias: NameRouter.ALIAS.MANAGEMENT_SETTING,
        component: ManagementSetting,
        meta: {
          icon: "fa fa-cog",
          alias: NameRouter.ALIAS.MANAGEMENT_SETTING,
          parentName: NameRouter.PAGES.MANAGEMENT_SETTING,
          code: "system-infos.index",
        },
        redirect: (to) => {
                    return {name: NameRouter.PAGES.CREATE_SETTING};
        },
        children: [
          {
            path: "/" + NameRouter.PAGES.CREATE_SETTING,
            name: NameRouter.PAGES.CREATE_SETTING,
            alias: NameRouter.ALIAS.CREATE_SETTING,
            meta: {
              icon: "",
              alias: NameRouter.ALIAS.CREATE_SETTING,
              parentName: NameRouter.PAGES.MANAGEMENT_SETTING,
              code: "system-infos.created",
            },
            component: CreateSetting,
          },
          {
            path: "/" + NameRouter.PAGES.LIST_SETTING,
            name: NameRouter.PAGES.LIST_SETTING,
            alias: NameRouter.ALIAS.LIST_SETTING,
            meta: {
              icon: "",
              alias: NameRouter.ALIAS.LIST_SETTING,
              parentName: NameRouter.PAGES.MANAGEMENT_SETTING,
              code: "system-infos.index",
            },
            component: ListSetting,
          },
        ],
      },
    ],
  },
]

<template>
  <div id="management-course" class="bg-white py-2">
    <div class="search py-2">
      <el-row :gutter="10">
        <el-col :span="6" :offset="18" class="text-right">
          <handle-button
              type="link"
              label="Chi tiết cơ sở"
              eventCode=""
              icon="fa fa-edit"
              typeButton="primary"
              :to="{ name: nameRouter.PAGES.FACILITY_DETAIl, params:{id: idBatch}}"/>
          <handle-button
              type="link"
              label="Thêm lớp học"
              eventCode=""
              icon="fa fa-edit"
              typeButton="primary"
              :to="{ name: nameRouter.PAGES.ADD_CLASS_TO_FACILITY, params:{id: idBatch}}"/>

        </el-col>
      </el-row>
    </div>
    <el-table
        ref="modules"
        v-loading="loading"
        :data="tableData"
        fixed
        style="width: 100%"
        border
        @select="handleSelected"
        @selection-change="handleSelectAll"
    >
      <el-table-column v-if="showCheckbox" type="selection" width="55"/>
      <el-table-column prop="name" label="Tên người dùng" width="200"/>
      <el-table-column prop="email" label="Email" min-width="150"/>
      <el-table-column prop="role" label="Quyền hạn" min-width="120">
        <template #default="scope">
          <div>{{ scope.row.description }} ({{ scope.row.role }})</div>
        </template>
      </el-table-column>
      <el-table-column
          label="Tình trạng"
          width="150">
        <template #default="scope">
          <el-button
              type="success"
              size="small"
              v-if="scope.row.usersHadFacility">
            Đã có trong cơ sở
          </el-button>
          <el-button
              type="warning"
              size="small"
              v-if="!scope.row.usersHadFacility">
            Chưa có trong cơ sở
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="isActive" label="Hoạt động" width="100">
        <template #default="scope">
          <div class="text-center">
            <el-checkbox v-model="scope.row.isActive" disabled/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Loại hình" width="200">
        <template #default="scope">
          <span>{{ configs.typeOfUser[scope.row.isTeacher] }}</span>
        </template>
      </el-table-column>
      <el-table-column
          min-width="100">
        <template #default="scope">
          <el-button
              type="warning"
              size="small"
              @click="addToFacility(scope.row.id)"
              :disabled="scope.row.usersHadFacility"
          >
            Thêm vào cơ sở
          </el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="pb-3 text-center">
      <pagination
          v-show="isShowPagination"
          :total="total"
          :page-index="pageIndex"
          @currentPageChange="nextPage"
      />
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import configs from "@/configs";
import {notifyErr, notifySuccess, setBreadcrumb} from "@/mixins/utils";
import {NameRouter} from "@/constants/nameRouter";
import {GetListUser} from "@/services/users";
import {AddUserToFacility, GetDetailFacility} from "@/services/facility";

export default {
  name: "ListUser",
  expose: ["validMaterial"],
  components: {
    pagination,
  },
  mixins: [setBreadcrumb],
  props: {
    modelValue: Array,
    showCheckbox: {type: Boolean, default: false},
  },
  data() {
    return {
      idBatch: this.$route.params.id,
      arrayFacilityHasUser: [],
      isPass: true,
      tableData: [],
      listRole: [],
      selected: [],
      isShowPagination: false,
      currentPage: 1,
      pageIndex: 1,
      total: 0,
      loading: false,
      nameRouter: NameRouter,
      setup: {
        keyword: null,
        role_level: "",
        is_active: "",
        dialogAdd: false
      },
      roleAdmin: 1,
      configs: configs,
      userId: null,
      model: {
        password: "",
        rePassword: "",
      },
    };
  },
  watch: {
    selected() {
      this.$emit("update:modelValue", this.selected);
    },
    tableData: {
      handler(newTable) {
        let that = this;
        that.$nextTick(function () {
          that.toggleSelection();
        });
      },
      immediate: true,
    },
  },

  async created() {
    await this.getUsers();
  },
  methods: {
    async getUsers(params) {
      this.loading = true;
      await GetDetailFacility(this.idBatch).then(res => {
        if (res.data.status == 'success') {
          let arrayUserExist = res.data.data.branchDetail[0].user?.map(e => e.id)
          this.arrayFacilityHasUser = arrayUserExist
        }
      })
      if (!params) {
        params = {
          per_page: configs.itemOnPage,
          page: this.currentPage,
          keyword: this.setup.keyword,
        };
      }

      await GetListUser(params)
          .then((e) => {
            if (e.data.status == "success") {
              let dataTable = [];
              e.data.data.list_user.data.map((e) => {
                dataTable.push({
                  id: e.id,
                  name: e.name,
                  isActive: configs.status.active === e.is_active,
                  userName: e.username,
                  role: e.role.name,
                  description: e.role.description,
                  email: e.email,
                  usersHadFacility: this.arrayFacilityHasUser.includes(e.id),
                  isTeacher: e.role ? e.role.is_teacher : 0
                });
              });
              this.tableData = dataTable;
              this.total = e.data.data.list_user.total;
              this.isShowPagination = e.data.data.list_user.total > 0;
              this.selected = this.modelValue;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            notifyErr();
          });
    },
    getInFoFacilityDetail(param) {
      this.loading = true

    },
    addToFacility(id) {
      this.loading = true;
      let param = {
        branch_id: this.idBatch,
        user_ids: [id]
      };

      AddUserToFacility(param).then(res => {
        if (res.data.status == 'success') {
          this.getUsers()
          notifySuccess("add")
        }
      }).catch(err => {
        return notifyErr(err.response.data.message)
      })

      this.loading= false


    },

    nextPage(index) {
      this.currentPage = index;
      this.pageIndex = index;
      this.getUsers();
    },
    handleSelected(selected, row) {
      let indexItem = this.selected.findIndex((id) => row.id == id);
      if (indexItem > -1) {
        this.selected.splice(indexItem, 1);
      }
      if (indexItem < 0) {
        this.selected.push(row.id);
      }
      this.$emit("update:modelValue", this.selected);
    },
    handleSelectAll(val) {
      if (val) {
        val.map((e) => {
          if (this.selected.findIndex((id) => e.id == id) > -1) return;
          this.selected.push(e.id);
        });
        this.$emit("update:modelValue", this.selected);
      }
    },
    toggleSelection(table) {
      if (!this.selected) {
        return false;
      }
      this.selected.forEach((e) => {
        let item = this.tableData.find((item) => e == item.id);
        if (!item) return false;
        this.$refs.modules.toggleRowSelection(item);
      });
    },
    validMaterial() {
      if (this.selected.length < 1) {
        this.isPass = false;
      } else {
        this.isPass = true;
      }
      return this.isPass;
    },

    reload() {
      this.setup = {
        keyword: null,
        role_level: "",
        is_active: "",
      };
      this.getUsers();
    },


  },
};
</script>
<script setup>
import CONFIG_APP from "@/configs";
import {ref} from "vue";
import {Search} from "@element-plus/icons-vue";
import {Lock} from "@element-plus/icons-vue";
import HandleButton from "@/components/button";

const dialogAdd = ref(false);
</script>
<style scoped lang="scss">
::v-deep .cell {
  word-break: break-word !important;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.input_password {
  width: 100%;
  display: flex;
}
</style>

<template>
  <el-form
      ref="contentForm"
      label-position="top"
      :model="form"
      :rules="addContentRule"
  >
    <el-form-item class="w-100" prop="hometown" label="Quê quán">
      <el-input v-model="form.hometown"></el-input>
    </el-form-item>
    <el-form-item class="w-100" prop="certificate" label="Giấy chứng nhận">
      <div class="border w-100 py-2 px-1 rounded">
        <el-tag
            v-for="tag in form.certificate"
            :key="tag"
            class="mx-1"
            closable
            :disable-transitions="false"
            @close="handleClose(tag, 'certificate')"
        >
          {{ tag }}
        </el-tag>
        <input
            v-if="certificateVisible"
            ref="certificate"
            id="certificate"
            class="ml-1 w-25"
            size="small"
            @keyup.enter="handleInputConfirm('certificate')"
            @blur="handleInputConfirm('certificate')"
        />
        <el-button
            v-else
            class="button-new-tag ml-1"
            size="small"
            @click="showInput('certificate')"
        >
          + Thêm giấy chứng nhận
        </el-button>
      </div>
    </el-form-item>
    <el-form-item class="w-100" prop="education" label="Hệ giáo dục">
      <div class="border w-100 py-2 px-1 rounded">
        <el-tag
            v-for="tag in form.education"
            :key="tag"
            class="mx-1"
            closable
            :disable-transitions="false"
            @close="handleClose(tag, 'education')"
        >
          {{ tag }}
        </el-tag>
        <input
            v-if="educationVisible"
            ref="education"
            id="education"
            class="ml-1 w-25"
            size="small"
            @keyup.enter="handleInputConfirm('education')"
            @blur="handleInputConfirm('education')"
        />
        <el-button
            v-else
            class="button-new-tag ml-1"
            size="small"
            @click="showInput('education')"
        >
          + Hệ giáo dục
        </el-button>
      </div>
    </el-form-item>
    <el-form-item class="w-100" prop="school" label="Trường học">
      <div class="border w-100 py-2 px-1 rounded">
        <el-tag
            v-for="tag in form.school"
            :key="tag"
            class="mx-1"
            closable
            :disable-transitions="false"
            @close="handleClose(tag, 'school')"
        >
          {{ tag }}
        </el-tag>
        <input
            v-if="schoolVisible"
            ref="school"
            id="school"
            class="ml-1 w-25"
            size="small"
            @keyup.enter="handleInputConfirm('school')"
            @blur="handleInputConfirm('school')"
        />
        <el-button
            v-else
            class="button-new-tag ml-1"
            size="small"
            @click="showInput('school')"
        >
          + Trường học
        </el-button>
      </div>
    </el-form-item>
    <el-form-item label="Ghi chú" prop="note">
      <editorTiny type="textarea" v-model="form.note" class="w-100"/>
    </el-form-item>
  </el-form>
  <div class="dialog-footer d-flex justify-content-end">
    <handle-button
        v-if="!form.student_id"
        @click="createStudentInfo"
        label="Tạo"
        event-code="student-info.store"
        icon="fa fa-plus"
        type-button="primary"
    />
    <handle-button
        v-if="form.student_id"
        @click="updateStudentInfo"
        label="Cập nhật"
        event-code="student-info.update"
        icon="fa fa-edit"
        type-button="primary"
    />
  </div>
</template>
<script>
import {textInputValidate, ValidService} from "@/validator";
import editorTiny from "@/components/editor";
import {
  CreateStudentInfo,
  UpdateStudentInfoExtend,
} from "@/services/students";
import {notifyErr, notifySuccess} from "@/mixins/utils";

export default {
  name: "Info-Student",
  components: {
    editorTiny
  },
  props: {
    form: Object,
  },
  data() {
    return {
      addContentRule: {
        hometown: [textInputValidate],
      },
      isDetail: true,
      certificateVisible: false,
      educationVisible: false,
      schoolVisible: false,
    };
  },
  created() {
  },
  methods: {
    // crete Student
    async createStudentInfo() {
      let valid = await this.$refs.contentForm.validate();
      if (!valid) return notifyErr("Vui lòng điền đầy đủ thông tin.");
      let params = {
        student_id: this.$route.params.id,
        hometown: this.form.hometown,
        certificate: this.form.certificate?.join(","),
        education: this.form.education,
        school: this.form.school,
        note: this.form.note,
      };
      CreateStudentInfo(params)
          .then((el) => {
            if (el.data.status == "success") {
              notifySuccess("add", "Tạo thông tin thành công");
            }
          })
          .catch((err) => {
            notifyErr(err.response.data.message);
          });
    },

    // update Student
    async updateStudentInfo() {
      event.preventDefault();
      let valid = await this.$refs.contentForm.validate();
      if (!valid) return notifyErr("Vui lòng điền đầy đủ thông tin.");
      let params = {
        hometown: this.form.hometown,
        certificate: this.form.certificate?.join(';'),
        education: this.form.education?.join(';'),
        school: this.form.school?.join(';'),
        note: this.form.note,
      };
      UpdateStudentInfoExtend(params, this.form.student_id)
          .then((res) => {
            if (res.data.status == "success") {
              notifySuccess("edit");
            }
          })
          .catch((err) => {
            notifyErr(err.response.data.message);
          });
    },

    handleInputConfirm(type) {
      let visible = type + 'Visible'
      if (this[visible]) {
        let inputValue = document.getElementById(type).value;
        if (inputValue) {
          this.form[type].push(inputValue);
        }
        document.getElementById(type).focus();
        document.getElementById(type).value = "";
      }
      this[visible] = false;
    },
    handleClose(tag, type) {
      this.form[type].splice(this.form[type].indexOf(tag), 1);
    },
    showInput(type) {
      let visible = type + 'Visible'
      this[visible] = true;
    },
  },
};
</script>
<script setup>
import configs from "@/configs";
import {ref, reactive} from "vue";
import HandleButton from "@/components/button";

const certificate = ref();
</script>
<style scoped></style>

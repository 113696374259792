<template>
  <div>
    <el-form :rules="roadmapForm" label-position="top" :model="infoRoadmap" ref="formRoadmap">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="text-bold">Ảnh đại diện
            <span class="text-danger">*</span>
          </div>
          <div class="py-3">
            <el-form-item prop="image">
              <el-upload
                  ref="avatar"
                  :limit="1"
                  class="avatar-uploader"
                  :class="imageUrl? 'disable-event': '' "
                  :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar">
                <el-icon v-if="!imageUrl" class="avatar-uploader-icon">
                  <Plus/>
                </el-icon>
              </el-upload>
            </el-form-item>
            <div v-if="imageUrl" class="ml-lg-5 pl-lg-5 pl-md-5">
              <el-button type="danger" size="small" class="p-1">
                <el-icon>
                  <Delete/>
                </el-icon>
                <span class="ml-2" @click="handleRemove()">Xóa ảnh</span>
              </el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <el-form-item label="Tên lộ trình" prop="title">
            <el-input type="text" v-model="infoRoadmap.title"/>
          </el-form-item>
          <el-form-item label="Mã code" prop="code">
            <el-input type="text" v-model="infoRoadmap.code"/>
          </el-form-item>
          <el-form-item label="Thời gian(phút)" prop="duration">
            <el-input type="text" v-model="infoRoadmap.duration"/>
          </el-form-item>
          <el-form-item label="Vị trí hiển thị" prop="sort_order">
            <el-input type="text" v-model="infoRoadmap.sort_order"/>
          </el-form-item>
          <el-form-item label="Phiên bản" prop="version">
            <el-input type="text" v-model="infoRoadmap.version"/>
          </el-form-item>
          <el-form-item label="Nội dung loại" prop="type" @change="$emit('resetContent')">
            <el-radio-group v-model="infoRoadmap.type">
              <el-radio :label="1" size="lage" border>
                có mô đun
              </el-radio>
              <el-radio :label="2" size="lage" border>
                Không có mô đun
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Trạng thái hoạt động">
            <el-radio-group v-model="infoRoadmap.is_active">
              <el-radio :label="config.status.active" size="lage" border>
                {{ config.status.activeStr }}
              </el-radio>
              <el-radio :label="config.status.inActive" size="lage" border>
                {{ config.status.inActiveStr }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div :class="{'hasErr': descriptionValid}">
            <editorTiny v-model="infoRoadmap.description"/>
            <small v-if="descriptionValid" style="color: #f56c6c">{{ messages.NULL }}</small>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {checkIsInteger, textInputValidate} from "@/validator";
import editorTiny from "@/components/editor";
import {UploadFile} from "@/services/common";
import {isImage, notifyFiled} from "@/mixins/utils";

export default {
  expose: ['validRoadmap', 'setImage'],
  name: 'Info-Roadmap',
  components: {
    editorTiny
  },
  props: {
    infoRoadmap: Object
  },
  data() {
    return {
      roadmapForm: {
        title: [textInputValidate],
        code:[textInputValidate],
        duration: [textInputValidate, checkIsInteger],
        sort_order: [textInputValidate],
        version: [textInputValidate],
        description: [textInputValidate],
        image: [textInputValidate]
      },
      descriptionValid: false,
      imageUrl: ''
    }
  },
  methods: {
    async uploadFile() {
      let formData = new FormData
      formData.append('file', this.file)
      let path = ''
      UploadFile(formData).then(e => {
        if (e.data.status == 'success') {
          this.infoRoadmap.image = e.data.data.filePath
          path = e.data.data.filePath
        }
        return path
      })
    },
    beforeAvatarUpload(uploadFile) {
      if (!isImage(uploadFile)) {
        notifyFiled()
        return false
      }
      this.imageUrl = URL.createObjectURL(uploadFile)
      this.file = uploadFile
      this.uploadFile()
      return true
    },
    handleRemove() {
      this.$refs.avatar.clearFiles()
      this.imageUrl = ''
      this.infoRoadmap.image = ''
    },
    async validRoadmap() {
      this.descriptionValid = this.infoRoadmap.description == ''
      let valid = await this.$refs.formRoadmap.validate((valid, fields) => {
        if (!valid || !this.infoRoadmap.description) {
          return false;
        }
      });
      return valid
    },
    setImage(url) {
      this.imageUrl = url
    }
  }
}
</script>
<script setup>
import messages from "@/constants/messages";
import config from "@/configs";
import {Delete, Plus} from '@element-plus/icons-vue'
</script>
<style lang="scss" scoped>
.avatar-uploader .avatar {
  max-width: 300px;
  max-height: 300px;
  min-height: 250px;
  min-width: 250px;
  display: block;
  position: relative;
}

.action-image {
  background: #000000b5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  pointer-events: none;
  display: none;

  svg {
    color: white;
  }

  .action-delete {
    font-size: 18px;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
}

::v-deep .el-upload:hover {
  .action-image {
    display: block;
  }
}

.hasErr {
  ::v-deep .tox {
    border: 1px solid red;
  }
}

::v-deep .is-error {
  .el-upload {
    border-color: red;
  }
}
</style>